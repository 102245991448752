export enum Roles {
  Admin = 'Admin',
  Ecommerce = 'Ecommerce',
  Operaciones = 'Operaciones',
  OperacionesAdmin = 'Operaciones.Admin',
  Almacen = 'Almacen',
  Completo = 'Completo',
  AmazonInvoices = 'AmazonInvoices',
  Reporting = 'Reporting',
  ChangePassword = 'ChangePassword',
  Aduanas = 'Aduanas',
  Debug = 'Debug',
  Brexit = 'Brexit',
  BrexitBrunel = 'Brexit.Brunel',
  BrexitLlorente = 'Brexit.Llorente',
  Tasks = 'Tasks',
  Tracking = 'Tracking',
  Warehouse = 'Warehouse',
  WarehousePda = 'Warehouse.Pda',
}

import { CustomsExportOperation, CustomsImportOperation, CustomsOperationProperty, CustomsClient } from '../models/customs.model';

export class BrexitShipment {
    id: number = 0;
    editUser: number = 0;
    editDate: Date;
    editUserName: string = "";
    status: number = 0

    customsFileId: number = 0;
    customsFileDescription: string = "";
    customsFileType: string = "";
    customsFileDate: Date = null;

    //clientId: number = 0;
    //clientDescription: string = "";
    //clientGroup: string = "";
    client: CustomsClient = new CustomsClient();
    clientWarehouse: string = "";

    invoiceInfo: string = "";
    truckInfo: string = "";
    customsExportInfo: CustomsExportOperation[] = [];
    customsImportInfo: CustomsImportOperation[] = [];
    properties: BrexitShipmentProperties = new BrexitShipmentProperties();
}

export class BrexitShipmentProperties {
    brexitShipmentId: number = 0;
    //mangoReadyToSendDuaInfo: boolean = false;
    list: CustomsOperationProperty[] = [];
}

/*
export class BrexitShipmentProperty {
    id: number = 0;
    Key: string = "";
    description: string = "";
    value: string = "";
}
*/
<!-- ventana principal -->

<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{'page-administracion-clients-hubs.title' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations' | translate}}
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations-administration' | translate}}
      </li>
      <li class="breadcrumb-item" title="{{'page-administracion-clients.title' | translate}}">
        <a routerLink="/oper/admin/clients">{{'page-navigation.operations-clients' | translate}}</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>{{'page-administracion-clients-hubs.title' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{'page-administracion-clients-hubs.title-description' | translate}}</em></p>
  </div>
</div>


<div class="row">
  <div class="col-sm-12">
    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox" id="clientHubsManagement">
        <div class="ibox-content sk-loading">

          <div class="sk-spinner sk-spinner-pulse" style="width: 75px; height: 75px; top: 100px;"></div>

          <div class="row">

            <div class="col-12">

              <div class="float-right m-b-lg">
                <div class="form-inline">
                  <button class="btn btn-sm btn-primary m-r-sm" type="button"
                    (click)="newClientHubs()">{{'page-administracion-clients-hubs.action-new' | translate}}</button>
                  <div class="input-group">
                    <input style="width: 300px;" type="text" class="form-control input-sm"
                      [(ngModel)]="searchData.value" placeholder="{{'general.search' | translate}}..."
                      (keyup.enter)="getClientHubsList()">

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" (click)="getClientHubsList()" type="button"
                        title="{{'general.search' | translate}}">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" (click)="exportExcel()"
                        title="{{'general.export-excel' | translate}}"> <i class="fa fa-download"
                          aria-hidden="true"></i></button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" data-toggle="collapse"
                        data-parent="#panelSearch" href="#panelAdvancedSearch" aria-expanded="false"
                        title="{{'search.title' | translate}}">
                        <i class="fa fa-wrench" aria-hidden="true"></i>
                      </button>
                    </span>

                  </div>
                </div>
              </div>

              <div id="panelSearch">
                <div id="panelAdvancedSearch" class="panel-collapse collapse">
                  <h2 class="search-panel-title">{{'search.title' | translate}}</h2>
                  <small class="font-bold">{{'search.title-description' | translate}}</small>
                  <div class="hr-line-dashed"></div>

                  <div>
                    <div *ngIf="searchData.showStatusSearch">
                      <label class="checkbox-inline m-r-md">
                        {{'search.showresults' | translate}}:
                      </label>

                      <label class="checkbox-inline m-r-md">
                        <input type="checkbox" [(ngModel)]="searchData.enabled">
                        &nbsp;{{'search.show-active' | translate}}
                      </label>

                      <label class="checkbox-inline">
                        <input type="checkbox" [(ngModel)]="searchData.disabled">
                        &nbsp;{{'search.show-disabled' | translate}}
                      </label>
                    </div>

                    <div *ngIf="searchData.showDateSearch" class="form-group form-inline">
                      <label class="m-r-md">{{'search.daterange' | translate}}:</label>

                      <label class="m-r-md">{{'search.date-from' | translate}}
                        <input type="date" class="form-control form-control-search m-l-sm mr-sm" id="fechadesde">
                      </label>

                      <label>{{'search.date-to' | translate}}
                        <input type="date" class="form-control form-control-search m-l-sm mr-sm">
                      </label>
                    </div>

                  </div>
                  <div class="hr-line-dashed"></div>
                </div>
              </div>

            </div>

            <div class="col-12">
              <p-table [value]="clientHubsList" sortMode="multiple"
                styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [paginator]="true" [rows]="10"
                [showCurrentPageReport]="true" [autoLayout]="true" [(first)]="firstClientHubs"
                currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
                [rowsPerPageOptions]="[10,25,50,100,200]" [columnResizeMode]="'expand'" [reorderableColumns]="true"
                [resizableColumns]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 65px;"></th>
                    <th pSortableColumn="clientName">
                      {{'page-administracion-clients-hubs.columname-client' | translate}}
                      <p-sortIcon field="clientName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="hubSourceName">
                      {{'page-administracion-clients-hubs.columname-hubsource' | translate}}
                      <p-sortIcon field="hubSourceName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="hubTargetName">
                      {{'page-administracion-clients-hubs.columname-hubtarget' | translate}}
                      <p-sortIcon field="hubTargetName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="wareTypeName">
                      {{'page-administracion-clients-hubs.columname-waretype' | translate}}
                      <p-sortIcon field="wareTypeName"></p-sortIcon>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                  <tr [ngClass]="{'row-disable' : item.disable}">
                    <td>
                      <div class="btn-group btn-group-xs">
                        <button class="btn btn-default btn-xs" title="{{'general.clone' | translate}}"
                          (click)="editClientHubs(item)"><i class="fa fa-clone"></i></button>

                        <button *ngIf="item.disable" class="btn btn-default btn-xs"
                          title="{{'general.enable' | translate}}" (click)="setClientHubsEnable(item)"><i
                            class="fa fa-eye"></i></button>
                        <button *ngIf="!item.disable" class="btn btn-default btn-xs"
                          title="{{'general.disable' | translate}}" (click)="setClientHubsDisable(item)"><i
                            class="fa fa-eye-slash"></i></button>

                        <button class="btn btn-default btn-xs" title="{{'general.delete' | translate}}"
                          (click)="deleteClientHubs(item)"><i class="fa fa-trash-o"></i></button>
                      </div>
                    </td>
                    <td>{{item.clientName}}</td>
                    <td>{{item.hubSourceName}}</td>
                    <td>{{item.hubTargetName}}</td>
                    <td>{{item.wareTypeName}}</td>
                  </tr>
                </ng-template>
              </p-table>

            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<!-- ventana modal de edición -->

<div class="modal inmodal fade" id="editClientHubsWindow" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">
          {{'page-administracion-clients-hubs.editwindow-title' | translate}}</h4>
        <small class="font-bold">{{'page-administracion-clients-hubs.editwindow-title-description' | translate}}</small>

      </div>
      <div class="modal-body" id="editClientHubsPanelEdicion">

        <div class="row">
          <div class="col-sm-12">

            <form [formGroup]="clientHubsDataForm">


              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-administracion-clients-hubs.columname-client' | translate}}</label>
                <div class="col-sm-8" [ngClass]="{ 'is-invalid': clientHubsDataFormSubmitted && f.form_tktId.errors }">
                  <select class="form-control input-sm" formControlName="form_tktId" id="form_tktId" style="width:100%;"
                    [ngClass]="{ 'is-invalid': clientHubsDataFormSubmitted && f.form_tktId.errors }">
                    <option *ngFor="let c1 of tktClientList" value="{{c1.id}}">{{c1.name}}</option>
                  </select>
                  <div *ngIf="clientHubsDataFormSubmitted && f.form_tktId.errors" class="invalid-feedback">
                    <div *ngIf="f.form_tktId.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-administracion-clients-hubs.columname-hubsource' | translate}}</label>
                <div class="col-sm-8"
                  [ngClass]="{ 'is-invalid': clientHubsDataFormSubmitted && f.form_hubSourceId.errors }">
                  <select class="form-control input-sm" formControlName="form_hubSourceId" id="form_hubSourceId"
                    style="width:100%;"
                    [ngClass]="{ 'is-invalid': clientHubsDataFormSubmitted && f.form_hubSourceId.errors }">
                    <option *ngFor="let c2 of hubsList" value="{{c2.id}}">({{c2.country}}) {{c2.alias}}</option>
                  </select>
                  <div *ngIf="clientHubsDataFormSubmitted && f.form_hubSourceId.errors" class="invalid-feedback">
                    <div *ngIf="f.form_hubSourceId.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-administracion-clients-hubs.columname-hubtarget' | translate}}</label>
                <div class="col-sm-8"
                  [ngClass]="{ 'is-invalid': clientHubsDataFormSubmitted && f.form_hubTargetId.errors }">
                  <select class="form-control input-sm" formControlName="form_hubTargetId" id="form_hubTargetId"
                    style="width:100%;"
                    [ngClass]="{ 'is-invalid': clientHubsDataFormSubmitted && f.form_hubTargetId.errors }">
                    <option *ngFor="let c3 of hubsList" value="{{c3.id}}">({{c3.country}}) {{c3.alias}}</option>
                  </select>
                  <div *ngIf="clientHubsDataFormSubmitted && f.form_hubTargetId.errors" class="invalid-feedback">
                    <div *ngIf="f.form_hubTargetId.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>
                </div>
              </div>


              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-administracion-clients-hubs.columname-waretype' | translate}}</label>
                <div class="col-sm-8"
                  [ngClass]="{ 'is-invalid': clientHubsDataFormSubmitted && f.form_wareTypeId.errors }">
                  <select class="form-control input-sm" formControlName="form_wareTypeId" id="form_wareTypeId"
                    style="width:100%;"
                    [ngClass]="{ 'is-invalid': clientHubsDataFormSubmitted && f.form_wareTypeId.errors }">
                    <option value="0"></option>
                    <option *ngFor="let i of wareTypeList" value="{{i.id}}">{{i.descripcion}}</option>
                  </select>
                  <div *ngIf="clientHubsDataFormSubmitted && f.form_wareTypeId.errors" class="invalid-feedback">
                    <div *ngIf="f.form_wareTypeId.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>
                </div>
              </div>






              <div class="form-group row">
                <label class="col-sm-3 col-form-label"></label>
                <div class="col-sm-8">
                  <label class="checkbox-inline">
                    <input type="checkbox" formControlName="form_disable">
                    {{'general.disabled' | translate}}</label>
                </div>
              </div>

            </form>
          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>
        <button type="button" class="btn btn-sm btn-primary"
          (click)="saveClientHubsData()">{{'general.save-changes' | translate}}</button>
      </div>
    </div>
  </div>
</div>

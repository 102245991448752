import { DocumentArchive } from '../models/documents.model';

export class HsCodes {
    codigo: string = "";
    descripcion: string = "";
    descripcionIngles: string = "";
    tipoIVA: number = 0;
    tipoUnidadesSuplementarias: string = "";
    pesoMinimoPorUnidad: number = 0;
    pesoMaximoPorUnidad: number = 0;
    vigente: boolean = true;
    requiereSoivre: boolean = false;
    requiereCites: boolean = false;
    dobleUso: boolean = false;
}

export class CustomsAgent {
    id: number = 0;
    name: string = "";
}

export class CustomsClient {
    id: number = 0;
    name: string = "";
    group: string = "";
}

export class CustomsInvoice {
    number: string = "";
    date: Date = null;
}

export class CustomsType {
    id: number = 0;
    description: string = "";
}

export class CustomsExportOperation {
    id: number = 0;
    editUser: number = 0;
    editDate: Date;
    editUserName: string = "";
    status: number = 0

    parent: number = 0;
    agent: CustomsAgent = new CustomsAgent();
    client: CustomsClient = new CustomsClient();
    invoices: CustomsInvoice[] = [];
    customsType: CustomsType = new CustomsType();
    date: Date = null;
    dateRequest: Date = null;
    mrn: string = "";
    truckRegistration: string = "";
    truckCountry: string = "";
    attachments: DocumentArchive[] = [];
    properties: CustomsExportOperationProperties = new CustomsExportOperationProperties();
}

export class CustomsImportOperation {
    id: number = 0;
    editUser: number = 0;
    editDate: Date;
    editUserName: string = "";
    status: number = 0

    parent: number = 0;
    agent: CustomsAgent = new CustomsAgent();
    client: CustomsClient = new CustomsClient();
    invoices: CustomsInvoice[] = [];
    customsType: CustomsType = new CustomsType();
    date: Date = null;
    dateRequest: Date = null;
    mrn: string = "";
    truckRegistration: string = "";
    truckCountry: string = "";
    attachments: DocumentArchive[] = [];
    properties: CustomsImportOperationProperties = new CustomsImportOperationProperties();
}

export class CustomsImportOperationProperties {
    operationId: number = 0;
    clientSentDuaInfo: boolean = false;
    clientSentDuaInfoDate: Date = null;
    customsOperationProperty: CustomsOperationProperty[] = [];
}

export class CustomsExportOperationProperties {
    operationId: number = 0;
    clientSentDuaInfo: boolean = false;
    clientSentDuaInfoDate: Date = null;
    customsOperationProperty: CustomsOperationProperty[] = [];
}

export class CustomsOperationProperty {
    property: string = "";
    description: string = "";
    value: string = "";
}

export class OperationDocument {
    operationParent: number = 0;
    operationCode: number = 0;
    documentCode: number = 0;
    documentTitle: string = '';
}


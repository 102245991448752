<!-- ventana principal -->

<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-sm-8">
      <h2>{{'page-home-tasks.title' | translate}}</h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
        </li>
        <li class="breadcrumb-item">
          {{'page-navigation.operations' | translate}}
        </li>
        <li class="breadcrumb-item">
          {{'page-navigation.tasks' | translate}}
        </li>
      </ol>
    </div>
  
    <div class="col-sm-4 page-description text-right text-muted small">
      <p><em>{{'page-home-tasks.title-description' | translate}}</em></p>
    </div>
  </div>
  
  
  <div class="row">
    <div class="col-sm-12">
      <div class="wrapper wrapper-content animated fadeInUp">
        <div class="ibox" id="tasksManagement">
          <div class="ibox-content sk-loading">
  
            <div class="sk-spinner sk-spinner-pulse" style="width: 75px; height: 75px; top: 100px;"></div>
  
            <div class="row">
  
              <div class="col-12">
  
                <div class="float-right m-b-lg">
                  <div class="form-inline">
                    <button class="btn btn-sm btn-primary m-r-sm" type="button"
                      (click)="newTask()">{{'page-tasks.action-new' | translate}}</button>
                    <div class="input-group">
                      <input style="width: 300px;" type="text" class="form-control input-sm"
                        [(ngModel)]="searchData.value" placeholder="{{'general.search' | translate}}..."
                        (keyup.enter)="getTasksList()">
  
                      <span class="input-group-append">
                        <button class="btn btn-white btn-sm" (click)="getTasksList()" type="button"
                          title="{{'general.search' | translate}}">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                      </span>
  
                      <span class="input-group-append">
                        <button class="btn btn-white btn-sm" type="button" (click)="exportExcel()"
                          title="{{'general.export-excel' | translate}}"> <i class="fa fa-download"
                            aria-hidden="true"></i></button>
                      </span>
  
                      <span class="input-group-append">
                        <button class="btn btn-white btn-sm" type="button" data-toggle="collapse"
                          data-parent="#panelSearch" href="#panelAdvancedSearch" aria-expanded="false"
                          title="{{'search.title' | translate}}">
                          <i class="fa fa-wrench" aria-hidden="true"></i>
                        </button>
                      </span>
  
                    </div>
                  </div>
                </div>
  
                <div id="panelSearch">
                  <div id="panelAdvancedSearch" class="panel-collapse collapse">
                    <h2 class="search-panel-title">{{'search.title' | translate}}</h2>
                    <small class="font-bold">{{'search.title-description' | translate}}</small>
                    <div class="hr-line-dashed"></div>
  
                    <div>
                      <div *ngIf="searchData.showStatusSearch">
                        <label class="checkbox-inline m-r-md">
                          {{'search.showresults' | translate}}:
                        </label>
  
                        <label class="checkbox-inline m-r-md">
                          <input type="checkbox" [(ngModel)]="searchData.enabled">
                          &nbsp;{{'search.show-active' | translate}}
                        </label>
  
                        <label class="checkbox-inline">
                          <input type="checkbox" [(ngModel)]="searchData.disabled">
                          &nbsp;{{'search.show-disabled' | translate}}
                        </label>
                      </div>
  
                      <div *ngIf="searchData.showDateSearch" class="form-group form-inline">
                        <label class="m-r-md">{{'search.daterange' | translate}}:</label>
  
                        <label class="m-r-md">{{'search.date-from' | translate}}
                          <input type="date" [(ngModel)]="searchData.dateFrom" class="form-control form-control-search m-l-sm mr-sm" id="fechadesde">
                        </label>
  
                        <label>{{'search.date-to' | translate}}
                          <input type="date" [(ngModel)]="searchData.dateTo" class="form-control form-control-search m-l-sm mr-sm">
                        </label>
                      </div>
                      <div class="form-group form-inline">
                        <label class="m-r-md" style="width: 100%; justify-content: left;">
                          {{'page-tasks.prioridad' | translate}}:
                          &nbsp;&nbsp;
                          <select class="form-control form-control-search" value="a" style="width: 85%;" multiple="multiple"
                            id="select2PrioFilter">
                            <option *ngFor="let item of prioList" value="{{item.codigo}}">
                              {{item.codigo}}-{{item.descripcion}}
                            </option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div class="hr-line-dashed"></div>
                  </div>
                </div>
  
              </div>
  
              <div class="col-12">
                <p-table [value]="tasksList"  sortMode="multiple"
                  styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [paginator]="true" [rows]="10"
                  [showCurrentPageReport]="true" [autoLayout]="true" [(first)]="firstHubs"
                  currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
                  [rowsPerPageOptions]="[10,25,50,100,200]" [reorderableColumns]="true"
                  [resizableColumns]="false">
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width: 65px;"></th>
                      <th pSortableColumn="descripcion">
                        {{'page-tasks.descripcion' | translate}}
                        <p-sortIcon field="descripcion"></p-sortIcon>
                      </th>
                      <th pResizableColumn>
                        {{'page-tasks.responsable' | translate}}
                        
                      </th>

                      <th pResizableColumn pSortableColumn="fechaAlta">
                        {{'page-tasks.fechaCreacion' | translate}}
                        <p-sortIcon field="fechaAlta"></p-sortIcon>
                      </th>
                      <th pResizableColumn pSortableColumn="cliente">
                        {{'page-tasks.cliente' | translate}}
                        <p-sortIcon field="cliente"></p-sortIcon>
                      </th>
                      <th pResizableColumn pSortableColumn="prioridad">
                        {{'page-tasks.prioridad' | translate}}
                        <p-sortIcon field="prioridad"></p-sortIcon>
                      </th>
                      <th pResizableColumn>
                        {{'page-tasks.estado' | translate}}
                      </th>
                      <th pResizableColumn pSortableColumn="fechaFin">
                        {{'page-tasks.fechaActu' | translate}}
                        <p-sortIcon field="fechaFin"></p-sortIcon>
                      </th>
                      <th pResizableColumn>
                        {{'page-tasks.comment' | translate}}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-tasks>
                    <tr >
                      <td>
                        <div class="btn-group btn-group-xs">
                       <!--<button (click)="editTask(tasks.id)"><i class="fa fa-pencil"></i></button>--><!--para debuggear en el front necesitamos activar este boton y desactivar el ancore de abajo -->
                       <a target="_blank" class="btn btn-default btn-xs navf" title="{{'general.edit' | translate}}"
                            [routerLink]='[tasks.id]'><i class="fa fa-pencil"></i></a> 
                          <a class="btn btn-default btn-xs"
                            title="{{'general.delete' | translate}}" (click)="deleteTask(tasks.id)">
                            <i class="fa fa-trash-o"></i></a>

                        </div>
                      </td>
                      <td><div [innerHTML]="tasks.descripcion"></div></td>
                      <td>{{tasks.responsable}}</td>
                      <td>{{tasks.fechaAlta | date: ('general.format-date' | translate) }}</td>
                      <td>{{tasks.cliente}}</td>
                      <td>{{tasks.priodescripcion}}</td>
                      <td>{{tasks.estadodescripcion}}</td>
                      <td>{{tasks.fechaActu | date: ('general.format-date' | translate) }}</td>
                      <td><div [innerHTML]="tasks.ultComent"></div></td>
                      
                    </tr>
                  </ng-template>
                </p-table>
  
              </div>
  
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  <!-- ventana modal de edición 
  
  <div class="modal inmodal fade" id="editTasksWindow" tabindex="-1" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-full">
      <div class="modal-content">
        <div class="modal-header modal-header-custom">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
              class="sr-only">{{'general.close' | translate}}</span></button>
  
          <h4 class="modal-title modal-title-custom">{{'page-tasks.title-window' | translate}}</h4>
  
        </div>
        <div class="modal-body" id="editTasksPanelEdicion">
  
          <div class="row">
            <div class="col-sm-7">
  
              <form [formGroup]="taskDataForm">
  
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label font-bold text-right">
                    {{'page-tasks.descripcion' | translate}} </label>
                  <div class="col-sm-6">
                    <input type="text" class="form-control input-sm" formControlName="task_descripcion">

  
                    <div *ngIf="taskDataFormSubmitted" class="invalid-feedback">
                     
                    </div>
  
                  </div>
                </div>
  
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label font-bold text-right">
                    {{'page-tasks.cliente' | translate}} </label>
                    <div class="col-sm-6">
                      <input type="text" class="form-control input-sm" formControlName="task_cliente">
    
                    </div>
                </div>
  
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label font-bold text-right">
                    {{'page-tasks.prioridad' | translate}} </label>
                    <div class="col-sm-6">
                      <select name="prio" id="prio" formControlName="task_prioridad"  class="form-control input-sm">
                        <option value="0">0-Indeterminado</option>
                        <option value="1">1-Muy Urgente</option>
                        <option value="2">2-Relevante</option>
                        <option value="3">3-No urgente</option>
                        <option value="4">4-No urgente no importante</option>
                      </select>
    
                      <div *ngIf="taskDataFormSubmitted" class="invalid-feedback">
                      </div>
    
                    </div>
                </div>
  
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label font-bold text-right">
                    {{'page-tasks.estado' | translate}} </label>
                  <div class="col-sm-8">
                    <select name="prio" id="prio" formControlName="task_estado"  class="form-control input-sm">
                      <option value="0">0-Activa</option>
                      <option value="1">1-Cancelada</option>
                      <option value="2">2-Eliminada</option>

                    </select>
  
                  </div>
                </div>
  
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label text-right">
                    {{'page-tasks.fechaFin' | translate}} </label>
                  <div class="col-sm-8">
                    <input type="date" class="form-control input-sm" formControlName="task_fechaFin">
                  </div>
                </div>
  
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label text-right">
                    {{'page-tasks.responsable' | translate}} </label>
                  <div class="col-sm-3">
                    <input type="text" class="form-control input-sm" formControlName="task_responsable">
                  </div>
                </div>

              </form>
            </div>
          </div>
  
        </div>
  
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>
          <button type="button" class="btn btn-sm btn-primary"
            (click)="saveTaskData()">{{'general.save-changes' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
-->
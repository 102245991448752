import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientInfo, ClientHubsInfo } from '../../../models/clients.model';
import { RouteHub, TipoMercancia } from '../../../models/routes.model';
import { RoutesService } from '../../../services/routes.service';
import { ClientsService } from '../../../services/clients.service';
import { SearchData } from '../../../models/searches.model';
import * as myGlobals from '../../../globals';

declare var $: any;

@Component({
  selector: 'app-administracion-clientes-hubs',
  templateUrl: './administracion-clientes-hubs.component.html',
  styleUrls: ['./administracion-clientes-hubs.component.css']
})
export class AdministracionClientesHubsComponent implements OnInit {

  clientHubsDataForm: FormGroup;
  clientHubsDataFormSubmitted: boolean = false;
  firstClientHubs: number = 0;

  searchData: SearchData = new SearchData();
  clientHubsList: ClientHubsInfo[] = [];
  currentClientHubs: ClientHubsInfo = new ClientHubsInfo();

  tktClientList: ClientInfo[] = [];
  hubsList: RouteHub[] = [];
  hubsSearchData: SearchData = new SearchData();
  wareTypeList: TipoMercancia = new TipoMercancia();

  loadingPage: boolean = false;
  select2TktId: any = null;
  select2HubSourceId: any = null;
  select2HubTargetId: any = null;
  select2WareTypeId: any = null;


  constructor(private routesService: RoutesService, private clientsService: ClientsService, private formBuilder: FormBuilder) { }

  // convenience getter for easy access to form fields
  get f() { return this.clientHubsDataForm.controls; }

  ngOnInit(): void {
    this.searchData.showDateSearch = false;
    this.searchData.showAdvancedOptions = false;

    this.clientHubsDataForm = this.formBuilder.group({
      form_tktId: [0, Validators.required],
      form_hubSourceId: [0, Validators.required],
      form_hubTargetId: [0, Validators.required],
      form_wareTypeId: [0, Validators.required],
      form_disable: [false, Validators.required]
    });

    this.select2TktId = $('#form_tktId').select2({
      dropdownParent: $('#editClientHubsPanelEdicion')
    });

    this.select2HubSourceId = $('#form_hubSourceId').select2({
      dropdownParent: $('#editClientHubsPanelEdicion')
    });

    this.select2HubTargetId = $('#form_hubTargetId').select2({
      dropdownParent: $('#editClientHubsPanelEdicion')
    });

    this.select2WareTypeId = $('#form_wareTypeId').select2({
      dropdownParent: $('#editClientHubsPanelEdicion')
    });

    this.hubsSearchData.disabled = false;
    this.loadingPage = true;
    this.getTktClientsList();
    this.getHubsList();
    this.getWareTypesData();
    this.getClientHubsList();

  }

  loadClientHubsDataForm(): void {
    this.clientHubsDataForm.setValue({
      form_tktId: this.currentClientHubs.clientId,
      form_hubSourceId: this.currentClientHubs.hubSourceId,
      form_hubTargetId: this.currentClientHubs.hubTargetId,
      form_wareTypeId: this.currentClientHubs.wareTypeId,
      form_disable: this.currentClientHubs.disable
    });

    this.select2TktId.val(this.currentClientHubs.clientId).trigger("change");
    this.select2HubSourceId.val(this.currentClientHubs.hubSourceId).trigger("change");
    this.select2HubTargetId.val(this.currentClientHubs.hubTargetId).trigger("change");
    this.select2WareTypeId.val(this.currentClientHubs.wareTypeId).trigger("change");
  }

  saveClientHubsDataForm(): void {
    this.f.form_tktId.setValue(this.select2TktId.val());
    this.f.form_hubSourceId.setValue(this.select2HubSourceId.val());
    this.f.form_hubTargetId.setValue(this.select2HubTargetId.val());
    this.f.form_wareTypeId.setValue(this.select2WareTypeId.val());

    this.currentClientHubs.clientId = this.f.form_tktId.value;
    this.currentClientHubs.clientName = this.select2TktId.find(':selected').text();
    this.currentClientHubs.hubSourceId = this.f.form_hubSourceId.value;
    this.currentClientHubs.hubSourceName = this.select2HubSourceId.find(':selected').text();
    this.currentClientHubs.hubTargetId = this.f.form_hubTargetId.value;
    this.currentClientHubs.hubTargetName = this.select2HubTargetId.find(':selected').text();
    this.currentClientHubs.wareTypeId = this.f.form_wareTypeId.value;
    this.currentClientHubs.wareTypeName = this.select2WareTypeId.find(':selected').text();
    this.currentClientHubs.disable = this.f.form_disable.value;
  }


  getTktClientsList() {
    this.clientsService.ClientsManagementGetTktClientList()
      .subscribe((data: any) => {
        this.tktClientList = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getHubsList() {
    this.routesService.RouteManagementGetRouteHubList(this.hubsSearchData)
      .subscribe((data: any) => {
        this.hubsList = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getWareTypesData() {
    this.routesService.RouteManagementGetWareTypeList()
      .subscribe((data: any) => {
        this.wareTypeList = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getClientHubsList() {
    this.clientHubsManagementSpinner();
    this.routesService.ClientsManagementHubsDataList(this.searchData)
      .subscribe((data: any) => {
        this.clientHubsList = data.body;
        this.firstClientHubs = 0;
        this.clientHubsManagementSpinner();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

    if (this.loadingPage) {
      this.loadingPage = false;
      this.clientHubsManagementSpinner();
    }
  }


  newClientHubs() {
    this.currentClientHubs = new ClientHubsInfo();
    this.loadClientHubsDataForm();
    this.clientHubsDataFormSubmitted = false;
    this.editClientHubsManagementToggle();
  }

  editClientHubs(data: ClientHubsInfo) {
    this.routesService.ClientsManagementHubsGetData(data)
      .subscribe((data: any) => {
        this.currentClientHubs = data;

        this.loadClientHubsDataForm();
        this.clientHubsDataFormSubmitted = false;
        this.editClientHubsManagementToggle();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  saveClientHubsData() {
    this.saveClientHubsDataForm();
    this.clientHubsDataFormSubmitted = true;

    // stop here if form is invalid
    if (this.clientHubsDataForm.invalid) {
      return;
    }

    this.routesService.ClientsManagementHubsSaveData(this.currentClientHubs)
      .subscribe(() => {
        this.getClientHubsList();
        this.editClientHubsManagementToggle();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });


  }

  deleteClientHubs(data: ClientHubsInfo) {
    var r = confirm("Are you sure you want to delete the item?");
    if (r == true) {

      this.routesService.ClientsManagementHubsDeleteData(data)
        .subscribe(() => {
          this.getClientHubsList();
        }
          , (error: string) => {
            myGlobals.showMessage(error, myGlobals.MessageType.Error);
          });

    }
  }

  setClientHubsEnable(data: ClientHubsInfo) {
    this.routesService.ClientsManagementHubsSetEnable(data)
      .subscribe(() => {
        this.getClientHubsList();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  setClientHubsDisable(data: ClientHubsInfo) {
    this.routesService.ClientsManagementHubsSetDisable(data)
      .subscribe(() => {
        this.getClientHubsList();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }



  editClientHubsManagementToggle() {
    $('#editClientHubsWindow').modal('toggle');
  }

  clientHubsManagementSpinner() {
    $('#clientHubsManagement').children('.ibox-content').toggleClass('sk-loading');
  }

  exportExcel() {
    myGlobals.exportExcel(this.clientHubsList);
  }

}

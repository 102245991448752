<!-- ventana principal -->

<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{'page-administracion-hubs.title' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations' | translate}}
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations-administration' | translate}}
      </li>
      <li class="breadcrumb-item active">
        <strong>{{'page-navigation.operations-hubs' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{'page-administracion-hubs.title-description' | translate}}</em></p>
  </div>
</div>


<div class="row">
  <div class="col-sm-12">
    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox" id="hubsManagement">
        <div class="ibox-content sk-loading">

          <div class="sk-spinner sk-spinner-pulse" style="width: 75px; height: 75px; top: 100px;"></div>

          <div class="row">

            <div class="col-12">

              <div class="float-right m-b-lg">
                <div class="form-inline">
                  <button class="btn btn-sm btn-primary m-r-sm" type="button"
                    (click)="newHub()">{{'page-administracion-hubs.action-new' | translate}}</button>
                  <div class="input-group">
                    <input style="width: 300px;" type="text" class="form-control input-sm"
                      [(ngModel)]="searchData.value" placeholder="{{'general.search' | translate}}..."
                      (keyup.enter)="getHubsList()">

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" (click)="getHubsList()" type="button"
                        title="{{'general.search' | translate}}">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" (click)="exportExcel()"
                        title="{{'general.export-excel' | translate}}"> <i class="fa fa-download"
                          aria-hidden="true"></i></button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" data-toggle="collapse"
                        data-parent="#panelSearch" href="#panelAdvancedSearch" aria-expanded="false"
                        title="{{'search.title' | translate}}">
                        <i class="fa fa-wrench" aria-hidden="true"></i>
                      </button>
                    </span>

                  </div>
                </div>
              </div>

              <div id="panelSearch">
                <div id="panelAdvancedSearch" class="panel-collapse collapse">
                  <h2 class="search-panel-title">{{'search.title' | translate}}</h2>
                  <small class="font-bold">{{'search.title-description' | translate}}</small>
                  <div class="hr-line-dashed"></div>

                  <div>
                    <div *ngIf="searchData.showStatusSearch">
                      <label class="checkbox-inline m-r-md">
                        {{'search.showresults' | translate}}:
                      </label>

                      <label class="checkbox-inline m-r-md">
                        <input type="checkbox" [(ngModel)]="searchData.enabled">
                        &nbsp;{{'search.show-active' | translate}}
                      </label>

                      <label class="checkbox-inline">
                        <input type="checkbox" [(ngModel)]="searchData.disabled">
                        &nbsp;{{'search.show-disabled' | translate}}
                      </label>
                    </div>

                    <div *ngIf="searchData.showDateSearch" class="form-group form-inline">
                      <label class="m-r-md">{{'search.daterange' | translate}}:</label>

                      <label class="m-r-md">{{'search.date-from' | translate}}
                        <input type="date" class="form-control form-control-search m-l-sm mr-sm" id="fechadesde">
                      </label>

                      <label>{{'search.date-to' | translate}}
                        <input type="date" class="form-control form-control-search m-l-sm mr-sm">
                      </label>
                    </div>

                  </div>
                  <div class="hr-line-dashed"></div>
                </div>
              </div>

            </div>

            <div class="col-12">
              <p-table [value]="hubsList" sortMode="multiple"
                styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [paginator]="true" [rows]="10"
                [showCurrentPageReport]="true" [autoLayout]="true" [(first)]="firstHubs"
                currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
                [rowsPerPageOptions]="[10,25,50,100,200]" [columnResizeMode]="'expand'" [reorderableColumns]="true"
                [resizableColumns]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 65px;"></th>
                    <th pSortableColumn="nombre">
                      {{'page-administracion-hubs.columname-name' | translate}}
                      <p-sortIcon field="nombre"></p-sortIcon>
                    </th>
                    <th pSortableColumn="alias">
                      {{'page-administracion-hubs.columname-alias' | translate}}
                      <p-sortIcon field="alias"></p-sortIcon>
                    </th>
                    <th pSortableColumn="direccion">
                      {{'page-administracion-hubs.columname-address' | translate}}
                      <p-sortIcon field="direccion"></p-sortIcon>
                    </th>
                    <th pSortableColumn="cp">
                      {{'page-administracion-hubs.columname-postalcode' | translate}}
                      <p-sortIcon field="cp"></p-sortIcon>
                    </th>
                    <th pSortableColumn="ciudad">
                      {{'page-administracion-hubs.columname-city' | translate}}
                      <p-sortIcon field="ciudad"></p-sortIcon>
                    </th>
                    <th pSortableColumn="pais">
                      {{'page-administracion-hubs.columname-country' | translate}}
                      <p-sortIcon field="pais"></p-sortIcon>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-hub>
                  <tr [ngClass]="{'row-disable' : hub.disable}">
                    <td>
                      <div class="btn-group btn-group-xs">
                        <button class="btn btn-default btn-xs" title="{{'general.edit' | translate}}"
                          (click)="editHub(hub.id)"><i class="fa fa-pencil"></i></button>
                        <button *ngIf="hub.disable" class="btn btn-default btn-xs"
                          title="{{'general.enable' | translate}}" (click)="setHubEnable(hub.id)"><i
                            class="fa fa-eye"></i></button>
                        <button *ngIf="!hub.disable" class="btn btn-default btn-xs"
                          title="{{'general.disable' | translate}}" (click)="setHubDisable(hub.id)"><i
                            class="fa fa-eye-slash"></i></button>
                      </div>
                    </td>
                    <td>{{hub.nombre}}</td>
                    <td>{{hub.alias}}</td>
                    <td>{{hub.direccion}}</td>
                    <td>{{hub.cp}}</td>
                    <td>{{hub.ciudad}}</td>
                    <td>{{hub.pais}}</td>
                  </tr>
                </ng-template>
              </p-table>

            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<!-- ventana modal de edición -->

<div class="modal inmodal fade" id="editHubWindow" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-full">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">{{'page-administracion-hubs.editwindow-title' | translate}}</h4>
        <small class="font-bold">{{'page-administracion-hubs.editwindow-title-description' | translate}}</small>

      </div>
      <div class="modal-body" id="editHubPanelEdicion">

        <div class="row">
          <div class="col-sm-7">

            <form [formGroup]="hubDataForm">

              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-administracion-hubs.editwindow-alias' | translate}} </label>
                <div class="col-sm-6">
                  <input type="text" class="form-control input-sm" formControlName="hub_alias"
                    [ngClass]="{ 'is-invalid': hubDataFormSubmitted && f.hub_alias.errors }">

                  <div *ngIf="hubDataFormSubmitted && f.hub_alias.errors" class="invalid-feedback">
                    <div *ngIf="f.hub_alias.errors.required"> {{'validations.isrequired' | translate }} </div>
                    <div *ngIf="f.hub_alias.errors.maxlength"> {{'validations.maxlength' | translate}} </div>
                  </div>

                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-administracion-hubs.editwindow-country' | translate}} </label>
                <div class="col-sm-6" [ngClass]="{ 'is-invalid': hubDataFormSubmitted && f.hub_pais.errors }">

                  <ng-select2 [data]="countriesList" width="100%" formControlName="hub_pais"
                    [dropdownParent]="'editHubPanelEdicion'"
                    [ngClass]="{ 'is-invalid': hubDataFormSubmitted && f.hub_pais.errors }">
                  </ng-select2>

                  <div *ngIf="hubDataFormSubmitted && f.hub_pais.errors" class="invalid-feedback">
                    <div *ngIf="f.hub_pais.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>

                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-administracion-hubs.editwindow-name' | translate}} </label>
                <div class="col-sm-8">
                  <input type="text" class="form-control input-sm" formControlName="hub_nombre"
                    [ngClass]="{ 'is-invalid': hubDataFormSubmitted && f.hub_nombre.errors }">

                  <div *ngIf="hubDataFormSubmitted && f.hub_nombre.errors" class="invalid-feedback">
                    <div *ngIf="f.hub_nombre.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>

                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-administracion-hubs.editwindow-address' | translate}} </label>
                <div class="col-sm-8">
                  <input type="text" class="form-control input-sm" formControlName="hub_direccion">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-administracion-hubs.editwindow-postalcode' | translate}} </label>
                <div class="col-sm-3">
                  <input type="text" class="form-control input-sm" formControlName="hub_cp">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-administracion-hubs.editwindow-city' | translate}} </label>
                <div class="col-sm-5">
                  <input type="text" class="form-control input-sm" formControlName="hub_ciudad">
                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-administracion-hubs.editwindow-email' | translate}} </label>
                <div class="col-sm-7">
                  <input type="email" class="form-control input-sm" formControlName="hub_email"
                    [ngClass]="{ 'is-invalid': hubDataFormSubmitted && f.hub_email.errors }">

                  <div *ngIf="hubDataFormSubmitted && f.hub_email.errors" class="invalid-feedback">
                    <div *ngIf="f.hub_email.errors.email"> {{'validations.email' | translate}} </div>
                  </div>

                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-administracion-hubs.editwindow-gps' | translate}} </label>
                <div class="col-sm-5">

                  <div class="input-group">
                    <input type="text" class="form-control input-sm" formControlName="hub_coordenadas"
                      [ngClass]="{ 'is-invalid': hubDataFormSubmitted && f.hub_coordenadas.errors }">
                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" (click)="showInMap()" type="button">
                        <i class="fa fa-location-arrow" aria-hidden="true"></i>
                      </button>
                    </span>
                  </div>

                  <div *ngIf="hubDataFormSubmitted && f.hub_coordenadas.errors" class="invalid-feedback">
                    <div *ngIf="f.hub_coordenadas.errors.required"> {{'validations.isrequired' | translate}}
                    </div>
                  </div>

                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label"></label>
                <div class="col-sm-8">

                  <label class="checkbox-inline">
                    <input type="checkbox" formControlName="hub_prealert">
                    {{'page-administracion-hubs.editwindow-prealert' | translate}}</label>

                  &nbsp;&nbsp;&nbsp;&nbsp;

                  <label class="checkbox-inline">
                    <input type="checkbox" formControlName="hub_disable">
                    {{'general.disabled' | translate}}</label>

                </div>
              </div>

            </form>
          </div>

          <div class="col-sm-5" style="min-height: 200px">
            <agm-map [latitude]="mapLatitude" [zoom]="mapZoom" [longitude]="mapLongitude"
              (mapClick)="mapClicked($event)" [disableDefaultUI]="false">
              <agm-marker [latitude]="mapLatitude" [longitude]="mapLongitude">
              </agm-marker>
            </agm-map>

          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>
        <button type="button" class="btn btn-sm btn-primary"
          (click)="saveHubData()">{{'general.save-changes' | translate}}</button>
      </div>
    </div>
  </div>
</div>

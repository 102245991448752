<!-- ventana principal -->

<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{ "warehouse-boxescontainer.title" | translate }}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{ "page-navigation.warehouse" | translate }}
      </li>
      <li class="breadcrumb-item active">
        <strong>{{ "warehouse-boxescontainer.title" | translate }}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p>
      <em>{{ "warehouse-boxescontainer.title-description" | translate }}</em>
    </p>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox">
        <div class="ibox-content" id="loading">
          <div
            class="sk-spinner sk-spinner-pulse"
            style="width: 75px; height: 75px; top: 100px"
          ></div>

          <div *ngIf="currentContainer == null">
            <h4 [hidden]="displayModalStart">
              Lea el código de barras del contenedor
            </h4>
            <div class="m-t-md">
              <input
                id="inputContainer"
                style="width: 300px"
                type="text"
                class="form-control input-sm"
                [(ngModel)]="scannedDataContainer"
                (keyup.enter)="loadContainer()"
                (focusout)="setFocusInputContainer()"
              />
            </div>

            <div *ngIf="errorContainerNotFound" class="text-danger m-t-md">
              ¡No existe el contenedor <strong>{{ requestCode }}</strong
              >!
            </div>
          </div>

          <div *ngIf="currentContainer != null">
            <div class="row">
              <div class="col-12">
                <div class="float-right">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-outline btn-default"
                      (click)="unloadContainer()"
                    >
                      Salir
                    </button>
                    <button
                      *ngIf="currentContainer.status.code == 0"
                      type="button"
                      class="btn btn-outline btn-default dropdown-toggle dropdown-toggle-split"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span class="sr-only"></span>
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      *ngIf="currentContainer.status.code == 0"
                    >
                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                        (click)="closeContainer()"
                        >El Contenedor está lleno</a
                      >
                    </div>
                  </div>
                </div>

                <h3>
                  <span
                    *ngIf="currentContainer.status.code == 0"
                    class="text-success"
                    >{{ currentContainer.id }}</span
                  >
                  <span
                    *ngIf="currentContainer.status.code != 0"
                    class="text-warning"
                    >{{ currentContainer.id }}</span
                  >
                  <br />
                  <small
                    >{{ currentContainer.title }}
                    <small class="font-italic">
                      <br />{{ getDate(currentContainer.date) }}</small
                    >
                  </small>
                </h3>
              </div>

              <div class="col-12" *ngIf="currentContainer.status.code != 0">
                <div class="m-t-sm m-b-sm text-danger">
                  Este contenedor no se puede utilizar
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-auto">
                <h5>
                  <small>Clientes</small><br />{{
                    getClients(currentContainer.clients)
                  }}
                </h5>
              </div>
              <div class="col-auto">
                <h5>
                  <small>Transportistas</small><br />{{
                    getCouriers(currentContainer.couriers)
                  }}
                </h5>
              </div>
              <div class="col-auto">
                <h5>
                  <small>Paises de destino</small><br />{{
                    getContries(currentContainer.countries)
                  }}
                </h5>
              </div>
            </div>

            <div class="row m-t-lg">
              <div class="col">
                <span class="text-success font-bold">{{
                  currentContainer.boxes
                }}</span>
                cajas asociadas
              </div>

              <div class="col-auto" *ngIf="currentContainer.status.code == 0">
                <button
                  class="col-auto"
                  class="btn btn-outline btn-primary btn-sx"
                  (click)="openAssistantToAddBox()"
                  type="button"
                >
                  Añadir
                </button>
                <span class="col-auto"></span>
                <button
                  class="col-auto"
                  class="btn btn-outline btn-warning btn-sx"
                  (click)="openAssistantToRemoveBox()"
                  type="button"
                >
                  Quitar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  header=""
  [(visible)]="displayModalStart"
  [modal]="true"
  [style]="{ width: '95vw' }"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
>
  <div class="m-md">
    <h2>
      Asistente de asignación de cajas a contenedores<br /><br />
      <small>Cuando esté listo pulse en Empezar</small>
    </h2>

    <div *ngIf="hasDevices" class="m-t-xl">
      <label class="checkbox-inline m-r-md">
        <input type="checkbox" [(ngModel)]="useCamera" />
        &nbsp;Usar la cámara para leer las tiquetas
      </label>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      (click)="executeStart()"
      label="Empezar"
      styleClass="p-button"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  #dialogError
  *ngIf="result != null"
  header=""
  [(visible)]="displayModalError"
  [modal]="true"
  [style]="{ width: '75vw' }"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  [maximizable]="false"
  (onShow)="maximizeDialog(dialogError)"
>
  <div class="m-l-md m-b-xl">
    <h4>
      <small>Etiqueta del Cliente</small><br />{{ scannedData.clientBarcode }}
    </h4>
    <h4>
      <small>Etiqueta del Transportista</small><br />{{
        scannedData.courierBarcode
      }}
    </h4>
  </div>

  <div class="m-md">
    <div class="text-danger" [innerHTML]="stringToHtml(result.message)"></div>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      (click)="closeModalError()"
      label="Cerrar"
      styleClass="p-button"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  #dialogBoxValidate
  [(visible)]="displayModalBoxValidate"
  [modal]="true"
  [style]="{ width: '95vw' }"
  [draggable]="false"
  [resizable]="false"
  [maximizable]="false"
  [closable]="false"
  (onShow)="startBoxValidation(dialogBoxValidate)"
  (onHide)="executeEndBoxValidate()"
>
  <ng-template pTemplate="header">
    <h3>
      <span *ngIf="scannedDataBoxMode == 1">Añadir cajas</span>
      <span *ngIf="scannedDataBoxMode == 2">Quitar cajas</span>
      <br /><br />
      <small *ngIf="currentContainer != null" class="m-t-md">
        <span class="text-success">{{ currentContainer.id }}</span>
        <br />
        {{ currentContainer.title }}
      </small>
    </h3>
  </ng-template>

  <div>
    <div *ngIf="result != null">
      <div *ngIf="result.success" class="m-t-md m-b-md text-success">
        <div [innerHTML]="stringToHtml(result.message)"></div>
      </div>
    </div>

    <div>
      <h4 *ngIf="scannedDataBoxStatus == 1">Lea la etiqueta del Cliente</h4>
      <h4 *ngIf="scannedDataBoxStatus == 2">
        Lea la etiqueta del transportista
      </h4>

      <div class="m-t-md">
        <input
          id="inputBarcode"
          style="width: 300px"
          type="text"
          class="form-control input-sm"
          [(ngModel)]="scannedDataBox"
          (keyup.enter)="barcodeReaded()"
          (focusout)="setFocusInputBarcode()"
        />
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      (click)="executeEndBoxValidate()"
      label="Terminar"
      styleClass="p-button"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  #dialogCameraReader
  header=""
  [(visible)]="hasDevices && useCamera && showReader"
  [modal]="true"
  [style]="{ width: '95vw' }"
  [draggable]="false"
  [resizable]="false"
  [closable]="true"
  [maximizable]="false"
  (onShow)="startCameraReader(dialogCameraReader)"
  (onHide)="onReaderCodeClose()"
>
  <ng-template pTemplate="header">
    <h3 *ngIf="currentContainer == null">
      Lea el código de barras del contenedor
    </h3>
    <h3 *ngIf="currentContainer != null">
      <span *ngIf="scannedDataBoxMode == 1">Añadir cajas</span>
      <span *ngIf="scannedDataBoxMode == 2">Quitar cajas</span>
      <br /><br />
      <small *ngIf="currentContainer != null" class="m-t-md">
        <span class="text-success">{{ currentContainer.id }}</span>
        <br />
        {{ currentContainer.title }}
      </small>
    </h3>
  </ng-template>

  <div *ngIf="result != null">
    <div
      *ngIf="result.success"
      class="m-t-md m-b-md text-info border-top-bottom"
    >
      <div [innerHTML]="stringToHtml(result.message)"></div>
    </div>
  </div>

  <div *ngIf="currentContainer != null">
    <h4 *ngIf="scannedDataBoxStatus == 1">Lea la etiqueta del Cliente</h4>
    <h4 *ngIf="scannedDataBoxStatus == 2">Lea la etiqueta del transportista</h4>
  </div>

  <div [hidden]="!hasDevices">
    <zxing-scanner
      #scanner
      [enable]="hasDevices && useCamera && showReader"
      [autostart]="true"
      [torch]="torchEnabled"
      [device]="currentDevice"
      [formats]="formatsEnabled"
      [tryHarder]="tryHarder"
      (deviceChange)="onDeviceChange($event)"
      (scanSuccess)="onReaderCodeResult($event)"
      (permissionResponse)="onHasPermission($event)"
      (camerasFound)="onCamerasFound($event)"
      (torchCompatible)="onTorchCompatible($event)"
    ></zxing-scanner>
  </div>

  <div *ngIf="errorContainerNotFound" class="text-danger m-t-md">
    ¡No existe el contenedor <strong>{{ requestCode }}</strong
    >!
  </div>

  <ng-template pTemplate="footer">
    <p-button
      *ngIf="currentContainer != null"
      class="m-t-md"
      (click)="executeEndBoxValidateByCamera()"
      label="Terminar"
      styleClass="p-button"
    ></p-button>
  </ng-template>
</p-dialog>

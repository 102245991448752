import { SearchData } from './searches.model';

export class TerrestrialService {

    id: number = 0;
    status: number=0;
    descripcion: string = "";
    rutaId: number = 0;
    rutaName: string = "";
    expedicionNumero: string = "";
    expedicionFecha: Date = null;
    proveedorId: number = 0;
    proveedorName: string = "";
    tipoVehiculoId: number = 0;
    tipoVehiculoName: string = "";
    matricula: string = "";
    estadoId: number = 0;
    estadoName: string = "";
    costeTotal: number = 0;
    costeADescontar: number = 0;
    totalPallets: number = 0;
    totalKm: number = 0;
    totalHours: number = 0;
    costPerKilometer: number = 0;
    usuario: number = 0;
    prealert: boolean = false;
    ware: TerrestrialServiceWare[] = [];
    stops: TerrestrialServiceStops[] = [];
    costs: TerrestrialServiceCostInfo[] = [];
    costsEstimated: TerrestrialServiceCostInfoEstimated = new TerrestrialServiceCostInfoEstimated();
}

export class TerrestrialServiceChangeStatus {
    idService: number = 0;
    idStatus: number = 0;
}

export class TerrestrialServiceSearchData extends SearchData {
    proveedorIds: string = "";
    statusIds: string = "";
}

export class TerrestrialServiceStatus {
    id: number = 0;
    description: string = "";
}

export class TerrestrialServiceWare {
    id: number = 0;
    clientId: number = 0;
    clientName: string = "";
    hubSourceId: number = 0;
    hubSourceName: string = "";
    hubTargetId: number = 0;
    hubTargetName: string = "";
    boxes: number = 0;
    typeServiceId: number = 0;
    typeServiceName: string = "";
    clientCountryId: string = "";
    clientCountryName: string = "";
    hubWareIn: number = -1;
    hubWareOut: number = -1;
}

export class TerrestrialServiceStops {
    id: number = 0;
    hubId: number = 0;
    hubName: string = "";
    country: string = "";
    km: number = 0;
    hours: number = 0;
    partialCost: number = 0;
    partialBoxes: number = 0;
    costPerBox: number = 0;
    dateEta: Date = null;
    dateDelivery: Date = null;
    boxInfo: TerrestrialServiceBoxInfo[] = [];
}

export class TerrestrialServiceBoxInfo {
    clientId: number = 0;
    clientName: string = "";
    clientCountryId: string = "";
    boxes: number = 0;
    pallets: number = 0;
    costPerBox: number = 0;
    typeServiceId: number = 0;
}

export class TerrestrialServiceCostInfo {
    idStop: number = 0;
    wareClient: string = "";
    hubSourceId: number = 0;
    hubSouceName: string = "";
    hubTargetId: number = 0;
    hubTargetName: string = "";
    boxes: number = 0;
    clientId: number = 0;
    clientName: string = "";
    serviceId: number = 0;
    serviceCode: string = "";
    serviceBilled: boolean = false;
    serviceInvoice: string = "";
    costId: number = 0;
    costValue: number = 0;
    costDate: Date = null;
    costBilled: boolean = false;
    costInvoice: string = "";
    pallets: number = 0;
}

export class TerrestrialServiceCostInfoEstimated {
    costs: TerrestrialServiceCostInfo[] = [];
    errors: string[] = [];
}

export class TerrestrialServiceCheckInfo {
    expedition: string = ""
}
<!-- ventana principal -->

<p-toast></p-toast>

<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2 *ngIf="serviceStatus.newService">{{'page-services-terrestrial-edit.title1' | translate}}</h2>
    <h2 *ngIf="!serviceStatus.newService">{{'page-services-terrestrial-edit.title2' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations' | translate}}
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations-services' | translate}}
      </li>
      <li class="breadcrumb-item" title="{{'page-services-terrestrial-list.title' | translate}}">
        <a routerLink="/oper/serv/terrestre">{{'page-navigation.operations-terrestrial' | translate}}</a>
      </li>

      <li class="breadcrumb-item active">
        <strong>{{'page-services-terrestrial-edit.title-small' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{'page-services-terrestrial-edit.title-description' | translate}}</em></p>
  </div>
</div>

<div [formGroup]="serviceDataForm">

  <div class="wrapper wrapper-content animated fadeInUp">
    <div class="row">

      <div class="col-sm">
        <div class="ibox" id="editTerrestialServicePanel">
          <div class="ibox-content">

            <div class="float-right">

              <span *ngIf="currentService.status == 2"
                class="text-danger m-r-md">{{'general.deleted' | translate}}</span>
              <span *ngIf="currentService.status != 2" class="text-info m-r-md">{{currentService.estadoName}}</span>

              <div *ngIf="currentService.status != 2" class="btn-group btn-group-xs">
                <button data-toggle="dropdown" class="btn btn-default btn-xs dropdown-toggle" aria-expanded="false">
                  <i class="fa fa-bars" aria-hidden="true"></i></button>
                <ul class="dropdown-menu dropdown-menu-right" x-placement="bottom-start"
                  style="position: absolute; top: 24px; left: 0px; will-change: top, left;">

                  <li><a class="dropdown-item" href="javascript:void(0);" (click)="showRouteInfo()"
                      title="{{'general.export-excel' | translate}}">{{'general.reload' | translate}}</a>
                  </li>

                  <div class="dropdown-divider"></div>

                  <li><a class="dropdown-item" href="javascript:void(0);"
                      (click)="exportExcelWare()">{{'page-services-terrestrial-edit.menu-exportware' | translate}}</a>
                  </li>

                  <li><a class="dropdown-item" href="javascript:void(0);"
                      (click)="exportExcelCosts()">{{'page-services-terrestrial-edit.menu-exportcosts' | translate}}</a>
                  </li>

                  <div *ngIf="isAdmin">
                    <div class="dropdown-divider"></div>
                    <li *ngFor="let s of lists.status">
                      <a class="dropdown-item" href="javascript:void(0);" (click)="changeStatus(s)">{{s.id}}.-
                        {{s.description}}</a>
                    </li>
                  </div>

                </ul>
              </div>

              <div *ngIf="serviceDataFormSubmitted && f.service_status.errors" class="invalid-feedback">
                <div *ngIf="f.service_status.errors.required">
                  {{'validations.isrequired' | translate }} </div>
              </div>
            </div>

            <h3 class="m-t-xl m-b-md">{{'page-services-terrestrial-edit.edit-general' | translate}}</h3>

            <div class="row">

              <div class="col-sm-auto">
                <div class="form-group row">
                  <label class="col-sm-auto col-form-label text-right">
                    {{'page-services-terrestrial-edit.edit-expedition-date' | translate}} </label>
                  <div class="col-sm">
                    <input type="date" class="form-control input-sm" formControlName="service_expeditiondate"
                      [ngClass]="{ 'is-invalid': serviceDataFormSubmitted && f.service_expeditiondate.errors }">

                    <div *ngIf="serviceDataFormSubmitted && f.service_expeditiondate.errors" class="invalid-feedback">
                      <div *ngIf="f.service_expeditiondate.errors.required">
                        {{'validations.isrequired' | translate }} </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-sm-auto">
                <div class="form-group row">
                  <label class="col-sm-auto col-form-label text-right">
                    {{'page-services-terrestrial-edit.edit-expedition-number' | translate}} </label>
                  <div class="col-sm">
                    <input type="text" class="form-control input-sm" formControlName="service_expeditionnumber"
                      [ngClass]="{ 'is-invalid': (serviceDataFormSubmitted && f.service_expeditionnumber.errors) || existeExpedicion }"
                      (blur)="validateExpedicion()">

                    <div *ngIf="serviceDataFormSubmitted && f.service_expeditionnumber.errors" class="invalid-feedback">
                      <div *ngIf="f.service_expeditionnumber.errors.required">
                        {{'validations.isrequired' | translate }} </div>
                      <div *ngIf="f.service_expeditionnumber.errors.maxlength">
                        {{'validations.maxlength' | translate}} </div>
                    </div>
                    <div *ngIf="existeExpedicion" class="invalid-feedback">
                      <div *ngIf="existeExpedicion">
                        {{'validations.value-in-use' | translate}} </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="row">

              <div class="col-sm">
                <div class="form-group row">
                  <label class="col-sm-auto col-form-label text-right">
                    {{'page-services-terrestrial-edit.edit-provider' | translate}} </label>
                  <div class="col-sm" style="min-width: 300px;">
                    <div [ngClass]="{ 'is-invalid': serviceDataFormSubmitted && f.service_provider.errors }">
                      <select class="form-control input-sm" formControlName="service_provider" id="service_provider"
                        style="width:100%;">
                        <option *ngFor="let p of lists.providers" value="{{p.idProveedor}}">{{p.empresa}}</option>
                      </select>
                    </div>
                    <div *ngIf="serviceDataFormSubmitted && f.service_provider.errors" class="invalid-feedback">
                      <div *ngIf="f.service_provider.errors.required"> {{'validations.isrequired' | translate }}
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="col-sm-auto">
                <div class="form-group row">
                  <label class="col-sm-auto col-form-label text-right">
                    {{'page-services-terrestrial-edit.edit-vehicle-type' | translate}} </label>
                  <div class="col-sm"
                    [ngClass]="{ 'is-invalid': serviceDataFormSubmitted && f.service_vehicletype.errors }">
                    <select class="form-control input-sm" formControlName="service_vehicletype" id="service_vehicletype"
                      style="width:200px;"
                      [ngClass]="{ 'is-invalid': serviceDataFormSubmitted && f.service_vehicletype.errors }">
                      <option *ngFor="let t of lists.vehicleTypes" value="{{t.id}}">{{t.descripcion}}</option>
                    </select>
                    <div *ngIf="serviceDataFormSubmitted && f.service_vehicletype.errors" class="invalid-feedback">
                      <div *ngIf="f.service_vehicletype.errors.required">
                        {{'validations.isrequired' | translate }} </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-auto">
                <div class="form-group row">
                  <label class="col-sm-auto col-form-label text-right">
                    {{'page-services-terrestrial-edit.edit-vehicle-license' | translate}} </label>
                  <div class="col-sm">
                    <input type="text" class="form-control input-sm" formControlName="service_vehiclelicense"
                      [ngClass]="{ 'is-invalid': serviceDataFormSubmitted && f.service_vehiclelicense.errors }">

                    <div *ngIf="serviceDataFormSubmitted && f.service_vehiclelicense.errors" class="invalid-feedback">
                      <div *ngIf="f.service_vehiclelicense.errors.required">
                        {{'validations.isrequired' | translate }} </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>

            <div class="row">

              <div class="col-sm-auto">
                <div class="form-group row">
                  <label class="col-sm-auto col-form-label text-right">
                    {{'page-services-terrestrial-edit.edit-cost-total' | translate}} </label>
                  <div class="col-sm">
                    <input type="number" class="form-control input-sm" formControlName="service_costtotal"
                      [ngClass]="{ 'is-invalid': serviceDataFormSubmitted && f.service_costtotal.errors }">

                    <div *ngIf="serviceDataFormSubmitted && f.service_costtotal.errors" class="invalid-feedback">
                      <div *ngIf="f.service_costtotal.errors.required">
                        {{'validations.isrequired' | translate }} </div>
                      <div *ngIf="f.service_costtotal.errors.invalidPositiveNumbers">
                        {{'validations.only-positivenumbers' | translate}}
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-sm-auto">
                <div class="form-group row">
                  <label class="col-sm-auto col-form-label text-right">
                    {{'page-services-terrestrial-edit.edit-cost-todiscount' | translate}} </label>
                  <div class="col-sm">
                    <input type="number" class="form-control input-sm" formControlName="service_costtodiscount"
                      [ngClass]="{ 'is-invalid': serviceDataFormSubmitted && f.service_costtodiscount.errors }">

                    <div *ngIf="serviceDataFormSubmitted && f.service_costtodiscount.errors" class="invalid-feedback">
                      <div *ngIf="f.service_costtodiscount.errors.invalidNumber">
                        {{'validations.only-numbers' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-sm">
                <div class="form-group row">
                  <label class="col-sm-auto col-form-label text-right">
                    {{'page-services-terrestrial-edit.edit-observations' | translate}} </label>
                  <div class="col-sm">
                    <input type="text" class="form-control input-sm" formControlName="service_observations">
                  </div>
                </div>
              </div>
            </div>


            <div class="hr-line-dashed"></div>
            <h3 class="m-t-xl m-b-md">{{'page-services-terrestrial-edit.edit-ware' | translate}}</h3>

            <div [ngClass]="{ 'invalid-feedback-border-custom p-xxs': !serviceStatus.wareIsOK }">
              <p-table [value]="currentService.ware" dataKey="id"
                styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [columnResizeMode]="'expand'">
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th style="width:200px;">{{'page-services-terrestrial-edit.columname-client' | translate}}</th>
                    <th style="width:200px;">
                      {{'page-services-terrestrial-edit.columname-clientcountry' | translate}}</th>
                    <th>{{'page-services-terrestrial-edit.columname-hubsource' | translate}}</th>
                    <th>{{'page-services-terrestrial-edit.columname-hubtarget' | translate}}</th>
                    <th style="width:140px;">{{'page-services-terrestrial-edit.columname-typeservice' | translate}}</th>
                    <th style="width:100px;">{{'page-services-terrestrial-edit.columname-boxs' | translate}}</th>
                    <th *ngIf="canEdit.ware" style="width:65px;" class="text-right">
                      <button class="btn btn-white btn-xs" type="button" (click)="exportExcelWare()"
                        title="{{'general.export-excel' | translate}}"> <i class="fa fa-download"
                          aria-hidden="true"></i></button>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-ware let-index="rowIndex">
                  <tr [ngClass]="{'row-disable' : ware.hubWareIn == -1 || ware.hubWareOut == -1}">
                    <td>{{ware.clientName}}</td>
                    <td>{{ware.clientCountryName}}</td>
                    <td>{{ware.hubSourceName}}</td>
                    <td>{{ware.hubTargetName}}</td>
                    <td>{{ware.typeServiceName}}</td>
                    <td>{{ware.boxes}}</td>
                    <td *ngIf="canEdit.ware">
                      <div class="btn-group btn-group-xs">
                        <button class="btn btn-white btn-xs" type="button" title="{{'general.edit' | translate}}"
                          (click)="editWare(index)"><i class="fa fa-pencil" aria-hidden="true"></i></button>

                        <button class="btn btn-white btn-xs" type="button" title="{{'general.delete' | translate}}"
                          (click)="deleteWare(index)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                      </div>
                    </td>

                  </tr>
                </ng-template>

                <ng-template pTemplate="footer">
                  <tr *ngIf="canEdit.ware">
                    <td>
                      <select class="form-control input-sm" formControlName="ware_client" id="ware_client"
                        style="width:100%;">
                        <option *ngFor="let i of lists.clients" value="{{i.id}}">{{i.name}}</option>
                      </select>
                    </td>
                    <td>
                      <select class="form-control input-sm" formControlName="ware_country" id="ware_country"
                        style="width:100%;">
                        <option value=""></option>
                        <option *ngFor="let i of lists.wareCountries" value="{{i.codigoalfa2}}">{{i.pais}}</option>
                      </select>
                    </td>
                    <td>
                      <select class="form-control input-sm" formControlName="ware_source" id="ware_source"
                        style="width:100%;">
                        <option value=""></option>
                        <option *ngFor="let i of lists.hubsSource" value="{{i.id}}">{{i.name}}</option>
                      </select>
                    </td>
                    <td>
                      <select class="form-control input-sm" formControlName="ware_target" id="ware_target"
                        style="width:100%;">
                        <option value=""></option>
                        <option *ngFor="let i of lists.hubsTarget" value="{{i.id}}">{{i.name}}</option>
                      </select>
                    </td>
                    <td>
                      <select class="form-control input-sm" formControlName="ware_types" id="ware_types"
                        style="width:100%;">
                        <option value=""></option>
                        <option *ngFor="let i of lists.wareTypes" value="{{i.id}}">{{i.name}}</option>
                      </select>
                    </td>
                    <td><input type="number" class="form-control input-sm" formControlName="ware_boxes"></td>
                    <td>
                      <div class="btn-group btn-group-xs">
                        <button class="btn btn-white btn-xs" type="button"
                          title="{{'general.save-changes' | translate}}" (click)="saveCurrentWare()"><i
                            class="fa fa-check" aria-hidden="true"></i></button>

                        <button class="btn btn-white btn-xs" type="button" title="{{'general.cancel' | translate}}"
                          (click)="cancelCurrentWare()"><i class="fa fa-times" aria-hidden="true"></i></button>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="!serviceStatus.wareReadySave">
                    <td colspan="7">
                      <div class="invalid-feedback-custom">
                        {{'validations.data-not-valid' | translate }}
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>

              <div class="alert alert-warning m-t-xs" *ngIf="hubs.notFound.length > 0">
                <h5>{{'page-services-terrestrial-edit.hubsnotfound-title' | translate}}:</h5>
                <small *ngFor="let i of hubs.notFound">{{i.name}}<br /></small>
              </div>

            </div>
            <div *ngIf="!serviceStatus.wareIsOK" class="invalid-feedback-custom">
              {{'validations.isrequired' | translate }} </div>


            <div class="row m-t-md">
              <span class="col-auto text-muted">{{'page-services-terrestrial-edit.totalkm' | translate}}:&nbsp;&nbsp;
                {{currentService.totalKm | number : '1.2-2'}}</span>
              <span class="col-auto text-muted">{{'page-services-terrestrial-edit.totalhours' | translate}}:&nbsp;&nbsp;
                {{currentService.totalHours | number : '1.2-2'}}</span>

              <span class="col-auto text-muted">{{'page-services-terrestrial-edit.text-cost' | translate}}:&nbsp;&nbsp;
                {{(f.service_costtotal.value - f.service_costtodiscount.value) | number : '1.2-2'}} &nbsp;
                ({{calculateCost | number : '1.2-2'}})</span>

              <span
                class="col-auto text-muted">{{'page-services-terrestrial-edit.costperkilometer' | translate}}:&nbsp;&nbsp;
                {{currentService.costPerKilometer | number : '1.2-2'}}</span>
              <span
                class="col-auto text-muted">{{'page-services-terrestrial-edit.totalpallets' | translate}}:&nbsp;&nbsp;
                {{currentService.totalPallets | number : '1.2-2'}}</span>

            </div>


            <div *ngIf="currentService.status != 2" class="row m-t-lg">

              <div class="col text-left">
                <button
                  *ngIf="canDoInvoice && currentService.costsEstimated.errors.length == 0 && currentService.costsEstimated.costs.length > 0"
                  type="button" class="ladda-button btn btn-sm btn-success m-r-sm" id="invoiceServiceButton"
                  (click)="invoiceService()">{{'page-services-terrestrial-edit.text-invoice' | translate}}</button>


                <button *ngIf="canDoFinalize" type="button" class="ladda-button btn btn-sm btn-white m-r-sm"
                  id="finalizeServiceButton"
                  (click)="finalizeService()">{{'page-services-terrestrial-edit.text-finalize' | translate}}</button>

                <!--
                <div *ngIf="canDoInvoice" class="btn-group">
                  <button type="button" class="btn btn-sm btn-sm btn-white dropdown-toggle dropdown-toggle-split"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="#">Action</a>
                  </div>
                  <button type="button" class="btn btn-sm btn-sm btn-white"
                    (click)="invoiceService()">{{'page-services-terrestrial-edit.text-invoice' | translate}}</button>
                </div>

                                  -->

              </div>

              <div class="col-auto text-right">
                <button *ngIf="canEdit.service" type="button" class="ladda-button btn btn-sm btn-primary"
                  (click)="saveServiceData()">{{'general.save-changes' | translate}}</button>

              </div>

            </div>




          </div>
        </div>
      </div>

      <div class="col-auto" style="width: 400px;">

        <div class="ibox">
          <div class="ibox-content">

            <!--
            <h3 class="m-b-md">{{'page-services-terrestrial-edit.edit-status' | translate}}</h3>

            <div [ngClass]="{ 'is-invalid': serviceDataFormSubmitted && f.service_status.errors }">
              <select class="form-control input-sm" formControlName="service_status" id="service_status"
                style="width:100%;" [ngClass]="{ 'is-invalid': serviceDataFormSubmitted && f.service_status.errors }">
                <option *ngFor="let s of lists.status" value="{{s.id}}">{{s.description}}</option>
              </select>
              <div *ngIf="serviceDataFormSubmitted && f.service_status.errors" class="invalid-feedback">
                <div *ngIf="f.service_status.errors.required">
                  {{'validations.isrequired' | translate }} </div>
              </div>
            </div>
            <br />
            <div class="hr-line-dashed"></div>
          -->

            <div
              [ngClass]="{ 'invalid-feedback-border-custom p-xs': !serviceStatus.routeIsOK || serviceStatus.warePending }">
              <div class="row m-b-sm">
                <div class="col-sm">
                  <h3>{{'page-services-terrestrial-edit.edit-route' | translate}}</h3>
                </div>
                <div class="col-sm-auto">
                  <div *ngIf="canEdit.route" class="btn-group btn-group-xs">
                    <button class="btn btn-default btn-xs"
                      (click)="openSelectRouteWindow()">{{'page-services-terrestrial-edit.routeselectionwindow-title' | translate}}</button>

                    <button *ngIf="serviceStatus.routeIsOK" class="btn btn-default btn-xs btn-outline"
                      title="{{'general.reload' | translate}}" (click)="reloadRoute()"><i class="fa fa-refresh"
                        aria-hidden="true"></i>
                    </button>

                    <button *ngIf="serviceStatus.routeIsOK" class="btn btn-default btn-xs btn-outline"
                      title="{{'general.clean' | translate}}" (click)="clearRoute()"><i class="fa fa-times"
                        aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="alert alert-warning small" *ngIf="serviceStatus.wareWithoutHub">
                {{'page-services-terrestrial-edit.wareWithoutHub-title' | translate}}
              </div>

              <div id="vertical-timeline" class="vertical-container dark-timeline">
                <div *ngFor="let s of currentService.stops; let i = index" class="vertical-timeline-block">
                  <div class="vertical-timeline-icon {{getStopBackground(i)}}">
                    <strong>{{letras[i]}}</strong></div>
                  <div class="vertical-timeline-content small {{getStopTextColor(i)}}">
                    <div class="m-b-xs">
                      <strong class="">{{s.hubName}} </strong>
                      <span class="text-muted" *ngIf="i != currentService.stops.length -1">
                        <br />{{s.km | number : '1.2-2'}} km, {{s.hours | number : '1.2-2'}} h &nbsp;
                        ({{s.partialBoxes}} {{'page-services-terrestrial-edit.text-boxes' | translate}})

                        <br /> {{s.partialCost | number : '1.2-2'}} € &nbsp;
                        ({{s.costPerBox | number : '1.2-2'}} € x
                        {{'page-services-terrestrial-edit.costperbox' | translate}})

                      </span>
                    </div>
                    <span *ngFor="let b of s.boxInfo">
                      {{b.boxes}} {{'page-services-terrestrial-edit.text-boxes' | translate}} &nbsp;
                      <span class="text-muted">{{b.pallets}}
                        {{'page-services-terrestrial-edit.text-pallets' | translate}} &nbsp;
                        <span
                          title="{{b.costPerBox | number : '1.2-2'}} € x {{'page-services-terrestrial-edit.costperbox' | translate}}">{{b.clientName}}
                          ({{b.destinationCountryId}})</span>
                      </span><br>
                    </span>
                  </div>
                </div>
              </div>


            </div>
            <div *ngIf="!serviceStatus.routeIsOK" class="invalid-feedback-custom">
              {{'validations.isrequired' | translate }} </div>

          </div>
        </div>

      </div>

      <div class="col-sm-12">
        <div class="ibox"
          *ngIf="currentService.costsEstimated.costs.length>0 ||currentService.costsEstimated.errors.length>0">
          <div class="ibox-content">
            <h3 class="m-b-md">{{'page-services-terrestrial-edit.edit-associated-costsestimated' | translate}}</h3>

            <div class="alert alert-warning small" *ngIf="currentService.costsEstimated.errors.length>0">
              <div *ngFor="let e of currentService.costsEstimated.errors">{{e}}</div>
            </div>

            <p-table [value]="currentService.costsEstimated.costs" sortMode="multiple" selectionMode="single"
              styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [autoLayout]="true"
              [columnResizeMode]="'expand'" [reorderableColumns]="true" [resizableColumns]="true">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="hubSouceName">
                    {{'page-services-terrestrial-edit.columname-hubsource' | translate}}
                    <p-sortIcon field="hubSouceName"></p-sortIcon>
                  </th>

                  <th pSortableColumn="hubTargetName">
                    {{'page-services-terrestrial-edit.columname-hubtarget' | translate}}
                    <p-sortIcon field="hubTargetName"></p-sortIcon>
                  </th>

                  <th pSortableColumn="wareClient">
                    {{'page-services-terrestrial-edit.columname-client-tkt' | translate}}
                    <p-sortIcon field="wareClient"></p-sortIcon>
                  </th>

                  <th pSortableColumn="boxes">
                    {{'page-services-terrestrial-edit.columname-boxs' | translate}}
                    <p-sortIcon field="boxes"></p-sortIcon>
                  </th>

                  <th pSortableColumn="clientName">
                    {{'page-services-terrestrial-edit.columname-client-conta' | translate}}
                    <p-sortIcon field="clientName"></p-sortIcon>
                  </th>

                  <th pSortableColumn="serviceCode">
                    {{'page-services-terrestrial-edit.columname-service' | translate}}
                    <p-sortIcon field="serviceCode"></p-sortIcon>
                  </th>

                  <th pSortableColumn="costId">
                    {{'page-services-terrestrial-edit.columname-cost' | translate}}
                    <p-sortIcon field="costId"></p-sortIcon>
                  </th>

                  <th pSortableColumn="costValue">
                    {{'page-services-terrestrial-edit.columname-costvalue' | translate}}
                    <p-sortIcon field="costValue"></p-sortIcon>
                  </th>

                  <th pSortableColumn="pallets">
                    {{'page-services-terrestrial-edit.columname-pallets' | translate}}
                    <p-sortIcon field="pallets"></p-sortIcon>
                  </th>

                  <th pSortableColumn="costDate">
                    {{'page-services-terrestrial-edit.columname-costdate' | translate}}
                    <p-sortIcon field="costDate"></p-sortIcon>
                  </th>

                  <th pSortableColumn="serviceInvoice">
                    {{'page-services-terrestrial-edit.columname-serviceinvoice' | translate}}
                    <p-sortIcon field="serviceInvoice"></p-sortIcon>
                  </th>

                  <th pSortableColumn="costInvoice">
                    {{'page-services-terrestrial-edit.columname-costinvoice' | translate}}
                    <p-sortIcon field="costInvoice"></p-sortIcon>
                  </th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-cost>
                <tr [ngClass]="{'row-disable' : cost.costBilled}">
                  <td>{{cost.hubSouceName}}</td>
                  <td>{{cost.hubTargetName}}</td>
                  <td>{{cost.wareClient}}</td>
                  <td>{{cost.boxes}}</td>
                  <td>{{cost.clientName}}</td>
                  <td>{{cost.serviceCode}}</td>
                  <td>{{cost.costId}}</td>
                  <td>{{cost.costValue | number : '1.2-2'}}</td>
                  <td>{{cost.pallets}}</td>
                  <td>{{cost.costDate | date: ('general.format-date' | translate)}}</td>
                  <td>{{cost.serviceInvoice}}</td>
                  <td>{{cost.costInvoice}}</td>
                </tr>
              </ng-template>
            </p-table>



          </div>
        </div>

        <div class="ibox" *ngIf="currentService.costs.length>0">
          <div class="ibox-content">
            <h3 class="m-b-md">{{'page-services-terrestrial-edit.edit-associated-costs' | translate}}</h3>

            <p-table [value]="currentService.costs" sortMode="multiple" selectionMode="single"
              styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [autoLayout]="true"
              [columnResizeMode]="'expand'" [reorderableColumns]="true" [resizableColumns]="true">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="hubSouceName">
                    {{'page-services-terrestrial-edit.columname-hubsource' | translate}}
                    <p-sortIcon field="hubSouceName"></p-sortIcon>
                  </th>

                  <th pSortableColumn="hubTargetName">
                    {{'page-services-terrestrial-edit.columname-hubtarget' | translate}}
                    <p-sortIcon field="hubTargetName"></p-sortIcon>
                  </th>

                  <th pSortableColumn="wareClient">
                    {{'page-services-terrestrial-edit.columname-client-tkt' | translate}}
                    <p-sortIcon field="wareClient"></p-sortIcon>
                  </th>

                  <th pSortableColumn="boxes">
                    {{'page-services-terrestrial-edit.columname-boxs' | translate}}
                    <p-sortIcon field="boxes"></p-sortIcon>
                  </th>

                  <th pSortableColumn="clientName">
                    {{'page-services-terrestrial-edit.columname-client-conta' | translate}}
                    <p-sortIcon field="clientName"></p-sortIcon>
                  </th>

                  <th pSortableColumn="serviceCode">
                    {{'page-services-terrestrial-edit.columname-service' | translate}}
                    <p-sortIcon field="serviceCode"></p-sortIcon>
                  </th>

                  <th pSortableColumn="costId">
                    {{'page-services-terrestrial-edit.columname-cost' | translate}}
                    <p-sortIcon field="costId"></p-sortIcon>
                  </th>

                  <th pSortableColumn="costValue">
                    {{'page-services-terrestrial-edit.columname-costvalue' | translate}}
                    <p-sortIcon field="costValue"></p-sortIcon>
                  </th>

                  <th pSortableColumn="pallets">
                    {{'page-services-terrestrial-edit.columname-pallets' | translate}}
                    <p-sortIcon field="pallets"></p-sortIcon>
                  </th>

                  <th pSortableColumn="costDate">
                    {{'page-services-terrestrial-edit.columname-costdate' | translate}}
                    <p-sortIcon field="costDate"></p-sortIcon>
                  </th>

                  <th pSortableColumn="serviceInvoice">
                    {{'page-services-terrestrial-edit.columname-serviceinvoice' | translate}}
                    <p-sortIcon field="serviceInvoice"></p-sortIcon>
                  </th>

                  <th pSortableColumn="costInvoice">
                    {{'page-services-terrestrial-edit.columname-costinvoice' | translate}}
                    <p-sortIcon field="costInvoice"></p-sortIcon>
                  </th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-cost>
                <tr [ngClass]="{'row-disable' : cost.costBilled}">
                  <td>{{cost.hubSouceName}}</td>
                  <td>{{cost.hubTargetName}}</td>
                  <td>{{cost.wareClient}}</td>
                  <td>{{cost.boxes}}</td>
                  <td>{{cost.clientName}}</td>
                  <td>{{cost.serviceCode}}</td>
                  <td>{{cost.costId}}</td>
                  <td>{{cost.costValue | number : '1.2-2'}}</td>
                  <td>{{cost.pallets}}</td>
                  <td>{{cost.costDate | date: ('general.format-date' | translate)}}</td>
                  <td>{{cost.serviceInvoice}}</td>
                  <td>{{cost.costInvoice}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>


<!-- ventana modal de selección de ruta -->

<div class="modal inmodal fade" id="routeSelectWindow" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-full">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">
          {{'page-services-terrestrial-edit.routeselectionwindow-title' | translate}}</h4>
        <small class="font-bold"></small>

      </div>
      <div class="modal-body" id="panelSelectRoute" style="background-color: #FFFFFF;">

        <div class="row">
          <div class="col-12">

            <div class="float-right m-b-lg">
              <div class="form-inline">
                <div class="input-group">

                  <input style="width: 300px;" type="text" class="form-control input-sm"
                    [(ngModel)]="routesSearchData.value" placeholder="{{'general.search' | translate}}..."
                    (keyup.enter)="getRoutesList()">

                  <span class="input-group-append">
                    <button class="btn btn-white btn-sm" (click)="getRoutesList()" type="button"
                      title="{{'general.search' | translate}}">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </span>

                  <span class="input-group-append">
                    <button class="btn btn-white btn-sm" type="button" data-toggle="collapse" data-parent="#panelSearch"
                      href="#panelAdvancedSearch" aria-expanded="false" title="{{'search.title' | translate}}">
                      <i class="fa fa-wrench" aria-hidden="true"></i>
                    </button>
                  </span>

                </div>
              </div>
            </div>

            <div id="panelSearch">
              <div id="panelAdvancedSearch" class="panel-collapse collapse">
                <h2 class="search-panel-title">{{'search.title' | translate}}</h2>
                <small class="font-bold">{{'search.title-description' | translate}}</small>
                <div class="hr-line-dashed"></div>

                <div class="form-group form-inline">
                  <label class="m-r-md" style="width: 100%; justify-content: left;">
                    Hubs:
                    &nbsp;&nbsp;
                    <select class="form-control form-control-search" style="width: 85%;" multiple="multiple"
                      id="select2HubsFilter">
                      <option *ngFor="let item of lists.hubsFilter" value="{{item.id}}">
                        {{item.name}}</option>
                    </select>
                  </label>
                </div>

                <!--
                  <div class="form-group form-inline m-t-md">
                    <label class="checkbox-inline m-r-xl">
                      <input type="checkbox" [(ngModel)]="searchRouteFilters.avoidTolls">&nbsp;
                      {{'page-administracion-routes.editwindowvalue-avoidtolls' | translate}}</label>

                    <label class="checkbox-inline m-r-xl">
                      <input type="checkbox" [(ngModel)]="searchRouteFilters.avoidHighways">&nbsp;
                      {{'page-administracion-routes.editwindowvalue-avoidhighways' | translate}}</label>

                    <label class="checkbox-inline">
                      <input type="checkbox" [(ngModel)]="searchRouteFilters.avoidFerries">&nbsp;
                      {{'page-administracion-routes.editwindowvalue-avoidferries' | translate}}</label>
                  </div>
-->

                <div class="hr-line-dashed"></div>
              </div>
            </div>

          </div>
        </div>

        <p-table [value]="routesList" sortMode="multiple" selectionMode="single"
          styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [paginator]="true" [rows]="10"
          [showCurrentPageReport]="true" [autoLayout]="true" [(first)]="routesFirst"
          currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
          [rowsPerPageOptions]="[10,25,50,100,200]" [columnResizeMode]="'expand'" [reorderableColumns]="true"
          [resizableColumns]="true">
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="id">#<p-sortIcon field="id"></p-sortIcon>
              </th>
              <th pSortableColumn="name">
                {{'page-administracion-routes.columname-name' | translate}}
                <p-sortIcon field="name"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-route>
            <tr>
              <td> {{route.id}} </td>
              <td>
                <div class="cursor-pointer" (click)="selectRoute(route.id, true)">
                  {{getRouteListName(route.name,route.description)}}
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>
      </div>
    </div>
  </div>
</div>

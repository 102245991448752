import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HubInfo } from '../models/hubs.model';
import { RouteInfo } from '../models/routes.model';
import { ClientConfig, ClientContaData, ClientHubsInfo } from '../models/clients.model';
import { SearchData } from '../models/searches.model';
import * as myGlobals from '../globals';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  constructor(private http: HttpClient) { }

  public RouteManagementGetList(searchData: SearchData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/RouteManagementGetList', searchData,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public RouteManagementGetRouteHubList(searchData: SearchData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/RouteManagementGetRouteHubList', searchData,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public RouteManagementSetEnable(idRoute: number) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/RouteManagementSetEnable?idRoute=' + idRoute,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public RouteManagementSetDisable(idRoute: number) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/RouteManagementSetDisable?idRoute=' + idRoute,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public RouteManagementGetData(idRoute: number) {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'route/RouteManagementGetData?idRoute=' + idRoute,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public RouteManagementSaveData(route: RouteInfo) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/RouteManagementSaveData', route,
      {
        headers: new HttpHeaders()
      });
  }



  public HubManagementGetList(searchData: SearchData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/HubManagementGetList', searchData,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public HubManagementSetEnable(idHub: number) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/HubManagementSetEnable?idHub=' + idHub,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public HubManagementSetDisable(idHub: number) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/HubManagementSetDisable?idHub=' + idHub,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public HubManagementGetData(idHub: number) {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'route/HubManagementGetData?idHub=' + idHub,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public HubManagementSaveData(hub: HubInfo) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/HubManagementSaveData', hub,
      {
        headers: new HttpHeaders()
      });
  }

  public ClientsManagementGetConfigClientList() {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'route/ClientsManagementGetConfigClientList',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public ClientsManagementGetConfigList(searchData: SearchData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/ClientsManagementGetConfigList', searchData,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public ClientsManagementGetConfig(idclient: number) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/ClientsManagementGetConfig?idclient=' + idclient,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }


  public ClientsManagementSetConfig(conf: ClientConfig) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/ClientsManagementSetConfig', conf,
      {
        headers: new HttpHeaders()
      });
  }


  public ClientsManagementDeleteConfig(idclient: number) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/ClientsManagementDeleteConfig?idclient=' + idclient,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }


  public ClientsManagementContaGetDataList(data: SearchData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/ClientsManagementContaGetDataList', data,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public ClientsManagementContaGetData(data: ClientContaData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/ClientsManagementContaGetData', data,
      {
        headers: new HttpHeaders()
      });
  }

  public ClientsManagementContaSaveConfig(data: ClientContaData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/ClientsManagementContaSaveConfig', data,
      {
        headers: new HttpHeaders()
      });
  }

  public ClientsManagementContaDeleteData(data: ClientContaData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/ClientsManagementContaDeleteData', data,
      {
        headers: new HttpHeaders()
      });
  }

  public RouteManagementGetServiceTypeList() {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'route/RouteManagementGetServiceTypeList',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public RouteManagementGetWareTypeList() {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'route/RouteManagementGetWareTypeList',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }


  public ClientsManagementHubsDataList(data: SearchData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/ClientsManagementHubsDataList', data,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }


  public ClientsManagementHubsGetData(data: ClientHubsInfo) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/ClientsManagementHubsGetData', data,
      {
        headers: new HttpHeaders()
      });
  }

  public ClientsManagementHubsSaveData(data: ClientHubsInfo) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/ClientsManagementHubsSaveData', data,
      {
        headers: new HttpHeaders()
      });
  }

  public ClientsManagementHubsSetDisable(data: ClientHubsInfo) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/ClientsManagementHubsSetDisable', data,
      {
        headers: new HttpHeaders()
      });
  }

  public ClientsManagementHubsSetEnable(data: ClientHubsInfo) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/ClientsManagementHubsSetEnable', data,
      {
        headers: new HttpHeaders()
      });
  }



  public ClientsManagementHubsDeleteData(data: ClientHubsInfo) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'route/ClientsManagementHubsDeleteData', data,
      {
        headers: new HttpHeaders()
      });
  }



  public GetProveedores() {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'route/GetProveedores',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public GetTiposVehiculos() {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'route/GetTiposVehiculos',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpRequest, HttpEventType, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, interval, Subscription } from 'rxjs';
import { Roles } from '../../../enums/roles.enum';
import { saveAs } from 'file-saver';
import { SearchData } from '../../../models/searches.model';
import * as myGlobals from '../../../globals';

declare var $: any;

@Component({
  selector: 'app-amazon-invoices',
  templateUrl: './amazon-invoices.component.html',
  styleUrls: ['./amazon-invoices.component.css']
})
export class AmazonInvoicesComponent implements OnInit {

  waitAMoment: boolean = false;
  addTaskDataForm: FormGroup;
  addTaskDataFormSubmitted: boolean = false;

  public loading: boolean;
  public searchData: SearchData = new SearchData();
  private updateTaskListSubscription: Subscription;

  public userIsAdmin: boolean = false;

  public addTaskForm: {
    description: string;
    type: number;
    file: File | null;
    messageStatus: string;
  };

  public showAllTask: boolean = false;
  public progress: number;
  public message: string;
  public tasks: InvoicesTaskInfo[];
  public eventsList: InvoicesTaskEvent[];
  public eventsTaskId: number;
  public firstTasks = 0;

  constructor(private http: HttpClient, private formBuilder: FormBuilder) { }

  // convenience getter for easy access to form fields
  get c() { return this.addTaskDataForm.controls; }

  resetAddTaskFormData() {
    this.addTaskForm = {
      description: "",
      type: 0,
      file: null,
      messageStatus: null
    };

    this.addTaskDataForm.setValue({
      addTask_type: "",
      addTask_description: "",
      addTask_file: null
    });

    this.progress = 0;
    this.addTaskDataFormSubmitted = false;
    this.setUploadFilenameLabel("");
  }

  ngOnInit() {
    let user = myGlobals.userGetData();
    this.userIsAdmin = false;
    if (myGlobals.userHasRole(Roles.Admin, user)) this.userIsAdmin = true;

    this.addTaskDataForm = this.formBuilder.group({
      addTask_description: ['', [Validators.required]],
      addTask_type: ['', [Validators.required]],
      addTask_file: [null, [Validators.required]]
    });

    this.resetAddTaskFormData();
    this.searchData.disabled = false;

    this.getTaskList();
    // Refescamos la lista cada 30 segundos.
    this.updateTaskListSubscription = interval(10000).subscribe(
      (val) => {
        this.getTaskList()
      }
    );
  }

  showAddTaskWindow() {
    this.resetAddTaskFormData();
    this.addTaskWindowToggle();
  }

  addTaskWindowToggle() {
    $('#addTaskWindow').modal('toggle');
  }

  taskHistoryuWindowToggle() {
    $('#taskHistoryWindow').modal('toggle');
  }


  setUploadFilenameLabel(fileName: string) {
    $('.custom-file-input').next('.custom-file-label').addClass("selected").html(fileName);
  }


  setFile(files) {
    this.setUploadFilenameLabel("");

    if (files.length === 0)
      return;
    this.addTaskForm.file = files[0];
    this.setUploadFilenameLabel(this.addTaskForm.file.name);
  }

  addTask() {
    this.addTaskForm.type = this.c.addTask_type.value;
    this.addTaskForm.description = this.c.addTask_description.value;
    this.addTaskDataFormSubmitted = true;

    // stop here if form is invalid
    if (this.addTaskDataForm.invalid) {
      return;
    }

    const formData = new FormData();
    formData.append(this.addTaskForm.file.name, this.addTaskForm.file);

    const uploadReq = new HttpRequest('POST', myGlobals.RootUrlTikitingApi + 'AmazonInvoices/AddTask?type=' + this.addTaskForm.type + '&description=' + this.addTaskForm.description, formData, {
      reportProgress: true,
    });

    this.http.request(uploadReq).subscribe(event => {
      if (event.type == HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);
      }
      if (this.progress == 100) {
        //this.resetAddTaskFormData();
        this.addTaskWindowToggle();
        this.getTaskList();
        this.progress = 0;
      }
    });
  }

  generateEdi(idTask: number) {

    let service = this.http.post(myGlobals.RootUrlTikitingApi + 'AmazonInvoices/GenerateEdi?idTask=' + idTask,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );

    service.subscribe(() => {
      //this.getTaskList();
      this.waitAMoment = true;
    }
      , (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      });
  }

  cancelTask(idTask: number) {
    var r = confirm("Are you sure you want to cancel the task?");
    if (r == true) {

      let service = this.http.post(myGlobals.RootUrlTikitingApi + 'AmazonInvoices/CancelTask?idTask=' + idTask,
        {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json'),
          observe: 'response'
        }
      );

      service.subscribe(() => {
        //this.getTaskList();
        this.waitAMoment = true;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

    }
  }

  stopGenerateEDI(idTask: number) {
    var r = confirm("Are you sure you want to stop the task?");
    if (r == true) {

      let service = this.http.post(myGlobals.RootUrlTikitingApi + 'AmazonInvoices/StopGenerateEDI?idTask=' + idTask,
        {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json'),
          observe: 'response'
        }
      );

      service.subscribe(() => {
        //this.getTaskList();
        this.waitAMoment = true;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

    }
  }

  sendEdi(idTask: number) {
    var r = confirm("Are you sure you want to send the EDI file to Pilot?");
    if (r == true) {

      let service = this.http.post(myGlobals.RootUrlTikitingApi + 'AmazonInvoices/SendEdi?idTask=' + idTask,
        {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json'),
          observe: 'response'
        }
      );

      service.subscribe(() => {
        //this.getTaskList();
        this.waitAMoment = true;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

    }
  }

  downloadCsv(idTask: number, filename: string) {
    let service = this.http.post(myGlobals.RootUrlTikitingApi + 'AmazonInvoices/DownloadCSV', idTask,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        responseType: 'blob' as 'json'
      });

    service.subscribe((data: any) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      saveAs(blob, filename);
    }
      , (error: HttpErrorResponse) => {
        myGlobals.showMessage(error.message, myGlobals.MessageType.Error);
      });
  }

  downloadEdi(idTask: number, filename: string) {
    let service = this.http.post(myGlobals.RootUrlTikitingApi + 'AmazonInvoices/DownloadEDI', idTask,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        responseType: 'blob' as 'json'
      });

    service.subscribe((data: any) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      saveAs(blob, filename);
    }
      , (error: HttpErrorResponse) => {
        myGlobals.showMessage(error.message, myGlobals.MessageType.Error);
      });
  }

  exportExcel() {
    myGlobals.exportExcel(this.tasks.map(x => ({
      "Id": x.id,
      "Date": x.date,
      "Description": x.description,
      "Status": x.statusDescription,
      "CSV": x.csvFilename,
      "EDI": x.ediFilename
    })));
  }

  getTaskList() {
    let service = this.http.post(myGlobals.RootUrlTikitingApi + 'AmazonInvoices/GetTaskList', this.searchData,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });

    service.subscribe((data: any) => {
      this.tasks = data.body;
      this.firstTasks = 0;
      this.waitAMoment = false;
    }
      , (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      });
  }

  getEventsList(idTask: number) {
    let service = this.http.get(myGlobals.RootUrlTikitingApi + 'AmazonInvoices/GetEventsList?idTask=' + idTask,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );

    service.subscribe((data: any) => {
      this.eventsList = data.body;
      this.eventsTaskId = idTask;
      this.taskHistoryuWindowToggle();
    }
      , (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      });
  }


  taskInProgress(status: number) {
    if (status == 1) { return true; }
    else { return false; }
  }

  taskCompletedOrCanceled(status: number) {
    if (status < 0) { return true; }
    else { return false; }
  }

  canSendEdi(status: number) {
    if (status == 4 || status == 7) { return true; }
    else { return false; }
  }

  closeEventsList() {
    this.eventsList = null;
    this.eventsTaskId = 0;
  }
}

export class InvoicesTaskInfo {
  id: number;
  date: Date;
  description: string;
  status: number;
  statusDescription: string;
  statusProgress: number;
  csvFilename: string;
  ediFilename: string;
  sent: boolean;
  events: InvoicesTaskEvent[];
}

export class InvoicesTaskEvent {
  date: Date;
  description: string;
}





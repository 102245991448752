<!-- ventana principal -->


<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{'page-home-tasks.title' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations' | translate}}
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.tasks' | translate}}
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.tasklines' | translate}}
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{'page-home-tasks.title-description' | translate}}</em></p>
  </div>
</div>
<div id="wrapper">
  <div class="row">
    <div class="row animated fadeInRight">
      <div class="col-sm-9">
        <div class="wrapper wrapper-content animated fadeInUp">
          <div class="ibox" id="taskManagement">
            <div class="ibox-content">
              <div class="row">
                <div class="col-lg-12">
                  <div class="m-b-md">
                    <div *ngIf="this.currentTaskId != 0; else elseButton" class="m-b-md"><button (click)="editTask()"
                        class="btn btn-white btn-xs float-right">{{'general.edit' | translate}}</button>
                    </div>
                    <ng-template #elseButton>
                      <button (click)="saveTaskData()"
                        class="btn btn-success btn-xs float-right">{{'general.add' | translate}}
                      </button>
                    </ng-template>
                    <h2>{{'page-task-lines.tarea' | translate}}</h2>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div *ngIf="this.currentTaskId != 0; else elseBlock" class="col-lg-6">
                    <dl class="row mb-0">
                      <div class="col-sm-4 text-sm-right">
                        <dt>{{'page-tasks.estado' | translate}}:</dt>
                      </div>
                      <div class="col-sm-6 text-sm-left">
                        <dd *ngIf="this.currentTaskData.estado == '0'" class="mb-1"><span
                            class="label label-primary">{{'page-tasks.activa' | translate}}</span></dd>
                        <dd *ngIf="this.currentTaskData.estado == '1'" class="mb-1"><span
                            class="label label-secondary">{{'page-tasks.cancelada' | translate}}</span></dd>
                        <dd *ngIf="this.currentTaskData.estado == '2'" class="mb-1"><span
                            class="label label-danger">{{'page-tasks.eliminada' | translate}}</span></dd>
                        <dd *ngIf="this.currentTaskData.estado == '3'" class="mb-1"><span
                            class="label label-info">{{'page-tasks.finalizada' | translate}}</span></dd>
                        <dd *ngIf="this.currentTaskData.estado == '4'" class="mb-1"><span
                            class="label label-warning">{{'page-tasks.pausada' | translate}}</span></dd>
                      </div>
                    </dl>
                    <dl class="row mb-0">
                      <div class="col-sm-4 text-sm-right">
                        <dt>{{'page-tasks.cliente' | translate}}:</dt>
                      </div>
                      <div class="col-sm-4 text-sm-left">
                        <dd class="mb-1">{{this.currentTaskData.cliente}}</dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <div *ngIf="this.currentTaskId != 0" class="col-lg-6">
                  <dl class="row mb-0">
                    <div class="col-sm-4 text-sm-right">
                      <dt>{{'page-tasks.fechaCreacion' | translate}} :</dt>
                    </div>
                    <div class="col-sm-8 text-sm-left">
                      <dd class="mb-1">{{this.currentTaskData.fechaAlta | date: ('general.format-date' | translate)}}
                      </dd>
                    </div>
                  </dl>
                  <dl class="row mb-0">
                    <div class="col-sm-4 text-sm-right">
                      <dt>{{'page-tasks.fechaFin' | translate}} :</dt>
                    </div>
                    <div class="col-sm-8 text-sm-left">
                      <dd class="mb-1">{{this.currentTaskData.fechaFin | date: ('general.format-date' | translate)}}
                      </dd>
                    </div>
                  </dl>
                  <dl class="row mb-0">
                    <div class="col-sm-4 text-sm-right">
                      <dt>{{'page-tasks.fechaLimite' | translate}} :</dt>
                    </div>
                    <div class="col-sm-8 text-sm-left">
                      <dd class="mb-1">{{this.currentTaskData.fechaLimite | date: ('general.format-date' | translate)}}
                      </dd>
                    </div>
                  </dl>
                  <dl class="row mb-0">
                    <div class="col-sm-4 text-sm-right">
                      <dt>{{'page-tasks.responsable' | translate}}:</dt>
                    </div>
                    <div class="col-sm-8 text-sm-left">
                      <dd class="mb-1">{{this.currentTaskData.responsable}}</dd>
                    </div>
                  </dl>
                  <br>
                </div>

                <div class="row">
                  <div *ngIf="this.currentTaskId != 0" class="col-lg-12">
                    <dl class="row mb-0">
                      <div class="col-sm-2 text-sm-right">
                        <dt>{{'page-task-lines.completado' | translate}}</dt>
                      </div>
                      <div class="col-sm-10 text-sm-left">
                        <dd>
                          <div class="progress m-b-1">
                            <div [ngStyle]="{width: this.currentTaskData.completado + '%'}"
                              class="progress-bar progress-bar-striped progress-bar-animated">
                            </div>
                          </div>
                          <small>{{'page-task-lines.progresocomp' | translate}}
                            <strong>{{this.currentTaskData.completado}} %</strong></small>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <ng-template #elseBlock>
                  <div class="col-lg-12">
                    <form [formGroup]="taskDataForm">
                      <dl class="row mb-0">
                        <div class="col-sm-4 text-sm-right">
                          <dt>{{'page-tasks.estado' | translate}}:</dt>
                        </div>
                        <div class="col-sm-8 text-sm-left">
                          <select name="prio" id="prio" formControlName="task_estado" class="form-control input-sm">
                            <option *ngFor="let p of estados" value="{{p.codigo}}">{{p.codigo}}-{{p.descripcion}}
                            </option>
                          </select>
                        </div>
                      </dl>
                      <dl class="row mb-0">
                        <div class="col-sm-4 text-sm-right">
                          <dt>{{'page-tasks.cliente' | translate}}:</dt>
                        </div>
                        <div class="col-sm-8 text-sm-left">
                          <input type="text" class="form-control input-sm" formControlName="task_cliente">
                        </div>
                      </dl>
                      <dl class="row mb-0">
                        <div class="col-sm-4 text-sm-right">
                          <dt>{{'page-tasks.fechaLimite' | translate}}:</dt>
                        </div>
                        <div class="col-sm-8 text-sm-left">
                          <input type="date" class="form-control input-sm" formControlName="task_fechaLimite">
                        </div>
                      </dl>
                      <dl class="row mb-0">
                        <div class="col-sm-4 text-sm-right">
                          <dt>{{'page-tasks.prioridad' | translate}}:</dt>
                        </div>
                        <div class="col-sm-8 text-sm-left">
                          <select name="prio" id="prio" formControlName="task_prioridad" class="form-control input-sm">
                            <option *ngFor="let p of prioridades" value="{{p.codigo}}">{{p.codigo}}-{{p.descripcion}}
                            </option>
                          </select>
                        </div>
                      </dl>
                      <dl class="row mb-0">
                        <div class="col-sm-4 text-sm-right">
                          <dt>{{'page-tasks.titulo' | translate}}:</dt>
                        </div>
                        <div class="col-sm-8 text-sm-left">
                          <input type="text" class="form-control input-sm" formControlName="task_titulo">
                        </div>
                      </dl>
                      <dl class="row mb-0">
                        <div class="col-sm-4 text-sm-right">
                          <dt>{{'page-tasks.descripcion' | translate}}:</dt>
                        </div>
                        <div class="col-sm-8 text-sm-left">
                          <div [ngxSummernote] formControlName="task_descripcion"></div>
                        </div>
                      </dl>
                      <dl class="row mb-0">
                        <div class="col-sm-4 text-sm-right">
                          <dt>{{'page-tasks.responsable' | translate}}:</dt>
                        </div>
                        <div class="col-sm-8 text-sm-left">
                          <select name="prio" formControlName="task_responsable" class="form-control input-sm" multiple>
                            <option *ngFor="let r of responsables" value="{{r.nombre}}">{{r.nombre}}</option>
                          </select>
                        </div>
                      </dl>
                    </form>
                  </div>
                </ng-template>
              </div>

              <div class="col-lg-12">
                <div class="ibox-title">
                  <h5>{{'page-task-lines.comentariosfeed' | translate}}</h5>
                  <button _ngcontent-rst-c130="" (click)="newLineData(0)" type="button" style="float:right"
                    class="btn btn-sm btn-secondary m-r-sm">Nuevo comentario</button>
                  <div class="ibox-tools">
                    <ul class="dropdown-menu dropdown-user">
                      <li><a href="#" class="dropdown-item">Config option 1</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div class="panel blank-panel">
                    <div class="panel-body">
                      <div class="tab-content">
                        <div class="tab-pane active">
                          <div class="feed-activity-list">
                            <div class="feed-element" *ngFor="let line of taskLinesList">
                              <div class="media-body ">
                                <strong>{{line.usuario}}</strong> {{'page-task-lines.comentariofeed' | translate}}.
                                <br>
                                <button style="float: right;" class="btn btn-default btn-xs"
                                  title="{{'general.delete' | translate}}" (click)="deleteTaskLine(line.idLine)">
                                  <i class="fa fa-trash-o"></i></button>
                                <small
                                  class="text-muted">{{line.fecha | date: ('general.format-date' | translate) }}</small>
                                <div class="m-t-xs"><div [innerHTML]="line.comentario" ></div>
                                </div><br>
                                <div class="btn-group btn-group-xs">
                                  <!-- <button class="btn btn-default btn-xs" title="{{'general.edit' | translate}}"
                      (click)="newLineData(line.idLine)"><i class="fa fa-pencil"></i></button>-->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <h4>{{'page-task-lines.descripcion' | translate }}</h4>
        <p>
          {{this.currentTaskData.tituloProyecto}}
        </p>
          <div [innerHTML]="this.currentTaskData.descripcion"></div>
                        
        <p class="small font-bold">
          <dd class="mb-1">{{this.currentTaskData.prioridad}}-
            <span *ngIf="this.currentTaskData.prioridad == 0" class="mb-1"><i
                class="fa fa-circle text-muted"></i>Indeterminado</span>
            <span *ngIf="this.currentTaskData.prioridad == 1" class="mb-1"><i class="fa fa-circle text-danger"></i>Muy
              urgente</span>
            <span *ngIf="this.currentTaskData.prioridad == 2" class="mb-1"><i
                class="fa fa-circle text-warning"></i>Relevante</span>
            <span *ngIf="this.currentTaskData.prioridad == 3" class="mb-1"><i class="fa fa-circle text-info"></i>No
              urgente</span>
            <span *ngIf="this.currentTaskData.prioridad == 4" class="mb-1"><i class="fa fa-circle text-primary"></i>No
              urgente no importante</span>
          </dd>
        </p>

      </div>

    </div>
  </div>
</div>

<!--ventana modal comentario-->
<div class="modal inmodal fade" id="newLineWindow" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-full">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>
        <h4 class="modal-title modal-title-custom">{{'page-task-lines.title' | translate}}</h4>
      </div>
      <div class="modal-body" id="editTasksPanelEdicion">
        <div class="row">
          <div class="col-sm-12">
            <form [formGroup]="lineDataForm">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-task-lines.comentario' | translate}} </label>
                <div class="col-sm-6">
                  <div [ngxSummernote] formControlName="line_comentario"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
        <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-white"
                data-dismiss="modal">{{'general.close' | translate}}</button>
              <button type="button" class="btn btn-sm btn-primary" data-dismiss="modal"
                (click)="saveLineData()">{{'general.save-changes' | translate}}</button>
        </div>
      </div>
  </div>
</div>

<!-- Modal edicion tarea -->

<div class="modal inmodal fade" id="editTareaWindow" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-full">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>
        <h4 class="modal-title modal-title-custom">{{'page-tasks.action-edit' | translate}}</h4>
      </div>
      <div class="modal-body" style="max-height:calc(100vh - 210px);overflow-y:auto ;" id="editTaskPanelEdicion">

        <div class="row">
          <div class="col-sm-7">

            <form [formGroup]="taskDataForm">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-tasks.titulo' | translate}} </label>
                <div class="col-sm-6">
                  <input type="text" class="form-control input-sm" formControlName="task_titulo">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-tasks.descripcion' | translate}} </label>
                <div class="col-sm-6">
                  <div  [ngxSummernote] formControlName="task_descripcion"></div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-tasks.cliente' | translate}} </label>
                <div class="col-sm-6">
                  <input type="text" class="form-control input-sm" formControlName="task_cliente">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-tasks.prioridad' | translate}} </label>
                <div class="col-sm-6">
                  <select name="prio" id="prio" formControlName="task_prioridad" class="form-control input-sm">
                    <option *ngFor="let p of prioridades" value="{{p.codigo}}">{{p.codigo}}-{{p.descripcion}}</option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-tasks.estado' | translate}} </label>
                <div class="col-sm-8">
                  <select name="prio" id="prio" formControlName="task_estado" class="form-control input-sm">
                    <option *ngFor="let p of estados" value="{{p.codigo}}">{{p.codigo}}-{{p.descripcion}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-tasks.fechaLimite' | translate}} </label>
                <div class="col-sm-8">
                  <input type="date" class="form-control input-sm" formControlName="task_fechaLimite">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-tasks.fechaFin' | translate}} </label>
                <div class="col-sm-8">
                  <input type="date" class="form-control input-sm" formControlName="task_fechaFin">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-tasks.responsable' | translate}} </label>
                <div class="col-sm-3">
                  <select name="prio" formControlName="task_responsable" class="form-control input-sm" multiple>
                    <option *ngFor="let r of responsables" value="{{r.nombre}}">{{r.nombre}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-tasks.completado' | translate}} </label>
                <div class="col-sm-3">
                  <input type="text" class="form-control input-sm" formControlName="task_completado">
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>


        <button type="button" class="btn btn-sm btn-primary" data-dismiss="modal"
          (click)="saveTaskData()">{{'general.save-changes' | translate}}</button>

      </div>
    </div>
  </div>
</div>
<script>
  function move(comp) {
    var elem = document.getElementById("myBar");
    elem.style.width = comp
  }

</script>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { taskInfo } from '../models/tasks.model';
import { RouteInfo } from '../models/routes.model';
import { ClientConfig, ClientContaData, ClientHubsInfo } from '../models/clients.model';
import { SearchData } from '../models/searches.model';
import * as myGlobals from '../globals';
import { TaskLines } from '../models/task-lines.model';

@Injectable({
  providedIn: 'root'
})
export class tasksService {

  constructor(private http: HttpClient) { }

  
  public TasksGetList(searchData: SearchData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Tasks/TasksGetList', searchData,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }
  public TaskManagementGetData(idTask: number) {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'Tasks/TasksGetData?idTask=' + idTask,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public TaskManagementSaveData(task: taskInfo) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Tasks/TasksSaveData', task,
      {
        headers: new HttpHeaders()
      });
  }
  public TaskManagementDelete (idTask:number){
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Tasks/TasksDelete',idTask,
    {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    })
  }
  public TaskLinesGet (idTask:number){
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Tasks/TasksGetTaskLines', idTask,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }
  public TaskLinesAddComment (lineInfo : TaskLines){
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Tasks/TaskNewLine',lineInfo,
    {
      headers: new HttpHeaders()
    });
  }
  public TaskLinesGetComment (idLine: number){
    return this.http.get(myGlobals.RootUrlTikitingApi + 'Tasks/TasksGetComment?idLine=' + idLine,
    {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }
  public TaskLinesDeleteLine (idLine: number){
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Tasks/TaskLinesDelete',idLine,
    {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    })
  }
  public TasksGetPrioridades(idPrio:number) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Tasks/TasksGetPrioridades', idPrio,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }
  public TasksGetResponsables(searchData: SearchData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Tasks/TasksGetResponsables', searchData,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { Roles } from '../../../enums/roles.enum';
import { GeneralOperationsStatus } from '../../../enums/general.enums';
import { CustomsOperations } from '../../../enums/customs.enums';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DocumentArchive } from '../../../models/documents.model';
import { GenericIdName } from '../../../models/general.model';
import { BrexitShipment } from '../../../models/brexit.model';
import { BrexitService } from '../../../services/brexit.service';
import { DocumentsService } from '../../../services/documents.service';
import {
  CustomsExportOperation,
  CustomsImportOperation,
  CustomsInvoice,
  CustomsImportOperationProperties,
  CustomsExportOperationProperties,
  OperationDocument,
} from '../../../models/customs.model';
import { SearchData } from '../../../models/searches.model';
import * as myGlobals from '../../../globals';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isThisMinute } from 'date-fns';
import { CountriesService } from '../../../services/countries.service';
import { Pais } from '../../../models/countries.model';
import { Select2OptionData } from 'ng-select2';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

declare var $: any;

@Component({
  selector: 'app-brexit',
  templateUrl: './brexit.component.html',
})
export class BrexitComponent implements OnInit {
  spinnerCustomsFilesList: any;
  spinnerShipmentList: any;
  modalShipmentCreate: any;
  modalShipmentEdit: any;
  modalExportEdit: any;
  modalImportEdit: any;

  processingData: boolean = false;
  canDelete: boolean = false;

  maxAttachmentSize: number = 15728640;
  getMaxAttachmentSize: string =
    '(max.: ' + this.maxAttachmentSize / (1024 * 1024) + 'Mb)';

  customsFilesSearchData: SearchData = new SearchData();
  customsFilesSearchData_dateFrom: string;
  customsFilesList: BrexitShipment[] = [];
  customsFilesListFirst = 0;

  shipmentsSearchData: SearchData = new SearchData();
  shipmentsSearchData_dateFrom: string;
  shipmentsList: BrexitShipment[] = [];
  shipmentsListFirst = 0;

  countriesList: Array<Select2OptionData>;

  loadingPage: boolean = false;
  loadingAttachment: boolean = false;
  isAdmin: boolean = false;

  shipment: {
    dataForm: FormGroup;
    dataSubmitted: boolean;
    currentData: BrexitShipment;
    operationDocumentList: OperationDocument[];
  };

  import: {
    code: number;
    customTypeList: Array<Select2OptionData>;
    agentList: Array<Select2OptionData>;
    dataForm: FormGroup;
    dataSubmitted: boolean;
    currentData: CustomsImportOperation;
    attachmentTypeList: Array<Select2OptionData>;
    attachmentCurrentData: {
      type: number;
      file: File | null;
      fileSize: number;
      observations: string;
    };
  };

  export: {
    code: number;
    customTypeList: Array<Select2OptionData>;
    agentList: Array<Select2OptionData>;
    dataForm: FormGroup;
    dataSubmitted: boolean;
    currentData: CustomsExportOperation;
    attachmentTypeList: Array<Select2OptionData>;
    attachmentCurrentData: {
      type: number;
      file: File | null;
      fileSize: number;
      observations: string;
    };
  };

  progress: number;

  constructor(
    private brexitService: BrexitService,
    private countriesService: CountriesService,
    private documentsService: DocumentsService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) {}

  get f() {
    return this.shipment.dataForm.controls;
  }
  get e() {
    return this.export.dataForm.controls;
  }
  get i() {
    return this.import.dataForm.controls;
  }

  ngOnInit(): void {
    let user = myGlobals.userGetData();

    this.shipmentsSearchData.showStatusSearch = false;
    if (myGlobals.userHasRoles([Roles.Admin, Roles.OperacionesAdmin], user))
      this.shipmentsSearchData.showStatusSearch = true;
    if (myGlobals.userHasRoles([Roles.Admin], user)) this.isAdmin = true;
    if (myGlobals.userHasRoles([Roles.Admin], user)) this.canDelete = true;

    let date = new Date(Date.now());
    this.shipmentsSearchData_dateFrom = this.datePipe.transform(
      new Date(date.setMonth(date.getMonth() - 1)),
      'yyyy-MM-dd'
    );
    this.customsFilesSearchData_dateFrom = this.datePipe.transform(
      new Date(date.setMonth(date.getMonth())),
      'yyyy-MM-dd'
    );

    this.shipment = {
      dataForm: this.formBuilder.group({
        route_name: ['', [Validators.required, Validators.maxLength(10)]],
        txtIdFichero: ['', [Validators.required, Validators.maxLength(200)]],
      }),
      dataSubmitted: false,
      currentData: new BrexitShipment(),
      operationDocumentList: [],
    };

    this.import = {
      code: 1101,
      customTypeList: [],
      agentList: [],
      dataForm: this.formBuilder.group({
        import_customsType_id: [
          { value: '', disabled: false },
          [Validators.required],
        ],
        import_agent_id: ['', [Validators.required]],
        import_invoices: ['', [Validators.maxLength(50)]],
        import_date: ['', [Validators.required]],
        import_dateRequest: [''],
        import_mrn: ['', [Validators.required, Validators.maxLength(50)]],
        import_truckRegistration: ['', [Validators.maxLength(50)]],
        import_truckCountry: [''],
      }),
      dataSubmitted: false,
      currentData: new CustomsImportOperation(),
      attachmentTypeList: [],
      attachmentCurrentData: {
        type: 0,
        file: null,
        fileSize: 0,
        observations: '',
      },
    };

    this.export = {
      code: 1102,
      customTypeList: [],
      agentList: [],
      dataForm: this.formBuilder.group({
        export_customsType_id: [
          { value: '', disabled: false },
          [Validators.required],
        ],
        export_agent_id: ['', [Validators.required]],
        export_invoices: ['', [Validators.maxLength(50)]],
        export_date: ['', [Validators.required]],
        export_dateRequest: [''],
        export_mrn: ['', [Validators.required, Validators.maxLength(50)]],
        export_truckRegistration: ['', [Validators.maxLength(50)]],
        export_truckCountry: [''],
      }),
      dataSubmitted: false,
      currentData: new CustomsExportOperation(),
      attachmentTypeList: [],
      attachmentCurrentData: {
        type: 0,
        file: null,
        fileSize: 0,
        observations: '',
      },
    };

    this.loadingPage = true;
    this.shipmentsSearchData.showDateSearch = false;
    this.shipmentsSearchData.disabled = true;

    this.spinnerCustomsFilesList = $(
      '#brexitManagementCustomsFilesList'
    ).children('.ibox-content');
    this.spinnerShipmentList = $('#brexitManagement').children('.ibox-content');
    this.modalShipmentCreate = $('#createBrexitShipmentWindow');
    this.modalShipmentEdit = $('#editBrexitShipmentWindow');
    this.modalExportEdit = $('#editBrexitShipmentExportDataWindow');
    this.modalImportEdit = $('#editBrexitShipmentImportDataWindow');

    this.getCountriesList();
    this.getShipmentsList();
    this.getOperationDocumentList();
    this.getOperationsTypesList(CustomsOperations.Import);
    this.getOperationsTypesList(CustomsOperations.Export);
    this.getAgentsList(CustomsOperations.Import);
    this.getAgentsList(CustomsOperations.Export);
  }

  disableImportOperationType() {
    if (this.import.currentData.attachments.length > 0)
      this.i.import_customsType_id.disable();
    else this.i.import_customsType_id.enable();
  }

  disableExportOperationType() {
    if (this.export.currentData.attachments.length > 0)
      this.e.export_customsType_id.disable();
    else this.e.export_customsType_id.enable();
  }

  loadExportAttachmentTypeList(e: any) {
    this.export.attachmentTypeList = this.shipment.operationDocumentList
      .filter((x: OperationDocument) => x.operationCode == e)
      .map((x: OperationDocument) => ({
        id: x.documentCode.toString(),
        text: x.documentTitle,
      }));
  }

  loadImportAttachmentTypeList(e: any) {
    this.import.attachmentTypeList = this.shipment.operationDocumentList
      .filter((x: OperationDocument) => x.operationCode == e)
      .map((x: OperationDocument) => ({
        id: x.documentCode.toString(),
        text: x.documentTitle,
      }));
  }

  getCountriesList() {
    this.countriesService.GetPaises().subscribe(
      (data: any) => {
        let array: Array<Select2OptionData> = Array.from(
          data.body.map((x: Pais) => ({ id: x.codigoalfa2, text: x.pais }))
        );
        array.push({ id: '', text: '' });
        this.countriesList = array.sort((x, y) => (x.text > y.text ? 1 : -1));
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  getOperationDocumentList() {
    let array = [this.import.code, this.export.code];
    this.brexitService.GetOperationsDocuments(array).subscribe(
      (data: any) => {
        this.shipment.operationDocumentList = data.body;
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  getAgentsList(type: CustomsOperations) {
    let code: number = 0;
    if (type == CustomsOperations.Import) code = this.import.code;
    if (type == CustomsOperations.Export) code = this.export.code;

    this.brexitService.GetAgentsList(code).subscribe(
      (data: any) => {
        if (type == CustomsOperations.Import) {
          this.import.agentList = data.body.map((x: GenericIdName) => ({
            id: x.id,
            text: x.name,
          }));
        }

        if (type == CustomsOperations.Export) {
          this.export.agentList = data.body.map((x: GenericIdName) => ({
            id: x.id,
            text: x.name,
          }));
        }
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  getOperationsTypesList(type: CustomsOperations) {
    let code: number = 0;
    if (type == CustomsOperations.Import) code = this.import.code;
    if (type == CustomsOperations.Export) code = this.export.code;

    this.brexitService.GetOperationsTypes(code).subscribe(
      (data: any) => {
        if (type == CustomsOperations.Import) {
          this.import.customTypeList = data.body.map((x: GenericIdName) => ({
            id: x.id,
            text: x.name,
          }));
        }

        if (type == CustomsOperations.Export) {
          this.export.customTypeList = data.body.map((x: GenericIdName) => ({
            id: x.id,
            text: x.name,
          }));
        }
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  getShipmentsList() {
    myGlobals.spinnerOn(this.spinnerShipmentList);
    this.shipmentsSearchData.dateFrom = new Date(
      this.shipmentsSearchData_dateFrom
    );

    this.brexitService.GetShipmentList(this.shipmentsSearchData).subscribe(
      (data: any) => {
        this.shipmentsList = data.body;
        this.shipmentsListFirst = 0;
        myGlobals.spinnerOff(this.spinnerShipmentList);
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  getCustomsFilesList() {
    myGlobals.spinnerOn(this.spinnerCustomsFilesList);
    this.customsFilesSearchData.dateFrom = new Date(
      this.customsFilesSearchData_dateFrom
    );

    this.brexitService
      .GetCustomsFilesListToSelect(this.customsFilesSearchData)
      .subscribe(
        (data: any) => {
          this.customsFilesList = data.body;
          this.customsFilesListFirst = 0;
          myGlobals.spinnerOff(this.spinnerCustomsFilesList);
        },
        (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        }
      );
  }

  createShipmentOpenWindow() {
    this.customsFilesList = [];
    this.customsFilesSearchData.value = '';
    myGlobals.modalOpen(this.modalShipmentCreate);
    myGlobals.spinnerOff(this.spinnerCustomsFilesList);
  }

  createShipmentCloseWindow(shipment: BrexitShipment) {
    this.shipment.currentData = shipment;
    this.saveShipment();
    myGlobals.modalClose(this.modalShipmentCreate);
    myGlobals.modalClose(this.modalShipmentEdit);
  }

  loadShipment(id: number) {
    this.brexitService.GetShipment(id).subscribe(
      (data: any) => {
        this.shipment.currentData = data.body;
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  editShipment(id: number) {
    this.loadShipment(id);
    myGlobals.modalOpen(this.modalShipmentEdit);
  }

  saveShipment() {
    if (!this.processingData) {
      this.processingData = true;
      this.shipment.currentData.editUser = myGlobals.userGetData().id;
      this.brexitService.SaveShipment(this.shipment.currentData).subscribe(
        (id: number) => {
          this.loadShipment(id);
          this.getShipmentsList();
          myGlobals.modalClose(this.modalShipmentEdit);
          this.processingData = false;
        },
        (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
          this.processingData = false;
        }
      );
    }
  }

  downloadAttachment(id: number, filename: string) {
    this.documentsService.DownloadDocument(id).subscribe({
      next: (data: any) => {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        saveAs(blob, filename);
      },
      complete: () => {},
      error: (err: HttpErrorResponse) => {
        myGlobals.showMessage(err, myGlobals.MessageType.Error);
      },
    });
  }

  /*
  downloadAttachment(id: number, filename: string) {
    this.documentsService.DownloadDocument(id)
      .subscribe((data: any) => {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        saveAs(blob, filename);
      }
        , (error: HttpErrorResponse) => {
          myGlobals.showMessage(error.message, myGlobals.MessageType.Error);
        });


  }
  */

  getShipmentProperties() {
    this.brexitService
      .GetShipmentProperties(
        this.shipment.currentData.properties.brexitShipmentId
      )
      .subscribe(
        (data: any) => {
          this.shipment.currentData.properties = data.body;
        },
        (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        }
      );
  }

  updateShipmentProperties() {
    this.brexitService
      .UpdateShipmentProperties(this.shipment.currentData.properties)
      .subscribe(
        (data: any) => {
          this.getShipmentProperties();
        },
        (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        }
      );
  }

  // Export -----------------------------------------------

  exportFormLoad(): void {
    this.export.dataForm.setValue({
      export_customsType_id:
        this.export.currentData.customsType.id == 0
          ? ''
          : this.export.currentData.customsType.id,
      export_agent_id:
        this.export.currentData.agent.id == 0
          ? ''
          : this.export.currentData.agent.id,
      export_invoices:
        this.export.currentData.invoices.length > 0
          ? this.export.currentData.invoices[0].number
          : '',
      export_date:
        this.export.currentData.date == null
          ? ''
          : this.datePipe.transform(this.export.currentData.date, 'yyyy-MM-dd'),
      export_dateRequest:
        this.export.currentData.dateRequest == null
          ? ''
          : this.datePipe.transform(
              this.export.currentData.dateRequest,
              'yyyy-MM-dd'
            ),
      export_mrn: this.export.currentData.mrn,
      export_truckRegistration: this.export.currentData.truckRegistration,
      export_truckCountry: this.export.currentData.truckCountry,
    });
  }

  exportFormSave(): void {
    this.export.currentData.mrn = this.e.export_mrn.value
      .split(' ')
      .join('')
      .toUpperCase();
    this.e.export_mrn.setValue(this.export.currentData.mrn);

    this.export.currentData.customsType.id = this.e.export_customsType_id.value;
    this.export.currentData.customsType.description =
      this.export.customTypeList.find(
        (x) => x.id == this.export.currentData.customsType.id.toString()
      ).text;

    this.export.currentData.agent.id = this.e.export_agent_id.value;
    this.export.currentData.agent.name = this.export.agentList.find(
      (x) => x.id == this.export.currentData.agent.id.toString()
    ).text;

    this.export.currentData.date = this.e.export_date.value;
    this.export.currentData.dateRequest = this.e.export_dateRequest.value;
    this.export.currentData.truckRegistration =
      this.e.export_truckRegistration.value;
    this.export.currentData.truckCountry = this.e.export_truckCountry.value;
    this.export.currentData.parent = this.shipment.currentData.id;

    this.export.currentData.invoices = [];
    let invoice = new CustomsInvoice();
    invoice.number = this.e.export_invoices.value;
    invoice.date = null;
    this.export.currentData.invoices.push(invoice);
  }

  exportAdd() {
    this.export.dataSubmitted = false;
    this.export.currentData = new CustomsExportOperation();
    this.export.currentData.parent = this.shipment.currentData.id;
    this.export.attachmentTypeList = [];
    this.exportFormLoad();
    this.exportAttachmentCurrentDataReset();
    this.disableExportOperationType();
    myGlobals.modalOpen(this.modalExportEdit);
  }

  exportAddAttachment(item: CustomsExportOperation) {}

  exportEdit(item: CustomsExportOperation) {}

  exportSave() {
    this.export.dataSubmitted = true;

    // stop here if form is invalid
    if (
      this.export.dataForm.invalid ||
      this.export.currentData.attachments.length == 0
    ) {
      return;
    }

    if (!this.processingData) {
      this.processingData = true;
      this.exportFormSave();
      this.export.currentData.editUser = myGlobals.userGetData().id;
      this.export.currentData.client = this.shipment.currentData.client;
      this.brexitService.AddExportData(this.export.currentData).subscribe(
        (data: any) => {
          myGlobals.modalClose(this.modalExportEdit);
          this.loadShipment(this.shipment.currentData.id);
          this.processingData = false;
        },
        (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
          this.processingData = false;
        }
      );
    }
  }

  GetExportProperties(properties: CustomsExportOperationProperties) {
    this.brexitService.GetExportProperties(properties.operationId).subscribe(
      (data: any) => {
        properties = data;
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  updateExportProperties(properties: CustomsExportOperationProperties) {
    this.brexitService.updateExportProperties(properties).subscribe(
      (data: any) => {
        this.GetExportProperties(properties);
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  exportSetUploadFilenameLabel(fileName: string) {
    $('.custom-export-file-input')
      .next('.custom-file-label')
      .addClass('selected')
      .html(fileName);
  }

  exportSetFile(files) {
    this.exportSetUploadFilenameLabel('');

    if (files.length === 0) return;
    this.export.attachmentCurrentData.file = files[0];
    this.export.attachmentCurrentData.fileSize =
      this.export.attachmentCurrentData.file.size;
    this.exportSetUploadFilenameLabel(
      this.export.attachmentCurrentData.file.name
    );
  }

  exportAttachmentCurrentDataReset(): void {
    this.exportSetUploadFilenameLabel('');
    this.export.attachmentCurrentData = {
      type: 0,
      file: null,
      fileSize: 0,
      observations: '',
    };
  }

  canAddFile(type: number, size: number): boolean {
    return type > 0 && size > 0 && size <= this.maxAttachmentSize
      ? true
      : false;
  }

  exportCurrentAttachmentAdd() {
    if (
      this.canAddFile(
        this.export.attachmentCurrentData.type,
        this.export.attachmentCurrentData.file.size
      )
    ) {
      this.loadingAttachment = true;
      this.export.attachmentCurrentData.file.arrayBuffer().then((buff) => {
        let bytes = new Uint8Array(buff);
        const stringChar = bytes.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, '');
        let base64String = btoa(stringChar);

        let item: DocumentArchive = new DocumentArchive();
        item.typeId = this.export.attachmentCurrentData.type;
        item.typeDescription = this.export.attachmentTypeList.find(
          (x) => x.id == item.typeId.toString()
        ).text;
        item.observations = this.export.attachmentCurrentData.observations;
        item.filename = this.export.attachmentCurrentData.file.name;
        item.lastFileDate = new Date(
          this.export.attachmentCurrentData.file.lastModified
        );
        item.format = this.export.attachmentCurrentData.file.type;
        item.archiveDate = new Date(Date.now());
        item.parentId = this.export.currentData.id;
        item.parentType = this.export.currentData.customsType.id;
        item.contentBase64 = base64String;
        item.size = this.export.attachmentCurrentData.file.size;

        this.export.currentData.attachments.push(item);
        this.exportAttachmentCurrentDataReset();
        this.loadingAttachment = false;
        this.disableExportOperationType();
      });
    }
  }

  exportCurrentAttachmentRemove(item: DocumentArchive) {
    const index: number = this.export.currentData.attachments.indexOf(item);
    if (index !== -1) {
      this.export.currentData.attachments.splice(index, 1);
      this.disableExportOperationType();
    }
  }

  // Import -----------------------------------------------

  importFormLoad(): void {
    this.import.dataForm.setValue({
      import_customsType_id:
        this.import.currentData.customsType.id == 0
          ? ''
          : this.import.currentData.customsType.id,
      import_agent_id:
        this.import.currentData.agent.id == 0
          ? ''
          : this.import.currentData.agent.id,
      import_invoices:
        this.import.currentData.invoices.length > 0
          ? this.import.currentData.invoices[0].number
          : '',
      import_date:
        this.import.currentData.date == null
          ? ''
          : this.datePipe.transform(this.import.currentData.date, 'yyyy-MM-dd'),
      import_dateRequest:
        this.import.currentData.dateRequest == null
          ? ''
          : this.datePipe.transform(
              this.import.currentData.dateRequest,
              'yyyy-MM-dd'
            ),
      import_mrn: this.import.currentData.mrn,
      import_truckRegistration: this.import.currentData.truckRegistration,
      import_truckCountry: this.import.currentData.truckCountry,
    });
  }

  importFormSave(): void {
    this.import.currentData.mrn = this.i.import_mrn.value
      .split(' ')
      .join('')
      .toUpperCase();
    this.i.import_mrn.setValue(this.import.currentData.mrn);

    this.import.currentData.customsType.id = this.i.import_customsType_id.value;
    this.import.currentData.customsType.description =
      this.import.customTypeList.find(
        (x) => x.id == this.import.currentData.customsType.id.toString()
      ).text;

    this.import.currentData.agent.id = this.i.import_agent_id.value;
    this.import.currentData.agent.name = this.import.agentList.find(
      (x) => x.id == this.import.currentData.agent.id.toString()
    ).text;

    this.import.currentData.date = this.i.import_date.value;
    this.import.currentData.dateRequest = this.i.import_dateRequest.value;
    this.import.currentData.truckRegistration =
      this.i.import_truckRegistration.value;
    this.import.currentData.truckCountry = this.i.import_truckCountry.value;
    this.import.currentData.parent = this.shipment.currentData.id;

    this.import.currentData.invoices = [];
    let invoice = new CustomsInvoice();
    invoice.number = this.i.import_invoices.value;
    invoice.date = null;
    this.import.currentData.invoices.push(invoice);
  }

  importAdd() {
    this.import.dataSubmitted = false;
    this.import.currentData = new CustomsImportOperation();
    this.import.currentData.parent = this.shipment.currentData.id;
    this.import.attachmentTypeList = [];
    this.importFormLoad();
    this.importAttachmentCurrentDataReset();
    this.disableImportOperationType();
    myGlobals.modalOpen(this.modalImportEdit);
  }

  importAddAttachment(item: CustomsImportOperation) {}

  importEdit(item: CustomsImportOperation) {}

  importSave() {
    this.import.dataSubmitted = true;

    // stop here if form is invalid
    if (
      this.import.dataForm.invalid ||
      this.import.currentData.attachments.length == 0
    ) {
      return;
    }

    if (!this.processingData) {
      this.processingData = true;
      this.importFormSave();
      this.import.currentData.editUser = myGlobals.userGetData().id;
      this.import.currentData.client = this.shipment.currentData.client;
      this.brexitService.AddImportData(this.import.currentData).subscribe(
        (data: any) => {
          myGlobals.modalClose(this.modalImportEdit);
          this.loadShipment(this.shipment.currentData.id);
          this.processingData = false;
        },
        (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
          this.processingData = false;
        }
      );
    }
  }

  GetImportProperties(properties: CustomsImportOperationProperties) {
    this.brexitService.GetImportProperties(properties.operationId).subscribe(
      (data: any) => {
        properties = data.body;
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  updateImportProperties(properties: CustomsImportOperationProperties) {
    this.brexitService.updateImportProperties(properties).subscribe(
      (data: any) => {
        this.GetImportProperties(properties);
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  importSetUploadFilenameLabel(fileName: string) {
    $('.custom-import-file-input')
      .next('.custom-file-label')
      .addClass('selected')
      .html(fileName);
  }

  importSetFile(files) {
    this.importSetUploadFilenameLabel('');

    if (files.length === 0) return;
    this.import.attachmentCurrentData.file = files[0];
    this.import.attachmentCurrentData.fileSize =
      this.import.attachmentCurrentData.file.size;
    this.importSetUploadFilenameLabel(
      this.import.attachmentCurrentData.file.name
    );
  }

  importAttachmentCurrentDataReset(): void {
    this.importSetUploadFilenameLabel('');
    this.import.attachmentCurrentData = {
      type: 0,
      file: null,
      fileSize: 0,
      observations: '',
    };
  }

  importCurrentAttachmentAdd() {
    if (
      this.import.attachmentCurrentData.type > 0 &&
      this.import.attachmentCurrentData.file.size > 0
    ) {
      this.loadingAttachment = true;
      this.import.attachmentCurrentData.file.arrayBuffer().then((buff) => {
        let bytes = new Uint8Array(buff);
        const stringChar = bytes.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, '');
        let base64String = btoa(stringChar);

        let item: DocumentArchive = new DocumentArchive();
        item.typeId = this.import.attachmentCurrentData.type;
        item.typeDescription = this.import.attachmentTypeList.find(
          (x) => x.id == item.typeId.toString()
        ).text;
        item.observations = this.import.attachmentCurrentData.observations;
        item.filename = this.import.attachmentCurrentData.file.name;
        item.lastFileDate = new Date(
          this.import.attachmentCurrentData.file.lastModified
        );
        item.format = this.import.attachmentCurrentData.file.type;
        item.archiveDate = new Date(Date.now());
        item.parentId = this.import.currentData.id;
        item.parentType = this.import.currentData.customsType.id;
        item.contentBase64 = base64String;
        item.size = this.import.attachmentCurrentData.file.size;

        this.import.currentData.attachments.push(item);
        this.importAttachmentCurrentDataReset();
        this.loadingAttachment = false;
        this.disableImportOperationType();
      });
    }
  }

  importCurrentAttachmentRemove(item: DocumentArchive) {
    const index: number = this.import.currentData.attachments.indexOf(item);
    if (index !== -1) {
      this.import.currentData.attachments.splice(index, 1);
      this.disableImportOperationType();
    }
  }

  deleteShipment(info: BrexitShipment) {
    if (!this.processingData) {
      this.processingData = true;

      let r = confirm('Are you sure you want to delete the item?');
      if (r == true) {
        this.brexitService.DeleteShipmentData(info.id).subscribe(
          (data: any) => {
            info.status = GeneralOperationsStatus.Deleted;
            this.processingData = false;
          },
          (error: string) => {
            myGlobals.showMessage(error, myGlobals.MessageType.Error);
            this.processingData = false;
          }
        );
      }
    }
  }

  deleteImportData(info: CustomsImportOperation) {
    if (!this.processingData) {
      this.processingData = true;

      let r = confirm('Are you sure you want to delete the item?');
      if (r == true) {
        this.brexitService.DeleteImportData(info.id).subscribe(
          (data: any) => {
            info.status = GeneralOperationsStatus.Deleted;
            this.processingData = false;
          },
          (error: string) => {
            myGlobals.showMessage(error, myGlobals.MessageType.Error);
            this.processingData = false;
          }
        );
      }
    }
  }

  deleteExportData(info: CustomsExportOperation) {
    if (!this.processingData) {
      this.processingData = true;

      let r = confirm('Are you sure you want to delete the item?');
      if (r == true) {
        this.brexitService.DeleteExportData(info.id).subscribe(
          (data: any) => {
            info.status = GeneralOperationsStatus.Deleted;
            this.processingData = false;
          },
          (error: string) => {
            myGlobals.showMessage(error, myGlobals.MessageType.Error);
            this.processingData = false;
          }
        );
      }
    }
  }

  getInvoicesInfo(invoices: CustomsInvoice[]): string {
    return invoices.map((x: CustomsInvoice) => x.number).join(' ');
  }

  exportActionSendDataToClient(properties: CustomsExportOperationProperties) {
    properties.clientSentDuaInfo = true;
    this.updateExportProperties(properties);
  }

  exportActionCancelSendingDataToClient(
    properties: CustomsExportOperationProperties
  ) {
    properties.clientSentDuaInfo = false;
    this.updateExportProperties(properties);
  }

  importActionSendDataToClient(properties: CustomsImportOperationProperties) {
    properties.clientSentDuaInfo = true;
    this.updateImportProperties(properties);
  }

  importActionCancelSendingDataToClient(
    properties: CustomsImportOperationProperties
  ) {
    properties.clientSentDuaInfo = false;
    this.updateImportProperties(properties);
  }

  exportExcel() {
    myGlobals.exportExcel(
      this.shipmentsList.map((x) => ({
        customsFileId: x.customsFileId,
        customsFileDate: new Date(x.customsFileDate),
        'client.group': x.client.group,
        'client.name': x.client.name,
        customsFileDescription: x.customsFileDescription,
        clientWarehouse: x.clientWarehouse,
        invoiceInfo: x.invoiceInfo,
        truckInfo: x.truckInfo,
      }))
    );
  }
}

<div *ngIf="!authenticated">
  <router-outlet></router-outlet>
</div>

<div *ngIf="authenticated">
  <div *ngIf="showChangePassword">
    <app-page-changepassword></app-page-changepassword>
  </div>

  <div *ngIf="!showChangePassword">
    <app-page-navigation></app-page-navigation>

    <div id="page-wrapper" class="gray-bg">
      <app-page-header></app-page-header>
      <router-outlet></router-outlet>
      <app-page-footer> </app-page-footer>
    </div>

    <app-page-rightsidebar></app-page-rightsidebar>
  </div>
</div>

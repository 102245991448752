export class RouteInfo {
    id: number = 0;
    name: string = "";
    description: string = "";
    disable: boolean = false;
    newRouteOnHubsChange: boolean = true;
    hubs: RouteHub[] = [];
    costs: RouteCost[] = [];
    totalKm: number = 0;
    totalHours: number = 0;
    avoidFerries: boolean = false;
    avoidHighways: boolean = false;
    avoidTolls: boolean = true;
}

export class RouteHub {
    id: number = 0;
    order: number = 0;
    title: string = "";
    alias: string = "";
    country: string = "";
    name: string = "";
    coordinatesGPS: string = "";
    km: number = 0;
    hours: number = 0;
    countryDescription: string = "";
}
export class RouteCost {
    id: string = "";
    rutaId: number = 0;
    proveedorId: number = 0;
    proveedorDescripcion: string = "";
    vehiculoId: number = 0;
    vehiculoDescripcion: string = "";
    coste: number = 0;
}

export class MapRoute extends Object {
    origen: Waypoint;
    destino: Waypoint;
    waypoints: Waypoint[];

    constructor(origen: Waypoint, destino: Waypoint, waypoints?: Waypoint[]) {
        super();
        this.origen = origen;
        this.destino = destino;
        this.waypoints = waypoints;
    }
}

export class Waypoint extends Object {
    location: Location;
    constructor(location: Location) {
        super();
        this.location = location;
    }
}

export class Location extends Object {
    lat: number;
    lng: number;
    constructor(lat: number, lng: number) {
        super();
        this.lat = lat;
        this.lng = lng;
    }
}

export class TipoVehiculo extends Object {
    id: number;
    descripcion: string;
}

export class TipoServicio extends Object {
    id: number;
    descripcion: string;
}

export class TipoMercancia extends Object {
    id: number;
    descripcion: string;
}
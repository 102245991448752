import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { taskInfo } from '../../../models/tasks.model';
import {tasksService} from '../../../services/tasks.service'
import { SearchData, CustomFilter } from '../../../models/searches.model';
import * as myGlobals from '../../../globals';
import { Prioridad } from 'src/app/models/prioridad.model'; 

declare var $: any;

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class tasksComponent implements OnInit {

  taskDataForm: FormGroup;
  taskDataFormSubmitted: boolean = false;

  public searchData: SearchData = new SearchData();
  public tasksList: taskInfo[];
  public currentTaskData: taskInfo = new taskInfo();
  prioFilterSelect2: any = null;
  
  public loadingPage: boolean = false;
  public prioList: Prioridad[];
  public firstHubs = 0;
  prioFilterLists = [];

  constructor(private router: Router,private tasksService: tasksService, private formBuilder: FormBuilder) { }

  // convenience getter for easy access to form fields
  get f() { return this.taskDataForm.controls; }

  ngOnInit(): void {
    this.searchData.showDateSearch = false;
    this.searchData.showAdvancedOptions = false;
    this.searchData.enabled = true;
    this.searchData.disabled = false;

    this.taskDataForm = this.formBuilder.group({
      task_responsable: ['', [Validators.maxLength(50)]],
      task_cliente: [''],
      task_estado: [''],
      task_descripcion: [''],
      task_fechaFin: [''],
      task_prioridad: 0
    });

    this.loadingPage = true;
    
    this.prioFilterSelect2 = $('#select2PrioFilter').select2();
    this.getPrioridadesList(1);
    this.getTasksList();
  }

  saveTaskDataForm(): void {
    this.currentTaskData.responsable = this.f.task_responsable.value;
    this.currentTaskData.cliente = this.f.task_cliente.value;
    this.currentTaskData.estado = this.f.task_estado.value;
    this.currentTaskData.descripcion = this.f.task_descripcion.value;
    this.currentTaskData.fechaFin = this.f.task_fechaFin.value;
    this.currentTaskData.prioridad = this.f.task_prioridad.value;
  }

  loadTaskDataForm(): void {
    this.taskDataForm.setValue({
      task_responsable: this.currentTaskData.responsable,
      task_cliente: this.currentTaskData.cliente,
      task_estado: this.currentTaskData.estado,
      task_descripcion: this.currentTaskData.descripcion,
      task_prioridad: this.currentTaskData.prioridad,
      task_fechaFin: this.currentTaskData.fechaFin,
    });

  }
  getTasksList() {
    
    this.searchData.customFilters = [];
    this.searchData.customFilters.push(new CustomFilter("priolist", this.prioFilterSelect2.val()));
    this.tasksService.TasksGetList(this.searchData)
      .subscribe((data: any) => {
        this.tasksList = data.body;
        this.firstHubs = 0;
        //this.tasksManagementSpinner();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

    if (this.loadingPage) {
      this.loadingPage = false;
      this.tasksManagementSpinner();
    }
  }
  getPrioridadesList(idPrio:number){
    this.tasksService.TasksGetPrioridades(idPrio)
      .subscribe((data: any) => {
        if(idPrio==1) {
          this.prioList = data.body;
        }
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

 /* editTask(idTask: number) {
    this.clearTasksData();
    this.loadTaskData(idTask);
    this.editTasksWindowToggle();
  }*/
  clearTasksData() {
    this.taskDataFormSubmitted = false;
    this.taskDataForm.reset();
    this.currentTaskData = new taskInfo();
    this.loadTaskDataForm();

  }
  deleteTask(idTask:number){
    var r = confirm("Are you sure you want to delete the item?");
    if (r == true) {
      this.tasksService.TaskManagementDelete(idTask)
        .subscribe(() => {
          this.getTasksList();
        }
          , (error: string) => {
            myGlobals.showMessage(error, myGlobals.MessageType.Error);
          });
    }
  }
  loadTaskData(idTask: number) {
    this.tasksManagementSpinner();
    this.tasksService.TaskManagementGetData(idTask)
      .subscribe((data: any) => {
        this.currentTaskData = data.body;
        this.loadTaskDataForm();
        this.tasksManagementSpinner();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
          this.tasksManagementSpinner();
        });
  }

  editTasksWindowToggle() {
    $('#editTasksWindow').modal('toggle');
  }

  tasksManagementSpinner() {
    $('#tasksManagement').children('.ibox-content').toggleClass('sk-loading');
  }
  
  newTask() {
   this.router.navigateByUrl('tasks/'+ 0)
    //this.editTasksWindowToggle();
  }

  editTask(id: number) {
    this.router.navigateByUrl('tasks/'+ id);
  }
  exportExcel() {
    myGlobals.exportExcel(this.tasksList);
  }

}

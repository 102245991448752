import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from '../../../models/user.model';
import { LoginService } from '../../../services/login.service';
import { addHours } from 'date-fns';
import * as myGlobals from '../../../globals';
import { TranslateService } from '@ngx-translate/core';
import { Translate } from '../../../helpers/translate';
import {
  AuthenticateRequest,
  AuthenticateResponse,
} from '../../../models/auth.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.css'],
  providers: [LoginService],
  encapsulation: ViewEncapsulation.None,
})
export class PageLoginComponent implements OnInit {
  translate: Translate = new Translate(null, null);
  form: AuthenticateRequest = new AuthenticateRequest();
  isLoginFailed: boolean = false;
  errorMessage: string = '';

  public appTitle: string = myGlobals.AppTitle;
  public appCopyrightText: string = myGlobals.AppCopyrightText;

  constructor(
    private loginService: LoginService,
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.translate = new Translate(this.translateService, this.datePipe);
    this.closeSession();
  }

  closeSession() {
    this.loginService.logOut();
  }

  getUserData(): User {
    let user: User = {
      id: 0,
      fullname: '',
      username: this.form.user,
      password: this.form.pass,
      roles: null,
      token: '',
      passwordWillExpireSoon: false,
      passwordExpireDays: 0,
      expiredtime: addHours(new Date(), 1),
      authenticated: false,
    };
    return user;
  }

  onSubmit() {
    this.isLoginFailed = false;
    let user = this.getUserData();
    this.loginService.authenticateUser(user).subscribe(
      (data: any) => {
        this.passwordWillExpireSoon(data);
        this.loginService.user = data;
        this.loginService.logIn(this.loginService.user);
        window.location.replace('/home');
      },
      (err: string) => {
        this.errorMessage = err;
        this.isLoginFailed = true;
      }
    );
  }

  passwordWillExpireSoon(user: User) {
    if (user.passwordWillExpireSoon) {
      if (user.passwordExpireDays == 1) {
        myGlobals.showMessage(
          'Today is the last day to change your password. \nOtherwise your account will be blocked.',
          myGlobals.MessageType.Information
        );
      } else if (user.passwordExpireDays > 1) {
        myGlobals.showMessage(
          'The password will expire in ' +
            (user.passwordExpireDays - 1) +
            ' days. \nIt is recommended to change it.',
          myGlobals.MessageType.Information
        );
      }
    }
  }
}

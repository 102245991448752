import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SearchData } from '../models/searches.model';
import { ViewExecuteData, GetExcelInfo } from '../models/reporting.model';
import * as myGlobals from '../globals';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  constructor(private http: HttpClient) {}

  public GetListOfViews(searchData: SearchData) {
    return this.http.post(
      myGlobals.RootUrlTikitingApi + 'Reporting/GetListOfViews',
      searchData,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  public GetView(idView: number) {
    return this.http.post(
      myGlobals.RootUrlTikitingApi + 'Reporting/GetView',
      idView,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  public GetViewData(idView: number) {
    return this.http.post(
      myGlobals.RootUrlTikitingApi + 'Reporting/GetViewData',
      idView,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  public GetViewExampleData(idView: number) {
    return this.http.post(
      myGlobals.RootUrlTikitingApi + 'Reporting/GetViewExampleData',
      idView,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  public GetViewResultData(data: ViewExecuteData) {
    return this.http.post(
      myGlobals.RootUrlTikitingApi + 'Reporting/GetViewResultData',
      data,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  public GetTikitingTrackingStatusList() {
    return this.http.get(
      myGlobals.RootUrlTikitingApi + 'Reporting/GetTikitingTrackingStatusList',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  public GetTipoFechaList() {
    return this.http.get(
      myGlobals.RootUrlTikitingApi + 'Reporting/GetTipoFechaList',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  public GetTipoReferenciaList() {
    return this.http.get(
      myGlobals.RootUrlTikitingApi + 'Reporting/GetTipoReferenciaList',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  public GetExcel(info: GetExcelInfo) {
    return this.http.post(
      myGlobals.RootUrlTikitingApi + 'Reporting/GetExcel',
      info,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
        responseType: 'blob' as 'json'
      }
    );
  }
}

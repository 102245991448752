import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SearchData } from '../../../models/searches.model';
import { HsCodes } from '../../../models/customs.model';
import { CustomsService } from '../../../services/customs.service'
import * as myGlobals from '../../../globals';
declare var $: any;

@Component({
  selector: 'app-administracion-hscodes',
  templateUrl: './administracion-hscodes.component.html',
  styleUrls: ['./administracion-hscodes.component.css']
})
export class AdministracionHscodesComponent implements OnInit {

  dataForm: FormGroup;
  dataFormSubmitted: boolean = false;

  searchData: SearchData = new SearchData();
  hsCodes: HsCodes[] = [];
  currentHsCodeData: HsCodes = new HsCodes();
  firstHsCodes = 0;
  loadingPage: boolean = false;

  constructor(private customsService: CustomsService, private formBuilder: FormBuilder) { }

  // convenience getter for easy access to form fields
  get f() { return this.dataForm.controls; }

  ngOnInit(): void {
    this.loadingPage = true;
    this.searchData.showDateSearch = false;
    this.searchData.disabled = true;

    this.dataForm = this.formBuilder.group({
      hs_codigo: ['', [Validators.required, Validators.maxLength(10)]],
      hs_descripcion: ['', [Validators.required, Validators.maxLength(200)]],
      hs_descripcionIngles: ['', [Validators.required, Validators.maxLength(200)]],
      hs_tipoIVA: [0, Validators.required],
      hs_tipoUnidadesSuplementarias: ['', Validators.maxLength(2)],
      hs_pesoMinimoPorUnidad: [0],
      hs_pesoMaximoPorUnidad: [0],
      hs_vigente: [true],
      hs_requiereSoivre: [false],
      hs_requiereCites: [false],
      hs_dobleUso: [false]
    });

    this.getHsCodesList();
  }

  getHsCodesList(): void {
    this.hsCodesManagementSpinner();
    this.customsService.HsCodesManagementGetList(this.searchData)
      .subscribe((data: any) => {
        this.hsCodes = data.body;
        this.firstHsCodes = 0;
        this.hsCodesManagementSpinner();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

    if (this.loadingPage) {
      this.loadingPage = false;
      this.hsCodesManagementSpinner();
    }
  }





  saveHsDataForm(): void {
    this.currentHsCodeData.codigo = this.f.hs_codigo.value;
    this.currentHsCodeData.descripcion = this.f.hs_descripcion.value;
    this.currentHsCodeData.descripcionIngles = this.f.hs_descripcionIngles.value;
    this.currentHsCodeData.dobleUso = this.f.hs_dobleUso.value;
    this.currentHsCodeData.pesoMaximoPorUnidad = this.f.hs_pesoMaximoPorUnidad.value ?? 0;
    this.currentHsCodeData.pesoMinimoPorUnidad = this.f.hs_pesoMinimoPorUnidad.value ?? 0;
    this.currentHsCodeData.requiereCites = this.f.hs_requiereCites.value;
    this.currentHsCodeData.requiereSoivre = this.f.hs_requiereSoivre.value;
    this.currentHsCodeData.tipoIVA = this.f.hs_tipoIVA.value ?? 0;
    this.currentHsCodeData.tipoUnidadesSuplementarias = this.f.hs_tipoUnidadesSuplementarias.value;
    this.currentHsCodeData.vigente = this.f.hs_vigente.value;
  }

  loadHsDataForm(): void {
    this.dataForm.setValue({
      hs_codigo: this.currentHsCodeData.codigo,
      hs_descripcion: this.currentHsCodeData.descripcion,
      hs_descripcionIngles: this.currentHsCodeData.descripcionIngles,
      hs_dobleUso: this.currentHsCodeData.dobleUso,
      hs_pesoMaximoPorUnidad: this.currentHsCodeData.pesoMaximoPorUnidad,
      hs_pesoMinimoPorUnidad: this.currentHsCodeData.pesoMinimoPorUnidad,
      hs_requiereCites: this.currentHsCodeData.requiereCites,
      hs_requiereSoivre: this.currentHsCodeData.requiereSoivre,
      hs_tipoIVA: this.currentHsCodeData.tipoIVA,
      hs_tipoUnidadesSuplementarias: this.currentHsCodeData.tipoUnidadesSuplementarias,
      hs_vigente: this.currentHsCodeData.vigente
    });
  }



  newHsCode() {
    this.clearHsCodeData();
    this.editHsCodeWindowToggle();
  }

  editHsCode(codigo: string) {
    this.clearHsCodeData();
    this.loadHsCodeData(codigo);
    this.editHsCodeWindowToggle();
  }

  saveHsCodeData() {
    this.dataFormSubmitted = true;

    // stop here if form is invalid
    if (this.dataForm.invalid) {
      return;
    }

    this.saveHsDataForm();
    this.customsService.HsCodesManagementSaveData(this.currentHsCodeData)
      .subscribe((data: any) => {
        this.getHsCodesList();
        this.editHsCodeWindowToggle();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });


  }

  clearHsCodeData() {
    this.dataFormSubmitted = false;
    this.dataForm.reset();
    this.currentHsCodeData = new HsCodes();
    this.loadHsDataForm();
  }

  loadHsCodeData(codigo: string) {
    this.customsService.HsCodesManagementGetData(codigo)
      .subscribe((data: any) => {
        this.currentHsCodeData = data;
        this.loadHsDataForm();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }


  setHsCodeDisable(codigo: string) {
    this.customsService.HsCodesManagementSetDisable(codigo)
      .subscribe(() => {
        this.getHsCodesList();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  setHsCodeEnable(codigo: string) {
    this.customsService.HsCodesManagementSetEnable(codigo)
      .subscribe(() => {
        this.getHsCodesList();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }



  exportExcel() {
    myGlobals.exportExcel(this.hsCodes);
  }

  hsCodesManagementSpinner() {
 //   $('#hsCodesManagement').children('.ibox-content').toggleClass('sk-loading');
  }

  editHsCodeWindowToggle() {
    $('#editHsCodeWindow').modal('toggle');
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { UsersService } from '../../../services/users.service';
import { SearchData } from '../../../models/searches.model';
import { CustomValidators } from '../../../helpers/CustomValidators';
import { UserInfo, UserRolEditor, UserRolesInfo, UserChangePasswordData } from '../../../models/user.model';
import { DatePipe } from '@angular/common';
import * as myGlobals from '../../../globals';
import { timestamp } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-users-management',
  templateUrl: './users-management.component.html',
  styleUrls: ['./users-management.component.css']
})
export class UsersManagementComponent implements OnInit {

  formData: FormGroup;
  formDataSubmitted = false;

  userDataForm: FormGroup;
  userDataFormSubmitted = false;
  public currentUserData: UserInfo = new UserInfo();
  public currentUserRolEditor: UserRolEditor[] = [];

  public users: UserInfo[];
  public search: string = '';
  public searchData: SearchData = new SearchData();

  public firstUsers = 0;

  public select2UserRoles: any = null;
  public loadingPage: boolean = false;

  public userExits: boolean = false;
  public userHasRoles: boolean = false;

  constructor(private usersService: UsersService, private formBuilder: FormBuilder, private datePipe: DatePipe) { }

  // convenience getter for easy access to form fields
  get f() { return this.userDataForm.controls; }


  ngOnInit(): void {

    this.formData = this.formBuilder.group({
      'newPwd': ['', Validators.required],
      'confirmPwd': ['', Validators.required]
    }, {
      validator: CustomValidators.matchPwds
    });

    this.userDataForm = this.formBuilder.group({
      user_username: ['', [Validators.required, Validators.maxLength(50)]],
      user_fullname: ['', [Validators.required, Validators.maxLength(100)]],
      user_password: ['', Validators.maxLength(50)],
      user_fechaInicio: [''],
      user_fechaFin: [''],
      user_usedomainauthenticate: [false],
      user_disable: [false],
      user_type: [0],
      user_typereferenceid: [''],
      user_email: ['']
    });

    this.select2UserRoles = $('#user_roles').select2({
      dropdownParent: $('#editUserPanelEdicion')
    });


    this.loadingPage = true;
    this.searchData.showDateSearch = false;
    this.searchData.disabled = false;
    this.getUsersList();
  }


  saveUserPassword(): void {
    this.formDataSubmitted = true;

    // stop here if form is invalid
    if (this.formData.invalid) {
      return;
    }

    let data = new UserChangePasswordData();
    data.username = this.currentUserData.username;
    data.newPassword = this.newPwd.value;

    this.usersService.SetPassword(data)
      .subscribe((data: any) => {
        this.clearPasswordForm();
        this.setPasswordWindowToggle();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }


  clearPasswordForm() {
    this.newPwd.setValue("");
    this.confirmPwd.setValue("");
    this.formDataSubmitted = false;
  }

  get newPwd() {
    return this.formData.get('newPwd');
  }

  get confirmPwd() {
    return this.formData.get('confirmPwd');
  }

  invalidPwdNew(): boolean {
    let r: boolean = false;
    if (this.formDataSubmitted && this.newPwd.errors) r = true;
    return r;
  }

  invalidPwdConfirm(): boolean {
    let r: boolean = false;
    if (this.formDataSubmitted && (this.confirmPwd.errors || this.pwdsDontMatch())) r = true;
    return r;
  }

  pwdsDontMatch(): boolean {
    let r: boolean = false;
    if (this.formData.errors != null) {
      r = this.formData.errors.pwdsDontMatch;
    }
    return r;
  }


  saveUserDataForm(): void {
    this.currentUserData.username = this.f.user_username.value;
    this.currentUserData.fullname = this.f.user_fullname.value;
    this.currentUserData.password = this.f.user_password.value;
    this.currentUserData.fechaInicio = this.f.user_fechaInicio.value;
    this.currentUserData.fechaFin = this.f.user_fechaFin.value;
    this.currentUserData.useDomainAuthenticate = this.f.user_usedomainauthenticate.value;
    this.currentUserData.disable = this.f.user_disable.value;
    this.currentUserData.type = this.f.user_type.value;
    this.currentUserData.typeReferenceId = this.f.user_typereferenceid.value
    this.currentUserData.email = this.f.user_email.value;
  }

  loadUsedrDataForm(): void {
    this.userDataForm.setValue({
      user_username: this.currentUserData.username,
      user_fullname: this.currentUserData.fullname,
      user_password: this.currentUserData.password,
      user_fechaInicio: this.datePipe.transform(this.currentUserData.fechaInicio, "yyyy-MM-dd"),
      user_fechaFin: this.datePipe.transform(this.currentUserData.fechaFin, "yyyy-MM-dd"),
      user_usedomainauthenticate: this.currentUserData.useDomainAuthenticate,
      user_disable: this.currentUserData.disable,
      user_type: this.currentUserData.type,
      user_typereferenceid: this.currentUserData.typeReferenceId,
      user_email: this.currentUserData.email
    });
  }

  getUserRolEditor() {
    this.usersService.GetUserRolEditor(this.currentUserData.id)
      .subscribe((data: any) => {
        this.currentUserRolEditor = data.body;
        this.setUserRoles();
        this.userHasRoles = this.currentUserHasRol();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getUsersList(): void {
    this.usersManagementSpinner();
    this.usersService.GetUsersList(this.searchData)
      .subscribe((data: any) => {
        this.users = data.body;
        this.firstUsers = 0;
        this.usersManagementSpinner();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

    if (this.loadingPage) {
      this.loadingPage = false;
      this.usersManagementSpinner();
    }
  }

  getUsersListCountItems(): number {
    return this.users != undefined ? this.users.length : 0;
  }



  setUserEnable(idUser: number): void {
    this.usersService.UserSetEnable(idUser)
      .subscribe(() => {
        this.getUsersList();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  setUserDisable(idUser: number): void {
    this.usersService.UserSetDisable(idUser)
      .subscribe(() => {
        this.getUsersList();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  setUserPassword(user: UserInfo): void {
    this.currentUserData = user;
    this.clearPasswordForm();
    this.setPasswordWindowToggle();
  }

  newUser() {
    this.clearUserData();
    this.getUserRolEditor();
    this.editUserWindowToggle();
  }

  editUser(idUser: number): void {
    this.clearUserData();
    this.loadUserData(idUser);
    this.editUserWindowToggle();
  }

  saveUserData(): void {
    this.userDataFormSubmitted = true;
    this.setUserRoles();
    this.userHasRoles = this.currentUserHasRol();

    // stop here if form is invalid
    if (this.userDataForm.invalid || this.userExits || !this.userHasRoles) {
      return;
    }

    this.saveUserDataForm();
    this.usersService.SaveUserData(this.currentUserData)
      .subscribe((data: any) => {
        this.getUsersList();
        this.editUserWindowToggle();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

  }


  existUser(username: string) {
    this.usersService.UserExist(username)
      .subscribe((data: any) => {
        this.userExits = data;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  clearUserData() {
    this.userDataFormSubmitted = false;
    this.userDataForm.reset();
    this.currentUserData = new UserInfo();
    this.userExits = false;
    this.loadUsedrDataForm();
  }

  loadUserData(idUser: number) {
    this.usersManagementSpinner();
    this.usersService.GetUserData(idUser)
      .subscribe((data: any) => {
        this.currentUserData = data.body;
        this.getUserRolEditor();
        this.loadUsedrDataForm();
        this.usersManagementSpinner();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
          this.usersManagementSpinner();
        });
  }

  currentUserHasRol(): boolean {
    return this.currentUserRolEditor.filter(x => x.rolSelected == true).length > 0 ? true : false;
  }

  setUserRoles(): void {
    let roles = this.currentUserRolEditor
      .filter(x => x.rolSelected == true);
    // .map(x => ({ id: x.rolId, name: x.rolName, fechaInicio: x.fechaInicio, fechaFin: x.fechaFin }));

    this.currentUserData.roles = [];

    roles.forEach(rol => {
      let userRol = new UserRolesInfo();
      userRol.id = rol.rolId;
      userRol.name = rol.rolName;
      userRol.fechaInicio = new Date(rol.fechaInicio);
      userRol.fechaFin = new Date(rol.fechaFin);

      this.currentUserData.roles.push(userRol);
    });
  }

  exportExcel() {
    myGlobals.exportExcel(this.users.map(x => ({
      "Id": x.id,
      "Username": x.username,
      "Full name": x.fullname,
      "Email": x.email
    })));
  }


  validateUsername() {
    this.userExits = false;
    if (this.currentUserData.id == 0 && !myGlobals.isEmptyOrSpaces(this.f.user_username.value)) this.existUser(this.f.user_username.value);
  }

  editUserWindowToggle() {
    $('#editUserWindow').modal('toggle');
  }

  setPasswordWindowToggle() {
    $('#setPasswordWindow').modal('toggle');
  }

  usersManagementSpinner() {
    $('#usersManagement').children('.ibox-content').toggleClass('sk-loading');
  }

}

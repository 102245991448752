import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Roles } from '../../../enums/roles.enum';
import * as myGlobals from '../../../globals';

@Component({
  selector: 'app-page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.css'],
})
export class PageHomeComponent implements OnInit {
  public showOperations: boolean = false;
  public showAduanas: boolean = false;
  public showTracking: boolean = false;
  public showShipments: boolean = false;
  public showInvoices: boolean = false;
  public showClients: boolean = false;
  public showClientsAmazonInvoices: boolean = false;
  public showSettings: boolean = false;
  public showReporting: boolean = false;
  public showTasks: boolean = false;
  public showBrexit: boolean = false;
  public showBrexitLlorente: boolean = false;
  public showBrexitBrunel: boolean = false;
  public showWareHouse: boolean = false;
  public showWareHousePda: boolean = false;

  public showDebug: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    let user = myGlobals.userGetData();
    if (myGlobals.userHasRoles([Roles.Admin, Roles.Operaciones], user))
      this.showOperations = true;
    if (myGlobals.userHasRoles([Roles.Admin, Roles.Aduanas], user))
      this.showAduanas = true;
    if (myGlobals.userHasRole(Roles.Admin, user)) this.showTracking = true;
    if (myGlobals.userHasRole(Roles.Admin, user)) this.showShipments = true;
    if (myGlobals.userHasRole(Roles.Admin, user)) this.showInvoices = true;
    if (myGlobals.userHasRoles([Roles.Admin, Roles.AmazonInvoices], user))
      this.showClientsAmazonInvoices = true;
    if (myGlobals.userHasRoles([Roles.Admin, Roles.Reporting], user))
      this.showReporting = true;
    if (myGlobals.userHasRole(Roles.Admin, user)) this.showSettings = true;
    if (myGlobals.userHasRoles([Roles.Tasks, Roles.Admin], user))
      this.showTasks = true;
    if (myGlobals.userHasRoles([Roles.Admin, Roles.Brexit], user))
      this.showBrexit = true;
    if (myGlobals.userHasRoles([Roles.BrexitLlorente], user))
      this.showBrexitLlorente = true;
    if (myGlobals.userHasRoles([Roles.BrexitBrunel], user))
      this.showBrexitBrunel = true;
    if (myGlobals.userHasRoles([Roles.Admin, Roles.Warehouse], user))
      this.showWareHouse = true;
    if (myGlobals.userHasRoles([Roles.Admin, Roles.WarehousePda], user))
      this.showWareHousePda = true;

    if (myGlobals.userHasRole(Roles.Debug, user)) this.showDebug = true;
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }
}

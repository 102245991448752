<!-- ventana principal -->

<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{'page-administracion-clients-boxesbypallet.title' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations' | translate}}
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations-administration' | translate}}
      </li>
      <li class="breadcrumb-item" title="{{'page-administracion-clients.title' | translate}}">
        <a routerLink="/oper/admin/clients">{{'page-navigation.operations-clients' | translate}}</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>{{'page-administracion-clients-boxesbypallet.title' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{'page-administracion-clients-boxesbypallet.title-description' | translate}}</em></p>
  </div>
</div>


<div class="row">
  <div class="col-sm-12">
    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox" id="clientManagement">
        <div class="ibox-content sk-loading">

          <div class="sk-spinner sk-spinner-pulse" style="width: 75px; height: 75px; top: 100px;"></div>

          <div class="row">

            <div class="col-12">

              <div class="float-right m-b-lg">
                <div class="form-inline">
                  <button class="btn btn-sm btn-primary m-r-sm" type="button"
                    (click)="newConfig()">{{'page-administracion-clients-boxesbypallet.action-new' | translate}}</button>
                  <div class="input-group">
                    <input style="width: 300px;" type="text" class="form-control input-sm"
                      [(ngModel)]="searchData.value" placeholder="{{'general.search' | translate}}..."
                      (keyup.enter)="getConfigList()">

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" (click)="getConfigList()" type="button"
                        title="{{'general.search' | translate}}">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" (click)="exportExcel()"
                        title="{{'general.export-excel' | translate}}"> <i class="fa fa-download"
                          aria-hidden="true"></i></button>
                    </span>

                  </div>
                </div>
              </div>

            </div>

            <div class="col-12">
              <p-table [value]="configList" sortMode="multiple"
                styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [paginator]="true" [rows]="10"
                [showCurrentPageReport]="true" [autoLayout]="true" [(first)]="firstConfig"
                currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
                [rowsPerPageOptions]="[10,25,50,100,200]" [columnResizeMode]="'expand'" [reorderableColumns]="true"
                [resizableColumns]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 65px;"></th>
                    <th pSortableColumn="clientName">
                      {{'page-administracion-clients-boxesbypallet.columname-clientname' | translate}}
                      <p-sortIcon field="clientName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="boxForPallet">
                      {{'page-administracion-clients-boxesbypallet.columname-boxforpallet' | translate}}
                      <p-sortIcon field="boxForPallet"></p-sortIcon>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-conf>
                  <tr>
                    <td>
                      <div class="btn-group btn-group-xs">
                        <button class="btn btn-default btn-xs" title="{{'general.edit' | translate}}"
                          (click)="editConfig(conf.clientId)"><i class="fa fa-pencil"></i></button>
                        <button class="btn btn-default btn-xs" title="{{'general.delete' | translate}}"
                          (click)="deleteConfig(conf.clientId)"><i class="fa fa-trash-o"></i></button>
                      </div>
                    </td>
                    <td>{{conf.clientName}}</td>
                    <td>{{conf.boxForPallet}}</td>
                  </tr>
                </ng-template>
              </p-table>

            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<!-- ventana modal de edición -->

<div class="modal inmodal fade" id="editConfigWindow" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">
          {{'page-administracion-clients-boxesbypallet.editwindow-title' | translate}}</h4>
        <small
          class="font-bold">{{'page-administracion-clients-boxesbypallet.editwindow-title-description' | translate}}</small>

      </div>
      <div class="modal-body" id="editConfigPanelEdicion">

        <div class="row">
          <div class="col-sm-12">

            <form [formGroup]="configDataForm">

              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-administracion-clients-boxesbypallet.columname-clientname' | translate}} </label>
                <div class="col-sm-7" [ngClass]="{ 'is-invalid': configDataFormSubmitted && f.config_clientId.errors }">
                  <select class="form-control input-sm" formControlName="config_clientId" id="config_clientId"
                    style="width:100%;"
                    [ngClass]="{ 'is-invalid': configDataFormSubmitted && f.config_clientId.errors }">
                    <option *ngFor="let c of clientsList" value="{{c.id}}">{{c.name}}</option>
                  </select>
                  <div *ngIf="configDataFormSubmitted && f.config_clientId.errors" class="invalid-feedback">
                    <div *ngIf="f.config_clientId.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-administracion-clients-boxesbypallet.columname-boxforpallet' | translate}} </label>
                <div class="col-sm-4">
                  <input type="number" class="form-control input-sm" formControlName="config_boxForPallet"
                    [ngClass]="{ 'is-invalid': configDataFormSubmitted && f.config_boxForPallet.errors }">
                  <div *ngIf="configDataFormSubmitted && f.config_boxForPallet.errors" class="invalid-feedback">
                    <div *ngIf="f.config_boxForPallet.errors.required"> {{'validations.isrequired' | translate}} </div>
                    <div *ngIf="f.config_boxForPallet.errors.invalidNumber"> {{'validations.only-numbers' | translate}}
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>
        <button type="button" class="btn btn-sm btn-primary"
          (click)="saveConfigData()">{{'general.save-changes' | translate}}</button>
      </div>
    </div>
  </div>
</div>

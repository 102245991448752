import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SearchData } from '../models/searches.model';
import { BrexitShipment, BrexitShipmentProperties } from '../models/brexit.model';
import { CustomsExportOperation, CustomsImportOperation, CustomsImportOperationProperties, CustomsExportOperationProperties } from '../models/customs.model';
import * as myGlobals from '../globals';
import { from } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BrexitService {

    constructor(public http: HttpClient) { }

    public GetShipmentList(searchData: SearchData) {
        return this.http.post(myGlobals.RootUrlTikitingApi + 'Brexit/GetShipmentList', searchData,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json'),
                observe: 'response'
            });
    }

    public GetCustomsFilesListToSelect(searchData: SearchData) {
        return this.http.post(myGlobals.RootUrlTikitingApi + 'Brexit/GetCustomsFilesListToSelect', searchData,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json'),
                observe: 'response'
            });
    }

    public GetShipment(id: number) {
        return this.http.post(myGlobals.RootUrlTikitingApi + 'Brexit/GetShipment', id,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json'),
                observe: 'response'
            });
    }

    public SaveShipment(data: BrexitShipment) {
        return this.http.post(myGlobals.RootUrlTikitingApi + 'Brexit/SaveShipment', data,
            {
                headers: new HttpHeaders()
            });
    }

    public GetOperationsTypes(operationCode: number) {
        return this.http.get(myGlobals.RootUrlTikitingApi + 'Brexit/GetOperationsTypes?operationCode=' + operationCode,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json'),
                observe: 'response'
            }
        );
    }

    public GetAttachmentsTypes(operationCode: number) {
        return this.http.get(myGlobals.RootUrlTikitingApi + 'Brexit/GetAttachmentsTypes?operationCode=' + operationCode,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json'),
                observe: 'response'
            }
        );
    }

    public GetOperationsDocuments(operationParents: number[]) {
        return this.http.post(myGlobals.RootUrlTikitingApi + 'Brexit/GetOperationsDocuments', operationParents,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json'),
                observe: 'response'
            }
        );
    }

    public GetAgentsList(operationCode: number) {
        return this.http.get(myGlobals.RootUrlTikitingApi + 'Brexit/GetAgentsList?operationCode=' + operationCode,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json'),
                observe: 'response'
            }
        );
    }

    public AddExportData(data: CustomsExportOperation) {
        return this.http.post(myGlobals.RootUrlTikitingApi + 'Brexit/AddExportData', data,
            {
                reportProgress: true,
                headers: new HttpHeaders()
            });
    }

    public AddImportData(data: CustomsImportOperation) {
        return this.http.post(myGlobals.RootUrlTikitingApi + 'Brexit/AddImportData', data,
            {
                reportProgress: true,
                headers: new HttpHeaders()
            });
    }

    public GetShipmentProperties(id: number) {
        return this.http.post(myGlobals.RootUrlTikitingApi + 'Brexit/GetShipmentProperties', id,
            {
                reportProgress: true,
                headers: new HttpHeaders()
            });
    }

    public GetImportProperties(id: number) {
        return this.http.post(myGlobals.RootUrlTikitingApi + 'Brexit/GetImportProperties', id,
            {
                reportProgress: true,
                headers: new HttpHeaders()
            });
    }

    public GetExportProperties(id: number) {
        return this.http.post(myGlobals.RootUrlTikitingApi + 'Brexit/GetExportProperties', id,
            {
                reportProgress: true,
                headers: new HttpHeaders()
            });
    }

    public DeleteShipmentData(id: number) {
        return this.http.post(myGlobals.RootUrlTikitingApi + 'Brexit/DeleteShipmentData', id,
            {
                reportProgress: true,
                headers: new HttpHeaders()
            });
    }


    public DeleteImportData(id: number) {
        return this.http.post(myGlobals.RootUrlTikitingApi + 'Brexit/DeleteImportData', id,
            {
                reportProgress: true,
                headers: new HttpHeaders()
            });
    }

    public DeleteExportData(id: number) {
        return this.http.post(myGlobals.RootUrlTikitingApi + 'Brexit/DeleteExportData', id,
            {
                reportProgress: true,
                headers: new HttpHeaders()
            });
    }





    public UpdateShipmentProperties(data: BrexitShipmentProperties) {
        return this.http.post(myGlobals.RootUrlTikitingApi + 'Brexit/UpdateShipmentProperties', data,
            {
                reportProgress: true,
                headers: new HttpHeaders()
            });
    }

    public updateExportProperties(data: CustomsExportOperationProperties) {
        return this.http.post(myGlobals.RootUrlTikitingApi + 'Brexit/UpdateExportProperties', data,
            {
                reportProgress: true,
                headers: new HttpHeaders()
            });
    }

    public updateImportProperties(data: CustomsImportOperationProperties) {
        return this.http.post(myGlobals.RootUrlTikitingApi + 'Brexit/UpdateImportProperties', data,
            {
                reportProgress: true,
                headers: new HttpHeaders()
            });
    }

}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TerrestrialServiceCheckInfo, TerrestrialService, TerrestrialServiceChangeStatus } from '../models/terrestrial-services.model';
import { SearchData } from '../models/searches.model';
import * as myGlobals from '../globals';

@Injectable({
  providedIn: 'root'
})
export class TerrestrialServices {

  constructor(public http: HttpClient) { }

  public GetHubsFilterList() {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'Services/GetHubsFilterList',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public GetTerrestrialServicesList(searchData: SearchData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Services/GetTerrestrialServicesList', searchData,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public GetTerrestrialService(id: number) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Services/GetTerrestrialService', id,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public SaveTerrestrialService(data: TerrestrialService) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Services/SaveTerrestrialService', data,
      {
        headers: new HttpHeaders()
      });
  }

  public GetProvidersList() {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'Services/GetProvidersList',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public GetStatusList() {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'Services/GetStatusList',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public InvoiceTerrestrialService(id: number) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Services/InvoiceTerrestrialService', id,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public ChangeStatusTerrestrialService(data: TerrestrialServiceChangeStatus) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Services/ChangeStatusTerrestrialService', data,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public DeleteTerrestrialService(id: number) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Services/DeleteTerrestrialService', id,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public ExpeditionInvoicedTerrestrialService(data: TerrestrialServiceCheckInfo) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Services/ExpeditionInvoicedTerrestrialService', data,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public ExistTerrestrialServiceExpedition(expedition: string) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Services/ExistTerrestrialServiceExpedition?expedition=' + expedition,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

}

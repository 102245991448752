<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{'page-reporting-queries-list.title' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.reporting' | translate}}
      </li>
      <li class="breadcrumb-item active">
        <strong>{{'page-navigation.reporting-queries' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{'page-reporting-queries-list.title-description' | translate}}</em></p>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox" id="queriesListManagement">
        <div class="ibox-content sk-loading">

          <div class="sk-spinner sk-spinner-pulse" style="width: 75px; height: 75px; top: 100px;"></div>

          <div class="row">

            <div class="col-12">
              <div class="float-right m-b-lg">
                <div class="form-inline">
                  <div class="input-group">
                    <input style="width: 300px;" type="text" class="form-control input-sm"
                      [(ngModel)]="searchData.value" placeholder="{{'general.search' | translate}}..."
                      (keyup.enter)="getViewsList()">

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" (click)="getViewsList()" type="button"
                        title="{{'general.search' | translate}}">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </span>

                    <span *ngIf="searchData.showAdvancedOptions" class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" data-toggle="collapse"
                        data-parent="#panelSearch" href="#panelAdvancedSearch" aria-expanded="false"
                        title="{{'search.title' | translate}}">
                        <i class="fa fa-wrench" aria-hidden="true"></i>
                      </button>
                    </span>

                  </div>
                </div>
              </div>

              <div id="panelSearch">
                <div id="panelAdvancedSearch" class="panel-collapse collapse">
                  <h2 class="search-panel-title">{{'search.title' | translate}}</h2>
                  <small class="font-bold">{{'search.title-description' | translate}}</small>
                  <div class="hr-line-dashed"></div>

                  <div>

                    <div *ngIf="searchData.showStatusSearch">
                      <label class="checkbox-inline m-r-md">
                        {{'search.showresults' | translate}}:
                      </label>

                      <label class="checkbox-inline m-r-md">
                        <input type="checkbox" [(ngModel)]="searchData.enabled">
                        &nbsp;{{'search.show-active' | translate}}
                      </label>

                      <label class="checkbox-inline">
                        <input type="checkbox" [(ngModel)]="searchData.disabled">
                        &nbsp;{{'search.show-disabled' | translate}}
                      </label>
                    </div>


                    <div *ngIf="searchData.showDateSearch" class="form-group form-inline">
                      <label class="m-r-md">{{'search.daterange' | translate}}:</label>

                      <label class="m-r-md">{{'search.date-from' | translate}}
                        <input type="date" class="form-control form-control-search m-l-sm" id="fechadesde">
                      </label>

                      <label>{{'search.date-to' | translate}}
                        <input type="date" class="form-control form-control-search m-l-sm">
                      </label>
                    </div>

                  </div>
                  <div class="hr-line-dashed"></div>
                </div>
              </div>
            </div>


            <div class="col-12">
              <p-table [value]="views" styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"
                [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [(first)]="first"
                currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
                [rowsPerPageOptions]="[10,25,50,100,200]" selectionMode="single">
                <ng-template pTemplate="body" let-view>
                  <tr [ngClass]="{'row-disable' : view.status!=0}">
                    <td>
                      <div class="cursor-pointer" (click)="loadView(view.id)">
                        <strong>{{view.name}}</strong><br />
                        <span>{{view.description}}</span>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>


          </div>

        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Roles } from '../../../enums/roles.enum';
import * as myGlobals from '../../../globals';
import { TranslateService } from '@ngx-translate/core';
import { TokenStorageService } from '../../../services/token-storage.service';
declare var $: any;

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css'],
})
export class PageHeaderComponent implements OnInit {
  public fullname: string;
  public appTitle: string = myGlobals.AppTitle;

  public isAdmin: boolean = false;
  public showDebug: boolean = false;

  constructor(
    private token: TokenStorageService,
    private translate: TranslateService
  ) {}

  setLanguage(language: string) {
    this.translate.use(language);
    this.translate.setDefaultLang(language);
    myGlobals.languageSetDefaultValue(language);
  }

  ngOnInit(): void {
    let user = myGlobals.userGetData();
    this.fullname = user.fullname;
    if (myGlobals.userHasRole(Roles.Admin, user)) this.isAdmin = true;
    if (myGlobals.userHasRole(Roles.Debug, user)) this.showDebug = true;
  }

  logout(): void {
    myGlobals.userLogOut();
  }

  toggleSidebar() {
    let preferences = this.token.getPreferences();
    preferences.hideSidebar = !preferences.hideSidebar;
    this.token.savePreferences(preferences);

    if (preferences.hideSidebar) $('body').addClass('mini-navbar');
    else $('body').removeClass('mini-navbar');
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { taskInfo } from '../../../../models/tasks.model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { tasksService } from '../../../../services/tasks.service';
import { SearchData } from '../../../../models/searches.model';
import * as myGlobals from '../../../../globals';
import { TaskLines } from '../../../../models/task-lines.model';
import { Prioridad } from '../../../../models/prioridad.model';
import { Responsable } from '../../../../models/responsable.model';

import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-tasks-lines',
  templateUrl: './tasks-lines.component.html',
  styleUrls: ['./tasks-lines.component.css'],
})
export class TasksLinesComponent implements OnInit {
  public Editor = ClassicEditor;
  taskDataForm: FormGroup;
  lineDataForm: FormGroup;
  taskeditDataForm: FormGroup;
  taskDataFormSubmitted: boolean = false;

  public taskLinesList: TaskLines[];
  public currentTaskId: number = 0;
  public searchData: SearchData = new SearchData();
  public tasksList: taskInfo[];
  public prioridades: Prioridad[];
  public estados: Prioridad[];
  public responsables: Responsable[];
  public responsableslist: string;
  public currentTaskData: taskInfo = new taskInfo();
  public currentLineData: TaskLines = new TaskLines();
  public newTask: taskInfo = new taskInfo();
  public loadingPage: boolean = false;
  public convert: boolean = false;
  public newEstado: string;
  public newCliente: string;
  public newDesc: string;
  public newPrioridad: number;
  public newResponsable: string;
  public newFechaFin: Date;

  select2TktId: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tasksService: tasksService,
    private formBuilder: FormBuilder,
    private datepipe: DatePipe
  ) {}
  get f() {
    return this.taskDataForm.controls;
  }
  get g() {
    return this.lineDataForm.controls;
  }

  ngOnInit(): void {
    this.searchData.showDateSearch = false;

    this.taskDataForm = this.formBuilder.group({
      task_responsable: [''],
      task_cliente: [''],
      task_estado: [''],
      task_titulo: [''],
      task_descripcion: [''],
      task_fechaFin: [''],
      task_prioridad: [0],
      task_fechaLimite: [''],
      task_completado: [''],
    });

    this.lineDataForm = this.formBuilder.group({
      line_comentario: [''],
    });

    this.currentTaskId = Number(this.route.snapshot.paramMap.get('id'));
    this.searchData.showDateSearch = true;
    if (this.currentTaskId != 0) {
      this.loadTaskData(this.currentTaskId);
      this.getTaskLines(this.currentTaskId);
    }
    this.getResponsablesList();
    //cogemos las prioridades
    this.getPrioridadesList(1);
    //cogemos los estados
    this.getPrioridadesList(2);
  }

  saveTaskDataForm(): void {
    this.currentTaskData.responsable = this.responsableslist;
    this.currentTaskData.cliente = this.f.task_cliente.value;
    this.currentTaskData.estado = this.f.task_estado.value;
    this.currentTaskData.descripcion = this.f.task_descripcion.value;
    this.currentTaskData.tituloProyecto = this.f.task_titulo.value;
    this.currentTaskData.fechaFin = this.f.task_fechaFin.value;
    this.currentTaskData.prioridad = this.f.task_prioridad.value;
    this.currentTaskData.fechaLimite = this.f.task_fechaLimite.value;
    this.currentTaskData.completado = this.f.task_completado.value;
  }
  loadTaskDataForm(): void {
    this.taskDataForm.setValue({
      task_responsable: this.currentTaskData.responsable.split('/'),
      task_cliente: this.currentTaskData.cliente,
      task_estado: this.currentTaskData.estado,
      task_descripcion: this.currentTaskData.descripcion,
      task_titulo: this.currentTaskData.tituloProyecto,
      task_prioridad: this.currentTaskData.prioridad,
      task_fechaFin: this.datepipe.transform(
        this.currentTaskData.fechaFin,
        'yyyy-MM-dd'
      ),
      task_fechaLimite: this.datepipe.transform(
        this.currentTaskData.fechaLimite,
        'yyyy-MM-dd'
      ),
      task_completado: this.currentTaskData.completado,
    });
  }

  saveLineDataForm(): void {
    this.currentLineData.comentario = this.g.line_comentario.value;
  }
  loadLineDataForm(): void {
    this.lineDataForm.setValue({
      line_comentario: this.currentLineData.comentario,
    });
  }

  saveTaskData() {
    this.taskDataFormSubmitted = true;
    if (
      this.f.task_responsable.value != null ||
      this.currentTaskData.responsable == ' '
    ) {
      this.responsableslist = this.f.task_responsable.value.join('/');
    } else {
      this.responsableslist = this.currentTaskData.responsable;
    }
    if (this.currentTaskId == 0) {
      this.saveTaskDataForm();
      this.currentTaskData.fechaAlta = new Date();
      this.currentTaskData.userAlta = myGlobals.userGetData().username;
      this.currentTaskData.completado = 0;
      this.tasksService.TaskManagementSaveData(this.currentTaskData).subscribe(
        (id: number) => {
          this.getTasksList();
          this.reload(id);
        },
        (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        }
      );
    } else {
      this.saveTaskDataForm();
      if (this.currentTaskData.estado == '3') {
        this.currentTaskData.fechaFin = new Date();
      }
      this.currentTaskData.userAlta = myGlobals.userGetData().username;
      this.tasksService.TaskManagementSaveData(this.currentTaskData).subscribe(
        (data: any) => {
          this.getTasksList();
        },
        (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        }
      );
    }
  }
  newLineData(idLine: number) {
    this.lineDataForm.reset();
    this.loadComment(idLine);
    this.newLineWindowToggle();
  }

  loadComment(idLine: number) {
    this.tasksService.TaskLinesGetComment(idLine).subscribe(
      (data: any) => {
        this.currentLineData = data.body;
        this.loadLineDataForm();
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }
  saveLineData() {
    this.taskDataFormSubmitted = true;
    // stop here if form is invalid
    this.saveLineDataForm();
    this.currentLineData.usuario = myGlobals.userGetData().username;
    this.currentLineData.fecha = new Date();
    this.currentLineData.idTask = this.currentTaskId;
    this.currentLineData.estado = 0;

    this.tasksService.TaskLinesAddComment(this.currentLineData).subscribe(
      (data: any) => {
        this.getTaskLines(this.currentTaskId);
        this.newLineWindowToggle;
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }
  getTaskLines(idTask: number) {
    this.tasksService.TaskLinesGet(idTask).subscribe(
      (data: any) => {
        this.taskLinesList = data.body;
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  loadTaskData(idTask: number) {
    this.tasksService.TaskManagementGetData(idTask).subscribe(
      (data: any) => {
        this.currentTaskData = data.body;
        this.loadTaskDataForm();
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }
  deleteTaskLine(idLine: number) {
    this.tasksService.TaskLinesDeleteLine(idLine).subscribe(
      (data: any) => {
        this.getTaskLines(this.currentTaskId);
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  getTasksList() {
    this.tasksService.TasksGetList(this.searchData).subscribe(
      (data: any) => {
        this.tasksList = data.body;
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }
  getPrioridadesList(idPrio: number) {
    this.tasksService.TasksGetPrioridades(idPrio).subscribe(
      (data: any) => {
        if (idPrio == 1) {
          this.prioridades = data.body;
        }
        if (idPrio == 2) {
          this.estados = data.body;
        }
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }
  getResponsablesList() {
    this.tasksService.TasksGetResponsables(this.searchData).subscribe(
      (data: any) => {
        this.responsables = data.body;
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }
  clearTasksData() {
    this.taskDataFormSubmitted = false;
    this.taskDataForm.reset();
    this.loadTaskDataForm();
  }

  editTask() {
    this.clearTasksData();
    this.loadTaskData(this.currentTaskId);
    this.editTasksWindowToggle();
  }
  reload(id: number) {
    this.router.navigateByUrl('tasks/' + id);
    this.loadTaskData(id);
    this.currentTaskId = id;
  }

  newLineWindowToggle() {
    $('#newLineWindow').modal('toggle');
  }
  editTasksWindowToggle() {
    $('#editTareaWindow').modal('toggle');
  }
}

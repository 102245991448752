<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{'page-customs-documents.title' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.customs' | translate}}
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.customs-modules' | translate}}
      </li>
      <li class="breadcrumb-item active">
        <strong>{{'page-navigation.customs-documents' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{'page-customs-documents.title-description' | translate}}</em></p>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox" id="customsDocumentsManagement">
        <div class="ibox-content sk-loading">
          <div class="sk-spinner sk-spinner-pulse" style="width: 75px; height: 75px; top: 100px;"></div>

          <div class="tabs-container">
            <ul class="nav nav-tabs" role="tablist">
              <li><a class="nav-link active show" data-toggle="tab" href="#tab-middleeast"> Middle East</a></li>
              <li><a class="nav-link" data-toggle="tab" href="#tab-brexit">Brexit</a></li>
            </ul>

            <div class="tab-content">
              <div role="tabpanel" id="tab-middleeast" class="tab-pane active show">
                <div class="panel-body">
                  <div class="row" id="tab-middleeast-controls">

                    <div class="col-lg-6">
                      <h5>{{'page-customs-documents.documenttype-title' | translate}}:</h5>
                      <ng-select2 [data]="middleeastRefTypesList" width="100%"
                        [dropdownParent]="'tab-middleeast-controls'" [(ngModel)]="middleeastRefTypesSelected">
                      </ng-select2>
                    </div>

                  </div>

                  <div class="row">
                    <div class="col-12">
                      <br />
                      <h5 class="m-xs">{{'page-customs-documents.searchbox-title' | translate}}:</h5>
                      <textarea type="text" class="form-control form-control-sm" style="width: 100%;"
                        [(ngModel)]="searchData.value" rows="15"></textarea>
                    </div>

                    <div class="col-12">
                      <br />
                      <button type="button" class="float-right btn btn-sm btn-primary"
                        (click)="downloadA()">{{'general.download' | translate}}</button>
                    </div>
                  </div>

                </div>
              </div>
              <div role="tabpanel" id="tab-brexit" class="tab-pane">
                <div class="panel-body">
                  <div class="row" id="tab-brexit-controls">

                    <div class="col-lg-6">
                      <h5>Tipo de referencia:</h5>
                      <ng-select2 [data]="brexitRefTypesList" width="100%" [dropdownParent]="'tab-brexit-controls'"
                        [(ngModel)]="brexitRefTypesSelected">
                      </ng-select2>
                    </div>

                    <div class="col-lg-6">
                      <h5>Documentos a descargar:</h5>
                      <ng-select2 [data]="docsTypesList" width="100%" [dropdownParent]="'tab-brexit-controls'"
                        [(ngModel)]="docsTypesSelected" [options]="select2MultipleOptions">
                      </ng-select2>
                    </div>

                  </div>

                  <div class="row">
                    <div class="col-12">
                      <br />
                      <h5 class="m-xs">{{'page-customs-documents.searchbox-title' | translate}}:</h5>
                      <textarea type="text" class="form-control form-control-sm" style="width: 100%;"
                        [(ngModel)]="searchData.value" rows="15"></textarea>
                    </div>

                    <div class="col-12">
                      <br />
                      <button type="button" class="float-right btn btn-sm btn-primary"
                        (click)="downloadB()">{{'general.download' | translate}}</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

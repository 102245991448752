import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Pais } from '../../../models/countries.model';
import {
  View,
  ViewExecuteResult,
  ActionResultData,
  ViewExecuteData,
  ViewParameter,
  ViewParameterData,
  CodeDescription,
  ViewExecuteResultColumn,
  GetExcelInfo,
} from '../../../models/reporting.model';
import { ClientInfo } from '../../../models/clients.model';
import { ClientsService } from '../../../services/clients.service';
import { ReportingService } from '../../../services/reporting.service';
import { CountriesService } from '../../../services/countries.service';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Select2OptionData } from 'ng-select2';
import * as myGlobals from '../../../globals';

declare var $: any;

@Component({
  selector: 'app-queries-execute',
  templateUrl: './queries-execute.component.html',
  styleUrls: ['./queries-execute.component.css'],
})
export class QueriesExecuteComponent implements OnInit {
  spinnerLoading: any;

  public currentView: View = new View();
  public currentViewExecuteResult = new ViewExecuteResult();
  public currentViewExecuteData = new ViewExecuteData();
  public actionResultData = new ActionResultData();
  public first = 0;

  loadingPage: boolean = false;
  defaultViewcolumns: ViewExecuteResultColumn[] = [];
  timerValue: number = 100;
  loadedValue: number = 0;

  listClientsBackup: ClientInfo[] = [];

  listCountries: Select2OptionData[] = [];
  listContaProviders: Select2OptionData[] = [];
  listTktCouriers: Select2OptionData[] = [];
  listTktClients: Select2OptionData[] = [];
  listContaClients: Select2OptionData[] = [];
  listTikitingStatus: Select2OptionData[] = [];
  listTipoFecha: Select2OptionData[] = [];
  listTipoReferencia: Select2OptionData[] = [];
  listBrands: Select2OptionData[] = [];

  select2MultipleOptions = {
    multiple: true,
    tags: true,
  };

  @ViewChild('dt') table: Table;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private reportingService: ReportingService,
    private countriesService: CountriesService,
    private clientsService: ClientsService,
    private datePipe: DatePipe,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.spinnerLoading = $('#queriesExecuteManagement').children(
      '.ibox-content'
    );
    this.loadingPage = true;
    this.loadView();
  }

  redirect() {
    this.router.navigateByUrl('/repo/queries');
  }

  @Input() get selectedColumns(): any[] {
    return this.currentViewExecuteData.userFilterData.columns;
  }

  @Input() get globalFilterFields(): string[] {
    return this.currentViewExecuteResult.columns.map((x) => x.field);
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this.currentViewExecuteData.userFilterData.columns =
      this.defaultViewcolumns.filter((col) => val.includes(col));
  }

  loadView() {
    myGlobals.spinnerOn(this.spinnerLoading);
    let id = Number(this.route.snapshot.paramMap.get('id'));
    if (isNaN(id)) {
      this.redirect();
    } else {
      this.reportingService.GetView(id).subscribe(
        (data: any) => {
          this.currentView = data.body;

          this.reportingService.GetViewData(this.currentView.id).subscribe(
            (data: any) => {
              this.currentViewExecuteResult = data.body;
              if (this.currentViewExecuteResult.columns.length > 0) {
                this.currentViewExecuteData.idView = this.currentView.id;
                this.currentViewExecuteData.parameters =
                  this.currentViewExecuteResult.parameters;
                this.currentViewExecuteData.userFilterData.columns =
                  this.currentViewExecuteResult.columns;
                this.defaultViewcolumns = this.currentViewExecuteResult.columns;
                this.actionResultData.successfull = true;
                this.first = 0;
                this.loadControls(this.currentViewExecuteData.parameters);
                myGlobals.spinnerOff(this.spinnerLoading);
              } else {
                myGlobals.spinnerOff(this.spinnerLoading);
                this.redirect();
              }
            },
            (error: string) => {
              myGlobals.spinnerOff(this.spinnerLoading);
              myGlobals.showMessage(error, myGlobals.MessageType.Error);
              this.redirect();
            }
          );
        },
        (error: string) => {
          myGlobals.spinnerOff(this.spinnerLoading);
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
          this.redirect();
        }
      );
    }
  }

  checkLoaded(add: boolean) {
    if (add) this.loadedValue = this.loadedValue + 1;
    else this.loadedValue = this.loadedValue - 1;
    if (this.loadedValue == 0) myGlobals.spinnerOff(this.spinnerLoading);
  }

  loadControls(parameters: ViewParameter[]) {
    this.listCountries = [];
    this.listContaProviders = [];
    this.listTktCouriers = [];
    this.listTktClients = [];
    this.listContaClients = [];
    this.listTikitingStatus = [];
    this.listTipoFecha = [];
    this.listTipoReferencia = [];
    this.listBrands = [];

    for (var i = 0; i < parameters.length; i++) {
      let item = parameters[i];
      if (this.isListCountriesParameter(item.type)) this.loadCountriesList();
      if (this.isListContaProvidersParameter(item.type))
        this.loadContaProvidersList();
      if (this.isListContaClientsParameter(item.type))
        this.loadContaClientsList();
      if (this.isListTktClientsParameter(item.type)) this.loadTktClientsList();
      if (this.isListBrandsParameter(item.type)) this.loadBrandsList();
      if (this.isListTktCouriersParameter(item.type))
        this.loadTktCouriersList();
      if (this.isListTikitingStatusParameter(item.type))
        this.loadTikitingStatusList();
      if (this.isListTipoFechaParameter(item.type)) this.loadTipoFechaList();
      if (this.isListTipoReferenciaParameter(item.type))
        this.loadTipoReferenciaList();
    }
  }

  getViewExecuteData(): ViewExecuteData {
    let data = new ViewExecuteData();
    data.idView = this.currentViewExecuteData.idView;
    data.userFilterData = this.currentViewExecuteData.userFilterData;
    data.parameters = this.currentViewExecuteData.parameters;
    return data;
  }

  executeView() {
    myGlobals.spinnerOn(this.spinnerLoading);
    this.actionResultData = new ActionResultData();
    this.reportingService
      .GetViewResultData(this.getViewExecuteData())
      .subscribe(
        (data: any) => {
          this.currentViewExecuteResult = this.checkExecuteResult(data.body);
          this.actionResultData.successfull = true;
          this.first = 0;
          myGlobals.spinnerOff(this.spinnerLoading);
        },
        (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
          myGlobals.spinnerOff(this.spinnerLoading);
        }
      );
  }

  getCellValue(
    rowData: any[],
    col: ViewExecuteResultColumn
  ): string | null | undefined {
    let formatDate: string = this.translate.instant('general.format-date');
    let formatDateTime: string = this.translate.instant(
      'general.format-datetime'
    );

    if (col.type.toLowerCase() == 'datetime') {
      if (rowData[col.field] != null) {
        let date = new Date(rowData[col.field]);
        let time =
          date.getSeconds() +
          date.getMinutes() * 60 +
          date.getHours() * 60 * 60;

        if (time > 0) {
          return this.datePipe.transform(date, formatDateTime);
        } else {
          return this.datePipe.transform(date, formatDate);
        }
      }
    } else {
      return rowData[col.field];
    }
  }

  exportExcelByApi(json: string) {
    let info = new GetExcelInfo();
    info.json = json;
    info.filename = 'result_export_' + new Date().getTime() + '.xlsx';

    this.reportingService.GetExcel(info).subscribe(
      (data: any) => {
        myGlobals.saveAsExcelFile(data.body, 'result');
      },
      (error: any) => {
        var reader = new FileReader();
        reader.onload = function () {
          myGlobals.showMessage(
            reader.result.toString(),
            myGlobals.MessageType.None
          );
        };
        reader.readAsText(error);
      }
    );
  }

  exportExcel() {
    let fechas = this.currentViewExecuteResult.columns
      .filter((x) => x.type.toLowerCase() == 'datetime')
      .map((x) => x.field);

    if (fechas.length > 0) {
      let rows = JSON.parse(JSON.stringify(this.currentViewExecuteResult.rows));
      rows.forEach((row: any) => {
        fechas.forEach((f) => {
          if (row[f] != null) {
            let date = new Date(row[f]);
            let minutesToAdd = date.getTimezoneOffset() * -1;
            console.log(minutesToAdd);

            let date2 = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              date.getHours(),
              date.getMinutes(),
              date.getSeconds(),
              0
            );

            row[f] = new Date(date2.getTime() + 60000 * minutesToAdd);
          }
        });
      });
      this.exportExcelByApi(JSON.stringify(rows));
    } else {
      myGlobals.exportExcel(this.currentViewExecuteResult.rows);
    }
  }

  isBooleanParameter(type: string): boolean {
    if (type == 'boolean') return true;
    else return false;
  }

  isStringParameter(type: string): boolean {
    if (type == 'string') return true;
    else return false;
  }

  isLongStringParameter(type: string): boolean {
    if (type == 'longstring') return true;
    else return false;
  }

  isDateParameter(type: string): boolean {
    if (type == 'date') return true;
    else return false;
  }

  isDateTimeParameter(type: string): boolean {
    if (type == 'datetime') return true;
    else return false;
  }

  isIntParameter(type: string): boolean {
    if (type == 'int') return true;
    else return false;
  }

  isBoolParameter(type: string): boolean {
    if (type == 'bool') return true;
    else return false;
  }

  isListCountriesParameter(type: string): boolean {
    if (type == 'listcountries') return true;
    else return false;
  }
  isListBrandsParameter(type: string): boolean {
    if (type == 'listbrands') return true;
    else return false;
  }

  isListContaProvidersParameter(type: string): boolean {
    if (type == 'listcontaproviders') return true;
    else return false;
  }

  isListContaClientsParameter(type: string): boolean {
    if (type == 'listcontaclients') return true;
    else return false;
  }

  isListTktClientsParameter(type: string): boolean {
    if (type == 'listtktclients') return true;
    else return false;
  }

  isListTktCouriersParameter(type: string): boolean {
    if (type == 'listtktcouriers') return true;
    else return false;
  }

  isListTikitingStatusParameter(type: string): boolean {
    if (type == 'listtikitingstatus') return true;
    else return false;
  }

  isListTipoFechaParameter(type: string): boolean {
    if (type == 'listtipofecha') return true;
    else return false;
  }

  isListTipoReferenciaParameter(type: string): boolean {
    if (type == 'listtiporeferencia') return true;
    else return false;
  }

  isSelectValueParameter(type: string): boolean {
    if (type == 'selectvalue') return true;
    else return false;
  }

  loadCountriesList() {
    this.countriesService.GetPaises().subscribe(
      (data: any) => {
        this.listCountries = data.body.map((x: Pais) => ({
          id: x.codigoalfa2,
          text: x.pais,
        }));
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  loadContaProvidersList() {
    this.clientsService.ClientsManagementGetContaProviderList().subscribe(
      (data: any) => {
        this.listContaProviders = data.body.map((x: ClientInfo) => ({
          id: x.id.toString(),
          text: x.name,
        }));
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  loadContaClientsList() {
    this.clientsService.ClientsManagementGetContaClientList().subscribe(
      (data: any) => {
        this.listContaClients = data.body.map((x: ClientInfo) => ({
          id: x.id.toString(),
          text: x.name,
        }));
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  loadTktClientsList() {
    this.clientsService.ClientsManagementGetTktClientList().subscribe(
      (data: any) => {
        this.listClientsBackup = data.body;
        this.listTktClients = this.listClientsBackup.map((x: ClientInfo) => ({
          id: x.id.toString(),
          text: x.name,
        }));
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  loadBrandsList() {
    this.clientsService.ClientsManagementGetBrands().subscribe(
      (data: any) => {
        this.listBrands = data.body.map((x: string) => ({ id: x, text: x }));
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  loadTktCouriersList() {
    this.clientsService.ClientsManagementGetTktProviderList().subscribe(
      (data: any) => {
        this.listTktCouriers = data.body.map((x: ClientInfo) => ({
          id: x.id.toString(),
          text: x.name,
        }));
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  loadTikitingStatusList() {
    this.reportingService.GetTikitingTrackingStatusList().subscribe(
      (data: any) => {
        this.listTikitingStatus = data.body.map((x: CodeDescription) => ({
          id: x.code,
          text: x.description,
        }));
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  loadTipoFechaList() {
    this.reportingService.GetTipoFechaList().subscribe(
      (data: any) => {
        this.listTipoFecha = data.body.map((x: CodeDescription) => ({
          id: x.code,
          text: x.description,
        }));
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  loadTipoReferenciaList() {
    this.reportingService.GetTipoReferenciaList().subscribe(
      (data: any) => {
        this.listTipoReferencia = data.body.map((x: CodeDescription) => ({
          id: x.code,
          text: x.description,
        }));
      },
      (error: string) => {
        myGlobals.showMessage(error, myGlobals.MessageType.Error);
      }
    );
  }

  changeValueListBrandsParameter(e: any) {
    if (e.length > 0) {
      this.listTktClients = [];
      e.forEach((value: string) => {
        this.listClientsBackup
          .filter((x) => x.company == value)
          .forEach((c) => {
            this.listTktClients.push({ id: c.id.toString(), text: c.name });
          });
      });
    } else {
      this.listTktClients = this.listClientsBackup.map((x: ClientInfo) => ({
        id: x.id.toString(),
        text: x.name,
      }));
    }
  }

  getItemsSelectValueParameter(parameter: ViewParameter): Select2OptionData[] {
    return parameter.data.map((x: ViewParameterData) => ({
      id: x.id,
      text: x.text,
    }));
  }

  checkExecuteResult(data: ViewExecuteResult): ViewExecuteResult {
    data.columns.forEach((col) => {
      if (col.type.toLowerCase() == 'datetime') {
        data.rows.forEach((row) => {
          if (row[col.field] != null) {
            row[col.field] = new Date(row[col.field]);
          }
        });
      }
    });

    return data;
  }
}

<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{'page-customs-brexit.title' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.customs' | translate}}
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.customs-modules' | translate}}
      </li>
      <li class="breadcrumb-item active">
        <strong>{{'page-navigation.customs-brexit' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{'page-customs-brexit.title-description' | translate}}</em></p>
  </div>
</div>



<div class="row">
  <div class="col-sm-12">
    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox" id="brexitManagement">
        <div class="ibox-content ">

          <div class="sk-spinner sk-spinner-pulse" style="width: 75px; height: 75px; top: 100px;"></div>

          <div class="row">
            <div class="col-12">

              <div class="float-right m-b-lg">
                <div class="form-inline">


                  <button class="btn btn-sm btn-primary m-r-sm" type="button"
                    (click)="createShipmentOpenWindow()">{{'page-customs-brexit.action-new' | translate}}</button>

                  <div class="input-group">

                    <input style="width: 300px;" type="text" class="form-control input-sm"
                      [(ngModel)]="shipmentsSearchData.value" placeholder="{{'general.search' | translate}}..."
                      (keyup.enter)="getShipmentsList()">

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" (click)="getShipmentsList()" type="button"
                        title="{{'general.search' | translate}}">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" (click)="exportExcel()"
                        title="{{'general.export-excel' | translate}}"> <i class="fa fa-download"
                          aria-hidden="true"></i></button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" data-toggle="collapse"
                        data-parent="#panelSearch" href="#panelAdvancedSearch" aria-expanded="false"
                        title="{{'search.title' | translate}}">
                        <i class="fa fa-wrench" aria-hidden="true"></i>
                      </button>
                    </span>

                  </div>
                </div>
              </div>

              <div id="panelSearch">
                <div id="panelAdvancedSearch" class="panel-collapse collapse">
                  <h2 class="search-panel-title">{{'search.title' | translate}}</h2>
                  <small class="font-bold">{{'search.title-description' | translate}}</small>
                  <div class="hr-line-dashed"></div>

                  <div *ngIf="shipmentsSearchData.showStatusSearch">
                    <label class="checkbox-inline m-r-md">
                      {{'search.showresults' | translate}}:
                    </label>
                    <label class="checkbox-inline m-r-md">
                      <input type="checkbox" [(ngModel)]="shipmentsSearchData.enabled">
                      &nbsp;{{'search.show-active' | translate}}
                    </label>
                    <label class="checkbox-inline">
                      <input type="checkbox" [(ngModel)]="shipmentsSearchData.deleted">
                      &nbsp;{{'search.show-deleted' | translate}}
                    </label>
                  </div>

                  <div class="form-group form-inline">
                    <label class="m-r-md">{{'search.daterange' | translate}}:</label>
                    <label class="m-r-md">{{'search.date-from' | translate}}
                      <input type="date" class="form-control form-control-search m-l-sm mr-sm"
                        [(ngModel)]="shipmentsSearchData_dateFrom">
                    </label>
                    <label>{{'search.date-to' | translate}}
                      <input type="date" class="form-control form-control-search m-l-sm mr-sm"
                        [(ngModel)]="shipmentsSearchData.dateTo">
                    </label>
                  </div>

                  <div class="hr-line-dashed"></div>
                </div>
              </div>

            </div>

            <div class="col-12">
              <p-table [value]="shipmentsList" sortMode="multiple"
                styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [paginator]="true" [rows]="25"
                [showCurrentPageReport]="true" [autoLayout]="true" [(first)]="shipmentsListFirst"
                currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
                [rowsPerPageOptions]="[10,25,50,100,200]" [columnResizeMode]="'expand'" [reorderableColumns]="true"
                [resizableColumns]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 35px;"></th>
                    <th pSortableColumn="customsFileId">
                      {{'page-customs-brexit.columname-customsFileId' | translate}}
                      <p-sortIcon field="customsFileId"></p-sortIcon>
                    </th>
                    <th pSortableColumn="customsFileDate">
                      {{'page-customs-brexit.columname-customsFileDate' | translate}}
                      <p-sortIcon field="customsFileDate"></p-sortIcon>
                    </th>
                    <th pSortableColumn="customsFileType">
                      {{'page-customs-brexit.columname-customsFileType' | translate}}
                      <p-sortIcon field="customsFileType"></p-sortIcon>
                    </th>
                    <th pSortableColumn="client.group">
                      {{'page-customs-brexit.columname-clientGroup' | translate}}
                      <p-sortIcon field="client.group"></p-sortIcon>
                    </th>
                    <th pSortableColumn="client.name">
                      {{'page-customs-brexit.columname-clientDescription' | translate}}
                      <p-sortIcon field="client.name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="customsFileDescription">
                      {{'page-customs-brexit.columname-customsFileDescription' | translate}}
                      <p-sortIcon field="customsFileDescription"></p-sortIcon>
                    </th>
                    <th pSortableColumn="clientWarehouse">
                      {{'page-customs-brexit.columname-clientWarehouse' | translate}}
                      <p-sortIcon field="clientWarehouse"></p-sortIcon>
                    </th>
                    <th pSortableColumn="invoiceInfo">
                      {{'page-customs-brexit.columname-invoiceInfo' | translate}}
                      <p-sortIcon field="invoiceInfo"></p-sortIcon>
                    </th>
                    <th pSortableColumn="truckInfo">
                      {{'page-customs-brexit.columname-truckInfo' | translate}}
                      <p-sortIcon field="truckInfo"></p-sortIcon>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-shipment>

                  <tr [ngClass]="{'row-disable' : shipment.status!=0}">
                    <td>
                      <div class="btn-group btn-group-xs">
                        <button class="btn btn-default btn-xs" title="{{'general.edit' | translate}}"
                          (click)="editShipment(shipment.id)"><i class="fa fa-pencil"></i></button>

                        <button *ngIf="canDelete" class="btn btn-default btn-xs btn-outline"
                          title="{{'general.delete' | translate}}" (click)="deleteShipment(shipment)"><i
                            class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>

                      </div>
                    </td>
                    <td>{{shipment.customsFileId}}</td>
                    <td>{{shipment.customsFileDate | date: ('general.format-date' | translate)}}</td>
                    <td>{{shipment.customsFileType}}</td>
                    <td>{{shipment.client.group}}</td>
                    <td>{{shipment.client.name}}</td>
                    <td>{{shipment.customsFileDescription}}</td>
                    <td>{{shipment.clientWarehouse}}</td>
                    <td>{{shipment.invoiceInfo}}</td>
                    <td>{{shipment.truckInfo}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>



          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal inmodal fade" id="editBrexitShipmentWindow" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-full-95">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">{{'page-customs-brexit.editwindow-title' | translate}}</h4>
        <small class="font-bold">{{'page-customs-brexit.editwindow-title-description' | translate}}</small>

      </div>
      <div class="modal-body">

        <div class="row m-b-lg">
          <div class="col-sm-12">
            <span class="m-r-lg"><strong
                class="m-r-sm">{{'page-customs-brexit.columname-customsFileId' | translate}}:</strong>{{shipment.currentData.customsFileId}}</span>
            <span class="m-r-lg"><strong
                class="m-r-sm">{{'page-customs-brexit.columname-customsFileType' | translate}}:</strong>{{shipment.currentData.customsFileType}}</span>
            <span class="m-r-lg"><strong class="m-r-sm">{{'page-customs-brexit.columname-customsFileDate' | translate}}:
              </strong>{{shipment.currentData.customsFileDate | date: ('general.format-date' | translate)}}</span>
            <span class="m-r-lg"><strong
                class="m-r-sm">{{'page-customs-brexit.columname-customsFileDescription' | translate}}:</strong>{{shipment.currentData.customsFileDescription}}</span>
          </div>

          <div class="col-sm-12">
            <span class="m-r-lg"><strong
                class="m-r-sm">{{'page-customs-brexit.columname-clientGroup' | translate}}:</strong>{{shipment.currentData.client.group}}</span>
            <span class="m-r-lg"><strong
                class="m-r-sm">{{'page-customs-brexit.columname-clientDescription' | translate}}:</strong>{{shipment.currentData.client.name}}</span>
            <span class="m-r-lg"><strong
                class="m-r-sm">{{'page-customs-brexit.columname-clientWarehouse' | translate}}:</strong>{{shipment.currentData.clientWarehouse}}</span>
          </div>

          <div class="col-sm-12">
            <span class="m-r-lg"><strong
                class="m-r-sm">{{'page-customs-brexit.columname-invoiceInfo' | translate}}:</strong>{{shipment.currentData.invoiceInfo}}</span>
            <span class="m-r-lg"><strong
                class="m-r-sm">{{'page-customs-brexit.columname-truckInfo' | translate}}:</strong>{{shipment.currentData.truckInfo}}</span>
          </div>




          <form [formGroup]="shipment.dataForm">
            <div class="row">


            </div>
          </form>



        </div>

        <div class="row">

          <div class="tabs-container col-sm-12">
            <ul class="nav nav-tabs" role="tablist">
              <li><a class="nav-link active" data-toggle="tab"
                  href="#tab-exportacion">{{'page-customs-brexit.tab-export-title' | translate}}</a></li>
              <li><a class="nav-link" data-toggle="tab"
                  href="#tab-importacion">{{'page-customs-brexit.tab-import-title' | translate}}</a></li>
            </ul>
            <div class="tab-content">
              <div role="tabpanel" id="tab-exportacion" class="tab-pane active">
                <div class="panel-body">
                  <div class="row m-xxs">

                    <div class="col-sm-12 p-xs">
                      <div class="float-right">
                        <div class="btn-group btn-group-xs">
                          <button class="btn btn-default btn-xs" title="{{'general.add' | translate}}"
                            (click)="exportAdd()"><i class="fa fa-plus"></i></button>
                        </div>
                      </div>
                    </div>

                    <div *ngFor="let info of shipment.currentData.customsExportInfo" class="col-sm-12 border-top p-xs">
                      <div *ngIf="info.status==0">
                        <div class="float-right">
                          <div class="btn-group btn-group-xs">
                            <button class="btn btn-default btn-xs" title="{{'general.edit' | translate}}"
                              (click)="exportEdit(info)" disabled><i class="fa fa-pencil"></i></button>
                            <button data-toggle="dropdown"
                              class="btn btn-default btn-xs dropdown-toggle dropdown-toggle-split"
                              aria-expanded="false"></button>
                            <ul class="dropdown-menu dropdown-menu-right">
                              <li><a class="dropdown-item disabled" href="javascript:void(0);"
                                  (click)="exportAddAttachment(info)">{{'general.attachments-add' | translate}}</a></li>

                              <li *ngIf="!info.properties.clientSentDuaInfo"><a class="dropdown-item"
                                  href="javascript:void(0);"
                                  (click)="exportActionSendDataToClient(info.properties)">{{'general.senddatato' | translate}}
                                  {{shipment.currentData.client.name}}</a></li>

                              <li
                                *ngIf="info.properties.clientSentDuaInfo && info.properties.clientSentDuaInfoDate == null">
                                <a class="dropdown-item" href="javascript:void(0);"
                                  (click)="exportActionCancelSendingDataToClient(info.properties)">{{'general.cancelsendingdatato' | translate}}
                                  {{shipment.currentData.client.name}}</a></li>

                              <li *ngIf="canDelete && info.status==0" class="dropdown-divider"></li>
                              <li *ngIf="canDelete && info.status==0"><a class="dropdown-item"
                                  href="javascript:void(0);"
                                  (click)="deleteExportData(info)">{{'general.delete' | translate}}</a></li>
                            </ul>
                          </div>
                        </div>

                        <div class="m-b-xs">
                          <span class="m-r-lg"><strong
                              class="m-r-sm">{{'page-customs-brexit.fields-agent' | translate}}:</strong>{{info.agent.name}}</span>

                          <span class="m-r-lg"><strong
                              class="m-r-sm">{{'page-customs-brexit.fields-date' | translate}}:</strong>{{info.date | date: ('general.format-date' | translate)}}</span>

                          <span class="m-r-lg"><strong
                              class="m-r-sm">{{'page-customs-brexit.fields-mrn' | translate}}:</strong>{{info.mrn}}</span>
                        </div>

                        <div class="m-b-xs">
                          <span class="m-r-lg"><strong
                              class="m-r-sm">{{'page-customs-brexit.fields-customstype' | translate}}:</strong>{{info.customsType.description}}</span>
                          <span class="m-r-lg"><strong
                              class="m-r-sm">{{'page-customs-brexit.fields-daterequest' | translate}}:</strong>{{info.dateRequest | date: ('general.format-date' | translate)}}</span>
                          <span class="m-r-lg"><strong
                              class="m-r-sm">{{'page-customs-brexit.fields-invoices' | translate}}:</strong>{{getInvoicesInfo(info.invoices)}}</span>
                        </div>

                        <div class="m-t-sm">
                          <span class="text-muted"><i class="fa fa-paperclip"></i>
                            {{'general.attachments' | translate}}</span>
                          <div class="row m-xxs">
                            <div *ngFor="let file of info.attachments"
                              class="bg-muted b-r-sm m-xxs p-xxs col-sm-auto border-left-right border-top-bottom cursor-pointer"
                              (click)="downloadAttachment(file.id, file.filename)">
                              <span class="small text-success "> {{file.typeDescription}}</span> <br>
                              <span class="small text-muted">{{file.filename}}</span><br>
                              <span class="small text-muted font-italic">{{file.observations}}</span>
                            </div>
                          </div>
                        </div>

                        <div class="m-t-sm">
                          <span
                            *ngIf="info.properties.clientSentDuaInfo && info.properties.clientSentDuaInfoDate == null"
                            class="text-info">
                            Marcado para enviar a {{shipment.currentData.client.name}}.</span>

                          <span *ngIf="info.properties.clientSentDuaInfoDate != null" class="text-info">Enviado a
                            {{shipment.currentData.client.name}} el
                            {{info.properties.clientSentDuaInfoDate | date: ('general.format-date' | translate)}}.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div role="tabpanel" id="tab-importacion" class="tab-pane">
                <div class="panel-body">

                  <div class="row m-xxs">

                    <div class="col-sm-12 p-xs">
                      <div class="float-right">
                        <div class="btn-group btn-group-xs">
                          <button class="btn btn-default btn-xs" title="{{'general.add' | translate}}"
                            (click)="importAdd()"><i class="fa fa-plus"></i></button>
                        </div>
                      </div>
                    </div>

                    <div *ngFor="let info of shipment.currentData.customsImportInfo" class="col-sm-12 border-top p-xs">
                      <div *ngIf="info.status==0">
                        <div class="float-right">
                          <div class="btn-group btn-group-xs">
                            <button class="btn btn-default btn-xs" title="{{'general.edit' | translate}}"
                              (click)="importEdit(info)" disabled><i class="fa fa-pencil"></i></button>
                            <button data-toggle="dropdown"
                              class="btn btn-default btn-xs dropdown-toggle dropdown-toggle-split"
                              aria-expanded="false"></button>
                            <ul class="dropdown-menu dropdown-menu-right">
                              <li><a class="dropdown-item disabled" href="javascript:void(0);"
                                  (click)="importAddAttachment(info)">{{'general.attachments-add' | translate}}</a></li>

                              <li *ngIf="!info.properties.clientSentDuaInfo"><a class="dropdown-item"
                                  href="javascript:void(0);"
                                  (click)="importActionSendDataToClient(info.properties)">{{'general.senddatato' | translate}}
                                  {{shipment.currentData.client.name}}</a></li>

                              <li
                                *ngIf="info.properties.clientSentDuaInfo && info.properties.clientSentDuaInfoDate == null">
                                <a class="dropdown-item" href="javascript:void(0);"
                                  (click)="importActionCancelSendingDataToClient(info.properties)">{{'general.cancelsendingdatato' | translate}}
                                  {{shipment.currentData.client.name}}</a></li>

                              <li *ngIf="canDelete && info.status==0" class="dropdown-divider"></li>
                              <li *ngIf="canDelete && info.status==0"><a class="dropdown-item"
                                  href="javascript:void(0);"
                                  (click)="deleteImportData(info)">{{'general.delete' | translate}}</a></li>

                            </ul>
                          </div>
                        </div>

                        <div class="m-b-xs">
                          <span class="m-r-lg"><strong
                              class="m-r-sm">{{'page-customs-brexit.fields-agent' | translate}}:</strong>{{info.agent.name}}</span>

                          <span class="m-r-lg"><strong
                              class="m-r-sm">{{'page-customs-brexit.fields-date' | translate}}:</strong>{{info.date | date: ('general.format-date' | translate)}}</span>

                          <span class="m-r-lg"><strong
                              class="m-r-sm">{{'page-customs-brexit.fields-mrn' | translate}}:</strong>{{info.mrn}}</span>
                        </div>

                        <div class="m-b-xs">
                          <span class="m-r-lg"><strong
                              class="m-r-sm">{{'page-customs-brexit.fields-customstype' | translate}}:</strong>{{info.customsType.description}}</span>
                          <span class="m-r-lg"><strong
                              class="m-r-sm">{{'page-customs-brexit.fields-daterequest' | translate}}:</strong>{{info.dateRequest | date: ('general.format-date' | translate)}}</span>
                          <span class="m-r-lg"><strong
                              class="m-r-sm">{{'page-customs-brexit.fields-invoices' | translate}}:</strong>{{getInvoicesInfo(info.invoices)}}</span>
                        </div>

                        <div class="m-t-sm">
                          <span class="text-muted"><i class="fa fa-paperclip"></i>
                            {{'general.attachments' | translate}}</span>
                          <div class="row m-xxs">
                            <div *ngFor="let file of info.attachments"
                              class="bg-muted b-r-sm m-xxs p-xxs col-sm-auto border-left-right border-top-bottom cursor-pointer"
                              (click)="downloadAttachment(file.id, file.filename)">
                              <span class="small text-success "> {{file.typeDescription}}</span> <br>
                              <span class="small text-muted">{{file.filename}}</span><br>
                              <span class="small text-muted font-italic">{{file.observations}}</span>
                            </div>
                          </div>
                        </div>

                        <div class="m-t-sm">
                          <span
                            *ngIf="info.properties.clientSentDuaInfo && info.properties.clientSentDuaInfoDate == null"
                            class="text-info">
                            Marcado para enviar a {{shipment.currentData.client.name}}.</span>

                          <span *ngIf="info.properties.clientSentDuaInfoDate != null" class="text-info">Enviado a
                            {{shipment.currentData.client.name}} el
                            {{info.properties.clientSentDuaInfoDate | date: ('general.format-date' | translate)}}.</span>
                        </div>

                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white "
          data-dismiss="modal">{{'general.close' | translate}}</button>
        <!--
        <button type="button" class="btn btn-sm btn-primary"
          (click)="saveShipment()">{{'general.save-changes' | translate}}</button>
-->
      </div>
    </div>
  </div>
</div>


<!-- ventana modal para crear un envio -->

<div class="modal inmodal fade" id="createBrexitShipmentWindow" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-full-95">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">{{'page-customs-brexit.createwindow-title' | translate}}</h4>
        <small class="font-bold"></small>

      </div>
      <div class="modal-body sk-loading">
        <div class="ibox" id="brexitManagementCustomsFilesList">
          <div class="ibox-content sk-loading">

            <div class="sk-spinner sk-spinner-pulse" style="width: 75px; height: 75px; top: 100px;"></div>

            <div class="row">
              <div class="col-sm-12">
                <div class="float-right m-b-lg">
                  <div class="form-inline">
                    <div class="input-group">
                      <input style="width: 300px;" type="text" class="form-control input-sm"
                        [(ngModel)]="customsFilesSearchData.value" placeholder="{{'general.search' | translate}}..."
                        (keyup.enter)="getCustomsFilesList()">

                      <span class="input-group-append">
                        <button class="btn btn-white btn-sm" (click)="getCustomsFilesList()" type="button"
                          title="{{'general.search' | translate}}">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                      </span>


                    </div>
                  </div>
                </div>

                <div class="form-group form-inline">
                  <label class="m-r-md">{{'search.date-from' | translate}}
                    <input type="date" class="form-control form-control-search m-l-sm mr-sm"
                      [(ngModel)]="customsFilesSearchData_dateFrom">
                  </label>
                  <label>{{'search.date-to' | translate}}
                    <input type="date" class="form-control form-control-search m-l-sm mr-sm"
                      [(ngModel)]="customsFilesSearchData.dateTo">
                  </label>
                </div>

              </div>
            </div>

            <p-table [value]="customsFilesList" sortMode="multiple"
              styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [paginator]="true" [rows]="10"
              [showCurrentPageReport]="true" [autoLayout]="true" [(first)]="customsFilesListFirst"
              currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
              [rowsPerPageOptions]="[10,25,50,100,200]" [columnResizeMode]="'expand'" [reorderableColumns]="true"
              [resizableColumns]="true">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width:2rem"> </th>
                  <th pSortableColumn="customsFileId">
                    {{'page-customs-brexit.columname-customsFileId' | translate}}
                    <p-sortIcon field="customsFileId"></p-sortIcon>
                  </th>
                  <th pSortableColumn="customsFileDate">
                    {{'page-customs-brexit.columname-customsFileDate' | translate}}
                    <p-sortIcon field="customsFileDate"></p-sortIcon>
                  </th>
                  <th pSortableColumn="customsFileType">
                    {{'page-customs-brexit.columname-customsFileType' | translate}}
                    <p-sortIcon field="customsFileType"></p-sortIcon>
                  </th>
                  <th pSortableColumn="client.group">
                    {{'page-customs-brexit.columname-clientGroup' | translate}}
                    <p-sortIcon field="client.group"></p-sortIcon>
                  </th>
                  <th pSortableColumn="client.name">
                    {{'page-customs-brexit.columname-clientDescription' | translate}}
                    <p-sortIcon field="client.name"></p-sortIcon>
                  </th>
                  <th pSortableColumn="customsFileDescription">
                    {{'page-customs-brexit.columname-customsFileDescription' | translate}}
                    <p-sortIcon field="customsFileDescription"></p-sortIcon>
                  </th>
                  <th pSortableColumn="invoiceInfo">
                    {{'page-customs-brexit.columname-invoiceInfo' | translate}}
                    <p-sortIcon field="invoiceInfo"></p-sortIcon>
                  </th>
                  <th pSortableColumn="truckInfo">
                    {{'page-customs-brexit.columname-truckInfo' | translate}}
                    <p-sortIcon field="truckInfo"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-shipment>
                <tr>
                  <td>
                    <button class="btn btn-white btn-xs" type="button" title="{{'general.select' | translate}}"
                      (click)="createShipmentCloseWindow(shipment)">
                      <i class="fa fa-crosshairs" aria-hidden="true"></i>
                    </button>
                  </td>
                  <td>{{shipment.customsFileId}}</td>
                  <td>{{shipment.customsFileDate | date: ('general.format-date' | translate)}}</td>
                  <td>{{shipment.customsFileType}}</td>
                  <td>{{shipment.client.group}}</td>
                  <td>{{shipment.client.name}}</td>
                  <td>{{shipment.customsFileDescription}}</td>
                  <td>{{shipment.invoiceInfo}}</td>
                  <td>{{shipment.truckInfo}}</td>
                </tr>
              </ng-template>
            </p-table>

          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<!-- ventana modal para entrada de exportación -->

<div class="modal inmodal fade" id="editBrexitShipmentExportDataWindow" tabindex="-1" role="dialog"
  data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered modal-full-90">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">{{'page-customs-brexit.operationwindow-title' | translate}}</h4>
        <small class="font-bold"></small>

      </div>
      <div class="modal-body" id="editBrexitShipmentExportDataPanelEdicion">

        <div class="row">
          <div class="col-sm-12">

            <form [formGroup]="export.dataForm">

              <div class="row">

                <div class="form-group row col-sm">
                  <label class="col-sm-auto col-form-label font-bold text-right">
                    {{'page-customs-brexit.fields-operationtype' | translate}}</label>
                  <div class="col-sm">
                    <ng-select2 [data]="export.customTypeList" width="100%" formControlName="export_customsType_id"
                      [dropdownParent]="'editBrexitShipmentExportDataPanelEdicion'"
                      [ngClass]="{ 'is-invalid': export.dataSubmitted && e.export_customsType_id.errors }"
                      (valueChanged)="loadExportAttachmentTypeList($event)">
                    </ng-select2>

                    <div *ngIf="export.dataSubmitted && e.export_customsType_id.errors" class="invalid-feedback">
                      <div *ngIf="e.export_customsType_id.errors.required"> {{'validations.isrequired' | translate}}
                      </div>
                    </div>

                  </div>
                </div>

                <div class="form-group row col-sm">
                  <label class="col-sm-auto col-form-label font-bold text-right">
                    {{'page-customs-brexit.fields-agent' | translate}}</label>
                  <div class="col-sm">
                    <ng-select2 [data]="export.agentList" width="100%" formControlName="export_agent_id"
                      [dropdownParent]="'editBrexitShipmentExportDataPanelEdicion'"
                      [ngClass]="{ 'is-invalid': export.dataSubmitted && e.export_agent_id.errors }">
                    </ng-select2>

                    <div *ngIf="export.dataSubmitted && e.export_agent_id.errors" class="invalid-feedback">
                      <div *ngIf="e.export_agent_id.errors.required"> {{'validations.isrequired' | translate}}
                      </div>
                    </div>

                  </div>
                </div>

                <div class="form-group row col-sm">
                  <label class="col-sm-auto col-form-label font-bold text-right">
                    {{'page-customs-brexit.fields-mrn' | translate}} </label>
                  <div class="col-sm">
                    <input type="text" class="form-control input-sm" formControlName="export_mrn"
                      [ngClass]="{ 'is-invalid': export.dataSubmitted && e.export_mrn.errors }">
                    <div *ngIf="export.dataSubmitted && e.export_mrn.errors" class="invalid-feedback">
                      <div *ngIf="e.export_mrn.errors.required"> {{'validations.isrequired' | translate}} </div>
                      <div *ngIf="e.export_mrn.errors.maxlength"> {{'validations.maxlength' | translate}} </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="row">

                <div class="form-group row col-sm">
                  <label class="col-sm-auto col-form-label font-bold text-right">
                    {{'page-customs-brexit.fields-date' | translate}} </label>
                  <div class="col-sm">
                    <input type="date" class="form-control input-sm" formControlName="export_date"
                      [ngClass]="{ 'is-invalid': export.dataSubmitted && e.export_date.errors }">
                    <div *ngIf="export.dataSubmitted && e.export_date.errors" class="invalid-feedback">
                      <div *ngIf="e.export_date.errors.required"> {{'validations.isrequired' | translate}} </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row col-sm">
                  <label class="col-sm-auto col-form-label text-right">
                    {{'page-customs-brexit.fields-daterequest' | translate}} </label>
                  <div class="col-sm">
                    <input type="date" class="form-control input-sm" formControlName="export_dateRequest">
                  </div>
                </div>

                <div class="form-group row col-sm"></div>

              </div>


              <div class="hr-line-dashed"></div>
              <div class="row">

                <div class="form-group row col-sm-12">
                  <label class="col-sm-auto col-form-label text-right">
                    {{'page-customs-brexit.fields-invoices' | translate}} </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control input-sm" formControlName="export_invoices"
                      [ngClass]="{ 'is-invalid': export.dataSubmitted && e.export_invoices.errors }">
                    <div *ngIf="export.dataSubmitted && e.export_invoices.errors" class="invalid-feedback">
                      <div *ngIf="e.export_invoices.errors.maxlength"> {{'validations.maxlength' | translate}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row col-sm-12">
                  <label class="col-sm-auto col-form-label text-right">
                    {{'page-customs-brexit.fields-vehicledata' | translate}} :</label>

                  <em class="col-sm-auto col-form-label">
                    {{'page-customs-brexit.fields-truckregistration' | translate}}</em>
                  <div class="col-sm">
                    <input type="text" class="form-control input-sm" formControlName="export_truckRegistration"
                      [ngClass]="{ 'is-invalid': export.dataSubmitted && e.export_truckRegistration.errors }">
                    <div *ngIf="export.dataSubmitted && e.export_truckRegistration.errors" class="invalid-feedback">
                      <div *ngIf="e.export_truckRegistration.errors.maxlength">
                        {{'validations.maxlength' | translate}}
                      </div>
                    </div>
                  </div>

                  <em class="col-sm-auto col-form-label">{{'page-customs-brexit.fields-truckcountry' | translate}}</em>
                  <div class="col-sm">
                    <ng-select2 [data]="countriesList" width="100%" formControlName="export_truckCountry"
                      [dropdownParent]="'editBrexitShipmentExportDataPanelEdicion'">
                    </ng-select2>
                  </div>

                </div>
              </div>

            </form>
            <div class="hr-line-dashed"></div>

            <div class="m-t-sm">

              <div [ngClass]="{ 'is-invalid': export.dataSubmitted && export.currentData.attachments.length == 0 }">
                <span class="text-muted"><i class="fa fa-paperclip"></i>
                  {{'general.attachments' | translate}}</span>
                <span *ngIf="loadingAttachment" class="m-l-md">Loading...</span>
              </div>
              <div *ngIf="export.dataSubmitted && export.currentData.attachments.length == 0" class="invalid-feedback">
                <div> {{'validations.isrequired' | translate}} </div>
              </div>

              <div class="row m-xxs">
                <div *ngFor="let file of export.currentData.attachments"
                  class="bg-muted b-r-sm m-xxs p-xxs col-sm-auto border-left-right border-top-bottom">
                  <button type="button" class="close btn-sm m-l-sm m-r-xxs" data-dismiss="modal"
                    (click)="exportCurrentAttachmentRemove(file)"><span aria-hidden="true"
                      title="{{'general.close' | translate}}">x</span></button>
                  <span class="small text-success "> {{file.typeDescription}}</span> <br>
                  <span class="small text-muted">{{file.filename}}</span><br>
                  <span class="small text-muted font-italic">{{file.observations}}</span>
                </div>
              </div>

            </div>


            <div class="hr-line-dashed"></div>
            <div class="row">
              <div class="form-group row col-sm-12">
                <label class="col-sm-auto col-form-label font-bold text-right">
                  {{'general.attachment' | translate}}</label>
                <div class="col-sm-4">
                  <ng-select2 [data]="export.attachmentTypeList" width="100%"
                    [(ngModel)]="export.attachmentCurrentData.type"
                    [dropdownParent]="'editBrexitShipmentExportDataPanelEdicion'">
                  </ng-select2>
                </div>
                <div class="col-sm">
                  <div [ngClass]="{ 'is-invalid': export.attachmentCurrentData.fileSize > maxAttachmentSize }">
                    <div class="custom-file">
                      <input #exportfile type="file" [(ngModel)]="export.attachmentCurrentData.file"
                        class="form-control form-control-sm custom-file-input custom-export-file-input"
                        (change)="exportSetFile(exportfile.files)">
                      <label for="exportfile" class="custom-file-label">{{'general.choosefile' | translate}}...</label>
                    </div>
                  </div>
                  <div *ngIf="export.attachmentCurrentData.fileSize > maxAttachmentSize" class="invalid-feedback">
                    <div> {{'validations.filesize-too-large' | translate}} {{getMaxAttachmentSize}}</div>
                  </div>
                </div>
                <div class="col-sm-auto">
                  <button type="button" class=" btn btn-sm btn-white"
                    (click)="exportCurrentAttachmentAdd()">{{'general.add' | translate}}</button>
                </div>
              </div>

              <div class="form-group row col-sm-12">
                <label class="col-sm-auto col-form-label text-right">
                  {{'general.observations' | translate}} </label>
                <div class="col-sm">
                  <input type="text" class="form-control input-sm"
                    [(ngModel)]="export.attachmentCurrentData.observations">
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>
        <button type="button" class="btn btn-sm btn-primary"
          (click)="exportSave()">{{'general.save-changes' | translate}}</button>
      </div>
    </div>
  </div>
</div>


<!-- ventana modal para entrada de importación -->

<div class="modal inmodal fade" id="editBrexitShipmentImportDataWindow" tabindex="-1" role="dialog"
  data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered modal-full-90">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">{{'page-customs-brexit.operationwindow-title' | translate}}</h4>
        <small class="font-bold"></small>

      </div>
      <div class="modal-body" id="editBrexitShipmentImportDataPanelEdicion">

        <div class="row">
          <div class="col-sm-12">

            <form [formGroup]="import.dataForm">

              <div class="row">

                <div class="form-group row col-sm">
                  <label class="col-sm-auto col-form-label font-bold text-right">
                    {{'page-customs-brexit.fields-operationtype' | translate}}</label>
                  <div class="col-sm">
                    <ng-select2 [data]="import.customTypeList" width="100%" formControlName="import_customsType_id"
                      [dropdownParent]="'editBrexitShipmentImportDataPanelEdicion'"
                      [ngClass]="{ 'is-invalid': import.dataSubmitted && i.import_customsType_id.errors }"
                      (valueChanged)="loadImportAttachmentTypeList($event)">
                    </ng-select2>
                    <div *ngIf="import.dataSubmitted && i.import_customsType_id.errors" class="invalid-feedback">
                      <div *ngIf="i.import_customsType_id.errors.required"> {{'validations.isrequired' | translate}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row col-sm">
                  <label class="col-sm-auto col-form-label font-bold text-right">
                    {{'page-customs-brexit.fields-agent' | translate}}</label>
                  <div class="col-sm">
                    <ng-select2 [data]="import.agentList" width="100%" formControlName="import_agent_id"
                      [dropdownParent]="'editBrexitShipmentImportDataPanelEdicion'"
                      [ngClass]="{ 'is-invalid': import.dataSubmitted && i.import_agent_id.errors }">
                    </ng-select2>
                    <div *ngIf="import.dataSubmitted && i.import_agent_id.errors" class="invalid-feedback">
                      <div *ngIf="i.import_agent_id.errors.required"> {{'validations.isrequired' | translate}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row col-sm">
                  <label class="col-sm-auto col-form-label font-bold text-right">
                    {{'page-customs-brexit.fields-mrn' | translate}} </label>
                  <div class="col-sm">
                    <input type="text" class="form-control input-sm" formControlName="import_mrn"
                      [ngClass]="{ 'is-invalid': import.dataSubmitted && i.import_mrn.errors }">
                    <div *ngIf="import.dataSubmitted && i.import_mrn.errors" class="invalid-feedback">
                      <div *ngIf="i.import_mrn.errors.required"> {{'validations.isrequired' | translate}} </div>
                      <div *ngIf="i.import_mrn.errors.maxlength"> {{'validations.maxlength' | translate}} </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group row col-sm">
                  <label class="col-sm-auto col-form-label font-bold text-right">
                    {{'page-customs-brexit.fields-date' | translate}} </label>
                  <div class="col-sm">
                    <input type="date" class="form-control input-sm" formControlName="import_date"
                      [ngClass]="{ 'is-invalid': import.dataSubmitted && i.import_date.errors }">
                    <div *ngIf="import.dataSubmitted && i.import_date.errors" class="invalid-feedback">
                      <div *ngIf="i.import_date.errors.required"> {{'validations.isrequired' | translate}} </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row col-sm">
                  <label class="col-sm-auto col-form-label font-bold text-right">
                    {{'page-customs-brexit.fields-daterequest' | translate}} </label>
                  <div class="col-sm">
                    <input type="date" class="form-control input-sm" formControlName="import_dateRequest">
                  </div>
                </div>

                <div class="form-group row col-sm"></div>
              </div>

              <div class="hr-line-dashed"></div>
              <div class="row">

                <div class="form-group row col-sm-12">
                  <label class="col-sm-auto col-form-label text-right">
                    {{'page-customs-brexit.fields-invoices' | translate}} </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control input-sm" formControlName="import_invoices"
                      [ngClass]="{ 'is-invalid': import.dataSubmitted && i.import_invoices.errors }">
                    <div *ngIf="import.dataSubmitted && i.import_invoices.errors" class="invalid-feedback">
                      <div *ngIf="i.import_invoices.errors.maxlength"> {{'validations.maxlength' | translate}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row col-sm-12">
                  <label class="col-sm-auto col-form-label text-right">
                    {{'page-customs-brexit.fields-vehicledata' | translate}} </label>

                  <em class="col-sm-auto col-form-label">
                    {{'page-customs-brexit.fields-truckregistration' | translate}}</em>
                  <div class="col-sm">
                    <input type="text" class="form-control input-sm" formControlName="import_truckRegistration"
                      [ngClass]="{ 'is-invalid': import.dataSubmitted && i.import_truckRegistration.errors }">
                    <div *ngIf="import.dataSubmitted && i.import_truckRegistration.errors" class="invalid-feedback">
                      <div *ngIf="i.import_truckRegistration.errors.maxlength">
                        {{'validations.maxlength' | translate}}
                      </div>
                    </div>
                  </div>

                  <em class="col-sm-auto col-form-label">{{'page-customs-brexit.fields-truckcountry' | translate}}</em>
                  <div class="col-sm">
                    <ng-select2 [data]="countriesList" width="100%" formControlName="import_truckCountry"
                      [dropdownParent]="'editBrexitShipmentImportDataPanelEdicion'">
                    </ng-select2>
                  </div>

                </div>
              </div>

            </form>
            <div class="hr-line-dashed"></div>

            <div class="m-t-sm">

              <div [ngClass]="{ 'is-invalid': import.dataSubmitted && import.currentData.attachments.length == 0 }">
                <span class="text-muted"><i class="fa fa-paperclip"></i>
                  {{'general.attachments' | translate}}</span>
                <span *ngIf="loadingAttachment" class="m-l-md">Loading...</span>
              </div>
              <div *ngIf="import.dataSubmitted && import.currentData.attachments.length == 0" class="invalid-feedback">
                <div> {{'validations.isrequired' | translate}} </div>
              </div>

              <div class="row m-xxs">
                <div *ngFor="let file of import.currentData.attachments"
                  class="bg-muted b-r-sm m-xxs p-xxs col-sm-auto border-left-right border-top-bottom">
                  <button type="button" class="close btn-sm m-l-sm m-r-xxs" data-dismiss="modal"
                    (click)="importCurrentAttachmentRemove(file)"><span aria-hidden="true"
                      title="{{'general.close' | translate}}">x</span></button>
                  <span class="small text-success "> {{file.typeDescription}}</span> <br>
                  <span class="small text-muted">{{file.filename}}</span><br>
                  <span class="small text-muted font-italic">{{file.observations}}</span>
                </div>
              </div>

            </div>

            <div class="hr-line-dashed"></div>
            <div class="row">

              <div class="form-group row col-sm-12">
                <label class="col-sm-auto col-form-label font-bold text-right">
                  {{'general.attachment' | translate}}</label>
                <div class="col-sm-4">
                  <ng-select2 [data]="import.attachmentTypeList" width="100%"
                    [(ngModel)]="import.attachmentCurrentData.type"
                    [dropdownParent]="'editBrexitShipmentImportDataPanelEdicion'">
                  </ng-select2>
                </div>
                <div class="col-sm">
                  <div [ngClass]="{ 'is-invalid': export.attachmentCurrentData.fileSize > maxAttachmentSize }">
                    <div class="custom-file">
                      <input #importfile type="file" [(ngModel)]="import.attachmentCurrentData.file"
                        class="form-control form-control-sm custom-file-input custom-import-file-input"
                        (change)="importSetFile(importfile.files)">
                      <label for="importfile" class="custom-file-label">{{'general.choosefile' | translate}}...</label>
                    </div>
                  </div>
                  <div *ngIf="import.attachmentCurrentData.fileSize > maxAttachmentSize" class="invalid-feedback">
                    <div> {{'validations.filesize-too-large' | translate}} {{getMaxAttachmentSize}}</div>
                  </div>
                </div>
                <div class="col-sm-auto">
                  <button type="button" class=" btn btn-sm btn-white"
                    (click)="importCurrentAttachmentAdd()">{{'general.add' | translate}}</button>
                </div>
              </div>

              <div class="form-group row col-sm-12">
                <label class="col-sm-auto col-form-label text-right">
                  {{'general.observations' | translate}} </label>
                <div class="col-sm">
                  <input type="text" class="form-control input-sm"
                    [(ngModel)]="import.attachmentCurrentData.observations">
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>
        <button type="button" class="btn btn-sm btn-primary"
          (click)="importSave()">{{'general.save-changes' | translate}}</button>
      </div>
    </div>
  </div>
</div>

export class HubInfo {
    id: number = 0;
    alias: string = "";
    nombre: string = "";
    direccion: string = "";
    cp: string = "";
    ciudad: string = "";
    pais: string = "";
    coordenadas: string = "";
    prealert: boolean = false;
    email: string = "";
    disable: boolean = false;
    idClient: number = 0;
    idCourier: number = 0;
    cliente: string = "";
}

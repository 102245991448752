<div class="row p-lg">

  <div *ngIf="showReporting" class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/repo/queries')">
          <h2>{{'page-reporting-queries-list.title' | translate}}</h2>
          <p class="m-t-sm">{{'page-reporting-queries-list.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="showOperations" class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/oper/serv/terrestre')">
          <h2>{{'page-services-terrestrial-list.title' | translate}}</h2>
          <p class="m-t-sm">{{'page-services-terrestrial-list.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="showBrexit" class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/customs/brexit')">
          <h2>{{'page-customs-brexit.title' | translate}}</h2>
          <p class="m-t-sm">{{'page-customs-brexit.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showBrexitLlorente" class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/brexit/llorente')">
          <h2>{{'page-brexit-llorente.title' | translate}}</h2>
          <p class="m-t-sm">{{'page-brexit-llorente.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showBrexitBrunel" class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/brexit/brunel')">
          <h2>{{'page-brexit-brunel.title' | translate}}</h2>
          <p class="m-t-sm">{{'page-brexit-brunel.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="showOperations" class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/oper/admin/routes')">
          <h2>{{'page-administracion-routes.title' | translate}}</h2>
          <p class="m-t-sm">{{'page-administracion-routes.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showOperations" class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/oper/admin/hubs')">
          <h2>{{'page-administracion-hubs.title' | translate}}</h2>
          <p class="m-t-sm">{{'page-administracion-hubs.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showOperations" class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/oper/admin/clients')">
          <h2>{{'page-administracion-clients.title' | translate}}</h2>
          <p class="m-t-sm">{{'page-administracion-clients.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showAduanas" class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/customs/admin/hscodes')">
          <h2>{{'page-customs-hscodes.title' | translate}}</h2>
          <p class="m-t-sm">{{'page-customs-hscodes.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showAduanas" class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/customs/documents')">
          <h2>{{'page-customs-documents.title' | translate}}</h2>
          <p class="m-t-sm">{{'page-customs-documents.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showSettings" class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/users/management')">
          <h2>{{'page-users-management.title' | translate}}</h2>
          <p class="m-t-sm">{{'page-users-management.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showClientsAmazonInvoices" class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/clients/amazoninvoices')">
          <h2>{{'page-amazon-invoices.title' | translate}}</h2>
          <p class="m-t-sm">{{'page-amazon-invoices.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showTasks" class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/tasks')">
          <h2>{{'page-home-tasks.title' | translate}}</h2>
          <p class="m-t-sm">{{'page-home-tasks.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showWareHouse" class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/warehouse/containers')">
          <h2>{{'warehouse-containers.title' | translate}}</h2>
          <p class="m-t-sm">{{'warehouse-containers.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showWareHousePda" class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/warehouse/boxescontainer')">
          <h2>{{'warehouse-boxescontainer.title' | translate}}</h2>
          <p class="m-t-sm">{{'warehouse-boxescontainer.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

</div>

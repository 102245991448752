import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as myGlobals from '../globals';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let user = myGlobals.userGetData();

        if (!user.authenticated) {
            // not logged in so redirect to login page with the return url
            myGlobals.userLogOut();
            return false;
        }
        else {
            // check if route is restricted by role
            var existRole: Boolean = false;

            if (route.data.roles) {
                for (let rol of user.roles) {
                    if (route.data.roles.indexOf(rol) > -1) {
                        existRole = true;
                    }
                }

                if (!existRole) {
                    // role not authorised so redirect to home page
                    this.router.navigate(['forbidden']);
                    return false;
                }
            }

            // authorised so return true
            myGlobals.UpdateSessionExpiration();
            return true;
        }
    }

}

import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  WareGroupingContainerInfo,
  WareGroupingContainerTempleteInfo,
  WareGroupingBoxContainerInfo,
} from '../../../models/warehouse.model';
import { SearchData } from '../../../models/searches.model';
import { WarehouseService } from '../../../services/warehouse.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Translate } from '../../../helpers/translate';
import { CodeName, CodeDescription } from '../../../models/general.model';
import { HttpErrorResponse } from '@angular/common/http';
import * as myGlobals from '../../../globals';
import { saveAs } from 'file-saver';

declare var $: any;

@Component({
  selector: 'app-warehouse-containers',
  templateUrl: './containers.component.html',
  styleUrls: ['./containers.component.css'],
})
export class WareHouseContainersComponent implements OnInit {
  loading: any;
  modalSelectTemplate: any;
  translate: Translate = new Translate(null, null);
  searchData: SearchData = new SearchData();
  searchDataTemplates: SearchData = new SearchData();
  templatesList: WareGroupingContainerTempleteInfo[] = [];
  containersList: WareGroupingContainerInfo[] = [];
  templatesListFirst = 0;
  containersListFirst = 0;

  constructor(
    private warehouseService: WarehouseService,
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.translate = new Translate(this.translateService, this.datePipe);
    this.loading = $('#loading');
    this.modalSelectTemplate = $('#modalSelectTemplete');
    this.getConteinersInfo();
  }

  getContainerTemplatesInfo() {
    this.warehouseService
      .getContainerTemplates(this.searchDataTemplates)
      .subscribe({
        next: (data: any) => {
          this.templatesList = data;
          this.templatesListFirst = 0;
        },
        complete: () => {},
        error: (err: any) => {
          myGlobals.showMessage(err, myGlobals.MessageType.Error);
        },
      });
  }

  getConteinersInfo() {
    myGlobals.spinnerOn(this.loading);
    this.warehouseService.getActiveContainersInfo(this.searchData).subscribe({
      next: (data: any) => {
        this.containersList = data;
        this.containersListFirst = 0;
      },
      complete: () => {
        myGlobals.spinnerOff(this.loading);
      },
      error: (err: any) => {
        myGlobals.showMessage(err, myGlobals.MessageType.Error);
      },
    });
  }

  showTemplateList() {
    this.searchDataTemplates.value = '';
    this.getContainerTemplatesInfo();
    myGlobals.modalOpen(this.modalSelectTemplate);
  }

  createContainer(templateId: number) {
    this.warehouseService.createContainerByTemplate(templateId).subscribe({
      next: () => {
        this.getConteinersInfo();
        myGlobals.modalClose(this.modalSelectTemplate);
      },
      complete: () => {},
      error: (err: any) => {
        myGlobals.showMessage(err, myGlobals.MessageType.Error);
      },
    });
  }

  openContainer(id: number) {
    this.warehouseService.openContainer(id).subscribe({
      next: () => {
        this.getConteinersInfo();
      },
      complete: () => {},
      error: (err: any) => {
        myGlobals.showMessage(err, myGlobals.MessageType.Error);
      },
    });
  }

  closeContainer(id: number) {
    this.warehouseService.closeContainer(id).subscribe({
      next: () => {
        this.getConteinersInfo();
      },
      complete: () => {},
      error: (err: any) => {
        myGlobals.showMessage(err, myGlobals.MessageType.Error);
      },
    });
  }

  getContainerLabel(id: number) {
    let filename = id + '.pdf';
    this.warehouseService.getContainerLabel(id).subscribe({
      next: (data: any) => {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        saveAs(blob, filename);
      },
      complete: () => {},
      error: (err: HttpErrorResponse) => {
        myGlobals.showMessage(
          'Encountered a problem getting the label',
          myGlobals.MessageType.Error
        );
      },
    });
  }

  getDate(value: Date | null): string | null | undefined {
    return this.translate.getDate(value);
  }

  getClients(data: CodeName[]): string {
    let values = data.map((x) => x.name);
    return values.join(', ');
  }

  getCouriers(data: CodeName[]): string {
    let values = data.map((x) => x.name);
    return values.join(', ');
  }

  getContries(data: CodeDescription[]): string {
    let values = data.map((x) => x.description + ' (' + x.code + ')');
    return values.join(', ');
  }

  stringToHtml(data: string): string {
    return myGlobals.stringToHtml(data);
  }

  downloadBoxList(id: number) {
    this.warehouseService.getContainerBoxList(id).subscribe({
      next: (data: WareGroupingBoxContainerInfo[]) => {
        myGlobals.exportExcel(
          data.map((x) => ({
            Client: x.client,
            Country: x.country,
            Courier: x.courier,
            Box: x.box,
          }))
        );
      },
      complete: () => {},
      error: (err: any) => {
        myGlobals.showMessage(err, myGlobals.MessageType.Error);
      },
    });
  }

  exportExcel() {
    myGlobals.exportExcel(
      this.containersList.map((x) => ({
        Reference: x.id.toString(),
        Date: this.getDate(x.date),
        Title: x.title,
        Clients: this.getClients(x.clients),
        Couriers: this.getCouriers(x.couriers),
        Countries: this.getContries(x.countries),
        Boxes: x.boxes,
      }))
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientInfo, ClientContaData } from '../../../models/clients.model';
import { Pais } from '../../../models/countries.model';
import { TipoServicio } from '../../../models/routes.model';
import { RoutesService } from '../../../services/routes.service';
import { ClientsService } from '../../../services/clients.service';
import { CountriesService } from '../../../services/countries.service';
import { SearchData } from '../../../models/searches.model';
import * as myGlobals from '../../../globals';

declare var $: any;

@Component({
  selector: 'app-administracion-clientes-relations',
  templateUrl: './administracion-clientes-relations.component.html',
  styleUrls: ['./administracion-clientes-relations.component.css']
})
export class AdministracionClientesRelationsComponent implements OnInit {

  clientContaDataForm: FormGroup;
  clientContaDataFormSubmitted: boolean = false;
  firstClientConta: number = 0;

  searchData: SearchData = new SearchData();
  clientContaList: ClientContaData[] = [];

  countriesList: Pais[] = [];
  tktClientList: ClientInfo[] = [];
  tktProviderList: ClientInfo[] = [];
  tktIdList: ClientInfo[] = [];
  contaClientList: ClientInfo[] = [];
  contaProviderList: ClientInfo[] = [];
  contaIdList: ClientInfo[] = [];
  serviceTypeList: TipoServicio[] = [];

  oldSetContaIdTypesValue: number = 0;

  currentClientConta: ClientContaData = new ClientContaData();

  loadingPage: boolean = false;
  select2ContaId: any = null;
  select2TktId: any = null;
  select2CountryId: any = null;
  select2ServiceTypeId: any = null;

  constructor(private routesService: RoutesService, private clientsService: ClientsService, private countriesService: CountriesService, private formBuilder: FormBuilder) { }

  // convenience getter for easy access to form fields
  get f() { return this.clientContaDataForm.controls; }

  ngOnInit(): void {
    this.searchData.showDateSearch = false;
    this.searchData.showAdvancedOptions = false;

    this.clientContaDataForm = this.formBuilder.group({
      form_tktId: [0, Validators.required],
      form_countryId: [''],
      form_contaId: [0, Validators.required],
      form_serviceTypeId: [0, Validators.required]
    });

    this.select2TktId = $('#form_tktId').select2({
      dropdownParent: $('#editClientContaPanelEdicion')
    });

    this.select2ContaId = $('#form_contaId').select2({
      dropdownParent: $('#editClientContaPanelEdicion')
    });

    this.select2CountryId = $('#form_countryId').select2({
      dropdownParent: $('#editClientContaPanelEdicion')
    });

    this.select2ServiceTypeId = $('#form_serviceTypeId').select2({
      dropdownParent: $('#editClientContaPanelEdicion')
    });

    this.loadingPage = true;
    this.getCountriesList();
    this.getTktClientsList();
    this.getTktProviderList();
    this.getContaClientsList();
    this.getContaProvidersList();
    this.getServiceTypeList();
    this.getClientContaList();
  }

  getCountriesList() {
    this.countriesService.GetPaises()
      .subscribe((data: any) => {
        this.countriesList = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getTktClientsList() {
    this.clientsService.ClientsManagementGetTktClientList()
      .subscribe((data: any) => {
        this.tktClientList = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getTktProviderList() {
    this.clientsService.ClientsManagementGetTktProviderList()
      .subscribe((data: any) => {
        this.tktProviderList = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getContaClientsList() {
    this.clientsService.ClientsManagementGetContaClientList()
      .subscribe((data: any) => {
        this.contaClientList = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getContaProvidersList() {
    this.clientsService.ClientsManagementGetContaProviderList()
      .subscribe((data: any) => {
        this.contaProviderList = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getServiceTypeList() {
    this.routesService.RouteManagementGetServiceTypeList()
      .subscribe((data: any) => {
        this.serviceTypeList = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  setContaIdTypes(value: number) {
    if (this.oldSetContaIdTypesValue != value) {
      this.loadContaIdTypes(value);
      this.loadContaIdList(value);
    }
  }


  loadContaIdTypes(value: number) {
    $("#ContaIdType1").prop("checked", false);
    $("#ContaIdType2").prop("checked", false);

    if (value == 1) $("#ContaIdType1").prop("checked", true);
    if (value == 2) $("#ContaIdType2").prop("checked", true);

    this.oldSetContaIdTypesValue = value;
  }

  loadContaIdList(value: number) {
    this.contaIdList = []
    this.tktIdList = []

    if (value == 1) {
      this.contaIdList = this.contaClientList;
      this.tktIdList = this.tktClientList;
      this.currentClientConta.contaProvider = false;
    }

    if (value == 2) {
      this.contaIdList = this.contaProviderList;
      this.tktIdList = this.tktProviderList;
      this.currentClientConta.contaProvider = true;
    }

    this.select2TktId.val(null).trigger('change');
    this.select2ContaId.val(null).trigger('change');
  }


  loadClientContaDataForm(): void {
    this.clientContaDataForm.setValue({
      form_tktId: this.currentClientConta.tktId,
      form_countryId: this.currentClientConta.countryId,
      form_contaId: this.currentClientConta.contaId,
      form_serviceTypeId: this.currentClientConta.serviceTypeId,

    });

    this.select2ContaId.val(this.currentClientConta.contaId).trigger("change");
    this.select2TktId.val(this.currentClientConta.tktId).trigger("change");
    this.select2CountryId.val(this.currentClientConta.countryId).trigger("change");
    this.select2ServiceTypeId.val(this.currentClientConta.serviceTypeId).trigger("change");
  }

  saveClientContaDataForm(): void {
    this.f.form_contaId.setValue(this.select2ContaId.val());
    this.f.form_tktId.setValue(this.select2TktId.val());
    this.f.form_countryId.setValue(this.select2CountryId.val());
    this.f.form_serviceTypeId.setValue(this.select2ServiceTypeId.val());

    this.currentClientConta.tktId = this.f.form_tktId.value;
    this.currentClientConta.tktName = this.select2TktId.find(':selected').text();
    this.currentClientConta.contaId = this.f.form_contaId.value;
    this.currentClientConta.contaName = this.select2ContaId.find(':selected').text();
    this.currentClientConta.countryId = this.f.form_countryId.value;
    this.currentClientConta.serviceTypeId = this.f.form_serviceTypeId.value;
  }


  getClientContaList() {
    this.clientContaManagementSpinner();
    this.routesService.ClientsManagementContaGetDataList(this.searchData)
      .subscribe((data: any) => {
        this.clientContaList = data.body;
        this.firstClientConta = 0;
        this.clientContaManagementSpinner();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

    if (this.loadingPage) {
      this.loadingPage = false;
      this.clientContaManagementSpinner();
    }
  }

  newClientConta() {
    this.currentClientConta = new ClientContaData();
    this.loadClientContaDataForm();
    this.setContaIdTypes(1);
    this.clientContaDataFormSubmitted = false;
    this.editClientContaWindowToggle();
  }

  editClientConta(data: ClientContaData) {
    this.routesService.ClientsManagementContaGetData(data)
      .subscribe((data: any) => {
        this.currentClientConta = data;
        if (this.currentClientConta.contaProvider) {
          this.loadContaIdTypes(2);
          this.loadContaIdList(2);
        } else {
          this.loadContaIdTypes(1);
          this.loadContaIdList(1);
        }
        this.loadClientContaDataForm();
        this.clientContaDataFormSubmitted = false;
        this.editClientContaWindowToggle();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  saveClientContaData() {
    this.saveClientContaDataForm();
    this.clientContaDataFormSubmitted = true;

    // stop here if form is invalid
    if (this.clientContaDataForm.invalid) {
      return;
    }

    this.routesService.ClientsManagementContaSaveConfig(this.currentClientConta)
      .subscribe(() => {
        this.getClientContaList();
        this.editClientContaWindowToggle();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  deleteClientConta(data: ClientContaData) {
    var r = confirm("Are you sure you want to delete the item?");
    if (r == true) {

      this.routesService.ClientsManagementContaDeleteData(data)
        .subscribe(() => {
          this.getClientContaList();
        }
          , (error: string) => {
            myGlobals.showMessage(error, myGlobals.MessageType.Error);
          });

    }
  }



  editClientContaWindowToggle() {
    $('#editClientContaWindow').modal('toggle');
  }

  clientContaManagementSpinner() {
    $('#clientContaManagement').children('.ibox-content').toggleClass('sk-loading');
  }

  exportExcel() {
    myGlobals.exportExcel(this.clientContaList.map(x => ({
      "Type": x.contaProvider ? "Provider" : "Client",
      "TKT Code": x.tktId,
      "TKT Description": x.tktName,
      "Country Code": x.countryId,
      "Country Description": x.countryName,
      "CONTA Code": x.contaId,
      "CONTA Description": x.countryName,
      "Service Type Code": x.serviceTypeId,
      "Service Type Description": x.serviceTypeName
    })));
  }

}

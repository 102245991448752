export class ClientInfo {
    id: number = 0;
    name: string = "";
    company: string = "";
}



export class ClientConfig extends Object {
    clientId: number = 0;
    clientName: string = "";
    boxForPallet: number = 0;
}

export class ClientContaData extends Object {
    tktId: number = 0;
    tktName: string = '';
    countryId: string = '';
    countryName: string = '';
    contaProvider: boolean = false;
    contaId: number = 0;
    contaName: string = '';
    serviceTypeId: number = 0;
    serviceTypeName: string = '';
}

export class ClientHubsInfo extends Object {
    clientId: number = 0;
    clientName: string = "";
    hubSourceId: number = 0;
    hubSourceCountry: string = "";
    hubSourceName: string = "";
    hubTargetId: number = 0;
    hubTargetCountry: string = "";
    hubTargetName: string = "";
    wareTypeId: number = 0;
    wareTypeName: string = "";
    disable: boolean = false;
}
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
      constructor(private loginService: LoginService) { }

      intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            const user = JSON.parse(localStorage.getItem('currentUser'));
            if (user && user.token) {
                  request = request.clone({
                        setHeaders: {
                              Authorization: `Bearer ${user.token}`
                        }
                  });
            }

            return next.handle(request);
      }
}

<!-- ventana principal -->

<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{'page-amazon-invoices.title' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.clients' | translate}}
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.clients-amazon' | translate}}
      </li>
      <li class="breadcrumb-item active">
        <strong>{{'page-navigation.clients-amazoninvoices' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{'page-amazon-invoices.title-description' | translate}}</em></p>
  </div>
</div>






<div class="row">
  <div class="col-sm-12">

    <div *ngIf="waitAMoment" class="col-sm-12 m-t-md">
      <strong>{{'page-amazon-invoices.waitamoment-title' | translate}}</strong><br />{{'page-amazon-invoices.waitamoment-description' | translate}}
    </div>

    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox" id="hubsManagement">
        <div class="ibox-content">

          <div class="row">
            <div class="col-12">
              <div class="float-right m-b-lg">
                <div class="form-inline">

                  <button type="button" class="btn btn-sm btn-primary m-r-sm" (click)="showAddTaskWindow()">
                    {{'page-amazon-invoices.action-new' | translate}} </button>
                  <div class="input-group">
                    <input style="width: 300px;" type="text" class="form-control input-sm"
                      [(ngModel)]="searchData.value" placeholder="{{'general.search' | translate}}..."
                      (keyup.enter)="getTaskList()">

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" title="{{'general.search' | translate}}"
                        (click)="getTaskList()">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" (click)="exportExcel()"
                        title="{{'general.export-excel' | translate}}"> <i class="fa fa-download"
                          aria-hidden="true"></i></button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" data-toggle="collapse"
                        data-parent="#panelSearch" href="#panelAdvancedSearch" aria-expanded="false"
                        title="{{'search.title' | translate}}">
                        <i class="fa fa-wrench" aria-hidden="true"></i>
                      </button>
                    </span>

                  </div>
                </div>
              </div>

              <div id="panelSearch">
                <div id="panelAdvancedSearch" class="panel-collapse collapse">
                  <h2 class="search-panel-title">{{'search.title' | translate}}</h2>
                  <small class="font-bold">{{'search.title-description' | translate}}</small>
                  <div class="hr-line-dashed"></div>

                  <div>
                    <div *ngIf="searchData.showStatusSearch">
                      <label class="checkbox-inline m-r-md">
                        {{'search.showresults' | translate}}:
                      </label>

                      <label class="checkbox-inline m-r-md">
                        <input type="checkbox" [(ngModel)]="searchData.enabled">
                        &nbsp;{{'search.show-active' | translate}}
                      </label>

                      <label class="checkbox-inline">
                        <input type="checkbox" [(ngModel)]="searchData.disabled">
                        &nbsp;{{'search.show-disabled' | translate}}
                      </label>
                    </div>

                    <div *ngIf="searchData.showDateSearch" class="form-group form-inline">
                      <label class="m-r-md">{{'search.daterange' | translate}}:</label>

                      <label class="m-r-md">{{'search.date-from' | translate}}
                        <input type="date" class="form-control form-control-search m-l-sm mr-sm"
                          [(ngModel)]="searchData.dateFrom">
                      </label>

                      <label>{{'search.date-to' | translate}}
                        <input type="date" class="form-control form-control-search m-l-sm mr-sm"
                          [(ngModel)]="searchData.dateTo">
                      </label>
                    </div>

                  </div>
                  <div class="hr-line-dashed"></div>
                </div>
              </div>

            </div>

            <div class="col-12">
              <p-table [value]="tasks" sortMode="multiple"
                styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [paginator]="true" [rows]="10"
                [showCurrentPageReport]="true" [autoLayout]="true" [(first)]="firstTasks"
                currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
                [rowsPerPageOptions]="[10,25,50,100,200]" [columnResizeMode]="'expand'" [reorderableColumns]="true"
                [resizableColumns]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="date">
                      {{'page-amazon-invoices.columname-date' | translate}}
                      <p-sortIcon field="date"></p-sortIcon>
                    </th>
                    <th pSortableColumn="description">
                      {{'page-amazon-invoices.columname-description' | translate}}
                      <p-sortIcon field="description"></p-sortIcon>
                    </th>
                    <th pSortableColumn="csvFilename">
                      {{'page-amazon-invoices.columname-csv' | translate}}
                      <p-sortIcon field="csvFilename"></p-sortIcon>
                    </th>
                    <th pSortableColumn="ediFilename">
                      {{'page-amazon-invoices.columname-edi' | translate}}
                      <p-sortIcon field="ediFilename"></p-sortIcon>
                    </th>
                    <th pSortableColumn="statusDescription">
                      {{'page-amazon-invoices.columname-status' | translate}}
                      <p-sortIcon field="statusDescription"></p-sortIcon>
                    </th>
                    <th>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-task>
                  <tr>
                    <td>{{task.date | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                    <td>{{task.description}}</td>
                    <td>
                      <a [routerLink]="[]"
                        (click)="downloadCsv(task.id,task.csvFilename)"><span>{{task.csvFilename}}</span></a>
                      <!-- <button type="button" class="btn btn-link btn-fw"
                          (click)="downloadCsv(task.id,task.csvFilename)"><small>{{task.csvFilename}}</small></button>
                       <a [routerLink]="" (click)="downloadCsv(task.id,task.csvFilename)">{{task.csvFilename}}</a> -->
                    </td>
                    <td>
                      <div *ngIf="task.statusProgress > 0" class="progress progress-sm"
                        style="height: 5px; min-width: 75px;">
                        <div class="progress-bar" role="progressbar" [ngStyle]="{'width': task.statusProgress +'%'}">
                        </div>
                      </div>
                      <a *ngIf="task.statusProgress == 0" [routerLink]="[]"
                        (click)="downloadEdi(task.id,task.ediFilename)"><span>{{task.ediFilename}}</span></a>
                      <!-- <button *ngIf="task.statusProgress == 0" type="button" class="btn btn-link btn-fw"F
                          (click)="downloadEdi(task.id,task.ediFilename)"><small>{{task.ediFilename}}</small></button>
                        <a [routerLink]="" (click)="downloadEdi(task.id,task.ediFilename)">{{task.ediFilename}}</a> -->
                    </td>
                    <td>
                      <span>
                        {{task.statusDescription}}
                        <span *ngIf="task.statusProgress > 0"> ... {{task.statusProgress}} % </span>
                      </span>
                    </td>
                    <td>
                      <div class="btn-group btn-group-xs m-r-xs">
                        <button type="button" class="btn btn-xs btn-outline btn-info" (click)="getEventsList(task.id)"
                          title="{{'page-amazon-invoices.tooltiptext-showevents' | translate}}">
                          <i class="fa fa-history" aria-hidden="true"></i>
                        </button>
                      </div>

                      <div class="btn-group btn-group-xs m-r-xs"
                        *ngIf="!taskInProgress(task.status) && !taskCompletedOrCanceled(task.status)">
                        <button type="button" class="btn btn-xs btn-outline btn-primary" (click)="generateEdi(task.id)"
                          title="{{'page-amazon-invoices.tooltiptext-generateedi' | translate}}">
                          <i class="fa fa-file-text-o" aria-hidden="true"></i>
                        </button>
                      </div>

                      <div class="btn-group btn-group-xs m-r-xs" *ngIf="canSendEdi(task.status)">
                        <button type="button" class="btn btn-xs btn-outline btn-success" (click)="sendEdi(task.id)"
                          title="{{'page-amazon-invoices.tooltiptext-sendedi' | translate}}">
                          <i class="fa fa-paper-plane" aria-hidden="true"></i>
                        </button>
                      </div>

                      <div class="btn-group btn-group-xs m-r-xs" *ngIf="taskInProgress(task.status)">
                        <button type="button" class="btn btn-xs btn-outline btn-warning"
                          (click)="stopGenerateEDI(task.id)"
                          title="{{'page-amazon-invoices.tooltiptext-generateedistop' | translate}}">
                          <i class="fa fa-stop" aria-hidden="true"></i>
                        </button>
                      </div>

                      <div class="btn-group btn-group-xs m-r-xs"
                        *ngIf="!taskInProgress(task.status) && !taskCompletedOrCanceled(task.status)">
                        <button type="button" class="btn btn-xs btn-outline btn-danger" (click)="cancelTask(task.id)"
                          title="{{'page-amazon-invoices.tooltiptext-canceltask' | translate}}">
                          <i class="fa fa-ban" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>

            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div *ngIf="userIsAdmin" class="col-sm-12 m-b-md">
          <span>{{'page-amazon-invoices.admin-message1' | translate}}
            <a href='http://localhost:5000/hangfire/'>Hangfire Dashboard</a>.
            {{'page-amazon-invoices.admin-message2' | translate}}.</span>
        </div>
      </div>

    </div>
  </div>
</div>



<!-- ventana modal de añadir tarea -->

<div class="modal inmodal fade" id="addTaskWindow" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">
          {{'page-amazon-invoices.addtaskwindow-title' | translate}}</h4>
        <small class="font-bold">{{'page-amazon-invoices.addtaskwindow-title-description' | translate}}</small>

      </div>
      <div class="modal-body">

        <div class="row">
          <div class="col-sm-12">

            <form [formGroup]="addTaskDataForm" class="m-t-md">

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-amazon-invoices.addtaskwindow-type' | translate}} </label>
                <div class="col-sm-7">
                  <select class="form-control form-control-sm" formControlName="addTask_type"
                    [ngClass]="{ 'is-invalid': addTaskDataFormSubmitted && c.addTask_type.errors }">
                    <option value="0" selected="selected" autocomplete="off">(Factura...) Transport Undels
                    </option>
                    <option value="1" autocomplete="off">(Extras...) Expecials WithOut BoxId</option>
                    <option value="2" autocomplete="off">Regularization</option>
                    <option value="3" selected="selected" autocomplete="off">(Abono Factura...) CREDIT
                      Transport
                      Undels</option>
                    <option value="4" selected="selected" autocomplete="off">(Abono Extras...) CREDIT
                      Expecials
                      WithOut BoxId</option>
                  </select>
                  <div *ngIf="addTaskDataFormSubmitted && c.addTask_type.errors" class="invalid-feedback">
                    <div *ngIf="c.addTask_type.errors.required"> {{'validations.isrequired' | translate}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-amazon-invoices.addtaskwindow-description' | translate}} </label>
                <div class="col-sm-7">
                  <input type="text" class="form-control form-control-sm" formControlName="addTask_description"
                    [ngClass]="{ 'is-invalid': addTaskDataFormSubmitted && c.addTask_description.errors }">
                  <div *ngIf="addTaskDataFormSubmitted && c.addTask_description.errors" class="invalid-feedback">
                    <div *ngIf="c.addTask_description.errors.required">
                      {{'validations.isrequired' | translate}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-amazon-invoices.addtaskwindow-csvfile' | translate}} </label>
                <div class="col-sm-7">
                  <div class="custom-file">
                    <input #file type="file" name="addTaskFile" accept=".csv"
                      class="form-control form-control-sm custom-file-input" (change)="setFile(file.files)"
                      formControlName="addTask_file"
                      [ngClass]="{ 'is-invalid': addTaskDataFormSubmitted && c.addTask_file.errors }">
                    <label for="file" class="custom-file-label">{{'general.choosefile' | translate}}...</label>
                    <div *ngIf="addTaskDataFormSubmitted && c.addTask_file.errors" class="invalid-feedback">
                      <div *ngIf="c.addTask_file.errors.required"> {{'validations.isrequired' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>

            <div *ngIf="progress > 0 && progress < 100">
              <h5>{{progress}}%</h5>
            </div>

          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>
        <button type="button" class="btn btn-sm btn-primary" (click)="addTask()">{{'general.add' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<!-- ventana modal del historial de la tarea -->

<div class="modal inmodal fade" id="taskHistoryWindow" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>
      </div>
      <div class="modal-body">
        <span class="text-primary" *ngFor="let event of eventsList"><span
            class="text-success">{{event.date | date:'dd/MM/yyyy HH:mm:ss'}}</span> -
          {{event.description}}<br /></span>
      </div>
    </div>
  </div>
</div>

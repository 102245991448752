import { isToday } from "date-fns";

export class taskInfo{
    id : number = 0;
    responsable: string = '';
    cliente: string = '';
    fechaFin: Date = null;
    estado: string = '';
    descripcion: string = '';
    tituloProyecto: string = '';
    prioridad: number = 0;
    fechaAlta: Date = null;
    userAlta: string='';
    fechaLimite: Date;
    completado: number=0;
    FechaActu: Date=null;
    priodescripcion: string="";
    estadodescripcion: string="";
    ultcoment: string="";
}
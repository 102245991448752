<!-- ventana principal -->

<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{'warehouse-containers.title' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.warehouse' | translate}}
      </li>
      <li class="breadcrumb-item active">
        <strong>{{'warehouse-containers.title' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{'warehouse-containers.title-description' | translate}}</em></p>
  </div>
</div>


<div class="row">
  <div class="col-sm-12">
    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox" id="warehousContainersManagement">
        <div class="ibox-content sk-loading" id="loading">

          <div class="sk-spinner sk-spinner-pulse" style="width: 75px; height: 75px; top: 100px;"></div>

          <div class="row">

            <div class="col-12">

              <div class="float-right m-b-lg">
                <div class="form-inline">
                  <button class="btn btn-sm btn-primary m-r-sm" type="button"
                    (click)="showTemplateList()">{{'warehouse-containers.action-new' | translate}}</button>
                  <div class="input-group">
                    <input style="width: 300px;" type="text" class="form-control input-sm"
                      [(ngModel)]="searchData.value" placeholder="{{'general.search' | translate}}..."
                      (keyup.enter)="getConteinersInfo()">

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" (click)="getConteinersInfo()" type="button"
                        title="{{'general.search' | translate}}">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" (click)="exportExcel()"
                        title="{{'general.export-excel' | translate}}"> <i class="fa fa-download"
                          aria-hidden="true"></i></button>
                    </span>

                  </div>
                </div>
              </div>

            </div>

            <div class="col-12">
              <p-table [value]="containersList" sortMode="multiple"
                styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [paginator]="true" [rows]="10"
                [showCurrentPageReport]="true" [autoLayout]="true" [(first)]="containersListFirst"
                currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
                [rowsPerPageOptions]="[10,25,50,100,200]" [columnResizeMode]="'expand'" [reorderableColumns]="true"
                [resizableColumns]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 35px;"></th>
                    <th pSortableColumn="id">
                      {{'warehouse-containers.columname-reference' | translate}}
                      <p-sortIcon field="id"></p-sortIcon>
                    </th>
                    <th pSortableColumn="date">
                      {{'warehouse-containers.columname-date' | translate}}
                      <p-sortIcon field="date"></p-sortIcon>
                    </th>
                    <th pSortableColumn="title">
                      {{'warehouse-containers.columname-title' | translate}}
                      <p-sortIcon field="title"></p-sortIcon>
                    </th>
                    <th pSortableColumn="clients">
                      {{'warehouse-containers.columname-clients' | translate}}
                      <p-sortIcon field="clients"></p-sortIcon>
                    </th>
                    <th pSortableColumn="couriers">
                      {{'warehouse-containers.columname-couriers' | translate}}
                      <p-sortIcon field="couriers"></p-sortIcon>
                    </th>
                    <th pSortableColumn="countries">
                      {{'warehouse-containers.columname-countries' | translate}}
                      <p-sortIcon field="countries"></p-sortIcon>
                    </th>
                    <th pSortableColumn="boxes">
                      {{'warehouse-containers.columname-boxes' | translate}}
                      <p-sortIcon field="boxes"></p-sortIcon>
                    </th>

                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-container>
                  <tr [ngClass]="{'row-disable' : container.status.code == 1}">
                    <td>
                      <div class="btn-group btn-group-xs">

                        <button *ngIf="container.status.code == 0" class="btn btn-default btn-xs"
                          title="{{'warehouse-containers.action-container-close' | translate}}"
                          (click)="closeContainer(container.id)"><i class="fa fa-window-close-o"></i></button>

                        <button *ngIf="container.status.code == 1" class="btn btn-default btn-xs"
                          title="{{'warehouse-containers.action-container-open' | translate}}"
                          (click)="openContainer(container.id)"><i class="fa fa-folder-open-o"></i></button>

                        <button *ngIf="container.status.code == 0" class="btn btn-default btn-xs"
                          title="{{'warehouse-containers.action-container-label' | translate}}"
                          (click)="getContainerLabel(container.id)"><i class="fa fa-tag"></i></button>

                        <button class="btn btn-default btn-xs"
                          title="{{'warehouse-containers.action-container-boxes' | translate}}"
                          (click)="downloadBoxList(container.id)"><i class="fa fa-th"></i></button>

                      </div>
                    </td>
                    <td>{{container.id}}</td>
                    <td>{{getDate(container.date)}}</td>
                    <td>{{container.title}}</td>
                    <td>{{getClients(container.clients)}}</td>
                    <td>{{getCouriers(container.couriers)}}</td>
                    <td>{{getContries(container.countries)}}</td>
                    <td>{{container.boxes}}</td>
                  </tr>

                </ng-template>
              </p-table>

            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<!-- ventana modal de selección de plantilla -->

<div class="modal inmodal fade" id="modalSelectTemplete" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-full-80">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">{{'warehouse-containers.selectTempletewindow-title' | translate}}
        </h4>
        <small class="font-bold"></small>

      </div>
      <div class="modal-body">

        <div class="row">
          <div class="col-sm-12">
            <div class="float-right m-b-lg">
              <div class="form-inline">
                <div class="input-group">
                  <input style="width: 300px;" type="text" class="form-control input-sm"
                    [(ngModel)]="searchDataTemplates.value" placeholder="{{'general.search' | translate}}..."
                    (keyup.enter)="getContainerTemplatesInfo()">

                  <span class="input-group-append">
                    <button class="btn btn-white btn-sm" (click)="getContainerTemplatesInfo()" type="button"
                      title="{{'general.search' | translate}}">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </span>


                </div>
              </div>
            </div>
          </div>
        </div>




        <p-table [value]="templatesList" styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"
          [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [(first)]="templatesListFirst"
          currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
          [rowsPerPageOptions]="[10,25,50,100,200]" selectionMode="single">
          <ng-template pTemplate="body" let-template>
            <tr>
              <td>
                <div class="cursor-pointer m-xxs" (click)="createContainer(template.id)">
                  <h4>{{template.name}}</h4>
                  <div [innerHTML]="stringToHtml(template.description)"></div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>
        <!--
        <button type="button" class="btn btn-sm btn-primary"
          (click)="closeAddHubsWindows()">{{'general.save-changes' | translate}}</button>
      -->
      </div>
    </div>
  </div>
</div>

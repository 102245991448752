import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statusmapping',
  templateUrl: './statusmapping.component.html',
  styleUrls: ['./statusmapping.component.css']
})
export class StatusmappingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<!-- ventana principal -->

<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{'page-administracion-clients-relations.title' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations' | translate}}
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations-administration' | translate}}
      </li>
      <li class="breadcrumb-item" title="{{'page-administracion-clients.title' | translate}}">
        <a routerLink="/oper/admin/clients">{{'page-navigation.operations-clients' | translate}}</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>{{'page-administracion-clients-relations.title' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{'page-administracion-clients-relations.title-description' | translate}}</em></p>
  </div>
</div>


<div class="row">
  <div class="col-sm-12">
    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox" id="clientContaManagement">
        <div class="ibox-content sk-loading">

          <div class="sk-spinner sk-spinner-pulse" style="width: 75px; height: 75px; top: 100px;"></div>

          <div class="row">

            <div class="col-12">

              <div class="float-right m-b-lg">
                <div class="form-inline">
                  <button class="btn btn-sm btn-primary m-r-sm" type="button"
                    (click)="newClientConta()">{{'page-administracion-clients-relations.action-new' | translate}}</button>
                  <div class="input-group">
                    <input style="width: 300px;" type="text" class="form-control input-sm"
                      [(ngModel)]="searchData.value" placeholder="{{'general.search' | translate}}..."
                      (keyup.enter)="getClientContaList()">

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" (click)="getClientContaList()" type="button"
                        title="{{'general.search' | translate}}">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" (click)="exportExcel()"
                        title="{{'general.export-excel' | translate}}"> <i class="fa fa-download"
                          aria-hidden="true"></i></button>
                    </span>

                  </div>
                </div>
              </div>

            </div>

            <div class="col-12">
              <p-table [value]="clientContaList" sortMode="multiple"
                styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [paginator]="true" [rows]="10"
                [showCurrentPageReport]="true" [autoLayout]="true" [(first)]="firstClientConta"
                currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
                [rowsPerPageOptions]="[10,25,50,100,200]" [columnResizeMode]="'expand'" [reorderableColumns]="true"
                [resizableColumns]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 65px;"></th>
                    <th pSortableColumn="contaProvider">
                      {{'page-administracion-clients-relations.columname-contatype' | translate}}
                      <p-sortIcon field="contaProvider"></p-sortIcon>
                    </th>
                    <th pSortableColumn="tktName">
                      {{'page-administracion-clients-relations.columname-clienttkt' | translate}}
                      <p-sortIcon field="tktName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="countryName">
                      {{'page-administracion-clients-relations.columname-country' | translate}}
                      <p-sortIcon field="countryName"></p-sortIcon>
                    </th>

                    <th pSortableColumn="contaName">
                      {{'page-administracion-clients-relations.columname-clienteconta' | translate}}
                      <p-sortIcon field="contaName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="serviceTypeName">
                      {{'page-administracion-clients-relations.columname-servicetype' | translate}}
                      <p-sortIcon field="serviceTypeName"></p-sortIcon>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                  <tr>
                    <td>
                      <div class="btn-group btn-group-xs">
                        <button class="btn btn-default btn-xs" title="{{'general.clone' | translate}}"
                          (click)="editClientConta(item)"><i class="fa fa-clone"></i></button>
                        <button class="btn btn-default btn-xs" title="{{'general.delete' | translate}}"
                          (click)="deleteClientConta(item)"><i class="fa fa-trash-o"></i></button>
                      </div>
                    </td>
                    <td>
                      <span *ngIf="item.contaProvider">
                        {{'page-administracion-clients-relations.value-provider' | translate}}</span>
                      <span *ngIf="!item.contaProvider">
                        {{'page-administracion-clients-relations.value-client' | translate}}</span>
                    </td>
                    <td>{{item.tktName}}</td>
                    <td>{{item.countryName}}</td>
                    <td>{{item.contaName}}</td>
                    <td>{{item.serviceTypeName}}</td>
                  </tr>
                </ng-template>
              </p-table>

            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>



<!-- ventana modal de edición -->

<div class="modal inmodal fade" id="editClientContaWindow" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">
          {{'page-administracion-clients-relations.editwindow-title' | translate}}</h4>
        <small
          class="font-bold">{{'page-administracion-clients-relations.editwindow-title-description' | translate}}</small>

      </div>
      <div class="modal-body" id="editClientContaPanelEdicion">

        <div class="row">
          <div class="col-sm-12">

            <form [formGroup]="clientContaDataForm">

              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-administracion-clients-relations.columname-contatype' | translate}}</label>
                <div class="col-sm-7">
                  <div>
                    <label class="checkbox-inline m-r-xl" (click)="setContaIdTypes(1)">
                      <input id="ContaIdType1" type="radio" value="0">
                      {{'page-administracion-clients-relations.value-client' | translate}}</label>

                    <label class="checkbox-inline" (click)="setContaIdTypes(2)">
                      <input id="ContaIdType2" type="radio" value="1">
                      {{'page-administracion-clients-relations.value-provider' | translate}}</label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-administracion-clients-relations.columname-clienttkt' | translate}}</label>
                <div class="col-sm-7" [ngClass]="{ 'is-invalid': clientContaDataFormSubmitted && f.form_tktId.errors }">
                  <select class="form-control input-sm" formControlName="form_tktId" id="form_tktId" style="width:100%;"
                    [ngClass]="{ 'is-invalid': clientContaDataFormSubmitted && f.form_tktId.errors }">
                    <option value=""></option>
                    <option *ngFor="let c1 of tktIdList" value="{{c1.id}}">{{c1.name}}</option>
                  </select>
                  <div *ngIf="clientContaDataFormSubmitted && f.form_tktId.errors" class="invalid-feedback">
                    <div *ngIf="f.form_tktId.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-administracion-clients-relations.columname-country' | translate}}</label>
                <div class="col-sm-5"
                  [ngClass]="{ 'is-invalid': clientContaDataFormSubmitted && f.form_countryId.errors }">
                  <select class="form-control input-sm" formControlName="form_countryId" id="form_countryId"
                    style="width:100%;"
                    [ngClass]="{ 'is-invalid': clientContaDataFormSubmitted && f.form_countryId.errors }">
                    <option value="">{{'general.all' | translate}}</option>
                    <option *ngFor="let c2 of countriesList" value="{{c2.codigoalfa2}}">{{c2.pais}}</option>
                  </select>
                  <div *ngIf="clientContaDataFormSubmitted && f.form_countryId.errors" class="invalid-feedback">
                    <div *ngIf="f.form_countryId.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>
                </div>
              </div>

              <div class="hr-line-dashed"></div>




              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-administracion-clients-relations.columname-clienteconta' | translate}}</label>
                <div class="col-sm-7"
                  [ngClass]="{ 'is-invalid': clientContaDataFormSubmitted && f.form_contaId.errors }">
                  <select class="form-control input-sm" formControlName="form_contaId" id="form_contaId"
                    style="width:100%;"
                    [ngClass]="{ 'is-invalid': clientContaDataFormSubmitted && f.form_contaId.errors }">
                    <option value=""></option>
                    <option *ngFor="let c3 of contaIdList" value="{{c3.id}}">{{c3.name}}</option>
                  </select>
                  <div *ngIf="clientContaDataFormSubmitted && f.form_contaId.errors" class="invalid-feedback">
                    <div *ngIf="f.form_contaId.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>
                </div>
              </div>


              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-administracion-clients-relations.columname-servicetype' | translate}}</label>
                <div class="col-sm-5"
                  [ngClass]="{ 'is-invalid': clientContaDataFormSubmitted && f.form_serviceTypeId.errors }">
                  <select class="form-control input-sm" formControlName="form_serviceTypeId" id="form_serviceTypeId"
                    style="width:100%;"
                    [ngClass]="{ 'is-invalid': clientContaDataFormSubmitted && f.form_serviceTypeId.errors }">
                    <option *ngFor="let c4 of serviceTypeList" value="{{c4.id}}">{{c4.descripcion}}</option>
                  </select>
                  <div *ngIf="clientContaDataFormSubmitted && f.form_serviceTypeId.errors" class="invalid-feedback">
                    <div *ngIf="f.form_serviceTypeId.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>
                </div>
              </div>


            </form>
          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>
        <button type="button" class="btn btn-sm btn-primary"
          (click)="saveClientContaData()">{{'general.save-changes' | translate}}</button>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Roles } from '../../../enums/roles.enum';
import { User } from '../../../models/user.model';
import * as myGlobals from '../../../globals';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {

  public user: User = myGlobals.userGetData();
  public isAdmin: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.isAdmin = myGlobals.userHasRole(Roles.Admin, this.user);
  }





}

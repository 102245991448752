<div class="page-body-wrapper full-page-wrapper animated fadeInDown page-login">
  <div class="lateralpanel-right">

    <app-control-language-selector></app-control-language-selector>

    <div class="container-fluid middle-box ">
      <div class="container-fluid">

        <div style="margin: 80px 0px;">
          <h2>{{'page-login.title' | translate}}</h2>
          <h5>{{'page-login.title-description' | translate}}</h5>
        </div>

        <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>

          <div class="form-group">
            <input type="text" class="form-control" placeholder="{{'page-login.username' | translate}}" name="user"
              [(ngModel)]="form.user" required #user="ngModel" [ngClass]="{'is-invalid': f.submitted && user.errors}" />
            <div class="text-danger small" *ngIf="user.errors && f.submitted">
              {{'validations.isrequired' | translate}}!
            </div>
          </div>

          <div class="form-group">
            <input type="password" class="form-control" placeholder="{{'page-login.password' | translate}}" name="pass"
              [(ngModel)]="form.pass" required #pass="ngModel" [ngClass]="{'is-invalid': f.submitted && pass.errors}" />
            <div class="text-danger small" *ngIf="pass.errors && f.submitted">
              {{'validations.isrequired' | translate}}!
            </div>
          </div>

          <div class="form-group m-t-xl">
            <button class="btn btn-primary block full-width">{{'page-login.login' | translate}}</button>
          </div>

          <div class="form-group m-t-xl">
            <div class="text-danger" *ngIf="f.submitted && isLoginFailed">{{ errorMessage }}</div>
          </div>

        </form>

        <div style="margin: 60px 0px;">{{appCopyrightText}}</div>
      </div>


    </div>
  </div>

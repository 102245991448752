import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as myGlobals from '../globals';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  constructor(public http: HttpClient) { }

  public GetPaises() {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'pais/GetPaises',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HsCodes } from '../models/customs.model';
import { SearchData } from '../models/searches.model';
import * as myGlobals from '../globals';

@Injectable({
  providedIn: 'root'
})
export class CustomsService {

  constructor(public http: HttpClient) { }

  public HsCodesManagementGetList(searchData: SearchData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Customs/HsCodesManagementGetList', searchData,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public HsCodesManagementGetData(code: string) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Customs/HsCodesManagementGetData?code=' + code,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public HsCodesManagementSaveData(data: HsCodes) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Customs/HsCodesManagementSaveData', data,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public HsCodesManagementSetEnable(code: string) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Customs/HsCodesManagementSetEnable?code=' + code,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public HsCodesManagementSetDisable(code: string) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Customs/HsCodesManagementSetDisable?code=' + code,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public DownloadDocumentsAzure(searchData: SearchData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Customs/DownloadDocumentsAzure', searchData,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
        responseType: 'blob' as 'json'
      });
  }

  public DownloadDocumentsBrexit(searchData: SearchData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'Customs/DownloadDocumentsBrexit', searchData,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
        responseType: 'blob' as 'json'
      });
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brexit-llorente',
  templateUrl: './brexit-llorente.component.html',
  styleUrls: ['./brexit-llorente.component.css']
})
export class BrexitLlorenteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

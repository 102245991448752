import { Component, OnInit } from '@angular/core';
import { SearchData, CustomFilter } from '../../../models/searches.model';
import { CustomsService } from '../../../services/customs.service';
import * as myGlobals from '../../../globals';
import { Select2OptionData } from 'ng-select2';
import { saveAs } from 'file-saver';

declare var $: any;

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css'],
})
export class DocumentsComponent implements OnInit {
  docsTypesSelected: string[] = [];
  docsTypesList: Select2OptionData[] = [
    { id: '110101', text: 'DUA de Importación' },
    { id: '110201', text: 'DUA de Exportación' },
    { id: '120101', text: 'Factura' },
  ];

  middleeastRefTypesSelected: string = '';
  middleeastRefTypesList: Select2OptionData[] = [
    { id: 'duas-invoices', text: "Duas por 'MRN' y/o Facturas por 'Número'" },
    { id: 'duas-shipment', text: 'Duas por número de pedido (shipment)' },
    {
      id: 'invoices-shipment',
      text: 'Facturas por número de pedido (shipment)',
    },
  ];

  brexitRefTypesSelected: string = '';
  brexitRefTypesList: Select2OptionData[] = [
    { id: 'mrn-imp', text: 'MRN Importación' },
    { id: 'mrn-exp', text: 'MRN Exportación' },
    { id: 'customs-ref', text: 'Customs reference' },
  ];

  select2MultipleOptions = {
    multiple: true,
    tags: true,
  };

  searchData: SearchData = new SearchData();
  selectTypeReference: any = null;

  constructor(private customsService: CustomsService) {}

  ngOnInit(): void {
    this.customsDocumentsManagementSpinner();
  }

  downloadA(): void {
    this.customsDocumentsManagementSpinner();
    this.searchData.customFilters = [
      { key: 'ref-type', value: this.middleeastRefTypesSelected },
    ];

    this.customsService.DownloadDocumentsAzure(this.searchData).subscribe(
      (data: any) => {
        const blob = new Blob([data.body], {
          type: 'application/octet-stream',
        });
        saveAs(blob, 'documents.zip');
        this.customsDocumentsManagementSpinner();
      },
      (error: any) => {
        var reader = new FileReader();
        reader.onload = function () {
          myGlobals.showMessage(
            reader.result.toString(),
            myGlobals.MessageType.None
          );
        };
        reader.readAsText(error);
        this.customsDocumentsManagementSpinner();
      }
    );
  }

  downloadB(): void {
    this.customsDocumentsManagementSpinner();
    this.searchData.customFilters = [
      { key: 'ref-type', value: this.brexitRefTypesSelected },
      { key: 'docs-types', value: this.docsTypesSelected },
    ];

    this.customsService.DownloadDocumentsBrexit(this.searchData).subscribe(
      (data: any) => {
        const blob = new Blob([data.body], {
          type: 'application/octet-stream',
        });
        saveAs(blob, 'documents.zip');
        this.customsDocumentsManagementSpinner();
      },
      (error: any) => {
        var reader = new FileReader();
        reader.onload = function () {
          myGlobals.showMessage(
            reader.result.toString(),
            myGlobals.MessageType.None
          );
        };
        reader.readAsText(error);
        this.customsDocumentsManagementSpinner();
      }
    );
  }

  customsDocumentsManagementSpinner() {
    $('#customsDocumentsManagement')
      .children('.ibox-content')
      .toggleClass('sk-loading');
  }
}

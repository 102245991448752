<!-- ventana principal -->

<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{'page-services-terrestrial-list.title' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations' | translate}}
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations-services' | translate}}
      </li>
      <li class="breadcrumb-item active">
        <strong>{{'page-navigation.operations-terrestrial' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{'page-services-terrestrial-list.title-description' | translate}}</em></p>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox" id="terrestrialServicesManagement">
        <div class="ibox-content sk-loading">

          <div class="sk-spinner sk-spinner-pulse" style="width: 75px; height: 75px; top: 100px;"></div>

          <div class="row">

            <div class="col-12">

              <div class="float-right m-b-lg">
                <div class="form-inline">

                  <a routerLink="0" class="btn btn-sm btn-primary m-r-sm"
                    title="{{'general.edit' | translate}}">{{'page-services-terrestrial-list.action-new' | translate}}</a>

                  <!--
                  <button class="btn btn-sm btn-primary m-r-sm" type="button"
                    (click)="newService()">{{'page-services-terrestrial-list.action-new' | translate}}</button>
-->
                  <div class="input-group">

                    <input style="width: 300px;" type="text" class="form-control input-sm"
                      [(ngModel)]="searchData.value" placeholder="{{'general.search' | translate}}..."
                      (keyup.enter)="getServicesList()">

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" (click)="getServicesList()" type="button"
                        title="{{'general.search' | translate}}">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" (click)="exportExcel()"
                        title="{{'general.export-excel' | translate}}"> <i class="fa fa-download"
                          aria-hidden="true"></i></button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" data-toggle="collapse"
                        data-parent="#panelSearch" href="#panelAdvancedSearch" aria-expanded="false"
                        title="{{'search.title' | translate}}">
                        <i class="fa fa-wrench" aria-hidden="true"></i>
                      </button>
                    </span>

                  </div>
                </div>
              </div>

              <div id="panelSearch">
                <div id="panelAdvancedSearch" class="panel-collapse collapse">
                  <h2 class="search-panel-title">{{'search.title' | translate}}</h2>
                  <small class="font-bold">{{'search.title-description' | translate}}</small>
                  <div class="hr-line-dashed"></div>

                  <div *ngIf="searchData.showStatusSearch">
                    <label class="checkbox-inline m-r-md">
                      {{'search.showresults' | translate}}:
                    </label>
                    <label class="checkbox-inline m-r-md">
                      <input type="checkbox" [(ngModel)]="searchData.enabled">
                      &nbsp;{{'search.show-active' | translate}}
                    </label>
                    <label class="checkbox-inline">
                      <input type="checkbox" [(ngModel)]="searchData.deleted">
                      &nbsp;{{'search.show-deleted' | translate}}
                    </label>
                  </div>

                  <div class="form-group form-inline">
                    <label class="m-r-md">{{'search.daterange' | translate}}:</label>
                    <label class="m-r-md">{{'search.date-from' | translate}}
                      <input type="date" class="form-control form-control-search m-l-sm mr-sm"
                        [(ngModel)]="searchData_dateFrom">
                    </label>
                    <label>{{'search.date-to' | translate}}
                      <input type="date" class="form-control form-control-search m-l-sm mr-sm"
                        [(ngModel)]="searchData.dateTo">
                    </label>
                  </div>

                  <div class="form-group row col-sm ">
                    <label class="col-sm-auto col-inline">
                      {{'page-services-terrestrial-list.filters-providers' | translate}}:
                    </label>
                    <div class="col-sm col-inline">
                      <ng-select2 [data]="listProviders" width="100%" [dropdownParent]="'panelAdvancedSearch'"
                        [(ngModel)]="selectDataProviders" [options]="select2MultiselectOptions">
                      </ng-select2>
                    </div>
                  </div>

                  <div class="form-group row col-sm ">
                    <label class="col-sm-auto col-inline">
                      {{'page-services-terrestrial-list.filters-status' | translate}}:
                    </label>
                    <div class="col-sm col-inline">
                      <ng-select2 [data]="listStatus" width="100%" [dropdownParent]="'panelAdvancedSearch'"
                        [(ngModel)]="selectDataStatus" [options]="select2MultiselectOptions">
                      </ng-select2>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>
                </div>
              </div>

            </div>

            <div class="col-12">
              <p-table [value]="servicesList" sortMode="multiple"
                styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [paginator]="true" [rows]="25"
                [showCurrentPageReport]="true" [autoLayout]="true" [(first)]="servicesListFirst"
                currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
                [rowsPerPageOptions]="[10,25,50,100,200]" [columnResizeMode]="'expand'" [reorderableColumns]="true"
                [resizableColumns]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 35px;"></th>
                    <th pSortableColumn="id">#
                      <p-sortIcon field="id"></p-sortIcon>
                    </th>
                    <th pSortableColumn="expedicionFecha">
                      {{'page-services-terrestrial-list.columname-date' | translate}}
                      <p-sortIcon field="expedicionFecha"></p-sortIcon>
                    </th>
                    <th pSortableColumn="expedicionNumero">
                      {{'page-services-terrestrial-list.columname-expedition' | translate}}
                      <p-sortIcon field="expedicionNumero"></p-sortIcon>
                    </th>
                    <th pSortableColumn="matricula">
                      {{'page-services-terrestrial-list.columname-vehiclelicense' | translate}}
                      <p-sortIcon field="matricula"></p-sortIcon>
                    </th>
                    <th pSortableColumn="proveedorName">
                      {{'page-services-terrestrial-list.columname-provider' | translate}}
                      <p-sortIcon field="proveedorName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="tipoVehiculoName">
                      {{'page-services-terrestrial-list.columname-vehicletype' | translate}}
                      <p-sortIcon field="tipoVehiculoName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="estadoName">
                      {{'page-services-terrestrial-list.columname-status' | translate}}
                      <p-sortIcon field="estadoName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="descripcion">
                      {{'page-services-terrestrial-list.columname-description' | translate}}
                      <p-sortIcon field="descripcion"></p-sortIcon>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-service>
                  <tr [ngClass]="{'row-disable' : service.status == 2}">
                    <td>
                      <div class="btn-group btn-group-xs">

                        <a routerLink="{{service.id}}" class="btn btn-default btn-xs"
                          title="{{'general.edit' | translate}}"><i class="fa fa-pencil"></i></a>
                        <!--

                        <button class="btn btn-default btn-xs" title="{{'general.edit' | translate}}"
                          (click)="editService(service.id)"><i class="fa fa-pencil"></i></button>
-->
                        <button *ngIf="isAdmin" class="btn btn-default btn-xs btn-outline"
                          title="{{'general.delete' | translate}}" (click)="deleteService(service.id)"><i
                            class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>

                      </div>
                    </td>
                    <td>{{service.id}}</td>
                    <td>{{service.expedicionFecha | date: ('general.format-date' | translate) }}</td>
                    <td>{{service.expedicionNumero}}</td>
                    <td>{{service.matricula}}</td>
                    <td>{{service.proveedorName}}</td>
                    <td>{{service.tipoVehiculoName}}</td>
                    <td>{{service.estadoName}}</td>
                    <td>{{service.descripcion}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>

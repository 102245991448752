import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientInfo, ClientConfig } from '../../../models/clients.model';
import { RoutesService } from '../../../services/routes.service';
import { ClientsService } from '../../../services/clients.service';
import { SearchData } from '../../../models/searches.model';
import * as myGlobals from '../../../globals';

declare var $: any;

@Component({
  selector: 'app-administracion-clientes-boxesbypallet',
  templateUrl: './administracion-clientes-boxesbypallet.component.html',
  styleUrls: ['./administracion-clientes-boxesbypallet.component.css']
})
export class AdministracionClientesBoxesbypalletComponent implements OnInit {

  configDataForm: FormGroup;
  configDataFormSubmitted: boolean = false;
  firstConfig: number = 0;

  searchData: SearchData = new SearchData();
  configList: ClientConfig[] = [];
  currentConfig: ClientConfig = new ClientConfig();
  clientsList: ClientInfo[] = [];

  loadingPage: boolean = false;
  select2Client: any = null;

  constructor(private routesService: RoutesService, private clientsService: ClientsService, private formBuilder: FormBuilder) { }

  // convenience getter for easy access to form fields
  get f() { return this.configDataForm.controls; }

  ngOnInit(): void {
    this.searchData.showDateSearch = false;
    this.searchData.showAdvancedOptions = false;

    this.configDataForm = this.formBuilder.group({
      config_clientId: [0, Validators.required],
      config_boxForPallet: [0, Validators.required]
    });

    this.select2Client = $('#config_clientId').select2({
      dropdownParent: $('#editConfigPanelEdicion')
    });

    this.loadingPage = true;
    this.getClientsList();
    this.getConfigList();

  }

  loadConfigDataForm(): void {
    this.configDataForm.setValue({
      config_clientId: this.currentConfig.clientId,
      config_boxForPallet: this.currentConfig.boxForPallet
    });

    this.select2Client.val(this.currentConfig.clientId).trigger("change");
  }

  saveConfigDataForm(): void {
    this.f.config_clientId.setValue(this.select2Client.val());

    this.currentConfig.clientId = this.f.config_clientId.value;
    this.currentConfig.clientName = this.select2Client.find(':selected').text();
    this.currentConfig.boxForPallet = this.f.config_boxForPallet.value;
  }

  getClientsList() {
    this.clientsService.ClientsManagementGetTktClientList()
      .subscribe((data: any) => {
        this.clientsList = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getConfigList() {
    this.clientManagementSpinner();
    this.routesService.ClientsManagementGetConfigList(this.searchData)
      .subscribe((data: any) => {
        this.configList = data.body;
        this.firstConfig = 0;
        this.clientManagementSpinner();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

    if (this.loadingPage) {
      this.loadingPage = false;
      this.clientManagementSpinner();
    }
  }

  newConfig() {
    this.currentConfig = new ClientConfig();
    this.loadConfigDataForm();
    this.select2Client.prop("disabled", false);
    this.configDataFormSubmitted = false;
    this.editConfigWindowToggle();
  }

  editConfig(id: number) {
    this.routesService.ClientsManagementGetConfig(id)
      .subscribe((data: any) => {
        this.currentConfig = data;
        this.loadConfigDataForm();
        this.select2Client.prop("disabled", true);
        this.configDataFormSubmitted = false;
        this.editConfigWindowToggle();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }




  deleteConfig(id: number) {
    var r = confirm("Are you sure you want to delete the item?");
    if (r == true) {

      this.routesService.ClientsManagementDeleteConfig(id)
        .subscribe(() => {
          this.getConfigList();
        }
          , (error: string) => {
            myGlobals.showMessage(error, myGlobals.MessageType.Error);
          });

    }
  }

  saveConfigData() {
    this.saveConfigDataForm();
    this.configDataFormSubmitted = true;

    // stop here if form is invalid
    if (this.configDataForm.invalid) {
      return;
    }

    this.routesService.ClientsManagementSetConfig(this.currentConfig)
      .subscribe(() => {
        this.getConfigList();
        this.editConfigWindowToggle();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

  }

  editConfigWindowToggle() {
    $('#editConfigWindow').modal('toggle');
  }

  clientManagementSpinner() {
    $('#clientManagement').children('.ibox-content').toggleClass('sk-loading');
  }

  exportExcel() {
    myGlobals.exportExcel(this.configList);
  }

}

import { Component, OnInit } from '@angular/core';
import { SearchData } from '../../../models/searches.model';
import { View } from '../../../models/reporting.model';
import { ReportingService } from '../../../services/reporting.service';
import { Router } from '@angular/router';
import * as myGlobals from '../../../globals';

declare var $: any;

@Component({
  selector: 'app-queries-list',
  templateUrl: './queries-list.component.html',
  styleUrls: ['./queries-list.component.css']
})
export class QueriesListComponent implements OnInit {
  views: View[];
  search: string = '';
  searchData: SearchData = new SearchData();
  loadingPage: boolean = false;
  public first = 0;

  constructor(private router: Router, private reportingService: ReportingService) { }

  ngOnInit(): void {
    this.loadingPage = true;
    this.searchData.disabled = false;
    this.searchData.showAdvancedOptions = false;
    this.searchData.showDateSearch = false;
    this.searchData.roles = myGlobals.userGetData().roles;
    this.getViewsList();
  }

  getViewsList(): void {
    this.viewsManagementSpinner();
    this.reportingService.GetListOfViews(this.searchData)
      .subscribe((data: any) => {
        this.views = data.body;
        this.first = 0;
        this.viewsManagementSpinner();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

    if (this.loadingPage) {
      this.loadingPage = false;
      this.viewsManagementSpinner();
    }
  }

  loadView(id: number) {
    this.router.navigateByUrl('/repo/query/' + id);
  }

  viewsManagementSpinner() {
    $('#queriesListManagement').children('.ibox-content').toggleClass('sk-loading');
  }

}

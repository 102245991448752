import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Roles } from '../../../enums/roles.enum';
import { Provider } from '../../../models/providers.model';
import { RoutesService } from '../../../services/routes.service';
import { RouteInfo, RouteHub, RouteCost, TipoVehiculo } from '../../../models/routes.model';
import { TerrestrialServices } from '../../../services/terrestrial-services.service'
import { DirectionsMapData, Waypoint, AppDirectionsMap, Location, Marker } from '../../../directives/app-directions-map.directive';
import { SearchData, CustomFilter } from '../../../models/searches.model';
import * as myGlobals from '../../../globals';
import { CustomValidators } from 'src/app/helpers/CustomValidators';
declare var $: any;

@Component({
  selector: 'app-administracion-rutas',
  templateUrl: './administracion-rutas.component.html',
  styleUrls: ['./administracion-rutas.component.css']
})
export class AdministracionRutasComponent implements OnInit {
  routeDataForm: FormGroup;
  routeDataFormSubmitted: boolean = false;

  addCostDataForm: FormGroup;
  addCostDataFormSubmitted: boolean = false;
  public select2CostProvider: any = null;
  public select2CostTypeVehicle: any = null;

  public letras = myGlobals.ArrayLetras;

  hubListHash: string = "";

  public hubsIsOK: boolean = false;
  public isAdmin: boolean = false;

  public loadingPage: boolean = false;
  public searchData: SearchData = new SearchData();
  public routesList: RouteInfo[];
  public currentRouteData: RouteInfo = new RouteInfo();

  public directionsMapData: DirectionsMapData = new DirectionsMapData();
  public mapResponse: any = undefined;

  public firstRoutes = 0;
  public firstSelectHub = 0;

  public hubsList: RouteHub[] = [];
  public hubsSearchData: SearchData = new SearchData();
  public hubsShowDialog: boolean = false;

  public typeVehicleList: TipoVehiculo[] = [];
  public providersList: Provider[] = [];

  hubsFilterSelect2: any = null;
  hubsFilterLists = [];


  @ViewChild(AppDirectionsMap) directive !: AppDirectionsMap;

  constructor(private routesService: RoutesService, private terrestrialServices: TerrestrialServices, private formBuilder: FormBuilder) {
  }

  // convenience getter for easy access to form fields
  get f() { return this.routeDataForm.controls; }
  get c() { return this.addCostDataForm.controls; }
  get h() { return this.currentRouteData.hubs; }

  ngOnInit(): void {

    let user = myGlobals.userGetData();
    if (myGlobals.userHasRole(Roles.Admin, user)) this.isAdmin = true;

    this.searchData.showDateSearch = false;
    this.hubsSearchData.showAdvancedOptions = false;
    this.hubsSearchData.enabled = true;
    this.hubsSearchData.disabled = false;

    this.routeDataForm = this.formBuilder.group({
      route_name: ['', [Validators.required, Validators.maxLength(500)]],
      route_description: ['', [Validators.required, Validators.maxLength(500)]],
      route_avoidFerries: [false],
      route_avoidHighways: [false],
      route_avoidTolls: [false],
      route_disable: [false],
      route_newrouteonhubschange: [true]
    });

    this.addCostDataForm = this.formBuilder.group({
      addCost_provider: ['', [Validators.required]],
      addCost_typeVehicle: ['', [Validators.required]],
      addCost_amount: [0, [Validators.required, CustomValidators.numeric]]
    });

    this.hubsFilterSelect2 = $('#select2HubsFilter').select2();

    this.select2CostProvider = $('#addCost_provider').select2({
      dropdownParent: $('#panelAddCost')
    });

    this.select2CostTypeVehicle = $('#addCost_typeVehicle').select2({
      dropdownParent: $('#panelAddCost')
    });


    this.loadingPage = true;
    this.getRoutesList();
    this.getTypeVehicleList();
    this.getProvidersList();
    this.getHubsFilterData();
  }

  getHubsFilterData() {
    this.terrestrialServices.GetHubsFilterList()
      .subscribe((data: any) => {
        this.hubsFilterLists = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getHubsList() {
    this.routesService.RouteManagementGetRouteHubList(this.hubsSearchData)
      .subscribe((data: any) => {
        this.hubsList = data.body;
        this.firstSelectHub = 0;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  saveRouteDataForm(): void {
    this.currentRouteData.name = this.f.route_name.value;
    this.currentRouteData.description = this.f.route_description.value;
    this.currentRouteData.disable = this.f.route_disable.value;
    this.currentRouteData.newRouteOnHubsChange = this.f.route_newrouteonhubschange.value;
    this.currentRouteData.avoidFerries = this.f.route_avoidFerries.value;
    this.currentRouteData.avoidHighways = this.f.route_avoidHighways.value;
    this.currentRouteData.avoidTolls = this.f.route_avoidTolls.value;
  }

  loadRouteDataForm(): void {
    this.routeDataForm.setValue({
      route_name: this.currentRouteData.name,
      route_description: this.currentRouteData.description,
      route_disable: this.currentRouteData.disable,
      route_avoidFerries: this.currentRouteData.avoidFerries,
      route_avoidHighways: this.currentRouteData.avoidHighways,
      route_avoidTolls: this.currentRouteData.avoidTolls,
      route_newrouteonhubschange: true
    });
  }

  getRoutesList() {
    this.searchData.customFilters = [];
    this.searchData.customFilters.push(new CustomFilter("hublist", this.hubsFilterSelect2.val()));

    this.routesManagementSpinner();
    this.routesService.RouteManagementGetList(this.searchData)
      .subscribe((data: any) => {
        this.routesList = data.body;
        this.firstRoutes = 0;
        this.routesManagementSpinner();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

    if (this.loadingPage) {
      this.loadingPage = false;
      this.routesManagementSpinner();
    }
  }

  setRouteDisable(idRoute: number) {
    this.routesService.RouteManagementSetDisable(idRoute)
      .subscribe(() => {
        this.getRoutesList();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  setRouteEnable(idRoute: number) {
    this.routesService.RouteManagementSetEnable(idRoute)
      .subscribe(() => {
        this.getRoutesList();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getHubListHash(): string {
    return this.currentRouteData.hubs.map(x => x.id).join("-");
  }

  newRoute() {
    this.clearRouteData();
    this.showMap();
    this.editRouteWindowToggle();
  }

  editRoute(idRoute: number) {
    this.clearRouteData();
    this.loadRouteData(idRoute);
    this.editRouteWindowToggle();
  }

  validateHubsList() {
    this.hubsIsOK = this.h.length > 1;
  }

  ValidateRouteName() {
    this.f.route_name.setValue(this.getRouteName());
  }

  saveRouteData() {
    this.ValidateRouteName();
    this.validateHubsList();
    this.routeDataFormSubmitted = true;

    let hash: string = this.getHubListHash()
    if (this.hubListHash != hash) {
      myGlobals.showMessage("It is necessary to recalculate before saving", myGlobals.MessageType.Warning);
      return;
    }

    // stop here if form is invalid
    if (this.routeDataForm.invalid || !this.hubsIsOK) {
      return;
    }

    this.saveRouteDataForm();
    this.routesService.RouteManagementSaveData(this.currentRouteData)
      .subscribe((data: any) => {
        this.getRoutesList();
        this.editRouteWindowToggle();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  clearRouteData() {
    this.routeDataFormSubmitted = false;
    this.routeDataForm.reset();
    this.currentRouteData = new RouteInfo();
    this.loadRouteDataForm();
    this.directionsMapData = new DirectionsMapData();
  }

  loadRouteData(idRoute: number) {
    this.routesManagementSpinner();
    this.routesService.RouteManagementGetData(idRoute)
      .subscribe((data: any) => {
        this.currentRouteData = data.body;
        this.loadRouteDataForm();
        this.showMap();
        this.hubListHash = this.getHubListHash();
        this.routesManagementSpinner();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
          this.routesManagementSpinner();
        });
  }


  addHub(hub: RouteHub) {
    this.currentRouteData.hubs.push(hub);
  }

  deleteHub(hub: RouteHub) {
    const index: number = this.currentRouteData.hubs.indexOf(hub);
    if (index !== -1) {
      this.currentRouteData.hubs.splice(index, 1);
    }
  }

  showAddHubDialog() {
    this.hubsSearchData.value = "";
    this.getHubsList();
    //this.hubsShowDialog = true;
    this.openAddHubsWindows();
  }

  addCost() {
    this.c.addCost_provider.setValue(this.select2CostProvider.val());
    this.c.addCost_typeVehicle.setValue(this.select2CostTypeVehicle.val());
    this.addCostDataFormSubmitted = true;

    // stop here if form is invalid
    if (this.addCostDataForm.invalid) {
      return;
    }

    let cost = new RouteCost();
    cost.coste = this.c.addCost_amount.value;
    cost.proveedorDescripcion = this.select2CostProvider.find(':selected').text();
    cost.proveedorId = this.c.addCost_provider.value;
    cost.rutaId = this.currentRouteData.id;
    cost.vehiculoDescripcion = this.select2CostTypeVehicle.find(':selected').text();
    cost.vehiculoId = this.c.addCost_typeVehicle.value;
    cost.id = cost.rutaId + "-" + cost.proveedorId + "-" + cost.vehiculoId;

    let item = this.currentRouteData.costs.find(x => x.id == cost.id);
    if (item != null) {
      const index: number = this.currentRouteData.costs.indexOf(item);
      if (index !== -1) {
        this.currentRouteData.costs.splice(index, 1);
      }
    }

    this.currentRouteData.costs.push(cost);
    this.addCostWindowToggle();
  }

  deleteCost(cost: RouteCost) {
    const index: number = this.currentRouteData.costs.indexOf(cost);
    if (index !== -1) {
      this.currentRouteData.costs.splice(index, 1);
    }
  }

  showAddCostDialog(cost: RouteCost) {
    if (cost == null) cost = new RouteCost();

    this.addCostDataFormSubmitted = false;

    this.addCostDataForm.setValue({
      addCost_provider: cost.proveedorId,
      addCost_typeVehicle: cost.vehiculoId,
      addCost_amount: cost.coste
    });

    this.select2CostProvider.val(this.c.addCost_provider.value).trigger("change");
    this.select2CostTypeVehicle.val(this.c.addCost_typeVehicle.value).trigger("change");
    this.addCostWindowToggle();
  }

  openAddHubsWindows() {
    this.addHubsWindowsToggle();
  }

  closeAddHubsWindows() {
    this.addHubsWindowsToggle();
  }




  addHubsWindowsToggle() {
    $('#addHubsWindow').modal('toggle');
  }

  addCostWindowToggle() {
    $('#addCostWindow').modal('toggle');
  }

  editRouteWindowToggle() {
    $('#editRouteWindow').modal('toggle');
  }

  routesManagementSpinner() {
    $('#routesManagement').children('.ibox-content').toggleClass('sk-loading');
  }

  exportExcel() {
    myGlobals.exportExcel(this.routesList);
  }


  getWaypoint(gpsData: string): Waypoint {
    let waypoint: Waypoint = new Waypoint(new Location(0, 0));

    let data = gpsData.split(',', 2);
    if (data.length == 2) {
      waypoint = new Waypoint(new Location(Number(data[0].trim()), Number(data[1].trim())));
    }
    return waypoint;
  }

  getRouteName(): string {
    let opt: string[] = [];
    if (!this.f.route_avoidTolls.value) opt.push("Tolls");
    if (this.f.route_avoidHighways.value) opt.push("NoHighways");
    if (this.f.route_avoidFerries.value) opt.push("NoFerries");

    if (opt.length > 0) {
      return this.currentRouteData.hubs.map(x => x.title).join(" - ") + " :: [ " + opt.join(", ") + " ]";
    }
    else {
      return this.currentRouteData.hubs.map(x => x.title).join(" - ");
    }
  }

  mapLoaded(data: any) {
    this.mapResponse = data.map;
    this.currentRouteData.hubs[0].km = 0;
    this.currentRouteData.hubs[0].hours = 0;
    this.currentRouteData.totalKm = 0;
    this.currentRouteData.totalHours = 0;

    for (let i = 0; i < this.mapResponse.routes[0].legs.length; i++) {
      this.currentRouteData.hubs[i + 1].km = (this.mapResponse.routes[0].legs[i].distance.value / 1000);
      // Formula de tiempo - se cogen los kilometros totales, se dividen por 80 km/h y se añade un 20% a mayores
      this.currentRouteData.hubs[i + 1].hours = (this.currentRouteData.hubs[i + 1].km / 80) * 1.20;

      //Calcular totales
      this.currentRouteData.totalKm += this.currentRouteData.hubs[i + 1].km;
      this.currentRouteData.totalHours += this.currentRouteData.hubs[i + 1].hours;
    }
  }

  showMap() {
    this.directionsMapData = new DirectionsMapData();
    this.ValidateRouteName();
    this.validateHubsList();

    this.directionsMapData.options.avoidFerries = this.f.route_avoidFerries.value;
    this.directionsMapData.options.avoidHighways = this.f.route_avoidHighways.value;
    this.directionsMapData.options.avoidTolls = this.f.route_avoidTolls.value;

    if (this.hubsIsOK) {

      let pos = 0;
      this.directionsMapData.origin = this.getWaypoint(this.h[pos].coordinatesGPS);
      this.directionsMapData.markers.push({
        lat: this.directionsMapData.origin.location.lat,
        lng: this.directionsMapData.origin.location.lng,
        label: this.letras[pos],
        draggable: false
      });

      for (let i = 1; i < this.h.length - 1; i++) {
        pos = i;
        let point = this.getWaypoint(this.h[pos].coordinatesGPS);
        this.directionsMapData.points.push(point);
        this.directionsMapData.markers.push({
          lat: point.location.lat,
          lng: point.location.lng,
          label: this.letras[pos],
          draggable: false
        });
      }

      pos = pos + 1;
      this.directionsMapData.destination = this.getWaypoint(this.h[pos].coordinatesGPS);
      this.directionsMapData.markers.push({
        lat: this.directionsMapData.destination.location.lat,
        lng: this.directionsMapData.destination.location.lng,
        label: this.letras[pos],
        draggable: false
      });

      this.directionsMapData.status.reset = false;
      this.directionsMapData.status.load = true;
    }

    this.hubListHash = this.getHubListHash();
  }

  getTypeVehicleList(): any {
    this.routesService.GetTiposVehiculos()
      .subscribe((data: any) => {
        this.typeVehicleList = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getProvidersList() {
    this.routesService.GetProveedores()
      .subscribe((data: any) => {
        this.providersList = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getRouteListName(name: string, description: string): string {
    if (myGlobals.isEmptyOrSpaces(name)) {
      return description;
    } else {
      return name;
    }
  }



}

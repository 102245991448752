import { Component, OnInit } from '@angular/core';
import { Roles } from '../../../enums/roles.enum';
import * as myGlobals from '../../../globals';

@Component({
  selector: 'app-page-navigation',
  templateUrl: './page-navigation.component.html',
  styleUrls: ['./page-navigation.component.css']
})
export class PageNavigationComponent implements OnInit {

  public appTitle: string = myGlobals.AppTitle;
  public appTitleShort: string = myGlobals.AppTitleShort;

  public showOperations: boolean = false;
  public showAduanas: boolean = false;
  public showTracking: boolean = false;
  public showShipments: boolean = false;
  public showInvoices: boolean = false;
  public showClients: boolean = false;
  public showClientsAmazonInvoices: boolean = false;
  public showSettings: boolean = false;
  public showReporting: boolean = false;
  public showBrexit: boolean = false;
  public showBrexitLlorente: boolean = false;
  public showBrexitBrunel: boolean = false;

  public showDebug: boolean = false;

  constructor() { }

  ngOnInit(): void {
    let user = myGlobals.userGetData();
    if (myGlobals.userHasRoles([Roles.Admin, Roles.Operaciones], user)) this.showOperations = true;
    if (myGlobals.userHasRoles([Roles.Admin, Roles.Aduanas, Roles.Brexit], user)) this.showAduanas = true;
    if (myGlobals.userHasRoles([Roles.Admin, Roles.Tracking], user)) this.showTracking = true;
    if (myGlobals.userHasRole(Roles.Admin, user)) this.showShipments = true;
    if (myGlobals.userHasRole(Roles.Admin, user)) this.showInvoices = true;
    if (myGlobals.userHasRoles([Roles.Admin, Roles.AmazonInvoices], user)) this.showClients = true;
    if (myGlobals.userHasRoles([Roles.Admin, Roles.AmazonInvoices], user)) this.showClientsAmazonInvoices = true;
    if (myGlobals.userHasRoles([Roles.Admin, Roles.Reporting], user)) this.showReporting = true;
    if (myGlobals.userHasRole(Roles.Admin, user)) this.showSettings = true;

    if (myGlobals.userHasRoles([Roles.Admin, Roles.Brexit], user)) this.showBrexit = true;
    if (myGlobals.userHasRoles([Roles.Admin, Roles.BrexitLlorente], user)) this.showBrexitLlorente = true;
    if (myGlobals.userHasRoles([Roles.Admin, Roles.BrexitBrunel], user)) this.showBrexitBrunel = true;

    if (myGlobals.userHasRole(Roles.Debug, user)) this.showDebug = true;
  }

}

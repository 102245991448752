export class ErrorInfo {
  code: string = '';
  description: string = '';
}

export class GenericKeyValue extends Object {
  key: string = '';
  value: string = '';

  constructor(key: string, value: string) {
    super();
    this.key = key;
    this.value = value;
  }
}
export class GenericIdName extends Object {
  id: number = 0;
  name: string = '';

  constructor(id: number, name: string) {
    super();
    this.id = id;
    this.name = name;
  }
}

export class CodeName extends Object {
  code: number = 0;
  name: string = '';
}

export class CodeDescription extends Object {
  code: string = '';
  description: string = '';
}

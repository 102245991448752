<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{'page-customs-hscodes.title' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.customs' | translate}}
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.customs-administration' | translate}}
      </li>
      <li class="breadcrumb-item active">
        <strong>{{'page-navigation.customs-hscodes' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{'page-customs-hscodes.title-description' | translate}}</em></p>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox" id="brexitManagementSpinner">
        <div class="ibox-content ">
          <!-- sk-loading -->
          <!--<div class="sk-spinner sk-spinner-pulse" style="width: 75px; height: 75px; top: 100px;"></div>-->

          <div class="row">

            <div class="col-12">
              <div class="float-right m-b-lg">
                <div class="form-inline">
                  <button class="btn btn-sm btn-primary m-r-sm" type="button"
                    (click)="newHsCode()">{{'page-customs-hscodes.action-new' | translate}}</button>
                  <div class="input-group">
                    <input style="width: 300px;" type="text" class="form-control input-sm"
                      [(ngModel)]="searchData.value" placeholder="{{'general.search' | translate}}..."
                      (keyup.enter)="getHsCodesList()">

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" (click)="getHsCodesList()" type="button"
                        title="{{'general.search' | translate}}">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" (click)="exportExcel()"
                        title="{{'general.export-excel' | translate}}"> <i class="fa fa-download"
                          aria-hidden="true"></i></button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" data-toggle="collapse"
                        data-parent="#panelSearch" href="#panelAdvancedSearch" aria-expanded="false"
                        title="{{'search.title' | translate}}">
                        <i class="fa fa-wrench" aria-hidden="true"></i>
                      </button>
                    </span>

                  </div>
                </div>
              </div>

              <div id="panelSearch">
                <div id="panelAdvancedSearch" class="panel-collapse collapse">
                  <h2 class="search-panel-title">{{'search.title' | translate}}</h2>
                  <small class="font-bold">{{'search.title-description' | translate}}</small>
                  <div class="hr-line-dashed"></div>

                  <div>
                    <div *ngIf="searchData.showStatusSearch">
                      <label class="checkbox-inline m-r-md">
                        {{'search.showresults' | translate}}:
                      </label>

                      <label class="checkbox-inline m-r-md">
                        <input type="checkbox" [(ngModel)]="searchData.enabled">
                        &nbsp;{{'search.show-active' | translate}}
                      </label>

                      <label class="checkbox-inline">
                        <input type="checkbox" [(ngModel)]="searchData.disabled">
                        &nbsp;{{'search.show-disabled' | translate}}
                      </label>
                    </div>

                    <div *ngIf="searchData.showDateSearch" class="form-group form-inline">
                      <label class="m-r-md">{{'search.daterange' | translate}}:</label>

                      <label class="m-r-md">{{'search.date-from' | translate}}
                        <input type="date" class="form-control form-control-search m-l-sm mr-sm" id="fechadesde">
                      </label>

                      <label>{{'search.date-to' | translate}}
                        <input type="date" class="form-control form-control-search m-l-sm mr-sm">
                      </label>
                    </div>

                  </div>
                  <div class="hr-line-dashed"></div>
                </div>
              </div>
            </div>


            <div class="col-12">
              <p-table [value]="hsCodes" sortMode="multiple"
                styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [paginator]="true" [rows]="10"
                [showCurrentPageReport]="true" [autoLayout]="true" [(first)]="firstHsCodes"
                currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
                [rowsPerPageOptions]="[10,25,50,100,200]" [columnResizeMode]="'expand'" [reorderableColumns]="true"
                [resizableColumns]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 65px;"></th>
                    <th style="width: 65px;" pSortableColumn="codigo">
                      {{'page-customs-hscodes.columname-code' | translate}}
                      <p-sortIcon field="codigo"></p-sortIcon>
                    </th>
                    <th pSortableColumn="descripcion">
                      {{'page-customs-hscodes.columname-description' | translate}}
                      <p-sortIcon field="descripcion"></p-sortIcon>
                    </th>
                    <th style="width: 65px;" pSortableColumn="tipoIVA">
                      {{'page-customs-hscodes.columname-vat' | translate}}
                      <p-sortIcon field="tipoIVA"></p-sortIcon>
                    </th>
                    <th style="width: 65px;" pSortableColumn="tipoUnidadesSuplementarias">
                      {{'page-customs-hscodes.columname-units' | translate}}
                      <p-sortIcon field="tipoUnidadesSuplementarias"></p-sortIcon>
                    </th>
                    <th>
                      {{'page-customs-hscodes.columname-others' | translate}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-hscode>
                  <tr [ngClass]="{'row-disable' : !hscode.vigente}">
                    <td>
                      <div class="btn-group btn-group-xs">
                        <button class="btn btn-default btn-xs" title="{{'general.clone' | translate}}"
                          (click)="editHsCode(hscode.codigo)"><i class="fa fa-clone"></i></button>

                        <button *ngIf="!hscode.vigente" class="btn btn-default btn-xs"
                          title="{{'general.enable' | translate}}" (click)="setHsCodeEnable(hscode.codigo)"><i
                            class="fa fa-eye"></i></button>

                        <button *ngIf="hscode.vigente" class="btn btn-default btn-xs"
                          title="{{'general.disable' | translate}}" (click)="setHsCodeDisable(hscode.codigo)"><i
                            class="fa fa-eye-slash"></i></button>
                      </div>
                    </td>
                    <td>{{hscode.codigo}}</td>
                    <td>{{hscode.descripcion}}</td>
                    <td>{{hscode.tipoIVA}} %</td>
                    <td>{{hscode.tipoUnidadesSuplementarias}}</td>
                    <td>
                      <span *ngIf="hscode.pesoMaximoPorUnidad >0 || hscode.pesoMinimoPorUnidad >0"
                        class="label m-l-xs">Credibilidad</span>
                      <span *ngIf="hscode.requiereSoivre" class="label m-l-xs">Soivre</span>
                      <span *ngIf="hscode.requiereCites" class="label m-l-xs">Cites</span>
                      <span *ngIf="hscode.dobleUso" class="label m-l-xs">Doble uso</span>
                    </td>
                  </tr>
                </ng-template>
              </p-table>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<!-- ventana modal de edición -->

<div class="modal inmodal fade" id="editHsCodeWindow" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-full">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">{{'page-customs-hscodes.editwindow-title' | translate}}</h4>
        <small class="font-bold">{{'page-customs-hscodes.editwindow-title-description' | translate}}</small>

      </div>
      <div class="modal-body" id="editHsCodePanelEdicion">

        <div class="row">
          <div class="col-sm-12">

            <form [formGroup]="dataForm">

              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-customs-hscodes.editwindow-codigo' | translate}} </label>
                <div class="col-sm-auto">
                  <input type="text" class="form-control input-sm" style="width: 125px;" formControlName="hs_codigo"
                    [ngClass]="{ 'is-invalid': dataFormSubmitted && f.hs_codigo.errors }">

                  <div *ngIf="dataFormSubmitted && f.hs_codigo.errors" class="invalid-feedback">
                    <div *ngIf="f.hs_codigo.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-customs-hscodes.editwindow-descripcion' | translate}} </label>
                <div class="col-sm-8">
                  <input type="text" class="form-control input-sm" formControlName="hs_descripcion"
                    [ngClass]="{ 'is-invalid': dataFormSubmitted && f.hs_descripcion.errors }">

                  <div *ngIf="dataFormSubmitted && f.hs_descripcion.errors" class="invalid-feedback">
                    <div *ngIf="f.hs_descripcion.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-customs-hscodes.editwindow-descripcioningles' | translate}} </label>
                <div class="col-sm-8">
                  <input type="text" class="form-control input-sm" formControlName="hs_descripcionIngles"
                    [ngClass]="{ 'is-invalid': dataFormSubmitted && f.hs_descripcionIngles.errors }">

                  <div *ngIf="dataFormSubmitted && f.hs_descripcionIngles.errors" class="invalid-feedback">
                    <div *ngIf="f.hs_descripcionIngles.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>
                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-customs-hscodes.editwindow-tipo-iva' | translate}} </label>
                <div class="col-sm-2">

                  <div class="form-group form-inline">
                    <input type="number" class="form-control input-sm" style="width: 75px;" formControlName="hs_tipoIVA"
                      [ngClass]="{ 'is-invalid': dataFormSubmitted && f.hs_tipoIVA.errors }">
                    <span class="m-l-sm">%</span>
                  </div>
                  <div *ngIf="dataFormSubmitted && f.hs_tipoIVA.errors" class="invalid-feedback">
                    <div *ngIf="f.hs_tipoIVA.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-customs-hscodes.editwindow-tipo-unidades' | translate}} </label>
                <div class="col-sm-auto">
                  <input type="text" class="form-control input-sm" style="width: 75px;"
                    formControlName="hs_tipoUnidadesSuplementarias">
                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-customs-hscodes.editwindow-credibilidad' | translate}} </label>
                <div class="col-sm-9">

                  <div class="form-group form-inline">
                    <span>
                      {{'page-customs-hscodes.editwindow-credibilidad-pesominimo' | translate}}:</span>
                    <input type="number" class="m-l-sm form-control input-sm" style="width: 100px;"
                      formControlName="hs_pesoMinimoPorUnidad">

                    <span class="m-l-xl">
                      {{'page-customs-hscodes.editwindow-credibilidad-pesomaximo' | translate}}:</span>
                    <input type="number" class="m-l-sm form-control input-sm" style="width: 100px;"
                      formControlName="hs_pesoMaximoPorUnidad">
                  </div>

                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-customs-hscodes.editwindow-medidas' | translate}}
                </label>
                <div class="col-sm-8">
                  <label class="checkbox-inline">
                    <input type="checkbox" formControlName="hs_requiereSoivre">
                    {{'page-customs-hscodes.editwindow-soivre' | translate}}</label><br />

                  <label class="checkbox-inline">
                    <input type="checkbox" formControlName="hs_requiereCites">
                    {{'page-customs-hscodes.editwindow-cites' | translate}}</label><br />

                  <label class="checkbox-inline">
                    <input type="checkbox" formControlName="hs_dobleUso">
                    {{'page-customs-hscodes.editwindow-dobleUso' | translate}}</label>

                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right"></label>
                <div class="col-sm-8">

                  <label class="checkbox-inline">
                    <input type="checkbox" formControlName="hs_vigente">
                    {{'page-customs-hscodes.editwindow-vigente' | translate}}</label>
                </div>
              </div>

            </form>

          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>
        <button type="button" class="btn btn-sm btn-primary"
          (click)="saveHsCodeData()">{{'general.save-changes' | translate}}</button>
      </div>
    </div>
  </div>
</div>

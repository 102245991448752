import { tr } from 'date-fns/locale';

export class SearchData {
    value: string = "";
    dateFrom: Date = null;
    dateTo: Date = null;
    enabled: boolean = true;
    disabled: boolean = true;
    deleted: boolean = false;
    showDateSearch: boolean = true;
    showStatusSearch: boolean = true;
    showAdvancedOptions: boolean = true;
    roles: string[] = null;
    customFilters: CustomFilter[] = [];
}

export class CustomFilter extends Object {
    key: string = "";
    value: Object = null;

    constructor(key: string, value: Object) {
        super();
        this.key = key;
        this.value = value;
    }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../../../helpers/CustomValidators';
import { LoginService } from '../../../services/login.service';
import { User, UserChangePasswordData } from '../../../models/user.model';
import { UsersService } from '../../../services/users.service';
import * as myGlobals from '../../../globals';

@Component({
  selector: 'app-page-changepassword',
  templateUrl: './page-changepassword.component.html',
  styleUrls: ['./page-changepassword.component.css']
})
export class PageChangepasswordComponent implements OnInit {

  formData: FormGroup;
  formDataSubmitted = false;

  constructor(private loginService: LoginService, private usersService: UsersService, fb: FormBuilder) {
    this.formData = fb.group({
      'oldPwd': ['', Validators.required],
      'newPwd': ['', Validators.required],
      'confirmPwd': ['', Validators.required]
    }, {
      validator: CustomValidators.matchPwds
    });

  }

  ngOnInit(): void {
  }

  changePassword(): void {
    this.formDataSubmitted = true;

    // stop here if form is invalid
    if (this.formData.invalid) {
      return;
    }

    let data = new UserChangePasswordData();
    data.username = myGlobals.userGetData().username;
    data.currentPassword = this.oldPwd.value;
    data.newPassword = this.newPwd.value;

    this.usersService.ChangePassword(data)
      .subscribe((data: any) => {
        this.loginService.logOut();
        window.location.reload();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  get oldPwd() {
    return this.formData.get('oldPwd');
  }

  get newPwd() {
    return this.formData.get('newPwd');
  }

  get confirmPwd() {
    return this.formData.get('confirmPwd');
  }

  invalidPwdOld(): boolean {
    let r: boolean = false;
    if (this.formDataSubmitted && this.oldPwd.errors) r = true;
    return r;
  }

  invalidPwdNew(): boolean {
    let r: boolean = false;
    if (this.formDataSubmitted && this.newPwd.errors) r = true;
    return r;
  }

  invalidPwdConfirm(): boolean {
    let r: boolean = false;
    if (this.formDataSubmitted && (this.confirmPwd.errors || this.pwdsDontMatch())) r = true;
    return r;
  }

  pwdsDontMatch(): boolean {
    let r: boolean = false;
    if (this.formData.errors != null) {
      r = this.formData.errors.pwdsDontMatch;
    }
    return r;
  }

}

<div class="passwordBox animated fadeInDown">
  <div class="row">

    <div class="col-md-12">
      <div class="ibox-content">

        <h2 class="font-bold">{{'page-changepassword.title' | translate}}</h2>
        <p>
          {{'page-changepassword.title-description' | translate}}
        </p>
        <div class="row">
          <div class="col-lg-12">

            <form [formGroup]="formData">

              <div class="form-group">
                <input formControlName="oldPwd" id="oldPwd" type="password" class="form-control"
                  placeholder="{{'page-changepassword.oldpassword' | translate}}"
                  [ngClass]="{ 'is-invalid': invalidPwdOld() }" autocomplete="off" required />
                <div *ngIf="invalidPwdOld()" class="invalid-feedback">
                  <div *ngIf="oldPwd.errors.required">{{'validations.isrequired' | translate}}</div>
                  <!--
                  <div *ngIf="oldPwd.errors.minlength"> {{'validations.minlength' | translate}} </div>
                  <div *ngIf="oldPwd.errors.maxlength"> {{'validations.maxlength' | translate}} </div>
                  <div *ngIf="oldPwd.errors.shouldBe1234">Old Password is not valid.</div>
                -->
                </div>
              </div>

              <div class="form-group">
                <input formControlName="newPwd" id="newPwd" type="password" class="form-control"
                  placeholder="{{'page-changepassword.newpassword' | translate}}"
                  [ngClass]="{ 'is-invalid': invalidPwdNew() }" autocomplete="off" required />
                <div *ngIf="invalidPwdNew()" class="invalid-feedback">
                  <div *ngIf="newPwd.errors.required">{{'validations.isrequired' | translate}}</div>
                </div>
              </div>

              <div class="form-group">
                <input formControlName="confirmPwd" id="confirmPwd" type="password" class="form-control"
                  placeholder="{{'page-changepassword.confirmpassword' | translate}}"
                  [ngClass]="{ 'is-invalid': invalidPwdConfirm() }" autocomplete="off" required />

                <div *ngIf="invalidPwdConfirm()" class="invalid-feedback">
                  <div *ngIf="pwdsDontMatch()"> {{'validations.dontmatch-pwds' | translate}} </div>
                  <div *ngIf="confirmPwd.errors.required">{{'validations.isrequired' | translate}}</div>
                </div>
              </div>

              <button class="btn btn-primary block full-width m-b"
                (click)="changePassword()">{{'page-changepassword.action-change' | translate}}</button>

            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as myGlobals from '../../../globals';

@Component({
  selector: 'app-control-language-selector',
  templateUrl: './control-language-selector.component.html',
  styleUrls: ['./control-language-selector.component.css'],
})
export class ControlLanguageSelectorComponent implements OnInit {
  constructor(private translate: TranslateService) {}

  ngOnInit(): void {}

  setLanguage(language: string) {
    this.translate.use(language);
    this.translate.setDefaultLang(language);
    myGlobals.languageSetDefaultValue(language);
  }
}

<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{'page-user-info.title' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item active">
        <strong>{{'page-user-info.title' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{'page-user-info.title-description' | translate}}</em></p>
  </div>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row m-b-lg m-t-lg">
    <div class="col-md-12">

      <div class="profile-image">
        <img src="../../../../assets/images/blank-user.jpg" class="rounded-circle circle-border m-b-md" alt="profile">
      </div>
      <div class="profile-info">
        <div class="">
          <div>
            <h2 class="no-margins">
              {{user.fullname}}
            </h2>
            <h4>{{'page-user-info.user-title' | translate}}</h4>
            <small>
              {{'page-user-info.user-title-description' | translate}}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="ibox">
        <div class="ibox-content">
          <!--
          <p> <strong>{{'page-user-info.property-username' | translate}}:</strong><br />{{user.username}} </p>
      
          <p> <strong>{{'page-user-info.property-roles' | translate}}:</strong><br />
            <span *ngFor="let r of user.roles">[ {{r}} ] </span>
          </p>
        -->
          <p> <strong>{{'page-user-info.property-remotedesktop' | translate}}:</strong><br />
            <span>{{'page-user-info.property-remotedesktop1' | translate}}
              <a href='https://vpn.tikiting.com:8004/'>{{'page-user-info.property-remotedesktop2' | translate}}</a>
              {{'page-user-info.property-remotedesktop3' | translate}}.</span>
          </p>
        </div>
      </div>
    </div>
  </div>

</div>

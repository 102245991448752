<!-- ventana principal -->

<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{'page-administracion-routes.title' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations' | translate}}
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations-administration' | translate}}
      </li>
      <li class="breadcrumb-item active">
        <strong>{{'page-navigation.operations-routes' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{'page-administracion-routes.title-description' | translate}}</em></p>
  </div>
</div>


<div class="row">
  <div class="col-sm-12">
    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox" id="routesManagement">
        <div class="ibox-content sk-loading">

          <div class="sk-spinner sk-spinner-pulse" style="width: 75px; height: 75px; top: 100px;"></div>

          <div class="row">

            <div class="col-12">

              <div class="float-right m-b-lg">
                <div class="form-inline">
                  <button class="btn btn-sm btn-primary m-r-sm" type="button"
                    (click)="newRoute()">{{'page-administracion-routes.action-new' | translate}}</button>
                  <div class="input-group">
                    <input style="width: 300px;" type="text" class="form-control input-sm"
                      [(ngModel)]="searchData.value" placeholder="{{'general.search' | translate}}..."
                      (keyup.enter)="getRoutesList()">

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" (click)="getRoutesList()" type="button"
                        title="{{'general.search' | translate}}">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" (click)="exportExcel()"
                        title="{{'general.export-excel' | translate}}"> <i class="fa fa-download"
                          aria-hidden="true"></i></button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" data-toggle="collapse"
                        data-parent="#panelSearch" href="#panelAdvancedSearch" aria-expanded="false"
                        title="{{'search.title' | translate}}">
                        <i class="fa fa-wrench" aria-hidden="true"></i>
                      </button>
                    </span>

                  </div>
                </div>
              </div>

              <div id="panelSearch">
                <div id="panelAdvancedSearch" class="panel-collapse collapse">
                  <h2 class="search-panel-title">{{'search.title' | translate}}</h2>
                  <small class="font-bold">{{'search.title-description' | translate}}</small>
                  <div class="hr-line-dashed"></div>

                  <div>
                    <div *ngIf="searchData.showStatusSearch">
                      <label class="checkbox-inline m-r-md">
                        {{'search.showresults' | translate}}:
                      </label>

                      <label class="checkbox-inline m-r-md">
                        <input type="checkbox" [(ngModel)]="searchData.enabled">
                        &nbsp;{{'search.show-active' | translate}}
                      </label>

                      <label class="checkbox-inline">
                        <input type="checkbox" [(ngModel)]="searchData.disabled">
                        &nbsp;{{'search.show-disabled' | translate}}
                      </label>
                    </div>

                    <div *ngIf="searchData.showDateSearch" class="form-group form-inline">
                      <label class="m-r-md">{{'search.daterange' | translate}}:</label>

                      <label class="m-r-md">{{'search.date-from' | translate}}
                        <input type="date" class="form-control form-control-search m-l-sm mr-sm" id="fechadesde">
                      </label>

                      <label>{{'search.date-to' | translate}}
                        <input type="date" class="form-control form-control-search m-l-sm mr-sm">
                      </label>
                    </div>

                  </div>

                  <div class="form-group form-inline">
                    <label class="m-r-md" style="width: 100%; justify-content: left;">
                      Hubs:
                      &nbsp;&nbsp;
                      <select class="form-control form-control-search" style="width: 85%;" multiple="multiple"
                        id="select2HubsFilter">
                        <option *ngFor="let item of hubsFilterLists" value="{{item.id}}">
                          {{item.name}}</option>
                      </select>
                    </label>
                  </div>

                  <div class="hr-line-dashed"></div>
                </div>
              </div>

            </div>

            <div class="col-12">
              <p-table [value]="routesList" sortMode="multiple"
                styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [paginator]="true" [rows]="10"
                [showCurrentPageReport]="true" [autoLayout]="true" [(first)]="firstRoutes"
                currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
                [rowsPerPageOptions]="[10,25,50,100,200]" [columnResizeMode]="'expand'" [reorderableColumns]="true"
                [resizableColumns]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 65px;"></th>
                    <th pSortableColumn="id">#<p-sortIcon field="id"></p-sortIcon>
                    </th>
                    <th pSortableColumn="name">
                      {{'page-administracion-routes.columname-name' | translate}}
                      <p-sortIcon field="name"></p-sortIcon>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-route>
                  <tr [ngClass]="{'row-disable' : route.disable}">
                    <td>
                      <div class="btn-group btn-group-xs">
                        <button class="btn btn-default btn-xs" title="{{'general.edit' | translate}}"
                          (click)="editRoute(route.id)"><i class="fa fa-pencil"></i></button>
                        <button *ngIf="route.disable" class="btn btn-default btn-xs"
                          title="{{'general.enable' | translate}}" (click)="setRouteEnable(route.id)"><i
                            class="fa fa-eye"></i></button>
                        <button *ngIf="!route.disable" class="btn btn-default btn-xs"
                          title="{{'general.disable' | translate}}" (click)="setRouteDisable(route.id)"><i
                            class="fa fa-eye-slash"></i></button>
                      </div>
                    </td>
                    <td> {{route.id}} </td>
                    <td>{{getRouteListName(route.name,route.description)}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<!-- ventana modal de edición -->

<div class="modal inmodal fade" id="editRouteWindow" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-full">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">{{'page-administracion-routes.editwindow-title' | translate}}</h4>
        <small class="font-bold">{{'page-administracion-routes.editwindow-title-description' | translate}}</small>

      </div>
      <div class="modal-body">
        <form [formGroup]="routeDataForm">
          <div class="row">

            <div class="col-sm-8">


              <div class="form-group row">
                <label class="col-sm-2 col-form-label font-bold text-right">
                  {{'page-administracion-routes.editwindow-name' | translate}} </label>
                <div class="col-sm-10">
                  <input type="text" class="form-control input-sm" formControlName="route_name"
                    [ngClass]="{ 'is-invalid': routeDataFormSubmitted && f.route_name.errors }" [readonly]="true">

                  <div *ngIf="routeDataFormSubmitted && f.route_name.errors" class="invalid-feedback">
                    <div *ngIf="f.route_name.errors.required"> {{'validations.isrequired' | translate }} </div>
                    <div *ngIf="f.route_name.errors.maxlength"> {{'validations.maxlength' | translate}} </div>
                  </div>

                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label font-bold text-right">
                  {{'page-administracion-routes.editwindow-description' | translate}} </label>
                <div class="col-sm-10">
                  <input type="text" class="form-control input-sm" formControlName="route_description"
                    [ngClass]="{ 'is-invalid': routeDataFormSubmitted && f.route_description.errors }">

                  <div *ngIf="routeDataFormSubmitted && f.route_description.errors" class="invalid-feedback">
                    <div *ngIf="f.route_description.errors.required"> {{'validations.isrequired' | translate }} </div>
                    <div *ngIf="f.route_description.errors.maxlength"> {{'validations.maxlength' | translate}} </div>
                  </div>

                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label font-bold text-right">
                  {{'page-administracion-routes.editwindow-costs' | translate}} </label>
                <div class="col-sm-10">
                  <p-table [value]="currentRouteData.costs" dataKey="id"
                    styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [autoLayout]="true"
                    [columnResizeMode]="'expand'">
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th>{{'page-administracion-routes.columname-provider' | translate}}</th>
                        <th>{{'page-administracion-routes.columname-vehicle' | translate}} </th>
                        <th>{{'page-administracion-routes.columname-cost' | translate}} </th>
                        <th style="width:1rem" class="text-right">
                          <button class="btn btn-white btn-xs" type="button" title="{{'general.add' | translate}}"
                            (click)="showAddCostDialog(null)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                          </button>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-cost let-index="rowIndex">
                      <tr [pReorderableRow]="index">
                        <td>{{cost.proveedorDescripcion}}</td>
                        <td>{{cost.vehiculoDescripcion}}</td>
                        <td>{{cost.coste | number : '1.2-2'}}</td>
                        <td>
                          <div class="btn-group btn-group-xs">
                            <button class="btn btn-default btn-xs" title="{{'general.clone' | translate}}"
                              (click)="showAddCostDialog(cost)"><i class="fa fa-clone"></i></button>

                            <button class="btn btn-white btn-xs" type="button" title="{{'general.delete' | translate}}"
                              (click)="deleteCost(cost)">
                              <i class="fa fa-trash-o" aria-hidden="true"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label"></label>
                <div class="col-sm-10">

                  <div>
                    <label class="checkbox-inline m-r-xl">
                      <input type="checkbox" formControlName="route_avoidTolls">
                      {{'page-administracion-routes.editwindowvalue-avoidtolls' | translate}}</label>

                    <label class="checkbox-inline m-r-xl">
                      <input type="checkbox" formControlName="route_avoidHighways">
                      {{'page-administracion-routes.editwindowvalue-avoidhighways' | translate}}</label>

                    <label class="checkbox-inline">
                      <input type="checkbox" formControlName="route_avoidFerries">
                      {{'page-administracion-routes.editwindowvalue-avoidferries' | translate}}</label>
                  </div>

                  <div class="m-t-xs">
                    <label class="checkbox-inline m-r-xl">
                      <input type="checkbox" formControlName="route_disable">
                      {{'general.disabled' | translate}}</label>

                    <label [hidden]="!isAdmin" class="checkbox-inline">
                      <input type="checkbox" formControlName="route_newrouteonhubschange">
                      {{'page-administracion-routes.editwindow-newrouteonhubschange' | translate}}</label>
                  </div>

                </div>
              </div>


            </div>

            <div class="col-sm-4" style="min-height: 200px">
              <agm-map appDirectionsMap [latitude]="directionsMapData.origin.location.lat"
                [longitude]="directionsMapData.origin.location.lng" [origin]="directionsMapData.origin"
                [destination]="directionsMapData.destination" [points]="directionsMapData.points"
                [options]="directionsMapData.options" [status]="directionsMapData.status"
                [zoom]="directionsMapData.zoom" (eventLoadedMap)="mapLoaded($event)">

                <agm-marker *ngFor="let marker of directionsMapData.markers; let i = index" [latitude]="marker.lat"
                  [longitude]="marker.lng" [label]="marker.label"></agm-marker>

              </agm-map>
            </div>

            <div class="col-sm-12">
              <div class="hr-line-dashed"></div>

              <div class="col-sm-12" [ngClass]="{ 'invalid-feedback-border-custom': !hubsIsOK }">
                <p-table [value]="currentRouteData.hubs" dataKey="id" [reorderableColumns]="true"
                  styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [autoLayout]="true"
                  [columnResizeMode]="'expand'">
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th style="width:1rem"></th>
                      <th>{{'page-administracion-routes.columname-country' | translate}}</th>
                      <th>{{'page-administracion-routes.columname-name' | translate}} </th>
                      <th>{{'page-administracion-routes.columname-title' | translate}}</th>
                      <th>{{'page-administracion-routes.columname-km' | translate}}</th>
                      <th>{{'page-administracion-routes.columname-hours' | translate}}</th>
                      <th style="width:1rem"></th>
                      <th style="width:1rem">
                        <button class="btn btn-white btn-xs" type="button" title="{{'general.add' | translate}}"
                          (click)="showAddHubDialog()">
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </button>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-hub let-index="rowIndex">
                    <tr [pReorderableRow]="index">
                      <td>
                        <span class="badge badge-danger">{{letras[index]}}</span>
                      </td>
                      <td>{{hub.countryDescription}}</td>
                      <td>{{hub.name}}</td>
                      <td>{{hub.title}}</td>
                      <td>{{hub.km | number : '1.2-2'}}</td>
                      <td>{{hub.hours | number : '1.2-2'}}</td>
                      <td>
                        <span class="pi pi-bars" pReorderableRowHandle></span>
                      </td>
                      <td>
                        <button class="btn btn-white btn-xs" type="button" title="{{'general.delete' | translate}}"
                          (click)="deleteHub(hub)">
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>

                <div class="row m-t-md">
                  <span
                    class="col-auto text-muted">{{'page-services-terrestrial-edit.totalkm' | translate}}:&nbsp;&nbsp;
                    {{currentRouteData.totalKm | number : '1.2-2'}}</span>
                  <span
                    class="col-auto text-muted">{{'page-services-terrestrial-edit.totalhours' | translate}}:&nbsp;&nbsp;
                    {{currentRouteData.totalHours | number : '1.2-2'}}</span>
                </div>
              </div>
              <div *ngIf="!hubsIsOK" class="invalid-feedback-custom"> {{'validations.isrequired' | translate }} </div>
            </div>

            <div id="panelDirectionsParent" class="col-sm-12 ">
              <div id="panelDirections" class="panel-collapse collapse">
                <div class="hr-line-dashed"></div>
                <div class="col-sm-12 directionsPanelContainer">
                  <div id="directionsPanel"></div>
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>

      <div class="modal-footer">

        <button class="btn btn-white btn-sm" type="button" data-toggle="collapse" data-parent="#panelDirectionsParent"
          href="#panelDirections" aria-expanded="false"
          title="{{'page-administracion-routes.action-routedetails' | translate}}">
          <i class="fa fa-map-marker" aria-hidden="true"></i>
        </button>

        <button type="button" class="btn btn-sm btn-white m-r-md" (click)="showMap()">{{'general.show' | translate}} /
          {{'general.recalculate' | translate}}</button>
        <button type="button" class="btn btn-sm btn-white "
          data-dismiss="modal">{{'general.close' | translate}}</button>
        <button type="button" class="btn btn-sm btn-primary"
          (click)="saveRouteData()">{{'general.save-changes' | translate}}</button>
      </div>
    </div>
  </div>
</div>



<!-- ventana modal de edición de costes -->

<div class="modal inmodal fade" id="addCostWindow" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">{{'page-administracion-routes.addcostwindow-title' | translate}}</h4>
        <small class="font-bold">{{'page-administracion-routes.addcostwindow-title-description' | translate}}</small>

      </div>
      <div class="modal-body" id="panelAddCost">

        <div class="row">
          <div class="col-sm-12">

            <form [formGroup]="addCostDataForm">

              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-bold text-right">
                  {{'page-administracion-routes.addcostwindow-provider' | translate}} </label>
                <div class="col-sm-7"
                  [ngClass]="{ 'is-invalid': addCostDataFormSubmitted && c.addCost_provider.errors }">
                  <select class="form-control input-sm" formControlName="addCost_provider" id="addCost_provider"
                    style="width:100%;"
                    [ngClass]="{ 'is-invalid': addCostDataFormSubmitted && c.addCost_provider.errors }">
                    <option *ngFor="let p of providersList" value="{{p.idProveedor}}">{{p.empresa}}</option>
                  </select>
                  <div *ngIf="addCostDataFormSubmitted && c.addCost_provider.errors" class="invalid-feedback">
                    <div *ngIf="c.addCost_provider.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-administracion-routes.addcostwindow-typeVehicle' | translate}} </label>
                <div class="col-sm-7"
                  [ngClass]="{ 'is-invalid': addCostDataFormSubmitted && c.addCost_typeVehicle.errors }">
                  <select class="form-control input-sm" formControlName="addCost_typeVehicle" id="addCost_typeVehicle"
                    style="width:100%;"
                    [ngClass]="{ 'is-invalid': addCostDataFormSubmitted && c.addCost_typeVehicle.errors }">
                    <option *ngFor="let t of typeVehicleList" value="{{t.id}}">{{t.descripcion}}</option>
                  </select>
                  <div *ngIf="addCostDataFormSubmitted && c.addCost_typeVehicle.errors" class="invalid-feedback">
                    <div *ngIf="c.addCost_typeVehicle.errors.required"> {{'validations.isrequired' | translate}} </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">
                  {{'page-administracion-routes.addcostwindow-amount' | translate}} </label>
                <div class="col-sm-4">
                  <input type="number" class="form-control input-sm" formControlName="addCost_amount"
                    [ngClass]="{ 'is-invalid': addCostDataFormSubmitted && c.addCost_amount.errors }">
                  <div *ngIf="addCostDataFormSubmitted && c.addCost_amount.errors" class="invalid-feedback">
                    <div *ngIf="c.addCost_amount.errors.required"> {{'validations.isrequired' | translate}} </div>
                    <div *ngIf="c.addCost_amount.errors.invalidNumber"> {{'validations.only-numbers' | translate}}
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>
        <button type="button" class="btn btn-sm btn-primary"
          (click)="addCost()">{{'general.save-changes' | translate}}</button>
      </div>
    </div>
  </div>
</div>


<!-- ventana modal de añadir hubs -->

<div class="modal inmodal fade" id="addHubsWindow" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-full-80">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">{{'page-administracion-hubs.selectionwindow-title' | translate}}</h4>
        <small class="font-bold"></small>

      </div>
      <div class="modal-body">

        <div class="row">
          <div class="col-sm-12">
            <div class="float-right m-b-lg">
              <div class="form-inline">
                <div class="input-group">
                  <input style="width: 300px;" type="text" class="form-control input-sm"
                    [(ngModel)]="hubsSearchData.value" placeholder="{{'general.search' | translate}}..."
                    (keyup.enter)="getHubsList()">

                  <span class="input-group-append">
                    <button class="btn btn-white btn-sm" (click)="getHubsList()" type="button"
                      title="{{'general.search' | translate}}">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </span>


                </div>
              </div>
            </div>
          </div>
        </div>

        <p-table [value]="hubsList" sortMode="multiple"
          styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [paginator]="true" [rows]="10"
          [showCurrentPageReport]="true" [autoLayout]="true" [(first)]="firstSelectHub"
          currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
          [rowsPerPageOptions]="[10,25,50,100,200]" [columnResizeMode]="'expand'" [reorderableColumns]="true"
          [resizableColumns]="true">
          <ng-template pTemplate="header">
            <tr>

              <th pSortableColumn="pais">
                {{'page-administracion-hubs.columname-country' | translate}}
                <p-sortIcon field="pais"></p-sortIcon>
              </th>

              <th pSortableColumn="alias">
                {{'page-administracion-hubs.columname-alias' | translate}}
                <p-sortIcon field="alias"></p-sortIcon>
              </th>
              <th pSortableColumn="nombre">
                {{'page-administracion-hubs.columname-name' | translate}}
                <p-sortIcon field="nombre"></p-sortIcon>
              </th>

              <th style="width:2rem"> </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-hub>
            <tr>
              <td>{{hub.countryDescription}} ({{hub.country}})</td>
              <td>{{hub.alias}}</td>
              <td>{{hub.name}}</td>
              <td>
                <button class="btn btn-white btn-xs" type="button" title="{{'general.select' | translate}}"
                  (click)="addHub(hub)">
                  <i class="fa fa-crosshairs" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>
        <!--  
        <button type="button" class="btn btn-sm btn-primary"
          (click)="closeAddHubsWindows()">{{'general.save-changes' | translate}}</button>
      -->
      </div>
    </div>
  </div>
</div>

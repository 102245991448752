import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../../globals';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.css']
})
export class PageFooterComponent implements OnInit {

  public copyrightText: string = myGlobals.AppCopyrightText;
  public appTitle: string = myGlobals.AppTitle;
  public authenticated: boolean = false;

  constructor() { }

  ngOnInit(): void {
    let user = myGlobals.userGetData();
    this.authenticated = user.authenticated;
  }

}

export class User {
    id: number = 0;
    fullname: string = "";
    username: string = "";
    password: string = "";
    roles: string[] = null;
    token: string = "";
    expiredtime: Date = new Date();
    authenticated: boolean = false;
    passwordWillExpireSoon: boolean = false;
    passwordExpireDays: number = 0;
}

export class UserInfo {
    id: number = 0;
    fullname: string = "";
    username: string = "";
    password: string = "";
    fechaInicio: Date = null;
    fechaFin: Date = null;
    useDomainAuthenticate: boolean = false;
    disable: boolean = false;
    roles: UserRolesInfo[] = [];
    type: number = 0;
    typeReferenceId: string = '';
    email: string = '';
}

export class UserRolesInfo {
    id: number;
    name: string;
    fechaInicio: Date;
    fechaFin: Date;
}

/*
export class RolInfo {
    id: number;
    name: string;
    disable: boolean;
}
*/

export class UserRolEditor {
    rolSelected: boolean;
    rolId: number;
    rolName: string;
    fechaInicio: string;
    fechaFin: string;
}

export class UserChangePasswordData {
    id: number = 0;
    username: string = "";
    currentPassword: string = "";
    newPassword: string = "";
    useDomain: boolean = true;
}

export class UserChangePasswordResponse {
    success: boolean = false;
    message: string = "";
}
export enum Roles {
  userPortal = 'UserPortal',
  clientPortal = 'ClientPortal',
  courierPortal = 'CourierPortal',
}

export class AuthenticateRequest {
  user: string = '';
  pass: string = '';
}

export class AuthenticateResponse {
  account: Account = new Account();
  token: string = '';
}

export class Account {
  companyCode: number = 0;
  companyName: string = '';
  id: number = 0;
  name: string = '';
  username: string = '';
  email: string = '';
  roles: string[] = [];
  expires: Date | null = null;
}
export class SessionInfo {
  account: Account = new Account();
  authenticated: boolean = false;
  token: string = '';
}

export class UserPreferences {
  language: string = '';
  hideSidebar: boolean = true;
}

export class RecoverPasswordRequest {
  user: string = '';
  email: string = '';
}

export class RecoverPasswordDataRequest extends AuthenticateRequest {
  code: string = '';
  passConfirm: string = '';
}

export class ChangePasswordDataRequest extends AuthenticateRequest {
  passNew: string = '';
  passNewConfirm: string = '';
}

export class SessionsList {
  hash: string = '';
  ip: string = '';
  expires: Date | null = null;
}

<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{'page-users-management.title' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.settings' | translate}}
      </li>
      <li class="breadcrumb-item active">
        <strong>{{'page-navigation.settings-users' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{'page-users-management.title-description' | translate}}</em></p>
  </div>
</div>


<div class="row">
  <div class="col-sm-12">
    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox" id="usersManagement">
        <div class="ibox-content sk-loading">
          <div class="sk-spinner sk-spinner-pulse" style="width: 75px; height: 75px; top: 100px;"></div>

          <div class="row">

            <div class="col-12">
              <div class="float-right m-b-lg">
                <div class="form-inline">
                  <button class="btn btn-sm btn-primary m-r-sm" type="button"
                    (click)="newUser()">{{'page-users-management.action-new' | translate}}</button>
                  <div class="input-group">
                    <input style="width: 300px;" type="text" class="form-control input-sm"
                      [(ngModel)]="searchData.value" placeholder="{{'general.search' | translate}}..."
                      (keyup.enter)="getUsersList()">

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" (click)="getUsersList()" type="button"
                        title="{{'general.search' | translate}}">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" (click)="exportExcel()"
                        title="{{'general.export-excel' | translate}}"> <i class="fa fa-download"
                          aria-hidden="true"></i></button>
                    </span>

                    <span class="input-group-append">
                      <button class="btn btn-white btn-sm" type="button" data-toggle="collapse"
                        data-parent="#panelSearch" href="#panelAdvancedSearch" aria-expanded="false"
                        title="{{'search.title' | translate}}">
                        <i class="fa fa-wrench" aria-hidden="true"></i>
                      </button>
                    </span>

                  </div>
                </div>
              </div>

              <div id="panelSearch">
                <div id="panelAdvancedSearch" class="panel-collapse collapse">
                  <h2 class="search-panel-title">{{'search.title' | translate}}</h2>
                  <small class="font-bold">{{'search.title-description' | translate}}</small>
                  <div class="hr-line-dashed"></div>

                  <div>
                    <div *ngIf="searchData.showStatusSearch">
                      <label class="checkbox-inline m-r-md">
                        {{'search.showresults' | translate}}:
                      </label>

                      <label class="checkbox-inline m-r-md">
                        <input type="checkbox" [(ngModel)]="searchData.enabled">
                        &nbsp;{{'search.show-active' | translate}}
                      </label>

                      <label class="checkbox-inline">
                        <input type="checkbox" [(ngModel)]="searchData.disabled">
                        &nbsp;{{'search.show-disabled' | translate}}
                      </label>
                    </div>

                    <div *ngIf="searchData.showDateSearch" class="form-group form-inline">
                      <label class="m-r-md">{{'search.daterange' | translate}}:</label>

                      <label class="m-r-md">{{'search.date-from' | translate}}
                        <input type="date" class="form-control form-control-search m-l-sm mr-sm" id="fechadesde">
                      </label>

                      <label>{{'search.date-to' | translate}}
                        <input type="date" class="form-control form-control-search m-l-sm mr-sm">
                      </label>
                    </div>

                  </div>
                  <div class="hr-line-dashed"></div>
                </div>
              </div>
            </div>


            <div class="col-12">
              <p-table [value]="users" sortMode="multiple"
                styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [paginator]="true" [rows]="10"
                [showCurrentPageReport]="true" [autoLayout]="true" [(first)]="firstUsers"
                currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
                [rowsPerPageOptions]="[10,25,50,100,200]" [columnResizeMode]="'expand'" [reorderableColumns]="true"
                [resizableColumns]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 65px;"></th>
                    <th pSortableColumn="fullname">
                      {{'page-users-management.columname-name' | translate}}
                      <p-sortIcon field="fullname"></p-sortIcon>
                    </th>
                    <th pSortableColumn="username">
                      {{'page-users-management.columname-user' | translate}}
                      <p-sortIcon field="username"></p-sortIcon>
                    </th>
                    <th pSortableColumn="email">
                      {{'page-users-management.columname-email' | translate}}
                      <p-sortIcon field="email"></p-sortIcon>
                    </th>
                    <th pSortableColumn="useDomainAuthenticate">
                      {{'page-users-management.columname-authentication' | translate}}
                      <p-sortIcon field="useDomainAuthenticate"></p-sortIcon>
                    </th>
                    <th>
                      {{'page-users-management.columname-type' | translate}}
                    </th>
                    <th>
                      {{'page-users-management.columname-roles' | translate}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-user>
                  <tr [ngClass]="{'row-disable' : user.disable}">
                    <td>
                      <div class="btn-group btn-group-xs">
                        <button class="btn btn-default btn-xs" title="{{'general.edit' | translate}}"
                          (click)="editUser(user.id)"><i class="fa fa-pencil"></i></button>
                        <button *ngIf="user.disable" class="btn btn-default btn-xs"
                          title="{{'general.enable' | translate}}" (click)="setUserEnable(user.id)"><i
                            class="fa fa-eye"></i></button>
                        <button *ngIf="!user.disable" class="btn btn-default btn-xs"
                          title="{{'general.disable' | translate}}" (click)="setUserDisable(user.id)"><i
                            class="fa fa-eye-slash"></i></button>
                        <button *ngIf="!user.disable" class="btn btn-default btn-xs"
                          title="{{'page-users-management.set-password' | translate}}"
                          (click)="setUserPassword(user)"><i class="fa fa-key"></i></button>
                      </div>
                    </td>
                    <td>{{user.fullname}}</td>
                    <td>{{user.username}}</td>
                    <td>{{user.email}}</td>
                    <td>
                      <span
                        *ngIf="user.useDomainAuthenticate">{{'page-users-management.value-integrated' | translate}}</span>
                      <span
                        *ngIf="!user.useDomainAuthenticate">{{'page-users-management.value-local' | translate}}</span>
                    </td>
                    <td>
                      <span *ngIf="user.type==0">{{'page-users-management.value-user' | translate}}</span>
                      <span *ngIf="user.type==1">{{'page-users-management.value-client' | translate}}</span>
                      <span *ngIf="user.type==2">{{'page-users-management.value-provider' | translate}}</span>
                    </td>
                    <td>
                      <span *ngFor="let rol of user.roles" class="label m-l-xs">{{rol.name}}</span>
                    </td>
                  </tr>
                </ng-template>
              </p-table>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>



<!-- ventana modal de edición -->

<div class="modal inmodal fade" id="editUserWindow" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-full">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">{{'page-users-management.editwindow-title' | translate}}</h4>
        <small class="font-bold">{{'page-users-management.editwindow-title-description' | translate}}</small>
      </div>
      <div class="modal-body" id="editUserPanelEdicion">
        <div class="tabs-container">
          <ul class="nav nav-tabs" role="tablist">
            <li><a class="nav-link active show" data-toggle="tab"
                href="#tab-general">{{'page-users-management.editwindow-general' | translate}}</a></li>
            <li><a class="nav-link" data-toggle="tab"
                href="#tab-roles">{{'page-users-management.editwindow-roles' | translate}}</a></li>
          </ul>
          <div class="tab-content">
            <div role="tabpanel" id="tab-general" class="tab-pane active show">
              <div class="panel-body">

                <div class="row">
                  <div class="col-sm-12">

                    <form [formGroup]="userDataForm">

                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-bold text-right">
                          {{'page-users-management.editwindow-fullname' | translate}} </label>
                        <div class="col-sm-6">
                          <input type="text" class="form-control input-sm" formControlName="user_fullname"
                            [ngClass]="{ 'is-invalid': userDataFormSubmitted && f.user_fullname.errors }">

                          <div *ngIf="userDataFormSubmitted && f.user_fullname.errors" class="invalid-feedback">
                            <div *ngIf="f.user_fullname.errors.required"> {{'validations.isrequired' | translate }}
                            </div>
                            <div *ngIf="f.user_fullname.errors.maxlength"> {{'validations.maxlength' | translate}}
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-bold text-right">
                          {{'page-users-management.editwindow-username' | translate}} </label>
                        <div class="col-sm-4">
                          <input type="text" class="form-control input-sm" formControlName="user_username"
                            [ngClass]="{ 'is-invalid': userDataFormSubmitted && f.user_username.errors }"
                            (focusout)="validateUsername()" [readonly]="currentUserData.id > 0">

                          <div *ngIf="userDataFormSubmitted && f.user_username.errors" class="invalid-feedback">
                            <div *ngIf="f.user_username.errors.required"> {{'validations.isrequired' | translate }}
                            </div>
                            <div *ngIf="f.user_username.errors.maxlength"> {{'validations.maxlength' | translate}}
                            </div>
                          </div>

                          <div *ngIf="userExits" class="invalid-feedback-custom">
                            {{'validations.userexist' | translate }}
                          </div>

                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-bold text-right">
                          {{'page-users-management.editwindow-password' | translate}} </label>
                        <div class="col-sm-4">
                          <input type="password" class="form-control input-sm" formControlName="user_password"
                            [ngClass]="{ 'is-invalid': userDataFormSubmitted && f.user_password.errors }">

                          <div *ngIf="userDataFormSubmitted && f.user_password.errors" class="invalid-feedback">
                            <div *ngIf="f.user_password.errors.required"> {{'validations.isrequired' | translate }}
                            </div>
                            <div *ngIf="f.user_password.errors.maxlength"> {{'validations.maxlength' | translate}}
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-bold text-right">
                          {{'page-users-management.columname-type' | translate}} </label>
                        <div class="col-sm-3">

                          <select class="form-control input-sm" formControlName="user_type" id="user_type">
                            <option value="0" selected>{{'page-users-management.value-user' | translate}}</option>
                            <option value="1">{{'page-users-management.value-client' | translate}}</option>
                            <option value="2">{{'page-users-management.value-provider' | translate}}</option>
                          </select>

                        </div>
                        <div class="col-sm-3">
                          <input type="text" class="form-control input-sm" formControlName="user_typereferenceid">
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-bold text-right">
                          {{'page-users-management.editwindow-email' | translate}} </label>
                        <div class="col-sm-6">
                          <input type="email" class="form-control input-sm" formControlName="user_email">
                        </div>
                      </div>

                      <div class="hr-line-dashed"></div>

                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-bold text-right">
                          {{'page-users-management.editwindow-startdate' | translate}} </label>
                        <div class="col-sm-3">
                          <input type="date" class="form-control input-sm" formControlName="user_fechaInicio"
                            [ngClass]="{ 'is-invalid': userDataFormSubmitted && f.user_fechaInicio.errors }">

                          <div *ngIf="userDataFormSubmitted && f.user_fechaInicio.errors" class="invalid-feedback">
                            <div *ngIf="f.user_fechaInicio.errors.required"> {{'validations.isrequired' | translate }}
                            </div>
                            <div *ngIf="f.user_fechaInicio.errors.maxlength"> {{'validations.maxlength' | translate}}
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-bold text-right">
                          {{'page-users-management.editwindow-enddate' | translate}} </label>
                        <div class="col-sm-3">
                          <input type="date" class="form-control input-sm" formControlName="user_fechaFin"
                            [ngClass]="{ 'is-invalid': userDataFormSubmitted && f.user_fechaFin.errors }">

                          <div *ngIf="userDataFormSubmitted && f.user_fechaFin.errors" class="invalid-feedback">
                            <div *ngIf="f.user_fechaFin.errors.required"> {{'validations.isrequired' | translate }}
                            </div>
                            <div *ngIf="f.user_fechaFin.errors.maxlength"> {{'validations.maxlength' | translate}}
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label"></label>
                        <div class="col-sm-7">
                          <label class="checkbox-inline">
                            <input type="checkbox" formControlName="user_usedomainauthenticate">
                            {{'page-users-management.editwindow-usedomainauthenticate' | translate}}</label>
                          <br />
                          <label class="checkbox-inline">
                            <input type="checkbox" formControlName="user_disable">
                            {{'page-users-management.editwindow-disable' | translate}}</label>
                        </div>
                      </div>

                    </form>

                  </div>
                </div>

              </div>
            </div>
            <div role="tabpanel" id="tab-roles" class="tab-pane">
              <div class="panel-body">

                <div class="row">
                  <div class="col-sm-12">

                    <div class="form-group row">
                      <label class="col-sm-1 col-form-label font-bold text-right"> </label>
                      <div class="col-sm-10">

                        <div class="">
                          <table class="table-roles">
                            <tr *ngFor="let item of currentUserRolEditor; let i = index">
                              <td>
                                <input type="checkbox" [(ngModel)]="currentUserRolEditor[i].rolSelected">
                              </td>

                              <td>
                                {{currentUserRolEditor[i].rolName}}
                              </td>

                              <td>
                                <input *ngIf="currentUserRolEditor[i].rolSelected" type="date"
                                  class="form-control form-control-search" style="width: 150px;"
                                  title="{{'page-users-management.editwindow-startdate' | translate}}"
                                  [(ngModel)]="currentUserRolEditor[i].fechaInicio" value="{{item.fechaInicio}}">
                              </td>

                              <td>
                                <input *ngIf="currentUserRolEditor[i].rolSelected" type="date"
                                  class="form-control form-control-search" style="width: 150px;"
                                  title="{{'page-users-management.editwindow-enddate' | translate}}"
                                  [(ngModel)]="currentUserRolEditor[i].fechaFin" value="{{item.fechaFin}}">
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div>
                          <div *ngIf="!userHasRoles" class="invalid-feedback-custom">
                            {{'validations.isrequired' | translate }}
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>
        <button type="button" class="btn btn-sm btn-primary"
          (click)="saveUserData()">{{'general.save-changes' | translate}}</button>
      </div>
    </div>
  </div>
</div>


<!-- ventana modal de establecer contraseña -->

<div class="modal inmodal fade" id="setPasswordWindow" tabindex="-1" role="dialog" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-header-custom">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">{{'general.close' | translate}}</span></button>

        <h4 class="modal-title modal-title-custom">{{'page-users-management.set-password' | translate}}</h4>
      </div>
      <div class="modal-body" id="setPasswordPanelEdicion">

        <div class="m-b-xl">
          <h2> {{currentUserData.fullname}}</h2>
        </div>

        <div class="row">
          <div class="col-sm-12">

            <form [formGroup]="formData">

              <div class="form-group">
                <input formControlName="newPwd" id="newPwd" type="password" class="form-control"
                  placeholder="{{'page-changepassword.newpassword' | translate}}"
                  [ngClass]="{ 'is-invalid': invalidPwdNew() }" autocomplete="off" required />
                <div *ngIf="invalidPwdNew()" class="invalid-feedback">
                  <div *ngIf="newPwd.errors.required">{{'validations.isrequired' | translate}}</div>
                </div>
              </div>

              <div class="form-group">
                <input formControlName="confirmPwd" id="confirmPwd" type="password" class="form-control"
                  placeholder="{{'page-changepassword.confirmpassword' | translate}}"
                  [ngClass]="{ 'is-invalid': invalidPwdConfirm() }" autocomplete="off" required />

                <div *ngIf="invalidPwdConfirm()" class="invalid-feedback">
                  <div *ngIf="pwdsDontMatch()"> {{'validations.dontmatch-pwds' | translate}} </div>
                  <div *ngIf="confirmPwd.errors.required">{{'validations.isrequired' | translate}}</div>
                </div>
              </div>

            </form>

          </div>
        </div>



      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-white" data-dismiss="modal">{{'general.close' | translate}}</button>
        <button type="button" class="btn btn-sm btn-primary"
          (click)="saveUserPassword()">{{'general.save-changes' | translate}}</button>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WarehouseService } from '../../../services/warehouse.service';
import {
  WareGroupingContainerInfo,
  WareGroupingBoxScannedDataInfo,
  GeneralActionResult,
} from '../../../models/warehouse.model';
import { Result, BarcodeFormat } from '@zxing/library';

import { CodeName, CodeDescription } from '../../../models/general.model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Translate } from '../../../helpers/translate';
import { DatePipe } from '@angular/common';
import * as myGlobals from '../../../globals';
import { Dialog } from 'primeng/dialog';
declare var $: any;

@Component({
  selector: 'app-warehouse-boxescontainer',
  templateUrl: './boxescontainer.component.html',
  styleUrls: ['./boxescontainer.component.css'],
})
export class WareHouseBoxescontainerComponent implements OnInit {
  loading: any;
  hasDevices: boolean = false;
  hasPermission: boolean = false;
  qrResultString: string = '';
  qrResult: Result | null = null;

  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;
  deviceSelected: string = '';
  availableDevices: MediaDeviceInfo[] = [];
  currentDevice: MediaDeviceInfo | undefined = undefined;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.CODE_39,
    BarcodeFormat.EAN_8,
    BarcodeFormat.ITF,
  ];

  requestCode: string = '';
  scannedDataBox: string = '';
  scannedDataBoxStatus: number = 0;
  scannedDataBoxMode: number = 0;
  scannedDataContainer: string = '';

  scannedData: WareGroupingBoxScannedDataInfo =
    new WareGroupingBoxScannedDataInfo();

  result: null | GeneralActionResult = null;
  currentContainer: null | WareGroupingContainerInfo = null;
  translate: Translate = new Translate(null, null);
  showReader: boolean = false;

  displayModalStart: boolean = false;
  displayModalBoxValidate: boolean = false;
  displayModalError: boolean = false;
  messageModalError: string = '';
  errorContainerNotFound: boolean = false;
  useCamera: boolean = false;
  checkingBoxLabels: boolean = false;

  constructor(
    private warehouseService: WarehouseService,
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.translate = new Translate(this.translateService, this.datePipe);
    this.loading = $('#loading');
    this.displayModalStart = true;
  }

  executeStart() {
    this.displayModalStart = false;
    this.gotoInputContainer();
    this.showReader = true;
  }

  gotoInputContainer() {
    this.scannedDataContainer = '';
    this.setFocusInputContainer();
  }

  startBoxValidation(dialog: Dialog) {
    this.maximizeDialog(dialog);
    this.readNewBox();
  }

  startCameraReader(dialog: Dialog) {
    this.maximizeDialog(dialog);
    if (this.currentContainer != null) this.readNewBox();
  }

  maximizeDialog(dialog: Dialog) {
    dialog.maximize();

    /*
        setTimeout(() => {
      dialog.maximize();
    }, 0);
    */
  }

  executeEndBoxValidate() {
    this.displayModalBoxValidate = false;
    this.closeAssistant();
  }

  executeEndBoxValidateByCamera() {
    this.showReader = false;
    this.closeAssistant();
  }

  containerNotFound() {
    this.currentContainer = null;
    this.errorContainerNotFound = true;
    this.clear();
  }

  reloadContainer() {
    this.scannedDataContainer = this.scannedData.containerId.toString();
    this.loadContainer();
  }

  loadContainer() {
    if (myGlobals.isEmptyOrSpaces(this.scannedDataContainer)) return;

    this.requestCode = this.scannedDataContainer;
    if (!myGlobals.isNumeric(this.scannedDataContainer)) {
      this.containerNotFound();
      return;
    }

    this.scannedData.containerId = Number(this.scannedDataContainer);
    if (this.useCamera) myGlobals.beep();

    this.getContainerInfo();
  }

  getContainerInfo() {
    this.warehouseService
      .getContainerInfo(this.scannedData.containerId)
      .subscribe({
        next: (data: any) => {
          this.currentContainer = data;
          this.errorContainerNotFound = this.currentContainer == null;
          if (this.currentContainer != null) this.showReader = false;
        },
        complete: () => {
          this.clear();
        },
        error: (err: any) => {
          myGlobals.showMessage(err, myGlobals.MessageType.Error);
        },
      });
  }

  clear() {
    this.scannedDataContainer = '';
    this.scannedDataBoxMode = 0;
    this.clearBoxBarcodes();
  }

  clearBoxBarcodes() {
    this.scannedDataBox = '';
    this.scannedData.clientBarcode = '';
    this.scannedData.courierBarcode = '';
    this.scannedDataBoxStatus = 0;
  }

  readNewBox() {
    this.scannedDataBox = '';
    this.scannedData.clientBarcode = '';
    this.scannedData.courierBarcode = '';
    this.scannedDataBoxStatus = 1;
    if (!this.showReader && this.useCamera) this.showReader = true;
    this.setFocusInputBarcode();
  }

  setFocusInputBarcode() {
    if (this.displayModalBoxValidate)
      document.getElementById('inputBarcode').focus();
  }

  setFocusInputContainer() {
    if (!this.useCamera) document.getElementById('inputContainer').focus();
  }

  closeModalError() {
    this.displayModalError = false;
    this.messageModalError = '';
    this.result = new GeneralActionResult();
    this.readNewBox();
  }

  unloadContainer() {
    this.currentContainer = new WareGroupingContainerInfo();
    this.scannedData = new WareGroupingBoxScannedDataInfo();
    this.currentContainer = null;
    this.result = null;
    this.scannedDataContainer = '';
    this.displayModalBoxValidate = false;
    this.displayModalStart = true;
  }

  openAssistantToAddBox() {
    if (this.useCamera) this.showReader = true;
    else this.displayModalBoxValidate = true;
    this.scannedDataBoxMode = 1;
    this.clearBoxBarcodes();
  }

  openAssistantToRemoveBox() {
    if (this.useCamera) this.showReader = true;
    else this.displayModalBoxValidate = true;
    this.scannedDataBoxMode = 2;
    this.clearBoxBarcodes();
  }

  barcodeReaded() {
    if (this.displayModalError) return;
    if (this.checkingBoxLabels) {
      this.scannedDataBox = '';
      return;
    }
    if (myGlobals.isEmptyOrSpaces(this.scannedDataBox)) return;

    if (this.scannedDataBoxStatus == 1) {
      if (this.scannedData.clientBarcode == this.scannedDataBox) {
        this.scannedDataBox = '';
        return;
      }

      this.scannedData.clientBarcode = this.scannedDataBox;
      this.scannedDataBoxStatus = 2;
      this.scannedDataBox = '';

      if (this.useCamera) myGlobals.beep();
    } else if (this.scannedDataBoxStatus == 2) {
      if (this.scannedData.clientBarcode == this.scannedDataBox) {
        this.scannedDataBox = '';
        return;
      }

      if (this.scannedData.courierBarcode == this.scannedDataBox) {
        this.scannedDataBox = '';
        return;
      }

      this.scannedData.courierBarcode = this.scannedDataBox;
      this.scannedDataBoxStatus = 1;
      this.scannedDataBox = '';

      if (this.useCamera) myGlobals.beep();

      if (this.scannedDataBoxMode == 1) this.containerAddBox();
      if (this.scannedDataBoxMode == 2) this.containerRemoveBox();
    }
  }

  containerAddBox() {
    this.checkingBoxLabels = true;
    //    this.showReader = false;
    this.warehouseService.addContainerBox(this.scannedData).subscribe({
      next: (data: any) => {
        this.result = data;
        if (!this.result.success) this.displayModalError = true;
        else this.readNewBox();
      },
      complete: () => {
        this.checkingBoxLabels = false;
      },
      error: (err: any) => {
        this.showContainerBoxException(err);
      },
    });
  }

  showContainerBoxException(err: any) {
    this.result = new GeneralActionResult();
    this.result.success = false;
    this.result.message = myGlobals.getErrorMessage(err);
  }

  containerRemoveBox() {
    this.checkingBoxLabels = true;
    //this.showReader = false;
    this.warehouseService.removeContainerBox(this.scannedData).subscribe({
      next: (data: any) => {
        this.result = data;
        if (!this.result.success) this.displayModalError = true;
        else this.readNewBox();
      },
      complete: () => {
        this.checkingBoxLabels = false;
      },
      error: (err: any) => {
        this.showContainerBoxException(err);
      },
    });
  }

  closeAssistant() {
    this.scannedDataBoxMode = 0;
    this.scannedDataBoxStatus = 0;
    this.result = null;
    this.reloadContainer();
  }

  getDate(value: Date | null): string | null | undefined {
    return this.translate.getDate(value);
  }

  getClients(data: CodeName[]): string {
    let values = data.map((x) => x.name);
    return values.join(', ');
  }

  getCouriers(data: CodeName[]): string {
    let values = data.map((x) => x.name);
    return values.join(', ');
  }

  getContries(data: CodeDescription[]): string {
    let values = data.map((x) => x.description + ' (' + x.code + ')');
    return values.join(', ');
  }

  stringToHtml(data: string): string {
    return myGlobals.stringToHtml(data);
  }

  onReaderCodeResult(code: string) {
    if (this.currentContainer == null) {
      this.scannedDataContainer = code;
      this.loadContainer();
    } else {
      this.scannedDataBox = code;
      this.barcodeReaded();
    }
  }

  onReaderCodeClose() {
    if (this.currentContainer == null) {
      this.gotoInputContainer();
    } else {
      //this.readNewBox();
    }
  }

  onDeviceChange(device: MediaDeviceInfo) {
    myGlobals.spinnerOn(this.loading);
    const selectedStr = device?.deviceId || '';
    if (this.deviceSelected === selectedStr) {
      myGlobals.spinnerOff(this.loading);
      return;
    }
    this.deviceSelected = selectedStr;
    this.currentDevice = device || undefined;
    myGlobals.spinnerOff(this.loading);
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  closeContainer() {
    if (this.currentContainer == null) return;

    this.warehouseService.closeContainer(this.currentContainer.id).subscribe({
      next: () => {
        this.getContainerInfo();
      },
      complete: () => {},
      error: (err: any) => {
        myGlobals.showMessage(err, myGlobals.MessageType.Error);
      },
    });
  }
}

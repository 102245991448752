import { Component, DoCheck, OnInit } from '@angular/core';
import { Roles } from './enums/roles.enum';
import { TokenStorageService } from './services/token-storage.service';
import * as myGlobals from './globals';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, DoCheck {
  title = myGlobals.AppTitle;
  public authenticated: boolean = false;
  public showChangePassword: boolean = false;

  constructor(private token: TokenStorageService) {}

  ngOnInit(): void {
    let user = myGlobals.userGetData();
    this.authenticated = user.authenticated;

    if (myGlobals.userHasRole(Roles.ChangePassword, user))
      this.showChangePassword = true;
  }

  ngDoCheck() {
    let preferences = this.token.getPreferences();
    if (preferences.hideSidebar) $('body').addClass('mini-navbar');
    else $('body').removeClass('mini-navbar');
  }
}

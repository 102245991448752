import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { RoutesService } from '../../../services/routes.service';
import { CountriesService } from '../../../services/countries.service';
import { HubInfo } from '../../../models/hubs.model';
import { Pais } from '../../../models/countries.model';
import { SearchData } from '../../../models/searches.model';
import * as myGlobals from '../../../globals';
import { Select2OptionData } from 'ng-select2';

declare var $: any;

@Component({
  selector: 'app-administracion-hubs',
  templateUrl: './administracion-hubs.component.html',
  styleUrls: ['./administracion-hubs.component.css']
})
export class AdministracionHubsComponent implements OnInit {

  hubDataForm: FormGroup;
  hubDataFormSubmitted: boolean = false;

  public searchData: SearchData = new SearchData();
  public hubsList: HubInfo[];
  public currentHubData: HubInfo = new HubInfo();
  public countriesList: Array<Select2OptionData>;
  public loadingPage: boolean = false;

  public firstHubs = 0;

  public mapLatitude: number = 0;
  public mapLongitude: number = 0;
  public mapZoom: number = 9;

  constructor(private routesService: RoutesService, private countriesService: CountriesService, private formBuilder: FormBuilder) { }

  // convenience getter for easy access to form fields
  get f() { return this.hubDataForm.controls; }

  ngOnInit(): void {

    this.searchData.showDateSearch = false;

    this.hubDataForm = this.formBuilder.group({
      hub_alias: ['', [Validators.required, Validators.maxLength(50)]],
      hub_pais: ['', Validators.required],
      hub_nombre: ['', Validators.required],
      hub_direccion: [''],
      hub_cp: [''],
      hub_ciudad: [''],
      hub_email: ['', Validators.email],
      hub_coordenadas: ['', Validators.required],
      hub_prealert: [false],
      hub_disable: [false]
    });

    this.loadingPage = true;
    this.getCountriesList();
    this.getHubsList();
  }

  saveHubDataForm(): void {
    this.currentHubData.alias = this.f.hub_alias.value;
    this.currentHubData.pais = this.f.hub_pais.value;
    this.currentHubData.nombre = this.f.hub_nombre.value;
    this.currentHubData.direccion = this.f.hub_direccion.value;
    this.currentHubData.cp = this.f.hub_cp.value;
    this.currentHubData.ciudad = this.f.hub_ciudad.value;
    this.currentHubData.email = this.f.hub_email.value;
    this.currentHubData.coordenadas = this.f.hub_coordenadas.value;
    this.currentHubData.prealert = this.f.hub_prealert.value;
    this.currentHubData.disable = this.f.hub_disable.value;
  }

  loadHubDataForm(): void {
    this.hubDataForm.setValue({
      hub_alias: this.currentHubData.alias,
      hub_pais: this.currentHubData.pais,
      hub_nombre: this.currentHubData.nombre,
      hub_direccion: this.currentHubData.direccion,
      hub_cp: this.currentHubData.cp,
      hub_ciudad: this.currentHubData.ciudad,
      hub_email: this.currentHubData.email,
      hub_coordenadas: this.currentHubData.coordenadas,
      hub_prealert: this.currentHubData.prealert,
      hub_disable: this.currentHubData.disable
    });
  }

  getCountriesList() {
    this.countriesService.GetPaises()
      .subscribe((data: any) => {
        this.countriesList = data.body.map((x: Pais) => ({ id: x.codigoalfa2, text: x.pais }));
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getHubsList() {
    this.hubsManagementSpinner();
    this.routesService.HubManagementGetList(this.searchData)
      .subscribe((data: any) => {
        this.hubsList = data.body;
        this.firstHubs = 0;
        this.hubsManagementSpinner();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

    if (this.loadingPage) {
      this.loadingPage = false;
      this.hubsManagementSpinner();
    }
  }

  setHubDisable(idHub: number) {
    this.routesService.HubManagementSetDisable(idHub)
      .subscribe(() => {
        this.getHubsList();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  setHubEnable(idHub: number) {
    this.routesService.HubManagementSetEnable(idHub)
      .subscribe(() => {
        this.getHubsList();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  newHub() {
    this.clearHubData();
    this.editHubWindowToggle();
  }

  editHub(idHub: number) {
    this.clearHubData();
    this.loadHubData(idHub);
    this.editHubWindowToggle();
  }

  saveHubData() {
    this.hubDataFormSubmitted = true;

    // stop here if form is invalid
    if (this.hubDataForm.invalid) {
      return;
    }

    this.saveHubDataForm();
    this.routesService.HubManagementSaveData(this.currentHubData)
      .subscribe((data: any) => {
        this.getHubsList();
        this.editHubWindowToggle();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  clearHubData() {
    this.hubDataFormSubmitted = false;
    this.hubDataForm.reset();
    this.currentHubData = new HubInfo();
    this.loadHubDataForm();
    this.showInMap();
  }

  loadHubData(idHub: number) {
    this.hubsManagementSpinner();
    this.routesService.HubManagementGetData(idHub)
      .subscribe((data: any) => {
        this.currentHubData = data.body;
        this.loadHubDataForm();
        this.showInMap();
        this.hubsManagementSpinner();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
          this.hubsManagementSpinner();
        });
  }

  mapClicked($event: any) {
    let cor = {
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    }
    this.f.hub_coordenadas.setValue(cor.lat + ", " + cor.lng);
    this.showInMap();
  }

  showInMap() {
    this.saveHubDataForm();
    let data = this.currentHubData.coordenadas.split(',', 2);
    if (data.length == 2) {
      this.mapLatitude = Number(data[0].trim());
      this.mapLongitude = Number(data[1].trim());
    }
    else {
      this.mapLatitude = 0;
      this.mapLongitude = 0;
    }
  }

  editHubWindowToggle() {
    $('#editHubWindow').modal('toggle');
  }

  hubsManagementSpinner() {
    $('#hubsManagement').children('.ibox-content').toggleClass('sk-loading');
  }


  exportExcel() {
    myGlobals.exportExcel(this.hubsList);
  }

}

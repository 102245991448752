import { Injectable } from '@angular/core';
import {
  Account,
  UserPreferences,
  SessionInfo,
  AuthenticateResponse,
} from '../models/auth.model';
import * as CryptoJS from 'crypto-js';
import * as myGlobals from '../globals';

const DEFAULT_KEY: string =
  '-a*>3(Oc]q1FsAfG>>:A>WD^d1:yxMTv$dqsaGb!o:#tg6Es*y<hhCcWH(R&h0^kY7Z&r@1&znC}p)!&_?&N*2+P7}B8jDsy^a1jfTTu8/3amr1357?US^4h0*ZP{';
@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor() {}

  getSessionInfo(): SessionInfo {
    let result: SessionInfo = new SessionInfo();

    result.token = this.getToken() ?? '';
    if (!myGlobals.isEmptyOrSpaces(result.token)) {
      let data = this.decryptByToken(
        window.localStorage.getItem(myGlobals.SESSION_KEY_ACCOUNT) ?? ''
      );
      if (!myGlobals.isEmptyOrSpaces(data)) {
        result.account = JSON.parse(data);
        if (result.account.expires != null) {
          let current = new Date(new Date().toUTCString());
          let expire = new Date(result.account.expires);
          if (expire > current) {
            result.authenticated = true;
          }
        }
      }
    }

    return result;
  }

  saveAuthenticateResponse(response: AuthenticateResponse): void {
    this.saveToken(response.token);
    this.saveAccount(response.account);
  }

  signOut(): void {
    window.localStorage.removeItem(myGlobals.SESSION_KEY_TOKEN);
    window.localStorage.removeItem(myGlobals.SESSION_KEY_ACCOUNT);
    window.localStorage.removeItem(myGlobals.SESSION_KEY_REFRESH_TOKEN);
  }

  getToken(): string | null {
    return window.localStorage.getItem(myGlobals.SESSION_KEY_TOKEN);
  }

  savePreferences(preferences: UserPreferences): void {
    window.localStorage.removeItem(myGlobals.SESSION_KEY_USER_PREFERENCES);
    let data = this.encrypt(JSON.stringify(preferences), DEFAULT_KEY);
    if (!myGlobals.isEmptyOrSpaces(data)) {
      window.localStorage.setItem(myGlobals.SESSION_KEY_USER_PREFERENCES, data);
    }
  }

  getPreferences(): UserPreferences {
    let result: UserPreferences = new UserPreferences();
    let data = this.decrypt(
      window.localStorage.getItem(myGlobals.SESSION_KEY_USER_PREFERENCES) ?? '',
      DEFAULT_KEY
    );
    if (!myGlobals.isEmptyOrSpaces(data)) {
      result = JSON.parse(data);
    }
    return result;
  }

  setLanguage(language: string): void {
    let preferences = this.getPreferences();
    preferences.language = language;
    this.savePreferences(preferences);
  }

  getLanguage(): string {
    let preferences = this.getPreferences();
    if (myGlobals.isEmptyOrSpaces(preferences.language)) {
      let lang = window.navigator.language.substring(0, 2).toLowerCase();
      if (myGlobals.isEmptyOrSpaces(lang)) preferences.language = 'es';
      else preferences.language = lang != 'es' ? 'en' : 'es';

      this.savePreferences(preferences);
    }
    return preferences.language;
  }

  refreshTokenOn(): void {
    window.localStorage.setItem(
      myGlobals.SESSION_KEY_REFRESH_TOKEN,
      new Date().toUTCString()
    );
  }

  refreshTokenOff(): void {
    window.localStorage.removeItem(myGlobals.SESSION_KEY_REFRESH_TOKEN);
  }

  refreshTokenStatus(): boolean {
    let result: boolean = false;

    let refresh = window.localStorage.getItem(
      myGlobals.SESSION_KEY_REFRESH_TOKEN
    );
    if (refresh != null) {
      let start = new Date(refresh);
      let minutes = Math.floor(
        (new Date().getTime() - start.getTime()) / 60000
      );
      if (minutes < 1) result = true;
    }

    return result;
  }

  // Metodos privados
  // --------------------------------------------------------------------------------------------------

  private saveToken(token: string): void {
    window.localStorage.removeItem(myGlobals.SESSION_KEY_TOKEN);
    window.localStorage.setItem(myGlobals.SESSION_KEY_TOKEN, token);
  }

  private saveAccount(account: Account): void {
    window.localStorage.removeItem(myGlobals.SESSION_KEY_ACCOUNT);
    let data = this.encryptByToken(JSON.stringify(account));
    if (!myGlobals.isEmptyOrSpaces(data)) {
      window.localStorage.setItem(myGlobals.SESSION_KEY_ACCOUNT, data);
    }

    if (window.matchMedia('(max-width: 768px)').matches) {
      var preferences = this.getPreferences();
      preferences.hideSidebar = false;
      this.savePreferences(preferences);
    }
  }

  private encryptByToken(data: string): string {
    let key: string = (this.getToken() ?? '') + DEFAULT_KEY;
    return this.encrypt(data, key);
  }

  private decryptByToken(data: string): string {
    let key: string = (this.getToken() ?? '') + DEFAULT_KEY;
    return this.decrypt(data, key);
  }

  private encrypt(data: string, key: string): string {
    let result: string = '';
    if (!myGlobals.isEmptyOrSpaces(key)) {
      result = CryptoJS.AES.encrypt(data, key).toString();
    }
    return result;
  }

  private decrypt(data: string, key: string): string {
    let result: string = '';
    if (!myGlobals.isEmptyOrSpaces(key)) {
      result = CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8);
    }
    return result;
  }
}

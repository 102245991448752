import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DocumentArchive } from '../models/documents.model';
import { BrexitShipment } from '../models/brexit.model';
import * as myGlobals from '../globals';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(public http: HttpClient) {}

  public GetDocumentTypes(group: string, subgroup: string) {
    return this.http.get(
      myGlobals.RootUrlTikitingApi +
        'Documents/GetDocumentTypes?group=' +
        group +
        '?subgroup=' +
        subgroup,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  public DownloadDocument(id: number) {
    return this.http.post(
      myGlobals.RootUrlTikitingApi + 'Documents/DownloadDocument',
      id,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        responseType: 'blob' as 'json',
      }
    );
  }

  public SaveDocument(data: DocumentArchive) {
    return this.http.post(
      myGlobals.RootUrlTikitingApi + 'Documents/SaveDocument',
      data,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }
}

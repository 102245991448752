import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as myGlobals from '../globals';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(public http: HttpClient) { }

  public ClientsManagementGetTktClientList() {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'client/ClientsManagementGetTktClientList',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }
  public ClientsManagementGetBrands() {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'client/ClientsManagementGetBrands',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }
  public ClientsManagementGetTktProviderList() {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'client/ClientsManagementGetTktProviderList',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public ClientsManagementGetContaClientList() {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'client/ClientsManagementGetContaClientList',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public ClientsManagementGetContaProviderList() {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'client/ClientsManagementGetContaProviderList',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public ClientsManagementGetCustomsAgentsList() {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'client/ClientsManagementGetCustomsAgentsList',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

}

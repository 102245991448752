<nav class="navbar-default navbar-static-side" role="navigation">
  <div class="sidebar-collapse">
    <ul class="nav metismenu" id="side-menu">

      <li class="nav-header">

        <div class="dropdown profile-element">
          <img alt="image" src="../../../../assets/images/logo.png">
          <a routerLink="/">
            <h2 class="text-capitalize">{{appTitle}}</h2>
          </a>
        </div>

        <div class="logo-element">
          <a routerLink="/">{{appTitleShort}}</a>
        </div>
      </li>

      <li *ngIf="showOperations">
        <a href="#">
          <i class="fa fa-truck"></i>
          <span class="nav-label">{{'page-navigation.operations' | translate}}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" aria-expanded="false">
          <li class="nav-second-level-header">{{'page-navigation.operations-services' | translate}}</li>
          <li><a routerLink="/oper/serv/terrestre">{{'page-navigation.operations-terrestrial' | translate}}</a></li>
          <!--
          <li *ngIf="showDebug"><a routerLink="">{{'page-navigation.operations-aerial' | translate}}</a></li>
          <li *ngIf="showDebug"><a routerLink="">{{'page-navigation.operations-maritime' | translate}}</a></li>
          -->
          <li class="nav-second-level-header">{{'page-navigation.operations-administration' | translate}}</li>
          <li><a routerLink="/oper/admin/routes">{{'page-navigation.operations-routes' | translate}}</a></li>
          <li><a routerLink="/oper/admin/hubs">{{'page-navigation.operations-hubs' | translate}}</a></li>
          <li><a routerLink="/oper/admin/clients">{{'page-navigation.operations-clients' | translate}}</a></li>
        </ul>
      </li>


      <li *ngIf="showTracking">
        <a href="#">
          <i class="fa fa-map-o"></i>
          <span class="nav-label">{{'page-navigation.trackandtrace' | translate}}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" aria-expanded="false">
          <li class="nav-second-level-header">{{'page-navigation.trackandtrace-actions' | translate}}</li>
          <li><a routerLink="/traking/status96">{{'page-navigation.trackandtrace-status96' | translate}}</a></li>
          <li><a routerLink="/traking/getstatuses">{{'page-navigation.trackandtrace-getstatuses' | translate}}</a></li>
          <li class="nav-second-level-header">{{'page-navigation.trackandtrace-administration' | translate}}</li>
          <li><a routerLink="/traking/statusmapping">{{'page-navigation.trackandtrace-statusmapping' | translate}}</a>
          </li>
        </ul>
      </li>

      <li *ngIf="showAduanas">
        <a href="#">
          <i class="fa fa-university"></i>
          <span class="nav-label">{{'page-navigation.customs' | translate}}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" aria-expanded="false">
          <li class="nav-second-level-header">{{'page-navigation.customs-modules' | translate}}</li>
          <li *ngIf="showBrexit">
            <a routerLink="/customs/brexit">{{'page-navigation.customs-brexit' | translate}}</a>
          </li>
          <li> <a routerLink="/customs/documents">{{'page-navigation.customs-documents' | translate}}</a> </li>
          <li class="nav-second-level-header">{{'page-navigation.customs-administration' | translate}}</li>
          <li><a routerLink="/customs/admin/hscodes">{{'page-navigation.customs-hscodes' | translate}}</a></li>
        </ul>
      </li>

      <!--
      <li *ngIf="showBrexit || showBrexitLlorente || showBrexitBrunel">
        <a href="#">
          <i class="fa fa-folder-o"></i>
          <span class="nav-label">{{'page-navigation.brexit' | translate}}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" aria-expanded="false">
          <li *ngIf="showBrexit"><a routerLink="brexit"> {{'page-navigation.brexit-general' | translate}}</a></li>
          <li *ngIf="showBrexitLlorente || showBrexitBrunel" class="nav-second-level-header">
            {{'page-navigation.brexit-providers' | translate}}</li>
          <li *ngIf="showBrexitLlorente"><a routerLink="brexit/llorente">
              {{'page-navigation.brexit-llorente' | translate}}</a></li>
          <li *ngIf="showBrexitBrunel"><a routerLink="brexit/brunel">
              {{'page-navigation.brexit-brunel' | translate}}</a>
          </li>
        </ul>
      </li>


      <li *ngIf="showDebug">
        <a href="#">
          <i class="fa fa-envelope"></i>
          <span class="nav-label">Tracking </span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level">
          <li><a routerLink="tracking/barcodechange">Barcode change</a></li>
          <li class="nav-second-level-header">Status</li>
          <li><a routerLink="tracking/status96">Status 96</a></li>
          <li><a routerLink="tracking/statusmapping">Status Mapping</a></li>
          <li class="nav-second-level-header">Process</li>
          <li><a routerLink="tracking/status96">Manual Tracking</a></li>
          <li><a routerLink="tracking/status96">Using File</a></li>
          <li class="nav separatorx2"></li>
        </ul>
      </li>

      -->

      <li *ngIf="showDebug">
        <a href="#">
          <i class="fa fa-envelope"></i>
          <span class="nav-label">Shipments </span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level">
          <li><a routerLink="files/shipments">Devos Management</a></li>
          <li class="nav separatorx2"></li>
        </ul>
      </li>

      <li *ngIf="showDebug">
        <a href="#">
          <i class="fa fa-envelope"></i>
          <span class="nav-label">Invoices </span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level">
          <li><a routerLink="files/shipments">Invoicing</a></li>
          <li class="nav-second-level-header">Rates</li>
          <li><a routerLink="files/shipments">Rates management</a></li>
          <li><a routerLink="files/shipments">New rate</a></li>
          <li class="nav-second-level-header">Regions</li>
          <li><a routerLink="files/shipments">Region Management</a></li>
          <li class="nav separatorx2"></li>
        </ul>
      </li>

      <li *ngIf="showClients">
        <a href="#">
          <i class="fa fa-address-card-o"></i>
          <span class="nav-label">{{'page-navigation.clients' | translate}} </span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level">
          <li *ngIf="showClientsAmazonInvoices" class="nav-second-level-header">
            {{'page-navigation.clients-amazon' | translate}}</li>
          <li *ngIf="showClientsAmazonInvoices"><a
              routerLink="/clients/amazoninvoices">{{'page-navigation.clients-amazoninvoices' | translate}}</a></li>
          <li class="nav separatorx2"></li>
        </ul>
      </li>


      <li *ngIf="showReporting">
        <a href="#">
          <i class="fa fa-file-text-o"></i>
          <span class="nav-label">{{'page-navigation.reporting' | translate}} </span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" aria-expanded="false">
          <li><a routerLink="/repo/queries">{{'page-navigation.reporting-queries' | translate}}</a></li>
        </ul>
      </li>


      <li *ngIf="showSettings">
        <a href="#">
          <i class="fa fa-cogs"></i>
          <span class="nav-label">{{'page-navigation.settings' | translate}} </span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" aria-expanded="false">
          <li><a routerLink="/users/management">{{'page-navigation.settings-users' | translate}}</a></li>
        </ul>
      </li>


    </ul>

  </div>
</nav>

  <div class="float-right social-action dropdown" title="{{'control-language-selector.tooltip' | translate}}">
    <button data-toggle="dropdown" class="btn-white" aria-expanded="false">
      <i class="fa fa-globe"></i><small class="m-l-xs m-r-xs">{{'control-language-selector.text' | translate}}</small>
      <span class="caret"></span>
    </button>
    <ul class="dropdown-menu dropdown-menu-right">
      <li><a href="javascript:void(0);"
          (click)="setLanguage('es')">{{'control-language-selector.language-es' | translate}}</a></li>
      <li><a href="javascript:void(0);"
          (click)="setLanguage('en')">{{'control-language-selector.language-en' | translate}}</a></li>
    </ul>
  </div>

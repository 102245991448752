export class View {
  id: number;
  name: string;
  description: string;
  idConnection: number;
  idDataSource: number;
  status: number;
}

export class ViewParameter {
  key: string;
  type: string;
  description: string;
  value: string;
  values: string[] = [];
  size: string = '6';
  data: ViewParameterData[] = [];
}

export class ViewUserFilterData {
  columns: ViewExecuteResultColumn[] = [];
}

export class ViewExecuteData {
  idView: number = 0;
  parameters: ViewParameter[] = [];
  userFilterData: ViewUserFilterData = new ViewUserFilterData();
}

export class ViewExecuteResultColumn {
  header: string;
  field: string;
  type: string;
}

export class ViewExecuteResult {
  columns: ViewExecuteResultColumn[] = [];
  rows: any[] = [];
  parameters: ViewParameter[] = [];
}

export class ActionResultData {
  successfull: boolean;
  message: string;
}

export class CodeDescription {
  code: string = '';
  description: string = '';
}

export class ViewParameterData {
  id: string = '';
  text: string = '';
}

export class GetExcelInfo {
  filename: string = '';
  json: string = '';
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servicios-aereos',
  templateUrl: './servicios-aereos.component.html',
  styleUrls: ['./servicios-aereos.component.css']
})
export class ServiciosAereosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { CodeName, CodeDescription } from './general.model';

export class SearchContainerInfo {
  id: number | null = null;
  from: Date | null = null;
  to: Date | null = null;
  status: WareGroupingContainerStatusInfo[] = [];
}

export class WareGroupingContainerStatusInfo {
  code: number = 0;
  description: string = '';
}

export class WareGroupingContainerTypeInfo {
  code: number = 0;
  description: string = '';
}

export class WareGroupingContainerInfo {
  id: number = 0;
  title: string = '';
  type: WareGroupingContainerTypeInfo = new WareGroupingContainerTypeInfo();
  status: WareGroupingContainerStatusInfo =
    new WareGroupingContainerStatusInfo();
  date: Date;
  clients: CodeName[] = [];
  couriers: CodeName[] = [];
  countries: CodeDescription[] = [];
  boxes: number = 0;
}

export class WareGroupingBoxContainerInfo {
  client: string = '';
  courier: string = '';
  country: string = '';
  box: string = '';
}

export class WareGroupingBoxScannedDataInfo {
  containerId: number = 0;
  clientBarcode: string = '';
  courierBarcode: string = '';
}

export class WareGroupingContainerTempleteInfo {
  id: number = 0;
  name: string = '';
  description: string = '';
}

export class GeneralActionResult {
  success: boolean = false;
  message: string = '';
}

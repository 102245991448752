import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgGridModule } from 'ag-grid-angular';
import { AppDirectionsMap } from './directives/app-directions-map.directive';
import * as myGlobals from './globals';
import { DatePipe } from '@angular/common';

// Translation module
// --------------------------------------------------------------------------------------------------
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(http: HttpClient) {
  //return new TranslateHttpLoader(http); // ubicacion por defecto: /assets/i18n/
  return new TranslateHttpLoader(http, myGlobals.AppLanguagePath, '.json');
}

// Scanner
// --------------------------------------------------------------------------------------------------
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { QRCodeModule } from 'angular2-qrcode';

// Plugins: controls
// --------------------------------------------------------------------------------------------------
import { ConfirmationService } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NgSelect2Module } from 'ng-select2';

import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';

import { AuthGuard } from './helpers/auth-guard.service';
import { JwtInterceptor } from './helpers/jwt-interceptor';
import { ErrorInterceptorService } from './helpers/error-interceptor.service';
import { LoginService } from './services/login.service';

import { NgxSummernoteModule } from 'ngx-summernote';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageHeaderComponent } from './components/shared/page-header/page-header.component';
import { PageNavigationComponent } from './components/shared/page-navigation/page-navigation.component';
import { PageRightsidebarComponent } from './components/shared/page-rightsidebar/page-rightsidebar.component';
import { PageFooterComponent } from './components/shared/page-footer/page-footer.component';
import { ServiciosAereosComponent } from './components/modulo-rutas/servicios-aereos/servicios-aereos.component';
import { ServiciosMaritimosComponent } from './components/modulo-rutas/servicios-maritimos/servicios-maritimos.component';
import { AdministracionRutasComponent } from './components/modulo-rutas/administracion-rutas/administracion-rutas.component';
import { AdministracionHubsComponent } from './components/modulo-rutas/administracion-hubs/administracion-hubs.component';
import { AdministracionClientesComponent } from './components/modulo-rutas/administracion-clientes/administracion-clientes.component';
import { PageNotfoundComponent } from './components/shared/page-notfound/page-notfound.component';
import { PageLoginComponent } from './components/shared/page-login/page-login.component';
import { PageHomeComponent } from './components/shared/page-home/page-home.component';
import { PageForbiddenComponent } from './components/shared/page-forbidden/page-forbidden.component';
import { UserInfoComponent } from './components/modulo-usuarios/user-info/user-info.component';
import { UsersManagementComponent } from './components/modulo-usuarios/users-management/users-management.component';
import { AmazonInvoicesComponent } from './components/modulo-clients/amazon-invoices/amazon-invoices.component';
import { PageChangepasswordComponent } from './components/shared/page-changepassword/page-changepassword.component';
import { AdministracionClientesBoxesbypalletComponent } from './components/modulo-rutas/administracion-clientes-boxesbypallet/administracion-clientes-boxesbypallet.component';
import { AdministracionClientesRelationsComponent } from './components/modulo-rutas/administracion-clientes-relations/administracion-clientes-relations.component';
import { AdministracionClientesHubsComponent } from './components/modulo-rutas/administracion-clientes-hubs/administracion-clientes-hubs.component';
import { QueriesListComponent } from './components/modulo-reporting/queries-list/queries-list.component';
import { QueriesExecuteComponent } from './components/modulo-reporting/queries-execute/queries-execute.component';
import { ServiciosTerrestresListComponent } from './components/modulo-rutas/servicios-terrestres-list/servicios-terrestres-list.component';
import { ServiciosTerrestresEditComponent } from './components/modulo-rutas/servicios-terrestres-edit/servicios-terrestres-edit.component';
import { AdministracionHscodesComponent } from './components/modulo-customs/administracion-hscodes/administracion-hscodes.component';
import { BrexitComponent } from './components/modulo-brexit/brexit/brexit.component';
import { BrexitLlorenteComponent } from './components/modulo-brexit/brexit-llorente/brexit-llorente.component';
import { BrexitBrunelComponent } from './components/modulo-brexit/brexit-brunel/brexit-brunel.component';
import { tasksComponent } from './components/modulo-tasks/tasks/tasks.component';
import { TasksLinesComponent } from './components/modulo-tasks/tasklines/tasks-lines/tasks-lines.component';
import { LogsComponent } from './components/modulo-systems/logs/logs.component';
import { DocumentsComponent } from './components/modulo-customs/documents/documents.component';
import { Status96Component } from './components/modulo-tracking/status96/status96.component';
import { StatusmappingComponent } from './components/modulo-tracking/statusmapping/statusmapping.component';
import { GetstatusesmanuallyComponent } from './components/modulo-tracking/getstatusesmanually/getstatusesmanually.component';
import { ControlLanguageSelectorComponent } from './components/shared/control-language-selector/control-language-selector.component';
import { WareHouseContainersComponent } from './components/modulo-warehouse/containers/containers.component';
import { WareHouseBoxescontainerComponent } from './components/modulo-warehouse/boxescontainer/boxescontainer.component';

@NgModule({
  declarations: [
    AppComponent,
    PageHeaderComponent,
    PageNavigationComponent,
    PageRightsidebarComponent,
    PageFooterComponent,
    ServiciosAereosComponent,
    ServiciosMaritimosComponent,
    AdministracionRutasComponent,
    AdministracionHubsComponent,
    AdministracionClientesComponent,
    PageNotfoundComponent,
    PageLoginComponent,
    PageHomeComponent,
    PageForbiddenComponent,
    UserInfoComponent,
    UsersManagementComponent,
    AmazonInvoicesComponent,
    AppDirectionsMap,
    PageChangepasswordComponent,
    AdministracionClientesBoxesbypalletComponent,
    AdministracionClientesRelationsComponent,
    AdministracionClientesHubsComponent,
    QueriesListComponent,
    QueriesExecuteComponent,
    ServiciosTerrestresListComponent,
    ServiciosTerrestresEditComponent,
    AdministracionHscodesComponent,
    BrexitComponent,
    BrexitLlorenteComponent,
    BrexitBrunelComponent,
    tasksComponent,
    TasksLinesComponent,
    LogsComponent,
    DocumentsComponent,
    Status96Component,
    StatusmappingComponent,
    GetstatusesmanuallyComponent,
    ControlLanguageSelectorComponent,
    WareHouseContainersComponent,
    WareHouseBoxescontainerComponent,
  ],
  imports: [
    QRCodeModule,
    ZXingScannerModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MenubarModule,
    MenuModule,
    RippleModule,
    TableModule,
    ToastModule,
    ContextMenuModule,
    TieredMenuModule,
    InputTextModule,
    ButtonModule,
    DialogModule,
    MessagesModule,
    ConfirmDialogModule,
    MultiSelectModule,
    NgSelect2Module,
    NgxSummernoteModule,
    AgGridModule.withComponents([]),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA9Yy45ItQLcJ4XI-iZEvzNEXl07JTiBJw',
      libraries: ['places'],
    }),
    TranslateModule.forRoot({
      defaultLanguage: myGlobals.languageGetDefaultValue(),
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    ConfirmationService,
    LoginService,
    AuthGuard,
    GoogleMapsAPIWrapper,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

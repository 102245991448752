<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{currentView.name}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.reporting' | translate}}
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/repo/queries">{{'page-navigation.reporting-queries' | translate}}</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>{{'page-reporting-queries-execute.title' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p><em>{{currentView.description}}</em></p>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox" id="queriesExecuteManagement">
        <div class="ibox-content sk-loading" id="parametersPanelEdicion">
          <div class="sk-spinner sk-spinner-pulse" style="width: 75px; height: 75px; top: 100px;"></div>
          <div class="row">

            <div class="col-12 ">

              <div class="float-right m-b-lg">
                <div class="btn-group">
                  <button class="btn btn-white btn-sm" type="button" (click)="executeView()"
                    title="{{'page-reporting-queries-execute.executequery' | translate}}"> <i class="fa fa-play"
                      aria-hidden="true"></i></button>
                  <button class="btn btn-white btn-sm" type="button" (click)="exportExcel()"
                    title="{{'general.export-excel' | translate}}"> <i class="fa fa-download"
                      aria-hidden="true"></i></button>

                  <button class="btn btn-white btn-sm" type="button" data-toggle="collapse" data-parent="#panelSearch"
                    href="#panelAdvancedSearch" aria-expanded="false"
                    title="{{'page-reporting-queries-execute.filters-title' | translate}} ({{'general.show-hide' | translate}})">
                    <i class="fa fa-filter" aria-hidden="true"></i>
                  </button>

                </div>
              </div>

              <div id="panelSearch">
                <div id="panelAdvancedSearch" class="panel-collapse collapse show">

                  <div *ngIf="currentViewExecuteData.parameters.length > 0" class="m-b-lg">
                    <h2 class="search-panel-title">{{'page-reporting-queries-execute.filters-title' | translate}}</h2>
                    <small class="font-bold">{{'search.title-description' | translate}}</small>
                    <div class="hr-line-dashed"></div>
                    <div class="row">
                      <div *ngFor="let p of currentViewExecuteData.parameters; let i = index" class="col-md-{{p.size}}">

                        <div *ngIf="isStringParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <input type="text" class="form-control form-control-search" style="width: 100%;"
                              [(ngModel)]="currentViewExecuteData.parameters[i].value" id="parameter_{{i}}">
                          </div>
                          <div class="col-2"></div>
                        </div>

                        <div *ngIf="isLongStringParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <textarea type="text" class="form-control form-control-search" style="width: 100%;"
                              [(ngModel)]="currentViewExecuteData.parameters[i].value" id="parameter_{{i}}"></textarea>
                          </div>
                        </div>

                        <div *ngIf="isBooleanParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <input type="checkbox" class="form-control form-control-search"
                              [(ngModel)]="currentViewExecuteData.parameters[i].value" id="parameter_{{i}}">
                          </div>
                        </div>
                        <div *ngIf="isDateParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <input type="date" class="form-control form-control-search"
                              [(ngModel)]="currentViewExecuteData.parameters[i].value" id="parameter_{{i}}">
                          </div>
                        </div>

                        <div *ngIf="isDateTimeParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <input type="datetime-local" class="form-control form-control-search"
                              [(ngModel)]="currentViewExecuteData.parameters[i].value" id="parameter_{{i}}">
                          </div>
                        </div>

                        <div *ngIf="isIntParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <input type="number" class="form-control form-control-search"
                              [(ngModel)]="currentViewExecuteData.parameters[i].value" id="parameter_{{i}}">
                          </div>
                        </div>

                        <div *ngIf="isBoolParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <input type="checkbox" class="form-control form-control-search"
                              [(ngModel)]="currentViewExecuteData.parameters[i].value" id="parameter_{{i}}">
                          </div>
                        </div>

                        <div *ngIf="isListCountriesParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <ng-select2 [data]="listCountries" width="100%" [dropdownParent]="'parametersPanelEdicion'"
                              [(ngModel)]="currentViewExecuteData.parameters[i].values"
                              [options]="select2MultipleOptions">
                            </ng-select2>
                          </div>
                        </div>

                        <div *ngIf="isListContaProvidersParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <ng-select2 [data]="listContaProviders" width="100%"
                              [dropdownParent]="'parametersPanelEdicion'"
                              [(ngModel)]="currentViewExecuteData.parameters[i].values"
                              [options]="select2MultipleOptions">
                            </ng-select2>
                          </div>
                        </div>

                        <div *ngIf="isListContaClientsParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <ng-select2 [data]="listContaClients" width="100%"
                              [dropdownParent]="'parametersPanelEdicion'"
                              [(ngModel)]="currentViewExecuteData.parameters[i].values"
                              [options]="select2MultipleOptions">
                            </ng-select2>
                          </div>
                        </div>

                        <div *ngIf="isListBrandsParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <ng-select2 [data]="listBrands" width="100%" [dropdownParent]="'parametersPanelEdicion'"
                              [(ngModel)]="currentViewExecuteData.parameters[i].values"
                              [options]="select2MultipleOptions"
                              (valueChanged)="changeValueListBrandsParameter($event)">
                            </ng-select2>
                          </div>
                        </div>

                        <div id="clients" *ngIf="isListTktClientsParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <ng-select2 [data]="listTktClients" width="100%" [dropdownParent]="'parametersPanelEdicion'"
                              [(ngModel)]="currentViewExecuteData.parameters[i].values"
                              [options]="select2MultipleOptions">
                            </ng-select2>
                          </div>
                        </div>

                        <div *ngIf="isListTktCouriersParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <ng-select2 [data]="listTktCouriers" width="100%"
                              [dropdownParent]="'parametersPanelEdicion'"
                              [(ngModel)]="currentViewExecuteData.parameters[i].values"
                              [options]="select2MultipleOptions">
                            </ng-select2>
                          </div>
                        </div>

                        <div *ngIf="isListTikitingStatusParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <ng-select2 [data]="listTikitingStatus" width="100%"
                              [dropdownParent]="'parametersPanelEdicion'"
                              [(ngModel)]="currentViewExecuteData.parameters[i].values"
                              [options]="select2MultipleOptions">
                            </ng-select2>
                          </div>
                        </div>

                        <div *ngIf="isListTipoFechaParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <ng-select2 [data]="listTipoFecha" width="100%" [dropdownParent]="'parametersPanelEdicion'"
                              [(ngModel)]="currentViewExecuteData.parameters[i].value">
                            </ng-select2>
                          </div>
                        </div>

                        <div *ngIf="isListTipoReferenciaParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <ng-select2 [data]="listTipoReferencia" width="100%"
                              [dropdownParent]="'parametersPanelEdicion'"
                              [(ngModel)]="currentViewExecuteData.parameters[i].value">
                            </ng-select2>
                          </div>
                        </div>

                        <div *ngIf="isSelectValueParameter(p.type)" class="row m-t-xs m-b-xs">
                          <div class="col-auto">{{p.description}}:</div>
                          <div class="col">
                            <ng-select2 [data]="getItemsSelectValueParameter(currentViewExecuteData.parameters[i])"
                              width="100%" [dropdownParent]="'parametersPanelEdicion'"
                              [(ngModel)]="currentViewExecuteData.parameters[i].value">
                            </ng-select2>
                          </div>
                        </div>

                      </div>
                    </div>


                  </div>

                </div>
              </div>

            </div>


            <div class="col-12" id="queryResultsPanel">
              <p-table #dt dataKey="id" sortMode="multiple" [columns]="selectedColumns"
                [value]="currentViewExecuteResult.rows" [autoLayout]="true"
                styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" [paginator]="true" [rows]="10"
                [showCurrentPageReport]="true" [(first)]="first"
                currentPageReportTemplate="{{'general.table-paginate-text' | translate}}"
                [rowsPerPageOptions]="[10,25,50,100,200]" [columnResizeMode]="'expand'" [reorderableColumns]="true"
                [resizableColumns]="true" [filterDelay]="0" [globalFilterFields]="globalFilterFields">

                <ng-template pTemplate="caption">

                  <div class="row">
                    <div class="col-9">
                      <p-multiSelect [options]="defaultViewcolumns" [(ngModel)]="selectedColumns" optionLabel="header"
                        selectedItemsLabel="{{'page-reporting-queries-execute.columns-selected' | translate}}"
                        [style]="{minWidth: '100%'}" maxSelectedLabels="0"
                        placeholder="{{'page-reporting-queries-execute.choose-columns' | translate}}...">
                      </p-multiSelect>
                    </div>
                    <div class="col-3">
                      <span class="p-input-icon-left" style="width: 100%;">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                          placeholder="{{'general.search' | translate}}" style="padding-left: 27px !important;" />
                      </span>
                    </div>
                  </div>
                </ng-template>

                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th *ngFor="let col of columns" pSortableColumn="{{col.field}}">
                      {{col.header}} <p-sortIcon field="{{col.field}}"></p-sortIcon>
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr>
                    <td *ngFor="let col of columns">
                      {{getCellValue(rowData,col)}}
                    </td>
                  </tr>
                </ng-template>

              </p-table>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>

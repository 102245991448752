import { AbstractControl, ValidationErrors } from '@angular/forms';

export class CustomValidators {
  /*
  static shouldBe1234(control: AbstractControl) : Promise<ValidationErrors | null> {
    return new Promise((resolve,reject) => {
        if(control.value !== '1234')
          resolve({ shouldBe1234: true });
        else 
          resolve(null);
    });    
  }

    this.formData = fb.group({
      'oldPwd': ['', Validators.required, ChangepasswordValidators.shouldBe1234],
      'newPwd': ['', Validators.required],
      'confirmPwd': ['', Validators.required]
    }, {
      validator: ChangepasswordValidators.matchPwds
    });

  */

  static matchPwds(control: AbstractControl) {
    let newPwd2 = control.get('newPwd');
    let confirmPwd2 = control.get('confirmPwd');
    if (newPwd2.value !== confirmPwd2.value) {
      return { "pwdsDontMatch": true };
    }
    return null;
  }


  // Number only validation
  static numeric(control: AbstractControl) {
    let val = control.value;
    if (val === null || val === '') return null;
    if (!val.toString().match(/^[0-9]+(\.?[0-9]+)?$/)) return { "invalidNumber": true };
    return null;
  }

  static numericPositive(control: AbstractControl) {
    let val = control.value;
    if (val === null || val === '') return null;
    if (!val.toString().match(/^[0-9]+(\.?[0-9]+)?$/)) return { "invalidPositiveNumbers": true };
    if (Number(val) <= 0) return { "invalidPositiveNumbers": true };
    return null;
  }
}
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Roles } from '../../../enums/roles.enum';
import { CountriesService } from '../../../services/countries.service';
import { TerrestrialServiceCheckInfo, TerrestrialService, TerrestrialServiceStops, TerrestrialServiceWare, TerrestrialServiceBoxInfo, TerrestrialServiceChangeStatus, TerrestrialServiceCostInfo, TerrestrialServiceStatus } from '../../../models/terrestrial-services.model';
import { RouteInfo, RouteHub, TipoMercancia, RouteCost, TipoVehiculo } from '../../../models/routes.model';
import { GenericIdName } from '../../../models/general.model';
import { SearchData, CustomFilter } from '../../../models/searches.model';
import { TerrestrialServices } from '../../../services/terrestrial-services.service'
import { RoutesService } from '../../../services/routes.service';
import { ClientInfo, ClientHubsInfo } from '../../../models/clients.model';
import { Provider } from '../../../models/providers.model';
import * as myGlobals from '../../../globals';
import { CustomValidators } from 'src/app/helpers/CustomValidators';
import { SelectItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { getWeekYearWithOptions } from 'date-fns/fp';

declare var $: any;

@Component({
  selector: 'app-servicios-terrestres-edit',
  templateUrl: './servicios-terrestres-edit.component.html',
  styleUrls: ['./servicios-terrestres-edit.component.css'],
  providers: [MessageService]
})
export class ServiciosTerrestresEditComponent implements OnInit {

  currentService: TerrestrialService = new TerrestrialService();
  currentWare: TerrestrialServiceWare = new TerrestrialServiceWare();
  currentRouteId: number = 0;
  wareManual: boolean = true;
  calculateCost: number = 0;
  canDoInvoice: boolean = false;
  canDoFinalize: boolean = false;
  loadPage: boolean = false;
  isAdmin: boolean = false;

  existeExpedicion: boolean = false;
  validandoExpedicion: boolean = false;

  canEdit = {
    service: false,
    route: false,
    ware: false
  }
  /*
    searchRouteFilters = {
      avoidTolls: true,
      avoidHighways: false,
      avoidFerries: false,
    }
  */
  hubs = {
    ware: [],
    route: [],
    notFound: []
  }

  serviceStatus = {
    warePending: false,
    newService: true,
    wareIsOK: true,
    wareReadySave: true,
    routeIsOK: true,
    wareWithoutHub: false
  }

  letras = myGlobals.ArrayLetras;
  routesSearchData: SearchData = new SearchData();
  routesList: RouteInfo[];
  routesCurrent: RouteInfo = new RouteInfo();
  routesFirst = 0;

  serviceDataForm: FormGroup;
  serviceDataFormSubmitted: boolean = false;

  select2Controls = {
    status: null,
    provider: null,
    vehicleType: null,
    wareClient: null,
    wareSource: null,
    wareTarget: null,
    wareTypes: null,
    wareCountry: null,
    hubsFilter: null
  }

  lists = {
    status: [],
    providers: [],
    vehicleTypes: [],
    clients: [],
    clientsConfig: [],
    clientsCountries: [],
    hubsSource: [],
    hubsTarget: [],
    wareTypes: [],
    wareCountries: [],
    clientHubsData: [],
    hubsFilter: []
  }

  constructor(private router: Router, private route: ActivatedRoute, private terrestrialServices: TerrestrialServices,
    private routesService: RoutesService, private formBuilder: FormBuilder, private messageService: MessageService,
    private datePipe: DatePipe, private countriesService: CountriesService) { }

  // convenience getter for easy access to form fields
  get f() { return this.serviceDataForm.controls; }

  newWare() {
    this.currentWare = new TerrestrialServiceWare();
    this.currentWare.id = 0;
    this.loadCurrentWare();
    this.serviceStatus.wareReadySave = true;
  }

  editWare(index: number) {
    this.newWare();
    this.currentWare = this.currentService.ware[index];
    this.currentWare.id = index + 1;
    this.loadCurrentWare();
  }

  getHubsSourceList(lists: any, clientId: number): GenericIdName[] {
    let data: GenericIdName[] = [];

    lists.clientHubsData
      .filter(x => x.clientId == (clientId))
      .forEach(e => {
        let r = data.filter(x => x.id == e.hubSourceId);
        if (r.length == 0) data.push(new GenericIdName(e.hubSourceId, e.hubSourceName));
      });

    return data.sort((x, y) => x.name > y.name ? 1 : -1);
  }

  getHubsTargetList(lists: any, clientId: number, sourceId: number): GenericIdName[] {
    let data: GenericIdName[] = [];

    lists.clientHubsData
      .filter(x => x.clientId == clientId && x.hubSourceId == sourceId)
      .forEach(e => {
        let r = data.filter(x => x.id == e.hubTargetId);
        if (r.length == 0) data.push(new GenericIdName(e.hubTargetId, e.hubTargetName));
      });

    return data.sort((x, y) => x.name > y.name ? 1 : -1);
  }

  getWareTypeList(lists: any, clientId: number, sourceId: number, targetId: number): GenericIdName[] {
    let data: GenericIdName[] = [];

    lists.clientHubsData.filter(x => x.clientId == clientId && x.hubSourceId == sourceId && x.hubTargetId == targetId)
      .forEach(e => {
        let r = data.filter(x => x.id == e.typeServiceId);
        if (r.length == 0) data.push(new GenericIdName(e.wareTypeId, e.wareTypeName));
      })

    return data.sort((x, y) => x.name > y.name ? 1 : -1);
  }


  validateExpedicion() {
    this.existeExpedicion = false;
    if (!myGlobals.isEmptyOrSpaces(this.f.service_expeditionnumber.value)) {
      this.validandoExpedicion = true;
      this.terrestrialServices.ExistTerrestrialServiceExpedition(this.f.service_expeditionnumber.value)
        .subscribe((exist: any) => {
          this.existeExpedicion = exist;
          this.validandoExpedicion = false;
        }
          , (error: string) => {
            myGlobals.showMessage(error, myGlobals.MessageType.Error);
            this.validandoExpedicion = false;
          });
    }
  }

  loadCurrentWare() {
    this.f.ware_client.setValue(this.currentWare.clientId);
    this.f.ware_source.setValue(this.currentWare.hubSourceId);
    this.f.ware_target.setValue(this.currentWare.hubTargetId);
    this.f.ware_types.setValue(this.currentWare.typeServiceId);
    this.f.ware_boxes.setValue(this.currentWare.boxes);
    this.f.ware_country.setValue(this.currentWare.clientCountryId);

    this.select2Controls.wareClient.val(this.currentWare.clientId).trigger('change');
    this.lists.hubsSource = this.getHubsSourceList(this.lists, this.currentWare.clientId);
    this.select2Controls.wareSource.val(this.currentWare.hubSourceId).trigger('change');
    this.lists.hubsTarget = this.getHubsTargetList(this.lists, this.currentWare.clientId, this.currentWare.hubSourceId);
    this.select2Controls.wareTarget.val(this.currentWare.hubTargetId).trigger('change');
    this.lists.wareTypes = this.getWareTypeList(this.lists, this.currentWare.clientId, this.currentWare.hubSourceId, this.currentWare.hubTargetId);
    this.select2Controls.wareTypes.val(this.currentWare.typeServiceId).trigger('change');
    this.select2Controls.wareCountry.val(this.currentWare.clientCountryId).trigger('change');

    this.select2Controls.wareClient.focus();
  }

  saveCurrentWare() {
    this.f.ware_client.setValue(this.select2Controls.wareClient.val());
    this.f.ware_source.setValue(this.select2Controls.wareSource.val());
    this.f.ware_target.setValue(this.select2Controls.wareTarget.val());
    this.f.ware_types.setValue(this.select2Controls.wareTypes.val());
    this.f.ware_country.setValue(this.select2Controls.wareCountry.val());

    let data = new TerrestrialServiceWare();
    data.clientId = this.f.ware_client.value;
    data.clientName = this.select2Controls.wareClient.find(':selected').text();
    data.hubSourceId = this.f.ware_source.value;
    data.hubSourceName = this.select2Controls.wareSource.find(':selected').text();
    data.hubTargetId = this.f.ware_target.value;
    data.hubTargetName = this.select2Controls.wareTarget.find(':selected').text();
    data.typeServiceId = this.f.ware_types.value;
    data.typeServiceName = this.select2Controls.wareTypes.find(':selected').text();
    data.boxes = this.f.ware_boxes.value;
    data.clientCountryId = this.f.ware_country.value;
    data.clientCountryName = this.select2Controls.wareCountry.find(':selected').text();

    if (data.clientId > 0
      && data.hubSourceId > 0
      && data.hubTargetId > 0
      && data.typeServiceId > 0
      && data.boxes > 0
      && !myGlobals.isEmptyOrSpaces(data.clientCountryId)) {

      if (this.currentWare.id == 0) {
        this.currentService.ware.push(data);
        this.validateWareList();
      }
      else {
        this.currentService.ware[this.currentWare.id - 1] = data;
      }
      this.newWare();
      this.showRouteInfo();

    }
    else {
      this.serviceStatus.wareReadySave = false;
    }

    if (this.currentService.rutaId > 0) this.showRouteInfo();
  }

  cancelCurrentWare() {
    this.newWare();
    //this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Revise los datos introducidos.' });
  }

  deleteWare(index: number) {
    this.currentService.ware.splice(index, 1);
    this.showRouteInfo();
  }

  ngOnInit(): void {

    let user = myGlobals.userGetData();
    if (myGlobals.userHasRoles([Roles.Admin, Roles.OperacionesAdmin], user)) this.isAdmin = true;

    this.routesSearchData.deleted = false;
    this.routesSearchData.disabled = false;
    this.routesSearchData.enabled = true;
    this.serviceDataForm = this.formBuilder.group({
      service_status: ['1', Validators.required],
      service_expeditionnumber: ['', [Validators.required, Validators.maxLength(50)]],
      service_expeditiondate: ['', Validators.required],
      service_provider: ['', [Validators.required, CustomValidators.numericPositive]],
      service_vehicletype: ['', [Validators.required, CustomValidators.numericPositive]],
      service_vehiclelicense: ['', Validators.required],
      service_observations: [''],
      service_costtotal: ['', [Validators.required, CustomValidators.numericPositive]],
      service_costtodiscount: [''],
      ware_client: [''],
      ware_source: [''],
      ware_target: [''],
      ware_boxes: [''],
      ware_types: [''],
      ware_country: ['']
    });

    //this.select2Controls.status = $('#service_status').select2();
    this.select2Controls.provider = $('#service_provider').select2();
    this.select2Controls.vehicleType = $('#service_vehicletype').select2();
    this.select2Controls.hubsFilter = $('#select2HubsFilter').select2({
      dropdownParent: $('#panelSelectRoute')
    });


    this.currentRouteId = Number(this.route.snapshot.paramMap.get('id'));

    this.getProvidersData();
    this.getClientHubsData();
    this.getClientsConfig();
    this.getClientsCountries();
    this.getCountriesList();

    //this.getWareTypesData();
    this.getTypeVehicleList();
    this.getStatusList();
    this.getHubsFilterData();
    this.loadService();
  }

  validateWareList() {
    this.serviceStatus.wareIsOK = this.currentService.ware.length > 0;
  }

  validateSelectRoute() {
    this.serviceStatus.routeIsOK = this.currentService.rutaId > 0;
  }

  saveServiceDataForm(): void {
    //this.f.service_status.setValue(this.select2Controls.status.val());
    this.f.service_provider.setValue(this.select2Controls.provider.val());
    this.f.service_vehicletype.setValue(this.select2Controls.vehicleType.val());

    //this.currentService.estadoId = this.f.service_status.value;
    //this.currentService.estadoName = this.select2Controls.status.find(':selected').text();
    this.currentService.expedicionNumero = this.f.service_expeditionnumber.value;
    this.currentService.expedicionFecha = this.f.service_expeditiondate.value;
    this.currentService.proveedorId = this.f.service_provider.value ?? 0;
    this.currentService.proveedorName = this.select2Controls.provider.find(':selected').text();
    this.currentService.tipoVehiculoId = this.f.service_vehicletype.value ?? 0;
    this.currentService.tipoVehiculoName = this.select2Controls.vehicleType.find(':selected').text();
    this.currentService.matricula = this.f.service_vehiclelicense.value;
    this.currentService.descripcion = this.f.service_observations.value;
    this.currentService.costeADescontar = this.f.service_costtodiscount.value;
    this.currentService.costeTotal = this.f.service_costtotal.value;
  }

  invoiceService(): void {
    this.terrestrialServices.InvoiceTerrestrialService(this.currentService.id)
      .subscribe(() => {
        this.reloadPage(this.currentService.id);
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  expeditionInvoiced(): boolean {
    let exits: boolean = false;

    this.terrestrialServices.ExpeditionInvoicedTerrestrialService(this.f.service_expeditionnumber.value)
      .subscribe(() => {
        exits = true;
        myGlobals.showMessage("The expedition you indicate is already invoiced", myGlobals.MessageType.Warning);
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

    return exits;
  }


  finalizeService(): void {
    this.currentService.estadoId = 3;
    this.saveServiceData();
  }



  changeStatus(status: any): void {
    let data = new TerrestrialServiceChangeStatus();
    data.idService = this.currentService.id;
    data.idStatus = status.id;

    this.terrestrialServices.ChangeStatusTerrestrialService(data)
      .subscribe(() => {
        this.reloadPage(this.currentService.id);
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }


  loadServiceDataForm(): void {
    this.serviceDataForm.setValue({
      service_status: this.currentService.estadoId,
      service_expeditionnumber: this.currentService.expedicionNumero,
      service_expeditiondate: this.datePipe.transform(this.currentService.expedicionFecha, "yyyy-MM-dd"),
      service_provider: this.currentService.proveedorId,
      service_vehicletype: this.currentService.tipoVehiculoId,
      service_vehiclelicense: this.currentService.matricula,
      service_observations: this.currentService.descripcion,
      service_costtotal: this.currentService.costeTotal,
      service_costtodiscount: this.currentService.costeADescontar,
      ware_client: '',
      ware_source: '',
      ware_target: '',
      ware_boxes: '',
      ware_types: '',
      ware_country: ''
    });

    this.select2Controls.provider.val(this.currentService.proveedorId).trigger("change");
    this.select2Controls.vehicleType.val(this.currentService.tipoVehiculoId).trigger("change");
  }


  checkCanDoInvoice() {
    this.canDoInvoice = this.currentService.estadoId == 3 && this.currentService.rutaId > 0;
  }

  checkCanDoFinalize() {
    this.canDoFinalize = this.currentService.estadoId > 0 && this.currentService.estadoId < 3 && this.currentService.rutaId > 0;
  }

  reloadPage(id: number) {
    if (this.currentService.id != id) {
      this.router.navigateByUrl('/oper/serv/terrestre/' + id);
      this.loadService();
    }
    else this.loadService();
  }

  saveServiceData() {
    if (this.validandoExpedicion) {
      myGlobals.showMessage("Ongoing validation tasks", myGlobals.MessageType.Information);
    }
    else {
      if (this.existeExpedicion) {
        myGlobals.showMessage("The expedition is already in use", myGlobals.MessageType.Information);
      }
      else {
        this.saveService();
      }
      /*
            let data = new TerrestrialServiceCheckInfo();
            data.expedition = this.f.service_expeditionnumber.value;
      
            this.terrestrialServices.ExpeditionInvoicedTerrestrialService(data)
              .subscribe((data: any) => {
      
                if (data.body == true) {
                  myGlobals.showMessage("The expedition you indicate is already invoiced", myGlobals.MessageType.Warning);
                }
                else {
                  this.saveService();
                }
              }
                , (error: string) => {
                  myGlobals.showMessage(error, myGlobals.MessageType.Error);
                });
                */
    }
  }

  saveService() {
    this.validateWareList();
    this.validateSelectRoute();
    this.saveServiceDataForm();

    this.serviceStatus.warePending = false;
    if (this.currentService.stops.length > 1) {
      this.serviceStatus.warePending = this.currentService.stops[this.currentService.stops.length - 1].boxInfo.length > 0;
    }

    this.serviceDataFormSubmitted = true;

    // stop here if form is invalid
    if (this.serviceDataForm.invalid || !this.serviceStatus.wareIsOK || !this.serviceStatus.routeIsOK || this.serviceStatus.warePending) {
      if (this.currentService.estadoId > 0) return;
    }

    this.showRouteInfo();
    this.terrestrialServices.SaveTerrestrialService(this.currentService)
      .subscribe((id: number) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Cambios aplicados.' });
        this.checkCanDoInvoice();
        this.checkCanDoFinalize();
        this.currentRouteId = id;
        this.reloadPage(id);
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }


  getHubsFilterData() {
    this.terrestrialServices.GetHubsFilterList()
      .subscribe((data: any) => {
        this.lists.hubsFilter = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }


  getProvidersData() {
    this.terrestrialServices.GetProvidersList()
      .subscribe((data: any) => {
        this.lists.providers = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getClientHubsData() {
    let data = new SearchData();
    data.enabled = true;
    data.disabled = false;
    data.deleted = false;
    this.routesService.ClientsManagementHubsDataList(data)
      .subscribe((data: any) => {
        this.lists.clientHubsData = data.body;
        this.loadClientsList();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getCountriesList() {
    this.countriesService.GetPaises()
      .subscribe((data: any) => {
        this.lists.wareCountries = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }


  getClientsConfig() {

    let data = new SearchData();
    data.deleted = false;
    data.disabled = false;

    this.routesService.ClientsManagementGetConfigList(data)
      .subscribe((data: any) => {
        this.lists.clientsConfig = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

  }

  getClientsCountries() {
    let data = new SearchData();
    data.deleted = false;
    data.disabled = false;

    this.routesService.ClientsManagementGetConfigList(data)
      .subscribe((data: any) => {
        this.lists.clientsCountries = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  /*
  getWareTypesData() {
    this.routesService.RouteManagementGetWareTypeList()
      .subscribe((data: any) => {
        this.lists.wareTypes = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }
  */

  getTypeVehicleList(): any {
    this.routesService.GetTiposVehiculos()
      .subscribe((data: any) => {
        this.lists.vehicleTypes = data.body;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getStatusList() {
    this.terrestrialServices.GetStatusList()
      .subscribe((data: any) => {
        this.lists.status = data.body.sort((x, y) => x.id > y.id ? 1 : -1);
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  setStatus(status: any) {
    this.currentService.estadoId = status.id;
    this.currentService.estadoName = status.description;
    this.f.service_status.setValue(status.id);
    this.canEdit.route = status.id < 2;
    this.canEdit.service = status.id < 4;

    if (!this.wareManual) this.canEdit.ware = false;
    else this.canEdit.ware = status.id < 2;
  }


  loadClientsList() {
    let list: GenericIdName[] = [];

    this.lists.clientHubsData.forEach(e => {
      let r = list.filter(x => x.id == e.clientId);
      if (r.length == 0) list.push(new GenericIdName(e.clientId, e.clientName));
    });

    this.lists.clients = list.sort((x, y) => x.name > y.name ? 1 : -1);
  }

  loadService() {
    this.terrestrialServices.GetTerrestrialService(this.currentRouteId)
      .subscribe((data: any) => {
        this.currentService = data.body;
        this.serviceStatus.newService = false;
        this.loadServiceDataForm();

        let status = new TerrestrialServiceStatus();
        status.id = this.currentService.estadoId;
        status.description = this.currentService.estadoName;

        this.loadPage = true;
        this.setStatus(status);
        this.selectRoute(this.currentService.rutaId, false);
        this.checkCanDoInvoice();
        this.checkCanDoFinalize();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  setSelect2Controls(controls: any, lists: any, route: RouteInfo) {
    controls.wareTypes = $('#ware_types').select2();
    controls.wareCountry = $('#ware_country').select2();

    lists.hubsSource = [];
    lists.hubsTarget = [];
    lists.wareTypes = [];

    controls.wareClient = $('#ware_client').select2().on('select2:select', function (e) {
      let list: GenericIdName[] = [];

      lists.clientHubsData
        .filter(x => x.clientId == (e.params.data.id))
        .forEach(e => {
          let r = list.filter(x => x.id == e.hubSourceId);
          if (r.length == 0) list.push(new GenericIdName(e.hubSourceId, e.hubSourceName));
        });

      if (route.hubs.length > 0) {
        lists.hubsSource = [];
        list.forEach(e => {
          if (route.hubs.filter(x => x.id == e.id).length > 0) lists.hubsSource.push(e);
        });
        lists.hubsSource = lists.hubsSource.sort((x, y) => x.name > y.name ? 1 : -1);
      } else {
        lists.hubsSource = list.sort((x, y) => x.name > y.name ? 1 : -1);
      }

      lists.hubsTarget = [];
      lists.wareTypes = [];
      controls.wareSource.val("").trigger("change");
      controls.wareTarget.val("").trigger("change");
      controls.wareTypes.val("").trigger("change");
    });

    controls.wareSource = $('#ware_source').select2().on('select2:select', function (e) {
      let list: GenericIdName[] = [];

      lists.clientHubsData
        .filter(x => x.clientId == controls.wareClient.val() && x.hubSourceId == e.params.data.id)
        .forEach(e => {
          let r = list.filter(x => x.id == e.hubTargetId);
          if (r.length == 0) list.push(new GenericIdName(e.hubTargetId, e.hubTargetName));
        });

      if (route.hubs.length > 0) {
        lists.hubsTarget = [];
        list.forEach(e => {
          if (route.hubs.filter(x => x.id == e.id).length > 0) lists.hubsTarget.push(e);
        });
        lists.hubsTarget = lists.hubsTarget.sort((x, y) => x.name > y.name ? 1 : -1);
      } else {
        lists.hubsTarget = list.sort((x, y) => x.name > y.name ? 1 : -1);
      }

      lists.wareTypes = [];
      controls.wareTarget.val("").trigger("change");
      controls.wareTypes.val("").trigger("change");
    });

    controls.wareTarget = $('#ware_target').select2().on('select2:select', function (e) {
      let list: GenericIdName[] = [];

      lists.clientHubsData.filter(x => x.clientId == controls.wareClient.val()
        && x.hubSourceId == controls.wareSource.val() && x.hubTargetId == e.params.data.id)
        .forEach(e => {
          let r = list.filter(x => x.id == e.typeServiceId);
          if (r.length == 0) list.push(new GenericIdName(e.wareTypeId, e.wareTypeName));
        })

      lists.wareTypes = list.sort((x, y) => x.name > y.name ? 1 : -1);
      if (list.length > 0) controls.wareTypes.val(list[0].id).trigger("change");
    });
  }

  getRoutesList() {
    this.routesSearchData.customFilters = [];
    this.routesSearchData.customFilters.push(new CustomFilter("hublist", this.select2Controls.hubsFilter.val()));
    /*
    this.routesSearchData.customFilters.push(new CustomFilter("avoidtolls", this.searchRouteFilters.avoidTolls));
    this.routesSearchData.customFilters.push(new CustomFilter("avoidhighways", this.searchRouteFilters.avoidHighways));
    this.routesSearchData.customFilters.push(new CustomFilter("avoidferries", this.searchRouteFilters.avoidFerries));
*/

    this.routesService.RouteManagementGetList(this.routesSearchData)
      .subscribe((data: any) => {
        this.routesList = data.body;
        this.routesFirst = 0;
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }


  reloadRoute() {
    this.selectRoute(this.currentService.rutaId, false);
  }

  selectRoute(idRoute: number, fromSearch: boolean) {
    this.routesService.RouteManagementGetData(idRoute)
      .subscribe((data: any) => {
        this.routesCurrent = data.body;
        this.currentService.rutaId = this.routesCurrent.id;
        this.currentService.rutaName = this.routesCurrent.name;
        this.getDataFromRoute();
        setTimeout(this.setSelect2Controls, 100, this.select2Controls, this.lists, this.routesCurrent);
        this.validateSelectRoute();

        if (!this.loadPage) this.showRouteInfo();
        else this.loadPage = false;

        if (fromSearch) this.routeSelectWindowToggle();

      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }


  getDataFromRoute() {
    let idProveedor = this.select2Controls.provider.val();
    let idVehiculo = this.select2Controls.vehicleType.val();

    if ((this.f.service_costtotal.value == 0 || this.f.service_costtotal.value == null) && idProveedor > 0) {
      let data = this.routesCurrent.costs.filter(x => x.proveedorId == idProveedor && x.vehiculoId == idVehiculo);
      if (data.length > 0) {
        this.f.service_costtotal.setValue(data[0].coste);
      }
    }
    if (this.f.service_costtotal.value == null) this.f.service_costtotal.setValue(0);

    this.currentService.totalKm = this.routesCurrent.totalKm;
    this.currentService.totalHours = this.routesCurrent.totalHours;
  }


  clearRoute() {
    this.selectRoute(0, false);
  }


  checkRouteHubs() {
    this.hubs.ware = [];
    this.hubs.route = [];
    this.hubs.notFound = [];

    this.currentService.ware
      .forEach(e => {
        let r = this.hubs.ware.filter(x => x.id == e.hubSourceId);
        if (r.length == 0) this.hubs.ware.push(new GenericIdName(e.hubSourceId, e.hubSourceName));
      });

    this.currentService.ware
      .forEach(e => {
        let r = this.hubs.ware.filter(x => x.id == e.hubTargetId);
        if (r.length == 0) this.hubs.ware.push(new GenericIdName(e.hubTargetId, e.hubTargetName));
      });

    this.routesCurrent.hubs
      .forEach(e => {
        let r = this.hubs.route.filter(x => x.id == e.id);
        if (r.length == 0) this.hubs.route.push(new GenericIdName(e.id, e.name));
      });

    this.hubs.ware.forEach(hub => {
      let h = this.hubs.route.filter(x => x.id == hub.id);
      if (h.length == 0) {
        let r = this.hubs.notFound.filter(x => x.id == hub.id);
        if (r.length == 0) this.hubs.notFound.push(new GenericIdName(hub.id, hub.name));
      }
    });

  }

  getStopBackground(i: number): string {
    let result = "";
    let s = this.currentService.stops[i];
    let hubs = this.currentService.ware.filter(x => x.hubTargetId == s.hubId || x.hubSourceId == s.hubId);

    if (hubs.length > 0 && s.km > 0 && s.boxInfo.length > 0) result = "bg-success";
    else result = "bg-muted";

    if (i == this.currentService.stops.length - 1
      && s.boxInfo.length > 0) result = "bg-danger";

    if (i == this.currentService.stops.length - 1
      && s.boxInfo.length == 0) result = "bg-primary";

    return result;
  }

  getStopTextColor(i: number): string {
    let result = "";
    let s = this.currentService.stops[i];
    let hubs = this.currentService.ware.filter(x => x.hubTargetId == s.hubId || x.hubSourceId == s.hubId);

    if (hubs.length > 0 && s.km > 0 && s.boxInfo.length > 0) result = "text-success";
    else result = "text-muted";

    if (i == this.currentService.stops.length - 1
      && s.boxInfo.length > 0) result = "text-danger";

    if (i == this.currentService.stops.length - 1
      && s.boxInfo.length == 0) result = "text-primary-color";

    return result;
  }

  showRouteInfo() {
    let coste = this.f.service_costtotal.value - this.f.service_costtodiscount.value;
    if (this.currentService.totalKm > 0)
      this.currentService.costPerKilometer = coste / this.currentService.totalKm;
    else this.currentService.costPerKilometer = 0;

    // Cargamos las paradas.
    this.calculateCost = 0;
    let stops: TerrestrialServiceStops[] = [];
    for (let y = 0; y < this.routesCurrent.hubs.length; y++) {
      let hub = this.routesCurrent.hubs[y];
      let item = new TerrestrialServiceStops();
      item.hubId = hub.id;
      item.hubName = "(" + hub.country + ") " + hub.alias;
      item.country = hub.country;

      if (y != this.routesCurrent.hubs.length - 1) {
        let h = this.routesCurrent.hubs[y + 1];
        item.km = h.km
        item.hours = h.hours
        if (this.currentService.totalKm > 0 && item.km > 0 && coste > 0) {
          item.partialCost = coste * item.km / this.currentService.totalKm;
          this.calculateCost = this.calculateCost + item.partialCost;
        }
      }

      stops.push(item);
    }
    this.currentService.stops = stops;

    // Validamos y la ruta tiene las paradas necesarias.
    this.checkRouteHubs();

    this.currentService.totalPallets = 0;

    // Repartimos la mercancía entre las paradas.
    for (let ii = 0; ii < this.currentService.ware.length; ii++) {
      let ware = this.currentService.ware[ii];
      ware.hubWareIn = -1;
      ware.hubWareOut = -1

      for (let i = 0; i < this.currentService.stops.length; i++) {
        let stop = this.currentService.stops[i];
        if (ware.hubSourceId == stop.hubId) ware.hubWareIn = i;
        if (ware.hubTargetId == stop.hubId && ware.hubWareIn != -1) {
          ware.hubWareOut = i;
          break;
        }
      }

      if (ware.hubWareIn != -1) {
        for (let i = ware.hubWareIn; i < this.currentService.stops.length; i++) {
          if (ware.hubWareOut != -1 && ware.hubWareOut == i) break;
          let stop = this.currentService.stops[i];
          let info = new TerrestrialServiceBoxInfo();
          info.boxes = ware.boxes;
          info.clientId = ware.clientId;
          info.clientName = ware.clientName;
          info.clientCountryId = ware.clientCountryId;
          info.pallets = 0;
          info.typeServiceId = ware.typeServiceId;

          let boxPerPallet = this.lists.clientsConfig.filter(x => x.clientId == ware.clientId).map(x => x.boxForPallet);
          if (boxPerPallet.length == 1 && ware.boxes > 0) {
            info.pallets = ware.boxes / boxPerPallet[0];
          }

          stop.boxInfo.push(info);

          this.currentService.totalPallets = this.currentService.totalPallets + info.pallets;
        }
      }
    }

    this.serviceStatus.wareWithoutHub = false;
    if (stops.length > 0) {
      if (this.currentService.ware
        .filter(x => x.hubWareIn == -1 || x.hubWareOut == -1).length > 0) this.serviceStatus.wareWithoutHub = true;
    }

    // Calcular coste por caja
    this.currentService.stops.forEach(s => {
      s.partialBoxes = 0;
      s.boxInfo.forEach(i => {
        s.partialBoxes = s.partialBoxes + i.boxes;
      });
      s.costPerBox = 0;
      if (s.partialCost > 0 && s.partialBoxes > 0) {
        s.costPerBox = s.partialCost / s.partialBoxes;
        s.boxInfo.forEach(i => {
          i.costPerBox = s.partialCost / i.boxes;
        });
      }
    });

  }


  openSelectRouteWindow() {
    let items: number[] = this.currentService.ware.map(x => (x.hubSourceId)).concat(this.currentService.ware.map(x => (x.hubTargetId)));
    if (items.length > 0) this.select2Controls.hubsFilter.val(items).trigger('change');

    this.getRoutesList();
    this.routeSelectWindowToggle();
  }


  routeSelectWindowToggle() {
    $('#routeSelectWindow').modal('toggle');
  }

  getRouteListName(name: string, description: string): string {
    if (myGlobals.isEmptyOrSpaces(name)) {
      return description;
    } else {
      return name;
    }
  }

  exportExcelWare() {
    myGlobals.exportExcel(this.currentService.ware.map(x => ({
      "Client": x.clientName,
      "Source": x.hubSourceName,
      "Traget": x.hubTargetName,
      "Country": x.clientCountryName,
      "Type": x.typeServiceName,
      "Boxes": x.boxes
    })));
  }

  exportExcelCosts() {
    let costs: TerrestrialServiceCostInfo[] = [];

    if (this.currentService.costs.length > 0) costs = this.currentService.costs;
    if (this.currentService.costsEstimated.costs.length > 0) costs = this.currentService.costsEstimated.costs;

    if (costs.length > 0) {
      myGlobals.exportExcel(costs.map(x => ({
        "HubSource": x.hubSouceName,
        "HubTarget": x.hubTargetName,
        "wareClient": x.wareClient,
        "boxes": x.boxes,
        "Client": x.clientName,
        "Service": x.serviceCode,
        "Cost": x.costId,
        "Amount": x.costValue,
        "Pallets": x.pallets,
        "Date": this.datePipe.transform(x.costDate, "dd/MM/yyyy"),
        "Invoice": x.serviceInvoice
      })));
    }
  }

}

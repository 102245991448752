import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { ErrorInfo } from '../models/general.model';
import { TokenStorageService } from '../services/token-storage.service';

export class Translate {
  constructor(
    private translate: TranslateService | null,
    private datePipe: DatePipe | null
  ) {}

  existsCurrentLanguage(): boolean {
    let result: boolean = true;
    if (this.translate?.currentLang == undefined) {
      result = false;
      this.translate?.use(new TokenStorageService().getLanguage());
    }
    return result;
  }

  getError(error: ErrorInfo): ErrorInfo {
    this.existsCurrentLanguage();
    let key = 'error-codes.' + error.code;
    let message: string = this.translate?.instant(key) ?? error.description;
    if (message !== key) error.description = message;
    return error;
  }

  getTrackingStatus(code: string, description: string): string {
    this.existsCurrentLanguage();
    let key = 'tracking-status.' + code;
    let message: string = this.translate?.instant(key) ?? description;
    if (message !== key) description = message;
    return description;
  }

  getValue(key: string): string {
    this.existsCurrentLanguage();
    return this.translate?.instant(key) ?? key;
  }

  getDate(value: Date | null): string | null | undefined {
    this.existsCurrentLanguage();
    let keyDate = 'general.format-date';
    let formatDate: string = this.translate?.instant(keyDate) ?? keyDate;
    if (formatDate == keyDate) formatDate = 'MM/dd/yyyy';

    let keyDateTime = 'general.format-datetime';
    let formatDateTime: string =
      this.translate?.instant(keyDateTime) ?? keyDateTime;
    if (formatDateTime == keyDateTime) formatDateTime = 'MM/dd/yyyy HH:mm:ss';

    if (value != null) {
      let date = new Date(value);
      let time =
        date.getSeconds() + date.getMinutes() * 60 + date.getHours() * 60 * 60;

      if (time > 0) {
        return (
          this.datePipe?.transform(date, formatDateTime) ?? date.toString()
        );
      } else {
        return this.datePipe?.transform(date, formatDate) ?? date.toString();
      }
    } else {
      return null;
    }
  }
}

import { Directive, Input, OnChanges, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { GoogleMapsAPIWrapper } from '@agm/core';
import * as myGlobals from '../globals';

// just an interface for type safety.
export class Marker {
    lat: number;
    lng: number;
    label?: string;
    draggable: boolean;
}


export class Waypoint extends Object {
    location: Location;
    constructor(location: Location) {
        super();
        this.location = location;
    }
}

export class Location extends Object {
    lat: number;
    lng: number;
    constructor(lat: number, lng: number) {
        super();
        this.lat = lat;
        this.lng = lng;
    }
}

export class DirectionsMapStatusData {
    load: boolean = false;
    reset: boolean = true;
}

export class DirectionsMapOptions {
    avoidFerries: boolean = false;
    avoidHighways: boolean = true;
    avoidTolls: boolean = true;
}

export class DirectionsMapData {
    origin: Waypoint = new Waypoint(new Location(0, 0));
    destination: Waypoint = new Waypoint(new Location(0, 0));
    points: Waypoint[] = [];
    markers: Marker[] = [];
    zoom: number = 14;
    status: DirectionsMapStatusData = new DirectionsMapStatusData();
    options: DirectionsMapOptions = new DirectionsMapOptions();
}

// the will keep typescript from throwing errors w.r.t the google object
declare var google: any;

@Directive({
    selector: '[appDirectionsMap]'
})
export class AppDirectionsMap implements OnChanges {

    @Input('status') status: DirectionsMapStatusData;
    @Input('origin') origin: Waypoint;
    @Input('destination') destination: Waypoint;
    @Input('points') points: Waypoint[];
    @Input('options') options: DirectionsMapOptions;
    @Output() eventLoadedMap = new EventEmitter<any>();

    public mapResponseData: any;

    // We'll keep a single google maps directions renderer instance so we get to reuse it.
    // using a new renderer instance every time will leave the previous one still active and visible on the page

    private directionsRenderer: any;

    // We inject AGM's google maps api wrapper that handles the communication with the Google Maps Javascript
    constructor(private gmapsApi: GoogleMapsAPIWrapper) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.status) {
            // this checks if the show directions input changed, if so the directions are removed
            // else we redraw the directions

            if (changes.status.currentValue.load == true) {
                this.drawDirectionsRoute();
            }
            else {
                if (changes.status.currentValue.reset == true) {
                    if (this.directionsRenderer !== undefined) { // check this value is not undefined
                        this.directionsRenderer.setDirections({ routes: [] });
                        return;
                    }
                }
            }
        }
    }


    drawDirectionsRoute() {
        this.gmapsApi.getNativeMap().then((map) => {

            if (!this.directionsRenderer) {
                // if you already have a marker at the coordinate location on the map, use suppressMarkers option
                // suppressMarkers prevents google maps from automatically adding a marker for you
                this.directionsRenderer = new google.maps.DirectionsRenderer({ suppressMarkers: true });
            }

            const directionsRenderer = this.directionsRenderer;
            const directionsService = new google.maps.DirectionsService();

            directionsRenderer.setMap(map);
            directionsRenderer.setPanel(document.getElementById('directionsPanel'));
            directionsService.route({
                origin: this.origin,
                destination: this.destination,
                waypoints: this.points,
                travelMode: 'DRIVING',
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidFerries: this.options.avoidFerries,  // evitar ferries
                avoidHighways: this.options.avoidHighways,  // evitar carreteras principales
                avoidTolls: this.options.avoidTolls  // evitar peajes
            }, (response, status) => {
                if (status === 'OK') {
                    directionsRenderer.setDirections(response);
                    this.mapResponseData = response;
                    this.loadedMap();

                    // If you'll like to display an info window along the route
                    // middleStep is used to estimate the midpoint on the route where the info window will appear
                    // const middleStep = (response.routes[0].legs[0].steps.length / 2).toFixed();
                    // const infowindow2 = new google.maps.InfoWindow();
                    // infowindow2.setContent(`${response.routes[0].legs[0].distance.text} <br> ${response.routes[0].legs[0].duration.text}  `);
                    // infowindow2.setPosition(response.routes[0].legs[0].steps[middleStep].end_location);
                    // infowindow2.open(map);

                } else {
                    myGlobals.showMessage('Directions request failed due to ' + status, myGlobals.MessageType.Error);
                }
            });

        });
    }

    loadedMap() {
        this.status.reset = false;
        this.status.load = false;
        this.eventLoadedMap.emit({ map: this.mapResponseData });
    }
}

export class DocumentArchive {
    id: number = 0;
    status: number = 0
    parentId: number = 0;
    parentType: number = 0;
    typeId: number = 0;
    typeDescription: string = "";
    filename: string = "";
    format: string = "";
    lastFileDate: Date = null;
    archiveDate: Date = null;
    observations: string = "";
    size: number = 0;
    contentBase64: string;
}

export class DocumentType {
    code: number = 0;
    group: string = "";
    subGroup: string = "";
    title: string = "";
}

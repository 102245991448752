import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';
import * as myGlobals from '../globals';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public user: User;
  public authenticate = false;

  constructor(private http: HttpClient) { }

  public authenticateUser(user: User): Observable<any> {
    const body = JSON.stringify(user);
    return this.http.post(myGlobals.RootUrlTikitingApi + 'login/authenticate', body,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        responseType: 'json'
      });
  }

  logOut() {
    localStorage.removeItem('jwt');
    localStorage.removeItem('currentUser');
    this.authenticate = false;
  }

  logIn(user: User) {
    if (user && user.token) {
      this.user = user;
      this.authenticate = true;
      localStorage.setItem('jwt', user.token);
      localStorage.setItem('currentUser', JSON.stringify(user));
      localStorage.setItem('logTime', user.expiredtime.toString());
    }
  }

}

<!-- ventana principal -->

<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-8">
    <h2>{{'page-administracion-clients.title' | translate}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations' | translate}}
      </li>
      <li class="breadcrumb-item">
        {{'page-navigation.operations-administration' | translate}}
      </li>
      <li class="breadcrumb-item active">
        <strong>{{'page-navigation.operations-clients' | translate}}</strong>
      </li>
    </ol>
  </div>

  <div class="col-sm-4 page-description text-right text-muted small">
    <p>{{'page-administracion-clients.title-description' | translate}}</p>
  </div>
</div>

<div class="row p-lg">

  <div class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/oper/admin/clients/relations')">
          <h2>{{'page-administracion-clients-relations.title' | translate}}</h2>
          <p class="m-t-sm">{{'page-administracion-clients-relations.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/oper/admin/clients/boxesbypallet')">
          <h2>{{'page-administracion-clients-boxesbypallet.title' | translate}}</h2>
          <p class="m-t-sm">{{'page-administracion-clients-boxesbypallet.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="ibox">
      <div class="ibox-content product-box">
        <div class="product-desc cursor-pointer" (click)="navigate('/oper/admin/clients/hubs')">
          <h2>{{'page-administracion-clients-hubs.title' | translate}}</h2>
          <p class="m-t-sm">{{'page-administracion-clients-hubs.title-description' | translate}}</p>
        </div>
      </div>
    </div>
  </div>


</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WareGroupingBoxScannedDataInfo } from '../models/warehouse.model';
import { SearchData } from '../models/searches.model';

import * as myGlobals from '../globals';

const URL = myGlobals.RootUrlTikitingApi + 'warehouse/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class WarehouseService {
  constructor(private http: HttpClient) {}

  public getContainerTemplates(data: SearchData) {
    return this.http.post(URL + 'getContainerTemplates', data, httpOptions);
  }

  public getContainerInfo(id: number) {
    return this.http.post(URL + 'getContainerInfo?id=' + id, httpOptions);
  }

  public getActiveContainersInfo(data: SearchData) {
    return this.http.post(URL + 'getActiveContainersInfo', data, httpOptions);
  }

  public getContainersInfo(data: SearchData) {
    return this.http.post(URL + 'getContainersInfo', data, httpOptions);
  }

  public createContainerByTemplate(id: number) {
    return this.http.post(
      URL + 'createContainerByTemplate?id=' + id,
      httpOptions
    );
  }

  public deleteContainer(id: number) {
    return this.http.post(URL + 'deleteContainer?id=' + id, httpOptions);
  }

  public openContainer(id: number) {
    return this.http.post(URL + 'openContainer?id=' + id, httpOptions);
  }

  public closeContainer(id: number) {
    return this.http.post(URL + 'closeContainer?id=' + id, httpOptions);
  }

  public lockedContainer(id: number) {
    return this.http.post(URL + 'lockedContainer?id=' + id, httpOptions);
  }

  public getContainerLabel(id: number) {
    return this.http.post(URL + 'getContainerLabel', id, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      responseType: 'blob' as 'json',
    });
  }

  public addContainerBox(data: WareGroupingBoxScannedDataInfo) {
    return this.http.post(URL + 'addContainerBox', data, httpOptions);
  }
  public removeContainerBox(data: WareGroupingBoxScannedDataInfo) {
    return this.http.post(URL + 'removeContainerBox', data, httpOptions);
  }

  public getContainerBoxList(id: number) {
    return this.http.post(URL + 'getContainerBoxList?id=' + id, httpOptions);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SearchData } from './../models/searches.model';
import * as myGlobals from '../globals';
import { UserInfo, UserChangePasswordData } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  public GetUsersList(searchData: SearchData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'UsersManagement/GetUserList', searchData,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public UserSetEnable(idUser: number) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'UsersManagement/UserSetEnable?idUser=' + idUser,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public UserSetDisable(idUser: number) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'UsersManagement/UserSetDisable?idUser=' + idUser,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }


  public GetUserData(idUser: number) {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'UsersManagement/GetUserData?idUser=' + idUser,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      });
  }

  public SaveUserData(user: UserInfo) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'UsersManagement/SaveUserData', user,
      {
        headers: new HttpHeaders()
      });
  }

  public UserExist(username: string) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'UsersManagement/UserExist?username=' + username,
      {
        headers: new HttpHeaders()
      });
  }

  public GetUserRolEditor(idUser: number) {
    return this.http.get(myGlobals.RootUrlTikitingApi + 'UsersManagement/GetUserRolEditor?idUser=' + idUser,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  public ChangePassword(data: UserChangePasswordData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'UsersManagement/ChangePassword', data,
      {
        headers: new HttpHeaders()
      });
  }

  public SetPassword(data: UserChangePasswordData) {
    return this.http.post(myGlobals.RootUrlTikitingApi + 'UsersManagement/SetPassword', data,
      {
        headers: new HttpHeaders()
      });
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouterEvent } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Roles } from './enums/roles.enum';
import { from } from 'rxjs';
import { AuthGuard } from './helpers/auth-guard.service';
import { PageNotfoundComponent } from './components/shared/page-notfound/page-notfound.component';
import { PageForbiddenComponent } from './components/shared/page-forbidden/page-forbidden.component';
import { PageHomeComponent } from './components/shared/page-home/page-home.component';
import { PageLoginComponent } from './components/shared/page-login/page-login.component';
import { PageChangepasswordComponent } from './components/shared/page-changepassword/page-changepassword.component';

// Tracking pages
import { Status96Component } from './components/modulo-tracking/status96/status96.component';
import { StatusmappingComponent } from './components/modulo-tracking/statusmapping/statusmapping.component';
import { GetstatusesmanuallyComponent } from './components/modulo-tracking/getstatusesmanually/getstatusesmanually.component';

// Clients pages
import { AmazonInvoicesComponent } from './components/modulo-clients/amazon-invoices/amazon-invoices.component';

// Customs pages
import { AdministracionHscodesComponent } from './components/modulo-customs/administracion-hscodes/administracion-hscodes.component';
import { DocumentsComponent } from './components/modulo-customs/documents/documents.component';

// Brexit pages
import { BrexitComponent } from './components/modulo-brexit/brexit/brexit.component';
import { BrexitBrunelComponent } from './components/modulo-brexit/brexit-brunel/brexit-brunel.component';
import { BrexitLlorenteComponent } from './components/modulo-brexit/brexit-llorente/brexit-llorente.component';

// Users pages
import { UserInfoComponent } from './components/modulo-usuarios/user-info/user-info.component';
import { UsersManagementComponent } from './components/modulo-usuarios/users-management/users-management.component';

// Operaciones pages
import { AdministracionHubsComponent } from './components/modulo-rutas/administracion-hubs/administracion-hubs.component';
import { ServiciosTerrestresListComponent } from './components/modulo-rutas/servicios-terrestres-list/servicios-terrestres-list.component';
import { ServiciosTerrestresEditComponent } from './components/modulo-rutas/servicios-terrestres-edit/servicios-terrestres-edit.component';
import { AdministracionRutasComponent } from './components/modulo-rutas/administracion-rutas/administracion-rutas.component';
import { AdministracionClientesComponent } from './components/modulo-rutas/administracion-clientes/administracion-clientes.component';
import { AdministracionClientesBoxesbypalletComponent } from './components/modulo-rutas/administracion-clientes-boxesbypallet/administracion-clientes-boxesbypallet.component';
import { AdministracionClientesRelationsComponent } from './components/modulo-rutas/administracion-clientes-relations/administracion-clientes-relations.component';
import { AdministracionClientesHubsComponent } from './components/modulo-rutas/administracion-clientes-hubs/administracion-clientes-hubs.component';

// Reporting pages
import { QueriesListComponent } from './components/modulo-reporting/queries-list/queries-list.component';
import { QueriesExecuteComponent } from './components/modulo-reporting/queries-execute/queries-execute.component';

// Tasks pages
import { tasksComponent } from './components/modulo-tasks/tasks/tasks.component';
import { TasksLinesComponent } from './components/modulo-tasks/tasklines/tasks-lines/tasks-lines.component';

// WareHouse
import { WareHouseContainersComponent } from './components/modulo-warehouse/containers/containers.component';
import { WareHouseBoxescontainerComponent } from './components/modulo-warehouse/boxescontainer/boxescontainer.component';
//import { TasksLinesComponent } from './components/modulo-tasks/tasklines/tasks-lines/tasks-lines.component';

const routes: Routes = [
  // WareHouse

  {
    path: 'warehouse/boxescontainer',
    component: WareHouseBoxescontainerComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.WarehousePda] },
  },
  {
    path: 'warehouse/containers',
    component: WareHouseContainersComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Warehouse] },
  },

  // Tracking pages
  {
    path: 'tracking/status96',
    component: Status96Component,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Tracking] },
  },
  {
    path: 'tracking/statusmapping',
    component: StatusmappingComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Tracking] },
  },
  {
    path: 'tracking/getstatuses',
    component: GetstatusesmanuallyComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Tracking] },
  },

  // Clients pages
  {
    path: 'clients/amazoninvoices',
    component: AmazonInvoicesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.AmazonInvoices] },
  },

  // Reporting pages
  {
    path: 'repo/queries',
    component: QueriesListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Reporting] },
  },
  {
    path: 'repo/query/:id',
    component: QueriesExecuteComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Reporting] },
  },

  // Brexit pages
  {
    path: 'customs/brexit',
    component: BrexitComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Brexit] },
  },
  {
    path: 'brexit/llorente',
    component: BrexitLlorenteComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.BrexitLlorente] },
  },
  {
    path: 'brexit/brunel',
    component: BrexitBrunelComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.BrexitBrunel] },
  },

  // Operaciones - Rutas
  {
    path: 'oper/serv/terrestre',
    component: ServiciosTerrestresListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Operaciones] },
  },
  {
    path: 'oper/serv/terrestre/:id',
    component: ServiciosTerrestresEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Operaciones] },
  },
  {
    path: 'oper/admin/hubs',
    component: AdministracionHubsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Operaciones] },
  },
  {
    path: 'oper/admin/routes',
    component: AdministracionRutasComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Operaciones] },
  },
  {
    path: 'oper/admin/clients',
    component: AdministracionClientesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Operaciones] },
  },
  {
    path: 'oper/admin/clients/relations',
    component: AdministracionClientesRelationsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Operaciones] },
  },
  {
    path: 'oper/admin/clients/boxesbypallet',
    component: AdministracionClientesBoxesbypalletComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Operaciones] },
  },
  {
    path: 'oper/admin/clients/hubs',
    component: AdministracionClientesHubsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Operaciones] },
  },

  // Customs pages
  {
    path: 'customs/admin/hscodes',
    component: AdministracionHscodesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Aduanas] },
  },
  {
    path: 'customs/documents',
    component: DocumentsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Aduanas] },
  },

  // users pages
  {
    path: 'users/info',
    component: UserInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'users/changepwd',
    component: PageChangepasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'users/management',
    component: UsersManagementComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin] },
  },

  //tasks pages
  {
    path: 'tasks',
    component: tasksComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Tasks] },
  },
  {
    path: 'tasks/:id',
    component: TasksLinesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Tasks] },
  },

  // general pages
  {
    path: 'forbidden',
    component: PageForbiddenComponent,
    canActivate: [AuthGuard],
  },

  //{ path: 'home', component: PageHomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: PageLoginComponent },
  //{ path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  { path: '', component: PageHomeComponent, canActivate: [AuthGuard] },
  { path: '**', component: PageNotfoundComponent },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Roles } from '../../../enums/roles.enum';
import { TerrestrialService, TerrestrialServiceSearchData, TerrestrialServiceStatus } from '../../../models/terrestrial-services.model';
import { TerrestrialServices } from '../../../services/terrestrial-services.service'
import { RoutesService } from '../../../services/routes.service';
import { Provider } from '../../../models/providers.model';
import { Select2OptionData } from 'ng-select2';
import * as myGlobals from '../../../globals';

declare var $: any;


@Component({
  selector: 'app-servicios-terrestres-list',
  templateUrl: './servicios-terrestres-list.component.html',
  styleUrls: ['./servicios-terrestres-list.component.css']
})
export class ServiciosTerrestresListComponent implements OnInit {

  servicesList: TerrestrialService[] = [];
  servicesListFirst = 0;
  loadingPage: boolean = false;
  searchData: TerrestrialServiceSearchData = new TerrestrialServiceSearchData();
  searchData_dateFrom: string;
  isAdmin: boolean = false;

  listProviders: Array<Select2OptionData>;
  listStatus: Array<Select2OptionData>;
  selectDataProviders: string[] = [];
  selectDataStatus: string[] = [];

  select2MultiselectOptions = {
    multiple: true,
    tags: true
  };

  constructor(private router: Router, private terrestrialServices: TerrestrialServices, private routesService: RoutesService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    let user = myGlobals.userGetData();

    this.searchData.showStatusSearch = false;
    if (myGlobals.userHasRoles([Roles.Admin, Roles.OperacionesAdmin], user)) this.searchData.showStatusSearch = true;
    if (myGlobals.userHasRoles([Roles.Admin], user)) this.isAdmin = true;

    let date = new Date(Date.now());
    this.searchData_dateFrom = this.datePipe.transform(new Date(date.setMonth(date.getMonth() - 2)), "yyyy-MM-dd");

    this.loadingPage = true;
    this.getStatusList();
    this.getProvidersList();
    this.getServicesList();
  }

  getStatusList() {
    this.terrestrialServices.GetStatusList()
      .subscribe((data: any) => {
        this.listStatus = Array.from(data.body.map((x: TerrestrialServiceStatus) => ({ id: x.id, text: x.description })));
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }

  getProvidersList() {
    this.terrestrialServices.GetProvidersList()
      .subscribe((data: any) => {
        this.listProviders = Array.from(data.body.map((x: Provider) => ({ id: x.idProveedor, text: x.empresa })));
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });
  }


  getServicesList() {
    this.terrestrialServicesManagementSpinner();
    this.searchData.dateFrom = new Date(this.searchData_dateFrom);
    this.searchData.statusIds = this.selectDataStatus.join(" ");
    this.searchData.proveedorIds = this.selectDataProviders.join(" ");

    this.terrestrialServices.GetTerrestrialServicesList(this.searchData)
      .subscribe((data: any) => {
        this.servicesList = data.body;
        this.servicesListFirst = 0;
        this.terrestrialServicesManagementSpinner();
      }
        , (error: string) => {
          myGlobals.showMessage(error, myGlobals.MessageType.Error);
        });

    if (this.loadingPage) {
      this.loadingPage = false;
      this.terrestrialServicesManagementSpinner();
    }
  }
/*
  newService() {
    this.router.navigateByUrl('/oper/serv/terrestre/0');
  }

  editService(id: number) {
    this.router.navigateByUrl('/oper/serv/terrestre/' + id);
  }
*/
  deleteService(id: number) {
    var r = confirm("Are you sure you want to delete the item?");
    if (r == true) {
      this.terrestrialServices.DeleteTerrestrialService(id)
        .subscribe(() => {
          this.getServicesList();
        }
          , (error: string) => {
            myGlobals.showMessage(error, myGlobals.MessageType.Error);
          });
    }
  }


  terrestrialServicesManagementSpinner() {
    $('#terrestrialServicesManagement').children('.ibox-content').toggleClass('sk-loading');
  }

  exportExcel() {
    myGlobals.exportExcel(this.servicesList);
  }


}

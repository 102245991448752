<div class="row border-bottom">
  <nav
    class="navbar navbar-static-top"
    role="navigation"
    style="margin-bottom: 0"
  >
    <div class="navbar-header">
      <button
        type="button"
        class="navbar-minimalize minimalize-styl-2 btn btn-default"
        (click)="toggleSidebar()"
      >
        <i class="fa fa-bars"></i>
      </button>

      <!--
      <a class="navbar-minimalize minimalize-styl-2 btn btn-default" href="#"
        ><i class="fa fa-bars"></i>
      </a>
      -->

      <!--
      <form role="search" class="navbar-form-custom" action="https://chuibility.github.io/inspinia/search_results.html">
        <div class="form-group">
          <input type="text" placeholder="Search for something..." class="form-control" name="top-search"
            id="top-search">
        </div>
      </form>
      -->
    </div>
    <ul class="nav navbar-top-links navbar-right">
      <li class="dropdown m-r">
        <a
          href="#"
          class="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          ><span class="font-normal">{{ fullname }} </span>

          <img
            alt="image"
            class="rounded-circle img-sm m-l-xs"
            src="../../../../assets/images/blank-user.jpg"
            alt="profile-picture"
          />
          <span class="caret"></span>
        </a>
        <ul class="dropdown-menu" style="margin-top: 14px">
          <li>
            <a routerLink="/users/info">{{ "users.user-info" | translate }}</a>
          </li>
          <li>
            <a routerLink="/users/changepwd">{{
              "users.user-changepwd" | translate
            }}</a>
          </li>
          <li *ngIf="isAdmin" class="dropdown-divider"></li>
          <li *ngIf="isAdmin">
            <a routerLink="/users/management">{{
              "users.user-management" | translate
            }}</a>
          </li>
          <li class="dropdown-divider"></li>
          <li>
            <a href="javascript:void(0);" (click)="logout()">{{
              "users.logout" | translate
            }}</a>
          </li>
        </ul>
      </li>

      <li
        class="dropdown navbar-top-links-item"
        title="{{ 'general.language-change' | translate }}"
      >
        <a
          href="#"
          class="dropdown-toggle custom-menu-item"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          ><i class="fa fa-lg fa-globe" aria-hidden="true"></i
        ></a>
        <ul class="dropdown-menu">
          <li>
            <a href="javascript:void(0);" (click)="setLanguage('es')"
              >Español</a
            >
          </li>
          <li>
            <a href="javascript:void(0);" (click)="setLanguage('en')"
              >English</a
            >
          </li>
        </ul>
      </li>

      <li *ngIf="showDebug" class="navbar-top-links-item">
        <a class="right-sidebar-toggle">
          <i class="fa fa-tasks"></i>
        </a>
      </li>
    </ul>
  </nav>
</div>
